import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

function RadioOption(props) {
  const useStyles = makeStyles((theme) => ({
    radioBorder: {
      ...theme.overrides.MuiOutlinedInput.root,
      "&:before, &:after": {},
      border: "2px solid rgba(255, 255, 255, 0.23)",
      padding: "6px",
      marginLeft: "0px",
      marginRight: "0px",
      width: "100%",
      borderColor: props.error
        ? theme.palette.error.main
        : props.selected === props.value
        ? theme.palette.primary.main
        : "rgba(255, 255, 255, 0.23)",
    },
    errorRadio: {
      color: theme.palette.error.main,
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={props.className}
      onMouseUp={(e) => {
        if (!props?.onMouseUp) return;

        if (props.selected === props.value) props.onMouseUp(e);
        else props.setMouseUp(true);
      }}
      onClick={props.onClick}
      {...(props.style ? { style: props.style } : {})}
    >
      <FormControlLabel
        value={props.value}
        label={props.label}
        className={classes.radioBorder}
        control={
          <Radio
            color={!props.error ? "primary" : "default"}
            className={props.error ? classes.errorRadio : ""}
            checked={props.selected === props.value}
          />
        }
      />
    </div>
  );
}

export default RadioOption;
