import axios from "axios";
import setAxiosDefaultSettings from "../../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const emailForm = async (emailData, handleErrors) => {
  const formData = new FormData();
  Object.keys(emailData).forEach((field) => formData.append(field, emailData[field]));

  return await sendEmail(axios.post, formData, handleErrors);
};

const sendEmail = async (axiosMethod, emailData, handleErrors = null) => {
  let emailSent = false;
  let responseError = null;

  await axiosMethod(
    "https://testsetup.ml/wp-content/themes/oceanwp-child/templates/temporary-email-sender.php/",
    emailData
  )
    .then(async (response) => {
      if (response.config?.method === "delete") emailSent = true;
      else if (response.data) emailSent = response.data;
      else {
        responseError = response.data?.error || "Failed to send email";
        console.log("No email", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect email Information";
    });

  if (handleErrors) handleErrors(responseError);

  return emailSent;
};
