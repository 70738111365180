import axios from "axios";
import setAxiosDefaultSettings from "../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const getConfig = async () => {
  return await axios
    .get(`/v2/billing/config?timestamp=${new Date().getTime()}`)
    .then((response) => response.data)
    .catch(() => false);
};

export const getSubscriptionList = async (subscriptionData) => {
  return await axios
    .get(`/v2/billing/get-subscription-list?timestamp=${new Date().getTime()}`, {
      params: subscriptionData,
    })
    .then((response) => response.data)
    .catch(() => false);
};

export const getSubscription = async (subscriptionData) => {
  return await axios
    .get(`/v2/billing/get-subscription?timestamp=${new Date().getTime()}`, {
      params: subscriptionData,
    })
    .then((response) => response.data)
    .catch(() => false);
};

export const createSubscription = async (subscriptionData) => {
  return await axios
    .post("/v2/billing/create-subscription", subscriptionData)
    .then((response) => response.data)
    .catch(() => false);
};

export const deleteSubscription = async (subscriptionData) => {
  return await axios
    .delete("/v2/billing/delete-subscription", {
      data: subscriptionData,
    })
    .then((response) => response.data)
    .catch(() => false);
};

export const createPaymentIntent = async (paymentIntentData) => {
  return await axios
    .post("/v2/billing/create-payment-intent", paymentIntentData)
    .then((response) => response.data)
    .catch(() => false);
};

export const getCustomer = async (customerData = null) => {
  return await axios
    .get(`/v2/billing/get-customer?timestamp=${new Date().getTime()}`, {
      params: customerData,
    })
    .then((response) => response.data)
    .catch(() => false);
};

export const hasPaidSubscription = async () => {
  const customer = await getCustomer();
  if (customer?.subscriptionID && customer?.status === "paid") return true;
  return false;
};

export const getPaymentMethod = async (paymentMethodData) => {
  return await axios
    .get(`/v2/billing/get-payment-method?timestamp=${new Date().getTime()}`, {
      params: paymentMethodData,
    })
    .then((response) => response.data)
    .catch(() => false);
};

export const updatePaymentMethod = async (paymentMethodData) => {
  return await axios
    .post(`/v2/billing/update-payment-method?timestamp=${new Date().getTime()}`, paymentMethodData)
    .then((response) => response.data)
    .catch(() => false);
};
