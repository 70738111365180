import { useState } from "react";
import CombInput from "./CombInput";

function NumberCombInput({ value, onChange, ...other }) {
  const [styledValue, setStyledValue] = useState(styleValue(value));

  return (
    <CombInput
      {...other}
      value={styledValue}
      onChange={(newValue) => {
        onChange(newValue);
        setStyledValue(styleValue(newValue));
      }}
    />
  );
}

const styleValue = (value) => {
  if (!value) return value;
  let newValue = value.slice(0, 1).replaceAll(/[^\d-]/g, "") + value.slice(1, value.length).replaceAll(/[^\d]/g, "");
  if (newValue.length > 2)
    newValue = newValue.slice(0, newValue.length - 2) + " " + newValue.slice(newValue.length - 2, 11);
  return newValue;
};

export default NumberCombInput;
