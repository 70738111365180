import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setClonedUser } from "../../redux/actions/authenticationActions";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UserIcon from "@material-ui/icons/Person";

import Header from "./components/Header";
import PropertyCheckout from "./PropertyCheckout";
import Properties from "./Properties";
import Property from "./property";
import Settings from "./Settings";
import Home from "./Home";

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    alignItems: "center",
    flexDirection: "column",
    [`${theme.breakpoints.down("xs")}`]: {
      paddingBottom: "70px",
    },
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
    minHeight: "75px",
  },
  clonedUserCard: {
    marginLeft: "5px",
    marginBottom: "5px",
    width: "290px",
    height: "45px",
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "1em",
    paddingTop: "12px",
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

function AppRouter(props) {
  useEffect(() => {
    const clonedUser = JSON.parse(getCookieValue("clonedUser") || "{}");
    if (clonedUser?.id) props.dispatch(setClonedUser(clonedUser));
  }, []);

  const classes = useStyles();
  const location = useLocation();

  return (
    <div>
      {!location.pathname.includes("/properties/checkout") && <Header />}
      <div
        id="appBarSpacer"
        className={classes.appBarSpacer}
        {...(location.pathname.includes("/forms/viewer") ? { style: { minHeight: "64px" } } : {})}
      />
      <Grid container directon="column" className={classes.main}>
        <Grid item container spacing={2} xs={12} md={10}>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/settings" component={Settings} />
            <Route path="/properties/checkout" component={PropertyCheckout} />
            <Route path="/properties" component={Properties} />
            <Route path="/:property_id" component={Property} />
          </Switch>
        </Grid>
      </Grid>
      {props.clonedUser && (
        <Button
          className={classes.clonedUserCard}
          variant="contained"
          color="primary"
          disabled
          disableElevation
          disableFocusRipple
          disableRipple
        >
          {props.clonedUser.firstName} {props.clonedUser.lastName}
          <UserIcon style={{ marginLeft: "5px", marginBottom: "2px" }} />
        </Button>
      )}
    </div>
  );
}

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const mapState = (state) => {
  return {
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(AppRouter);
