import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { logout } from "../../services/authenticationServices";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/EditRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Collapse from "@material-ui/core/Collapse";

import { getToken, addToken, deleteToken } from "../../utils/notification.jsx";
import ToastFactory from "../components/ToastFactory";
import EditProfileDoubleDialog from "./components/EditProfileDoubleDialog";
import { getUser, getSignature } from "../../services/userServices.js";
import { getCustomer, getPaymentMethod, getSubscriptionList } from "../../services/billingServices.js";
import { getBuilding } from "../../services/buildingServices.js";
import moment from "moment";

import AmexLogo from "../../assets/logos/amex.png";
import DinersClubLogo from "../../assets/logos/dinersclub.png";
import DiscoverLogo from "../../assets/logos/discover.png";
import JCBLogo from "../../assets/logos/jcb.png";
import MasterCardLogo from "../../assets/logos/mastercard.png";
import UnionPayLogo from "../../assets/logos/unionpay.png";
import VisaLogo from "../../assets/logos/visa.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  profileTitle: {
    margin: theme.spacing(3, 0, 3),
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "10px",
  },
  header: {
    marginTop: theme.spacing(4),
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  logoutButtonContainer: {
    padding: theme.spacing(4, 0, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 0, 0),
    },
  },
  logoutButton: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
    minWidth: "350px",
    minHeight: "60px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "225px",
      minHeight: "40px",
    },
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  profileItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 28px",
    marginBottom: "12px",
    cursor: "pointer",
  },
  profileField: {
    fontSize: "1.3em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.8em",
      marginLeft: "-15px",
    },
  },
  profileValue: {
    color: "rgba(255, 255, 255, 0.4)",
    fontSize: "1.3em",
    maxWidth: "50vw",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.8em",
      maxWidth: "55vw",
    },
  },
  editButton: {
    marginLeft: "20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "inherit",
      marginLeft: "10px",
      marginRight: "-15px",
    },
  },
  profileRow: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "0",
    },
    "& > h6": {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "12px 0px",
    },
  },
  profileRowSwitch: {
    marginTop: "6px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginRight: "-15px",
      marginTop: "2px",
    },
  },
  notificationDevicesCount: {
    color: theme.palette.text.disabled,
    marginLeft: "10px",
  },
  switchRoot: {
    width: 28,
    height: 16,
    [theme.breakpoints.up("sm")]: {
      width: 42,
      height: 22,
    },
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$switchChecked": {
      transform: "translateX(12px)",
      [theme.breakpoints.up("sm")]: {
        transform: "translateX(19px)",
      },
      color: theme.palette.common.white,
      "& + $switchTrack": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  switchThumb: {
    width: 12,
    height: 12,
    [theme.breakpoints.up("sm")]: {
      width: 18,
      height: 18,
    },
    boxShadow: "none",
  },
  switchTrack: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 24 / 2,
    },
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  switchChecked: {},
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  subscriptionCost: {
    marginLeft: "40px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginLeft: "0px",
    },
  },
  renewalDate: {
    [`${theme.breakpoints.down("xs")}`]: {
      marginRight: "-15px",
    },
  },
  expirationDate: {
    color: theme.palette.text.disabled,
    marginLeft: "10px",
  },
  cardNumber: {
    [`${theme.breakpoints.up("sm")}`]: {
      marginLeft: "10px",
    },
  },
  cardBrand: {
    [`${theme.breakpoints.up("sm")}`]: {
      display: "none",
    },
    marginRight: "6px",
  },
  cardLogo: {
    [`${theme.breakpoints.down("xs")}`]: {
      display: "none",
    },
  },
  amexLogo: {
    width: "9.5%",
    borderRadius: "6px",
  },
  dinersLogo: {
    width: "8%",
    borderRadius: "6px",
  },
  discoverLogo: {
    width: "13%",
    borderRadius: "6px",
  },
  jcbLogo: {
    width: "6%",
  },
  mastercardLogo: {
    width: "11%",
  },
  unionpayLogo: {
    width: "8%",
  },
  visaLogo: {
    width: "9%",
    borderRadius: "6px",
  },
  moreBtn: {
    paddingLeft: "26px",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $moreArrow": {
        transform: "translateY(2px)",
      },
      "& $lessArrow": {
        transform: "translateY(-2px)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  moreArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  lessArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  freeProperty: {
    display: "inline-block",
    width: "180px",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "80px",
    },
  },
}));

const profileFieldNames = {
  email: "Email",
  firstName: "First Name",
  lastName: "Last Name",
  address: "Address",
  dayPhone: "Day Phone",
  eveningPhone: "Evening Phone",
  fax: "Fax",
  companyName: "Company Name",
};

const cardLogos = {
  amex: AmexLogo,
  diners: DinersClubLogo,
  discover: DiscoverLogo,
  jcb: JCBLogo,
  mastercard: MasterCardLogo,
  unionpay: UnionPayLogo,
  visa: VisaLogo,
  unknown: null,
};

function Settings(props) {
  const [editProfileDialog, setEditProfileDialog] = useState(false);
  const [profile, setProfile] = useState([]);
  const [security, setSecurity] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [propertyPlans, setPropertyPlans] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodSubscriptions, setPaymentMethodSubscriptions] = useState({});
  const [showMoreBillingInfo, setShowMoreBillingInfo] = useState(false);
  const [deviceToken, setDeviceToken] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [disableNotisDialog, setDisableNotisDialog] = useState(false);

  const updateUser = async () => {
    const userReceived = await getUser(null, {
      admin: props.clonedUser?.id ? true : null,
      clonedUserID: props.clonedUser?.id,
    });
    console.log("userReceived: ", userReceived);
    if (userReceived) {
      delete userReceived.ID;
      delete userReceived.tips;
      delete userReceived.isAdmin;

      if (userReceived.signature) userReceived.signature = await getSignature(props.clonedUser?.id);

      let profileInfo = [];
      let securityInfo = [];
      let additionalInfo = [];
      let firstName, lastName, field;

      for (field in userReceived) {
        if (field === "address") {
          let address = userReceived.address.split(", ");
          let unitNum = address[4];
          let addressData = {
            streetNum: address[0],
            streetName: address[1],
            streetType: address[2],
            direction: address[3],
            municipality: address[5],
            province: address[6],
            postalCode: address[7],
          };

          let direction = address[3] ? " " + address[3] : "";
          let unit = address[4] ? "#" + address[4] + " " : "";
          address =
            address[0] +
            " " +
            address[1] +
            " " +
            address[2] +
            direction +
            ", " +
            address[5] +
            ", " +
            address[6] +
            " " +
            address[7];

          profileInfo.push({
            key: "address",
            field: "Address",
            value: unit + address,
            address: address,
            unitNum: unitNum,
            addressData: addressData,
          });
        } else if (field === "firstName") firstName = userReceived[field];
        else if (field === "lastName") lastName = userReceived[field];
        else if (field === "email")
          securityInfo.push({
            key: field,
            field: profileFieldNames[field],
            value: userReceived[field],
          });
        else if (field === "deviceToken") {
          additionalInfo.push({
            key: "notifications",
            field: "Notifications",
            value: userReceived[field] ? userReceived[field].split(", ") : [],
          });
          if (Notification.permission === "granted" && userReceived[field]?.includes(await getToken()))
            setDeviceToken(true);
        } else if (field === "signature") {
          additionalInfo.push({
            key: "signature",
            field: "Signature",
            value: null,
            signature: userReceived[field],
          });
        } else if (profileFieldNames.hasOwnProperty(field))
          profileInfo.push({
            key: field,
            field: profileFieldNames[field],
            value: userReceived[field],
          });
      }

      profileInfo.unshift({
        key: "name",
        field: "Name",
        value: firstName + " " + lastName,
        firstName: firstName,
        lastName: lastName,
      });
      setProfile(profileInfo);

      securityInfo.push({
        key: "password",
        field: "Password",
        value: null,
      });
      setSecurity(securityInfo);

      setAdditional(additionalInfo);
    }
  };

  const updateCustomer = async () => {
    const customerReceived = await getCustomer({ clonedUserID: props.clonedUser?.id });
    console.log("customerReceived: ", customerReceived);
    if (customerReceived) {
      setCustomer(customerReceived);
      const paymentMethodReceived = await getPaymentMethod({ paymentMethodID: customerReceived.paymentMethodID });
      console.log("paymentMethodReceived: ", paymentMethodReceived);
      if (paymentMethodReceived) setDefaultPaymentMethod(paymentMethodReceived);
    }
  };

  useEffect(() => {
    updateUser();
    updateCustomer();
  }, []);

  const createEditProfileDialog = (profileData) => {
    let fields;
    if (profileData.key === "notifications") return;
    if (profileData.key === "name")
      fields = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
      };
    else if (profileData.key === "address")
      fields = {
        unitNum: profileData.unitNum,
        address: profileData.address,
        addressData: profileData.addressData,
      };
    else if (profileData.key === "signature")
      fields = {
        signature: profileData.signature,
      };
    else if (profileData.key === "paymentMethod")
      fields = {
        customer: profileData.customer,
        cardholderName: profileData.cardholderName,
        oldPaymentMethodID: profileData.oldPaymentMethodID,
        subscriptionList: profileData.subscriptionList,
      };
    else fields = { [profileData.key]: profileData.value };

    setEditProfileDialog({
      form: profileData.key,
      fields: fields,
    });
  };

  const createPaymentMethodCard = (paymentMethod) => {
    return (
      <Card
        className={`${classes.infoCard} ${classes.profileItem}`}
        onClick={() =>
          createEditProfileDialog({
            key: "paymentMethod",
            field: "Payment Method",
            value: null,
            customer: customer,
            cardholderName: paymentMethod?.billing_details.name,
            oldPaymentMethodID: paymentMethod?.id,
            subscriptionList: paymentMethodSubscriptions[paymentMethod?.id],
          })
        }
      >
        <CardContent className={classes.profileRow}>
          {paymentMethod && paymentMethod.card.brand !== "unknown" && (
            <img
              src={cardLogos[paymentMethod.card.brand]}
              className={`${classes.cardLogo} ${classes[`${paymentMethod.card.brand}Logo`]}`}
              alt="Card Logo"
            />
          )}
          <Typography variant="h6" gutterBottom className={classes.profileField}>
            {paymentMethod ? (
              <>
                <span className={classes.cardNumber}>
                  <b className={classes.cardBrand}>
                    {paymentMethod.card.brand !== "unknown" &&
                      paymentMethod.card.brand?.charAt(0).toUpperCase() + paymentMethod.card.brand.slice(1)}
                  </b>
                  <b>{`•••• ${paymentMethod.card.last4}`}</b>
                </span>
                <span style={{ whiteSpace: "nowrap" }} className={classes.expirationDate}>
                  Expires{" "}
                  {`${paymentMethod.card.exp_month < 10 ? 0 : ""}${paymentMethod.card.exp_month}/${
                    paymentMethod.card.exp_year
                  }`}
                </span>
              </>
            ) : (
              "Payment Method"
            )}
          </Typography>
        </CardContent>
        <CardContent className={classes.profileRow}>
          <Grid container>
            {paymentMethod?.id === customer?.paymentMethodID && (
              <Typography variant="h6" align="right" noWrap className={classes.profileValue}>
                Default
              </Typography>
            )}
            <EditIcon className={classes.editButton} />
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const updateBillingInfo = async () => {
    let buildingsReceived = await getBuilding({
      nestProperties: true,
      nestLeases: true,
      nestActiveLeases: true,
    });
    setBuildings(buildingsReceived);
    console.log(buildingsReceived);

    const subscriptionList = await getSubscriptionList({ customerID: customer.ID });

    let subscriptions = {};
    let tempPaymentMethods = { [defaultPaymentMethod.id]: defaultPaymentMethod };
    let tempPaymentMethodSubscriptions = {};

    for (let subscription of subscriptionList) {
      let propertyID = subscription?.metadata?.propertyID;
      if (propertyID) {
        subscriptions[propertyID] = subscription;
        let paymentMethodID = subscription.default_payment_method
          ? subscription.default_payment_method
          : defaultPaymentMethod.id;
        if (!tempPaymentMethodSubscriptions[paymentMethodID]) tempPaymentMethodSubscriptions[paymentMethodID] = [];
        tempPaymentMethodSubscriptions[paymentMethodID].push(subscription.id);
        if (!tempPaymentMethods[paymentMethodID]) {
          const paymentMethodReceived = await getPaymentMethod({ paymentMethodID: paymentMethodID });
          tempPaymentMethods[paymentMethodID] = paymentMethodReceived;
        }
      }
    }

    setPropertyPlans(subscriptions);
    setPaymentMethods(Object.values(tempPaymentMethods));
    setPaymentMethodSubscriptions(tempPaymentMethodSubscriptions);
  };

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const handleLogout = () => logout(props.dispatch);

  const classes = useStyles();

  const profileContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.profileTitle} variant="h5" align="center">
          My Profile
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {[...profile].map((profileData) => {
          return (
            <Card
              key={profileData.key}
              className={`${classes.infoCard} ${classes.profileItem}`}
              onClick={() => createEditProfileDialog(profileData)}
            >
              <CardContent className={classes.profileRow}>
                <Typography variant="h6" className={classes.profileField}>
                  {profileData.field}
                </Typography>
              </CardContent>

              <CardContent className={classes.profileRow}>
                <Grid container>
                  <Typography variant="h6" align="right" noWrap className={classes.profileValue}>
                    {profileData.value}
                  </Typography>
                  <EditIcon className={classes.editButton} />
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </>
  );

  const securityContent = (
    <>
      <Grid item xs={12} style={{ padding: "8px 0px" }}>
        <Typography className={classes.profileTitle} variant="h5" align="center">
          Security
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {[...security].map((securityData) => {
          return (
            <Card
              key={securityData.key}
              className={`${classes.infoCard} ${classes.profileItem}`}
              onClick={() => createEditProfileDialog(securityData)}
            >
              <CardContent className={classes.profileRow}>
                <Typography variant="h6" className={classes.profileField}>
                  {securityData.field}
                </Typography>
              </CardContent>

              <CardContent className={classes.profileRow}>
                <Grid container>
                  <Typography variant="h6" align="right" noWrap className={classes.profileValue}>
                    {securityData.value}
                  </Typography>
                  <EditIcon className={classes.editButton} />
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </>
  );

  const billingContent = (
    <>
      <Grid item xs={12} style={{ padding: "8px 0px" }}>
        <Typography className={classes.profileTitle} variant="h5" align="center">
          Billing
        </Typography>
      </Grid>
      {createPaymentMethodCard(defaultPaymentMethod)}
      <Card style={{ cursor: "default" }} className={`${classes.infoCard} ${classes.profileItem}`}>
        <CardContent className={classes.profileRow}>
          <Typography variant="h6" className={classes.profileField}>
            Simple Plan
          </Typography>
        </CardContent>

        <CardContent className={classes.profileRow}>
          <Typography variant="h6" className={`${classes.profileField} ${classes.subscriptionCost}`}>
            $100 / month
          </Typography>
        </CardContent>

        <CardContent className={classes.profileRow}>
          <Grid container>
            <Typography variant="h6" align="right" noWrap className={`${classes.profileValue} ${classes.renewalDate}`}>
              Renews {customer && moment(customer.currentPeriodEnd).format("MM/DD/YYYY")}
            </Typography>
          </Grid>
        </CardContent>
      </Card>

      <div style={{ textAlign: "center" }}>
        <Button
          className={classes.moreBtn}
          variant="contained"
          onClick={async () => {
            if (buildings.length === 0) await updateBillingInfo();
            setShowMoreBillingInfo(true);
          }}
        >
          Show More Information
          <ExpandMoreIcon className={classes.moreArrow} />
        </Button>
      </div>
    </>
  );

  const fullBillingContent = (
    <>
      <Grid item xs={12} style={{ padding: "8px 0px" }}>
        <Typography className={classes.profileTitle} variant="h5" align="center">
          Property Plans
        </Typography>
      </Grid>
      {paymentMethods.map((paymentMethod) => {
        return (
          <>
            {paymentMethodSubscriptions[paymentMethod?.id] && createPaymentMethodCard(paymentMethod)}
            {buildings.map((building, buildingIdx) => {
              if (building.ID) {
                return building.properties.map((property, propertyIdx) => {
                  let propertyPaymentMethodID = propertyPlans[property.ID]?.default_payment_method;
                  let freeProperty = buildingIdx === 0 && propertyIdx === 0;
                  if (
                    (!propertyPlans[property.ID] && !freeProperty) ||
                    (propertyPaymentMethodID !== paymentMethod.id &&
                      !(!propertyPaymentMethodID && paymentMethod.id === customer.paymentMethodID))
                  )
                    return null;

                  let address = `${property["unitNum"] ? `#${property["unitNum"]} ` : ""}${building["streetNum"]} ${
                    building["streetName"]
                  } ${building["streetType"]}${building["direction"] ? ` ${building["direction"]}` : ""}`;

                  return (
                    <Card
                      key={property.ID}
                      style={{ cursor: "default" }}
                      className={`${classes.infoCard} ${classes.profileItem}`}
                    >
                      <CardContent className={classes.profileRow}>
                        <Typography variant="h6" className={classes.profileField}>
                          {address}
                        </Typography>
                      </CardContent>
                      <CardContent className={classes.profileRow}>
                        <Typography variant="h6" className={`${classes.profileField} ${classes.subscriptionCost}`}>
                          {freeProperty ? "Free Property" : `$${propertyPlans[property.ID].plan.amount / 100} / year`}
                        </Typography>
                      </CardContent>
                      <CardContent className={classes.profileRow}>
                        <Grid container>
                          <Typography
                            variant="h6"
                            align="right"
                            noWrap
                            className={`${classes.profileValue} ${classes.renewalDate}`}
                          >
                            {freeProperty ? (
                              <span className={classes.freeProperty}></span>
                            ) : (
                              `Renews ${moment(propertyPlans[property.ID].current_period_end * 1000).format(
                                "MM/DD/YYYY"
                              )}`
                            )}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                });
              }
            })}
          </>
        );
      })}

      <div style={{ textAlign: "center" }}>
        <Button className={classes.moreBtn} variant="contained" onClick={() => setShowMoreBillingInfo(false)}>
          Show Less Information
          <ExpandLessIcon className={classes.lessArrow} />
        </Button>
      </div>
    </>
  );

  const additionalContent = (
    <>
      <Grid item xs={12} style={{ padding: "8px 0px" }}>
        <Typography className={classes.profileTitle} variant="h5" align="center">
          Additional Settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {[...additional].map((additionalData) => {
          return (
            <Card
              key={additionalData.key}
              className={`${classes.infoCard} ${classes.profileItem}`}
              onClick={async () => {
                if (additionalData.key === "notifications") {
                  if (!deviceToken) {
                    const token = await getToken();
                    if (token) {
                      additionalData.value.push(token);
                      console.log(additionalData.value);
                      if (additionalData.value.length > 5) addToast("Maximum # Of Devices Reached (5)", "error");
                      else await addToken(additionalData.value.join(", "), setDeviceToken, addToast);
                    } else {
                      addToast("Error Occured. Make Sure Notifications Are Allowed On Your Browser", "error");
                    }
                  } else setDisableNotisDialog({ deviceTokens: additionalData.value });
                } else createEditProfileDialog(additionalData);
              }}
            >
              <CardContent className={classes.profileRow}>
                <Typography variant="h6" className={classes.profileField}>
                  {additionalData.field}
                  {additionalData.key === "notifications" && additionalData?.value?.length > 0 && (
                    <span className={classes.notificationDevicesCount}>{`(${additionalData?.value?.length} device${
                      additionalData?.value?.length !== 1 ? "s" : ""
                    } connected)`}</span>
                  )}
                </Typography>
              </CardContent>

              <CardContent className={classes.profileRow}>
                <Grid container>
                  <Typography variant="h6" align="right" noWrap className={classes.profileValue}>
                    {additionalData.key !== "notifications" ? additionalData?.value?.length : ""}
                  </Typography>
                  {additionalData.key === "notifications" ? (
                    <Switch
                      color="primary"
                      className={classes.profileRowSwitch}
                      classes={{
                        root: classes.switchRoot,
                        switchBase: classes.switchBase,
                        thumb: classes.switchThumb,
                        track: classes.switchTrack,
                        checked: classes.switchChecked,
                      }}
                      checked={deviceToken === true}
                    />
                  ) : (
                    <EditIcon className={classes.editButton} />
                  )}
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </>
  );

  const dialogContent = (
    <>
      <EditProfileDoubleDialog
        addToast={addToast}
        form={editProfileDialog.form}
        fields={editProfileDialog.fields}
        editProfileDialog={editProfileDialog}
        setEditProfileDialog={setEditProfileDialog}
        updateUser={updateUser}
        updateCustomer={updateCustomer}
        updateBillingInfo={updateBillingInfo}
      />

      <Dialog
        open={disableNotisDialog !== false}
        onClose={() => setDisableNotisDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Are you sure you want to disable notifications on this device?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                You will no longer receive important alerts and updates about your properties
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={async () => {
                  const token = await getToken();
                  const tokenIndex = disableNotisDialog.deviceTokens.indexOf(token);
                  if (tokenIndex > -1) {
                    disableNotisDialog.deviceTokens.splice(tokenIndex, 1);
                    console.log(disableNotisDialog.deviceTokens);
                    await deleteToken(disableNotisDialog.deviceTokens.join(", "), setDeviceToken, addToast);
                  } else addToast("Error Occured While Disabling Notifications", "error");
                  setDisableNotisDialog(false);
                }}
                variant="contained"
                className={classes.deleteBtn}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth onClick={() => setDisableNotisDialog(false)} color="primary" variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={logoutDialog} onClose={() => setLogoutDialog(false)} TransitionComponent={Transition}>
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Are you sure you want to logout?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth onClick={handleLogout} variant="contained" className={classes.deleteBtn}>
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth onClick={() => setLogoutDialog(false)} color="primary" variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4" align="center" className={classes.header}>
          Settings
        </Typography>
      </Grid>

      <Container maxWidth="md">
        <Grid item xs={12}>
          {dialogContent}
          <Box component="div" display={{ xs: "none", sm: "block" }}>
            <Paper className={classes.content}>
              {profileContent}
              {securityContent}
              <Collapse in={!showMoreBillingInfo}>{billingContent}</Collapse>
              <Collapse in={showMoreBillingInfo}>{fullBillingContent}</Collapse>
              {additionalContent}
            </Paper>
          </Box>
          <Box component="div" display={{ xs: "block", sm: "none" }}>
            {profileContent}
            {securityContent}
            <Collapse in={!showMoreBillingInfo}>{billingContent}</Collapse>
            <Collapse in={showMoreBillingInfo}>{fullBillingContent}</Collapse>
            {additionalContent}
          </Box>
        </Grid>
      </Container>

      <Grid container direction="column" alignItems="center" className={classes.logoutButtonContainer}>
        <Grid item xs={12}>
          <Button variant="contained" className={classes.logoutButton} onClick={() => setLogoutDialog(true)}>
            Logout
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const mapState = (state) => {
  return {
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => ({
  dispatch: (data) => dispatch(data),
});

export default connect(mapState, mapDispatch)(Settings);
