import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditRounded";
import TenantIcon from "@material-ui/icons/AccountCircleRounded";
import AddTenantIcon from "@material-ui/icons/PersonAddRounded";
import ExitIcon from "@material-ui/icons/ExitToAppRounded";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import MoveOutDoubleDialog from "./MoveOutDoubleDialog";

import { getAlerts } from "../../../../services/property/alertServices";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  editTenantIcon: {
    marginTop: "-15px",
  },
  emptyTenants: {
    textAlign: "center",
    paddingBottom: "30px",
  },
  emptyTenantsIcon: {
    fontSize: "4.5em",
  },
  moveOutBtn: {
    paddingRight: "26px",
    paddingLeft: "26px",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $moveOutIcon": {
        transform: "translateX(5px)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  moveOutIcon: {
    margin: "-5px 0px",
    marginLeft: "10px",
    transition: "transform .2s ease-in-out",
  },
  tenantTitle: {
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
    marginLeft: theme.spacing(6),
  },
  tenantIcon: {
    fontSize: "4.5em",
    marginTop: "-10px",
    marginBottom: "-8px",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "3.375em",
    },
  },
  tenantName: {
    [theme.breakpoints.down("xs")]: {
      // fontSize: "1.05em", //1.4
    },
  },
  tenantEmail: {
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.75em", //1
    },
  },
}));

function TenantList({
  tenants,
  setTenantFormData,
  setTenantCount,
  addToast,
  updateLease,
  refreshProperty,
  lease,
  history,
  ...other
}) {
  const classes = useStyles();

  const [moveOutDialog, setMoveOutDialog] = useState(false);
  const [moveOutAlert, setMoveOutAlert] = useState(null);

  const getMoveOutAlert = async () => {
    let moveOutAlertReceived = await getAlerts(null, {
      propertyID: lease?.propertyID,
      leaseID: lease?.ID,
      type: "moveOut",
    });
    moveOutAlertReceived = moveOutAlertReceived[0];
    console.log(moveOutAlertReceived);
    setMoveOutAlert(moveOutAlertReceived);
  };

  useEffect(() => {
    getMoveOutAlert();
  }, []);

  useEffect(() => {
    if (setTenantCount) setTenantCount(tenants?.length);
  }, [tenants]);

  return (
    <div {...other}>
      {updateLease && (
        <MoveOutDoubleDialog
          addToast={addToast}
          moveOutDialog={moveOutDialog}
          setMoveOutDialog={setMoveOutDialog}
          refreshLease={updateLease}
          refreshProperty={refreshProperty}
          history={history}
        />
      )}

      <Grid item xs={12} style={{ paddingBottom: 10 }}>
        <Toolbar>
          <Typography variant="h5" align="center" className={classes.tenantTitle}>
            Tenants
          </Typography>

          <Tooltip title="Add Tenant" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
            <IconButton onClick={() => setTenantFormData({ tenantItem: null, action: "Add" })}>
              <AddTenantIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Grid>

      {tenants?.length > 0 ? (
        <Grid container spacing={2}>
          {[...tenants].map((tenantItem) => {
            return (
              <Grid
                item
                key={tenantItem.ID}
                xs={12}
                md={tenants.length > 1 ? 6 : null}
                lg={tenants.length > 2 ? 4 : null}
              >
                <Card
                  className={classes.infoCard}
                  onClick={() =>
                    setTenantFormData({
                      tenantItem: tenantItem,
                      action: "Edit",
                    })
                  }
                >
                  <CardContent style={{ textAlign: "center" }}>
                    <Grid container justifyContent="flex-end">
                      <EditIcon className={classes.editTenantIcon} />
                    </Grid>
                    <TenantIcon className={classes.tenantIcon} />
                    <Typography variant="h6" align="center" className={classes.tenantName} noWrap>
                      {`${tenantItem.firstName} ${tenantItem.lastName}`}
                    </Typography>
                    <Typography variant="body2" align="center" className={classes.tenantEmail} noWrap>
                      {tenantItem.email}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        tenants && (
          <Grid container className={classes.emptyTenants}>
            <Grid item xs={12}>
              <TenantIcon className={classes.emptyTenantsIcon} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No Tenants
              </Typography>
              <Typography variant="body1" align="center">
                Press the plus icon to add your first one
              </Typography>
            </Grid>
          </Grid>
        )
      )}
      {lease?.status == "active" && tenants?.length > 0 && !setTenantCount && !moveOutAlert && (
        <Grid container spacing={2} style={{ marginTop: "20px", justifyContent: "center" }}>
          <Button variant="contained" className={classes.moveOutBtn} onClick={() => setMoveOutDialog(true)}>
            Move Out Tenants
            <ExitIcon className={classes.moveOutIcon} />
          </Button>
        </Grid>
      )}
    </div>
  );
}

export default TenantList;
