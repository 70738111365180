import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const addConditionReport = async (conditionReportData, handleErrors) => {
  return await updateConditionReport(axios.post, conditionReportData, handleErrors);
};

const updateConditionReport = async (axiosMethod, conditionReportData, handleErrors = null) => {
  let conditionReportReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/leases/condition-reports/", conditionReportData, { timeout: 10000 })
    .then(async (response) => {
      if (response.config && response.config.method === "delete") conditionReportReceived = true;
      else if (response && response.data) conditionReportReceived = response.data;
      else {
        responseError = (response.data && response.data.error) || "Updating Condition Report failed";
        console.log("No condition report", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Condition Report Information";
    });

  if (handleErrors) handleErrors(responseError);

  return conditionReportReceived;
};
