import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const getTenants = async (tenantData) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/properties/leases/tenants/?timestamp=${new Date().getTime()}`, {
      params: { ...tenantData, clonedUserID },
    })
    .then((response) => response.data);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addTenant = async (tenantData, handleErrors) => {
  const formData = new FormData();
  Object.keys(tenantData).forEach((field) => formData.append(field, tenantData[field]));

  return await updateTenant(axios.post, formData, handleErrors);
};

export const editTenant = async (tenantData, handleErrors) => {
  return await updateTenant(axios.put, tenantData, handleErrors);
};

export const deleteTenant = async (tenantData, handleErrors) => {
  return await updateTenant(axios.delete, { data: tenantData }, handleErrors);
};

const updateTenant = async (axiosMethod, tenantData, handleErrors = null) => {
  let tenantReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/leases/tenants/", tenantData)
    .then(async (response) => {
      if (response.config?.method === "delete") tenantReceived = true;
      else if (response.data) tenantReceived = response.data;
      else {
        responseError = response.data?.error || "Tenant Update failed";
        console.log("No tenant", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Tenant Information";
    });

  if (handleErrors) handleErrors(responseError);

  return tenantReceived;
};
