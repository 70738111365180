import streetTypes from "../../../assets/constants/streetTypes.json";

const loadScript = (src, position, id) => {
  if (!position) return;

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const initializeGoogleApi = () => {
  if (typeof window !== "undefined") {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCmvIE21EcVDyDzUD8AAnckJJUrB6AdsBU&libraries=places&loading=async",
        document.querySelector("head"),
        "google-maps"
      );
    }
  }
};

const getAddressData = (address_components) => {
  const addressData = {
    streetNum: "",
    streetName: "",
    streetType: "",
    direction: "",
    municipality: "",
    province: "",
    postalCode: "",
  };

  for (const component of address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        addressData.streetNum = component.short_name;
        break;
      }
      case "route": {
        let streetName = component.long_name.split(" ");

        let direction = streetName[streetName.length - 1];
        if (direction === "North" || direction === "South" || direction === "East" || direction === "West") {
          direction = direction[0];
          streetName.pop();
        } else direction = "";

        let streetType = streetName[streetName.length - 1];
        if (streetTypes.includes(streetType)) streetName.pop();
        else streetType = "";

        streetName = streetName.join(" ");

        addressData.streetName = streetName;
        addressData.streetType = streetType;
        addressData.direction = direction;
        break;
      }
      case "locality": {
        addressData.municipality = component.long_name;
        break;
      }
      case "administrative_area_level_1": {
        addressData.province = component.short_name;
        break;
      }
      case "postal_code":
        addressData.postalCode = component.short_name;
        break;
      default: // Do nothing
    }
  }

  return addressData;
};

export { initializeGoogleApi, getAddressData };
