import { SET_BUILDING, SET_PROPERTY, SET_LEASE, SET_REFRESH_PROPERTY } from "../actionTypes/buildingActionTypes";

const defaultState = {
  building: null,
  property: null,
  lease: null,
  refreshProperty: null,
};

const BuildingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_BUILDING:
      return { ...state, building: action.building };
    case SET_PROPERTY:
      return { ...state, property: action.property };
    case SET_LEASE:
      return { ...state, lease: action.lease };
    case SET_REFRESH_PROPERTY:
      return { ...state, refreshProperty: action.refreshProperty };
    default:
      return state;
  }
};

export default BuildingReducer;
