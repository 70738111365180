import axios from "axios";
import moment from "moment";
import { getLedger } from "./ledgerServices.js";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";
import { isArray } from "lodash";

setAxiosDefaultSettings(axios);

export const editROIData = async (roiData, handleErrors = null) => {
  let dataReceived = false;
  let responseError = null;

  await axios
    .put(`/v2/buildings/properties/`, roiData)
    .then(async (response) => {
      if (response.data) dataReceived = response.data;
      else {
        responseError = response.data?.error || "Failed to edit ROI Data";
        console.log("No ROI Data: ", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect ROI Data";
    });

  if (handleErrors) handleErrors(responseError);

  return dataReceived;
};

const getAppreciationData = async () => {
  let dataReceived = false;
  let responseError = null;

  await axios
    .get(`/v2/appreciation-data/?timestamp=${new Date().getTime()}`)
    .then(async (response) => {
      if (response.config?.method === "delete") dataReceived = true;
      else if (response.data) dataReceived = response.data;
      else {
        responseError = response.data?.error || "Failed to get Appreciation Data";
        console.log("No Appreciation Data: ", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
    });

  return dataReceived;
};

const getTotalCashflow = async (propertyData, leaseData) => {
  if (!leaseData || !("ID" in leaseData)) return 0;
  let ledgerData = [];

  for (let lease of propertyData.leases) {
    let ledger = await getLedger({ leaseID: lease["ID"] });
    if (isArray(ledger)) ledgerData = ledgerData.concat(ledger);
  }

  ledgerData.sort((a, b) => (+moment(a.date).isBefore(b.date) ? -1 : 1));

  let lastMonthCashflow = 0;
  let totalCashflow = 0;
  let monthlyCashflow = [];
  //console.log("Ledger data is: ", ledgerData);
  if (ledgerData && ledgerData.length > 0) {
    let lastMonthPayments = 0;
    let lastPaymentPeriod = ledgerData[0]["date"];
    for (let ledgerItem of ledgerData) {
      if (lastPaymentPeriod !== ledgerItem["date"]) {
        //console.log(`In ${lastPaymentPeriod}, $${lastMonthPayments} was paid`);
        totalCashflow += propertyData?.mortgagePayment
          ? lastMonthPayments - parseFloat(propertyData?.mortgagePayment)
          : lastMonthPayments;
        monthlyCashflow.push({
          date: moment(lastPaymentPeriod).format("MM-DD-YYYY"),
          totalCashflow: totalCashflow.toFixed(2),
        });
        //console.log("Total cashflow: ", totalCashflow);
        lastPaymentPeriod = ledgerItem["date"];
        lastMonthPayments = 0;
      }
      if (ledgerItem["type"] === "payment" && ledgerItem["reason"] === "Rent")
        lastMonthPayments += parseFloat(ledgerItem["amount"]);
    }
    //console.log(`In ${lastPaymentPeriod}, $${lastMonthPayments} was paid`);
    lastMonthCashflow = propertyData?.mortgagePayment
      ? lastMonthPayments - parseFloat(propertyData?.mortgagePayment)
      : lastMonthPayments;
    totalCashflow += lastMonthCashflow;
    monthlyCashflow.push({
      date: moment(lastPaymentPeriod).format("MM-DD-YYYY"),
      totalCashflow: totalCashflow.toFixed(2),
    });
  }
  //console.log("Last Month's Cashflow: ", lastMonthCashflow);
  return monthlyCashflow;
};

const getAmortizationSchedule = (propertyData) => {
  let start = moment(propertyData.mortgageStartDate);
  let month = start;
  let end = moment();
  let mortgageBalance = parseFloat(propertyData.initialMortgage);
  let amortizationSchedule = [{ date: start.format("MM-DD-YYYY"), mortgageBalance: mortgageBalance.toFixed(2) }];
  month = month.add(1, "months");
  let principal = [0, 0, 0];
  while (month < end) {
    let interest = (mortgageBalance * (parseFloat(propertyData.interestRate) / 100)) / 12;
    principal[2] = principal[1];
    principal[1] = principal[0];
    principal[0] = parseFloat(propertyData.mortgagePayment) - interest;
    mortgageBalance -= principal[0];
    amortizationSchedule.push({ date: month.format("MM-DD-YYYY"), mortgageBalance: mortgageBalance.toFixed(2) });
    month = month.add(1, "months");
  }

  return amortizationSchedule;
};

const getPropertyValue = (buildingData, propertyData, appreciationData) => {
  let appraisalDate = propertyData?.appraisalDate ? propertyData?.appraisalDate : propertyData?.purchaseDate;
  let propertyValue = propertyData?.propertyValue ? propertyData.propertyValue : propertyData.initialPropertyValue;
  let monthlyValue = [
    {
      date: moment(appraisalDate).quarter(moment(appraisalDate).quarter()).startOf("quarter").format("MM-DD-YYYY"),
      propertyValue: propertyValue.toFixed(2),
    },
  ];

  //console.log("appraisalDate: ", appraisalDate, "monthlyValue: ", monthlyValue);

  if (!appreciationData) return monthlyValue;

  for (let appreciationItem of appreciationData) {
    if (!(buildingData["municipality"] in appreciationItem)) break;
    if (moment(appreciationItem["increaseDate"]).isBefore(moment(appraisalDate).add(1, "months"))) continue;

    let appreciationRate = parseFloat(appreciationItem[buildingData["municipality"]]);
    //console.log(`Property value before ${appreciationItem["increaseDate"]} was ${propertyValue}`);
    if (moment(appreciationItem["increaseDate"]).isSameOrAfter(moment(appraisalDate).add(1, "months"))) {
      propertyValue *= appreciationRate / 100 + 1;
      monthlyValue.push({
        date: moment(appreciationItem["increaseDate"]).format("MM-DD-YYYY"),
        propertyValue: propertyValue.toFixed(2),
      });
    }
  }

  return monthlyValue;
};

const getQuarterlyROI = (propertyData, monthlyValue, amortizationSchedule, monthlyCashflow) => {
  let earliestDate = moment
    .min([
      moment(monthlyValue[0].date),
      ...(amortizationSchedule?.length > 0 ? [moment(amortizationSchedule[0].date)] : []),
      ...(monthlyCashflow?.length > 0 ? [moment(monthlyCashflow[0].date)] : []),
    ])
    .format("MM-DD-YYYY");

  let earliestQuarter = moment(earliestDate)
    .quarter(moment(earliestDate).quarter())
    .startOf("quarter")
    .format("MM-DD-YYYY");

  let currentQuarter = earliestQuarter;

  //console.log("earliestQuarter: ", currentQuarter, monthlyValue, amortizationSchedule, monthlyCashflow);
  let quarterlyROI = [];
  let valueIndex = 0;
  let amortizationIndex = 0;
  let cashflowIndex = 0;
  let currentROI = 0;
  while (moment(currentQuarter).isSameOrBefore(moment())) {
    /* Distribute the appreciation from the initial property value to the latest appraisal 
    across all the quarters between the purchase date and the latest appraisal date */
    if (
      valueIndex == 0 &&
      moment(monthlyValue[0].date).isSameOrAfter(currentQuarter, "quarter") &&
      moment(currentQuarter).isAfter(propertyData.purchaseDate, "quarter")
    ) {
      //Add the appreciation for the current quarter at or after the earliest quarter
      currentROI +=
        (monthlyValue[0].propertyValue - propertyData.initialPropertyValue) /
        moment(monthlyValue[0].date).diff(propertyData.purchaseDate, "quarter");

      //On the first iteration of the loop, also add the appreciation that came before the earliestQuarter
      if (moment(earliestQuarter).isSame(currentQuarter, "quarter"))
        currentROI +=
          ((monthlyValue[0].propertyValue - propertyData.initialPropertyValue) *
            (moment(earliestQuarter).diff(propertyData.purchaseDate, "quarter") - 1)) /
          moment(monthlyValue[0].date).diff(propertyData.purchaseDate, "quarter");
    }

    // Add any appreciation this quarter to the ROI
    while (
      monthlyValue?.length > valueIndex &&
      moment(monthlyValue[valueIndex].date).isSame(currentQuarter, "quarter")
    ) {
      currentROI +=
        monthlyValue[valueIndex].propertyValue - monthlyValue[valueIndex > 0 ? valueIndex - 1 : 0].propertyValue;
      valueIndex++;
    }

    // Add any mortgage paydown this quarter to the ROI
    while (
      amortizationSchedule?.length > amortizationIndex &&
      moment(amortizationSchedule[amortizationIndex].date).isSame(currentQuarter, "quarter")
    ) {
      currentROI +=
        amortizationSchedule[amortizationIndex > 0 ? amortizationIndex - 1 : 0].mortgageBalance -
        amortizationSchedule[amortizationIndex].mortgageBalance;
      amortizationIndex++;
    }

    // Add any cashflow this quarter to the ROI
    while (
      monthlyCashflow?.length > cashflowIndex &&
      moment(monthlyCashflow[cashflowIndex].date).isSame(currentQuarter, "quarter")
    ) {
      currentROI +=
        monthlyCashflow[cashflowIndex].totalCashflow -
        monthlyCashflow[cashflowIndex > 0 ? cashflowIndex - 1 : 0].totalCashflow;
      cashflowIndex++;
    }

    quarterlyROI.push({ date: moment(currentQuarter).format("MM-DD-YYYY"), return: currentROI.toFixed(2) });
    currentQuarter = moment(currentQuarter).add(1, "quarter");
  }

  return quarterlyROI;
};

export const getROIData = async (buildingData, propertyData, leaseData) => {
  if (!propertyData?.ID || !propertyData?.initialPropertyValue) return null;
  let roiData = {};

  const monthlyCashflow = await getTotalCashflow(propertyData, leaseData);
  roiData["monthlyCashflow"] = monthlyCashflow;

  const amortizationSchedule = propertyData?.initialMortgage ? getAmortizationSchedule(propertyData) : [];
  roiData["amortizationSchedule"] = amortizationSchedule;
  let mortgageBalance =
    amortizationSchedule.length > 0 ? amortizationSchedule.slice(-1)[0].mortgageBalance : propertyData?.initialMortgage;
  roiData["mortgageBalance"] = mortgageBalance;
  roiData["mortgagePaidDown"] = (propertyData?.initialMortgage - mortgageBalance).toFixed(2);

  const appreciationData = await getAppreciationData();
  const monthlyValue = getPropertyValue(buildingData, propertyData, appreciationData);
  roiData["monthlyValue"] = monthlyValue;

  const lastestPropertyValue = parseFloat(monthlyValue.slice(-1)[0].propertyValue);
  let previousPropertyValue = parseFloat(
    monthlyValue.length > 1 ? monthlyValue.slice(-2)[0].propertyValue : lastestPropertyValue
  );

  let overallAppreciation = lastestPropertyValue - propertyData?.initialPropertyValue;
  let overallEquity =
    (propertyData?.initialMortgage && propertyData?.initialMortgage - mortgageBalance) + overallAppreciation;

  roiData["overallAppreciation"] = overallAppreciation.toFixed(2);
  roiData["overallEquity"] = overallEquity.toFixed(2);

  if (monthlyValue.length > 1 && lastestPropertyValue !== previousPropertyValue)
    roiData["quarterlyAppreciation"] = (lastestPropertyValue - previousPropertyValue).toFixed(2);
  else roiData["quarterlyAppreciation"] = overallAppreciation.toFixed(2);

  let quarterlyROI = getQuarterlyROI(propertyData, monthlyValue, amortizationSchedule, monthlyCashflow);
  roiData["quarterlyROI"] = quarterlyROI;

  return roiData;
};
