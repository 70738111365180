import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuTitleText: {
    padding: theme.spacing(1),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DoubleDialog({
  children,
  mainTitle,
  mainDialogOpen,
  closeMainDialog,
  confirmationDialogData,
  closeConfirmationDialog,
  width,
}) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={mainDialogOpen && mainDialogOpen !== null}
        onClose={closeMainDialog}
        TransitionComponent={Transition}
        maxWidth={width}
      >
        <DialogTitle disableTypography className={classes.menuTitle}>
          <Typography variant="h6" className={classes.menuTitleText}>
            {mainTitle}
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={closeMainDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
      <Dialog
        open={confirmationDialogData !== false}
        onClose={closeConfirmationDialog}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                {confirmationDialogData.title}
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                {confirmationDialogData.body}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={() => confirmationDialogData.callback()}
                color={confirmationDialogData.primaryBtnClass ? "inherit" : "primary"}
                className={confirmationDialogData.primaryBtnClass ? confirmationDialogData.primaryBtnClass : ""}
                variant="contained"
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={closeConfirmationDialog}
                color={confirmationDialogData.secondaryBtnClass ? "inherit" : "primary"}
                className={confirmationDialogData.secondaryBtnClass ? confirmationDialogData.secondaryBtnClass : ""}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DoubleDialog;
