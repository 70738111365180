import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoubleDialog from "../../../components/DoubleDialog";
import EditLeaseForm from "./EditLeaseForm";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

function EditLeaseDoubleDialog(props) {
  const classes = useStyles();
  const [confirmationData, setConfirmationData] = useState(false);

  //The function called if the confirm button is clicked and the callback function for confirm is successful
  const closeDialogSuccessfully = () => {
    props.addToast("Lease Successfully Edited", "success");
    setConfirmationData(false);
    props.setEditLeaseDialog(false);
    props.updateLease();
    props.refreshProperty();
  };

  //The function that sets up the text and callback for the confirmation dialog
  const setConfirmationDialog = (confirmCallback) => {
    setConfirmationData({
      title: "Please ensure the information you've entered is correct", //The title of the confirmation dialog
      body: "This information will be used on official government forms", //The body of the confirmation dialog
      callback: async () => {
        let confirmSuccess = await confirmCallback();
        if (confirmSuccess) closeDialogSuccessfully();
        else setConfirmationData(false);
      },
      secondaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the secondary button (You can also use primaryBtnClass)
    });
  };

  return (
    <DoubleDialog
      mainTitle={"Edit Lease"}
      mainDialogOpen={props.editLeaseDialog} //The condition for the main dialog to open
      closeMainDialog={() => props.setEditLeaseDialog(false)} //A function to close the main dialog
      confirmationDialogData={confirmationData} //The title, body, and callback of the confirmation dialog
      closeConfirmationDialog={() => setConfirmationData(false)} //A function to close the confirmation dialog
      width={"sm"} //maxWidth prop for the main dialog
    >
      <EditLeaseForm
        dispatch={props.dispatch}
        form={props.form}
        fields={props.fields}
        leaseID={props.leaseID}
        addToast={props.addToast}
        setConfirmationCallback={setConfirmationDialog}
      />
    </DoubleDialog>
  );
}

export default EditLeaseDoubleDialog;
