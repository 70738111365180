import React, { useState, useEffect } from "react";
import FormCheckoutForm from "./components/FormCheckoutForm";

import { useParams } from "react-router";
import { connect } from "react-redux";
import { setLTBFormData } from "../../../../redux/actions/LTBFormActions";
import { getConfig } from "../../../../services/billingServices.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function FormCheckout(props) {
  const { property_id } = useParams();

  useEffect(() => {
    if (!props.LTBFormData) props.history.push(`/${property_id ? property_id : "properties"}`);
  });

  const [stripe, setStripe] = useState(null);

  const getSripeConfig = async () => {
    const config = await getConfig();

    if (config) setStripe(await loadStripe(config.publishableKey));
  };

  useEffect(() => {
    getSripeConfig();
  }, []);

  return (
    <>
      {stripe ? (
        <Elements stripe={stripe}>
          <FormCheckoutForm
            history={props?.history}
            dispatch={props.LTBFormData.dispatch}
            propertyID={property_id}
            alertID={props.LTBFormData.alertID}
            parentID={props.LTBFormData.parentID}
          />
        </Elements>
      ) : null}
    </>
  );
}

const mapState = (state) => {
  return {
    LTBFormData: state.LTBForm.data,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};
export default connect(mapState, mapDispatch)(FormCheckout);
