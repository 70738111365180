import { useEffect, useRef, useState } from "react";

function VisitedSensor({ children, percentHeight, percentTop, permanent, refState, visitedArray, passedArray }) {
  const ref = useRef();
  const [visited, setVisited] = useState(false);
  const [passed, setPassed] = useState(false);
  const [closeToVisited, setCloseToVisited] = useState(false);
  const [closeToPassed, setCloseToPassed] = useState(false);
  const [visitedProp, setVisitedProp] = Array.isArray(visitedArray) ? visitedArray : [null, null];
  const [passedProp, setPassedProp] = Array.isArray(passedArray) ? passedArray : [null, null];

  const updateVisited = () => {
    if (!refState?.current && !ref?.current) return;

    let windowHeight = window.innerHeight * (percentHeight ? percentHeight : 0.95);
    let windowTop = 0 + window.innerHeight * (percentTop ? percentTop : 0);
    let elementBoundingRect =
      refState != null ? refState.current.getBoundingClientRect() : ref.current.getBoundingClientRect();

    if (Array.isArray(visitedArray)) setVisitedProp(windowHeight > elementBoundingRect.top);
    else setVisited(windowHeight > elementBoundingRect.top);

    if (Array.isArray(passedArray)) setPassedProp(windowTop > elementBoundingRect.bottom);
    else setPassed(windowTop > elementBoundingRect.bottom);

    setCloseToVisited(windowHeight + window.innerHeight * 0.5 > elementBoundingRect.top);
    setCloseToPassed(windowTop - window.innerHeight * 0.5 > elementBoundingRect.bottom);
  };

  useEffect(() => {
    updateVisited();
    document.addEventListener("scroll", updateVisited);
    return () => {
      document.removeEventListener("scroll", updateVisited);
    };
  }, []);

  useEffect(() => {
    document.removeEventListener("scroll", updateVisited);
    document.addEventListener("scroll", updateVisited);
  }, [percentHeight, percentTop, refState, visitedArray, passedArray]);

  if (permanent || (closeToVisited && !closeToPassed))
    return children(
      refState != null ? refState : ref,
      Array.isArray(visitedArray) ? visitedProp : visited,
      Array.isArray(passedArray) ? passedProp : passed
    );
  else return <div ref={refState != null ? refState : ref}></div>;
}

export default VisitedSensor;
