import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { setLease } from "../../../redux/actions/buildingActions";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/AddRounded";
import MenuIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useMediaQuery } from "@material-ui/core";
import { editUser } from "../../../services/userServices";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 96px)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
    paddingTop: "60px",
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "10px",
  },
  infoCard: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  leaseItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 20px",
    marginBottom: "12px",
  },
  leaseField: {
    fontSize: "1.3em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  leaseValue: {
    color: "rgba(255, 255, 255, 0.4)",
    fontSize: "1.3em",
    maxWidth: "50vw",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
      maxWidth: "55vw",
    },
  },
  leaseSubValue: {
    fontSize: "1.2em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.9em",
    },
  },
  leaseRow: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "12px",

      [`${theme.breakpoints.down("xs")}`]: {
        paddingBottom: "0px",
      },
    },
    "& > h6": {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "12px 0px",
    },
  },
  editLeaseButton: {
    marginLeft: "20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "inherit",
      marginLeft: "10px",
    },
  },
  leaseTitle: {
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
  },
  leaseRowSwitch: {
    marginTop: "6px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginTop: "2px",
    },
  },
  switchRoot: {
    width: 28,
    height: 16,
    [theme.breakpoints.up("sm")]: {
      width: 42,
      height: 22,
    },
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$switchChecked": {
      transform: "translateX(12px)",
      [theme.breakpoints.up("sm")]: {
        transform: "translateX(19px)",
      },
      color: theme.palette.common.white,
      "& + $switchTrack": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  switchThumb: {
    width: 12,
    height: 12,
    [theme.breakpoints.up("sm")]: {
      width: 18,
      height: 18,
    },
    boxShadow: "none",
  },
  switchTrack: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 24 / 2,
    },
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  switchChecked: {},
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  moreBtn: {
    paddingLeft: "26px",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $moreArrow": {
        transform: "translateY(2px)",
      },
      "& $lessArrow": {
        transform: "translateY(-2px)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  moreArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  lessArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  noLease: {
    textAlign: "center",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
  },
  noLeaseIcon: {
    position: "relative",
    display: "inline-block",
    color: "white",
  },
  leaseOverlayIcon: {
    fontSize: "60px",
    left: "50%",
    top: "58%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.background.paper,
    position: "absolute",
  },
  tipArrow: {
    "&:before": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tipBubble: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "10px 20px",
  },
  tooltipButton: {
    background: "none",
    boxShadow: "none",
    padding: "0px",
    fontWeight: "600",
    cursor: "pointer",
  },
}));

function LeaseHistory({ tenantsByLease, historyExpanded, setHistoryExpanded, ...props }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [tips, setTips] = useState(props.profile?.tips ? JSON.parse(props.profile.tips) : {});

  const isLeasePage = () => {
    const currentPropertyPage = location.pathname.split("/")[2];
    if (currentPropertyPage.includes("lease")) return true;
    return false;
  };

  useEffect(() => {
    setTips(props.profile?.tips ? JSON.parse(props.profile.tips) : {});
  }, [props.profile]);

  return (
    <>
      <Grid item xs={12}>
        <Toolbar style={{ minHeight: "0px", marginBottom: theme.spacing(1.5) }}>
          {isLeasePage() ? (
            <Box component="div" visibility={{ xs: "hidden" /*"visibile"*/, sm: "hidden" }}>
              <IconButton onClick={() => props.history.push(`/${props.property["ID"]}/setup`)}>
                <AddIcon />
              </IconButton>
            </Box>
          ) : null}

          <Typography
            className={classes.leaseTitle}
            variant="h5"
            align="center"
            style={{ marginTop: "0px" /*marginLeft: !isLeasePage() || breakpointsDownXS ? "0px" : theme.spacing(6)*/ }}
          >
            {historyExpanded ? "Lease History" : "Current Lease"}
          </Typography>
          {isLeasePage() ? (
            <>
              <Tooltip
                placement="bottom"
                arrow
                open={!tips?.addLease}
                classes={{
                  tooltip: classes.tipBubble,
                  arrow: classes.tipArrow,
                }}
                PopperProps={{
                  onClick: () => editUser({ tips: { ...tips, addLease: true } }, null, props.dispatch),
                  style: {
                    pointerEvents: "auto",
                    marginTop: "-20px",
                    display: !!props?.mobile != breakpointsDownXS ? "none" : "block",
                  },
                }}
                title={
                  <>
                    <Typography style={{ textAlign: "center" }}>
                      Click to add an active lease or schedule an upcoming lease
                    </Typography>
                    <div style={{ textAlign: "right", marginTop: "5px" }}>
                      <Button className={classes.tooltipButton}>OK</Button>
                    </div>
                  </>
                }
              >
                <Box component="div" display={{ xs: "block" /*"none"*/, sm: "block" }}>
                  <Tooltip title="Add Lease" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                    <IconButton
                      onClick={() => {
                        editUser({ tips: { ...tips, addLease: true } }, null, props.dispatch);
                        props.history.push(`/${props.property["ID"]}/setup`);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Tooltip>
              {
                /*<Tooltip title="More Options" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                  <IconButton onClick={() => {}}>
                    <MenuIcon />
                  </IconButton>
                </Tooltip>*/ <></>
              }
            </>
          ) : null}
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        {("leases" in props.property && props.property["leases"].length > 0) || props.lease ? (
          (historyExpanded ? props.property["leases"] : [props.lease]).map((lease) => {
            return (
              <Card
                key={lease.ID}
                className={`${classes.infoCard} ${classes.leaseItem}`}
                onClick={() => {
                  props.dispatch(setLease(lease));
                  setHistoryExpanded(false);
                }}
              >
                <CardContent className={classes.leaseRow}>
                  <Radio color="primary" checked={lease.ID === props.lease["ID"]} style={{ paddingLeft: "0px" }} />
                </CardContent>
                <CardContent className={classes.leaseRow} style={{ flex: "1", justifyContent: "flex-start" }}>
                  <Typography variant="h6" className={classes.leaseField}>
                    <Chip
                      label={
                        lease.status == "movingIn" ? "Moving In" : lease.status == "active" ? "Active" : "Moved Out"
                      }
                      style={{
                        backgroundColor:
                          lease.status == "movingIn"
                            ? theme.palette.secondary.dark
                            : lease.status == "active"
                            ? theme.palette.primary.main
                            : theme.palette.warning.dark,
                      }}
                    />
                  </Typography>
                </CardContent>
                <CardContent className={classes.leaseRow}>
                  <Grid container style={{ flexDirection: "column" }}>
                    <Typography
                      variant="h6"
                      align="right"
                      noWrap
                      className={classes.leaseValue}
                      style={{ color: "white" }}
                    >
                      {tenantsByLease && lease.ID in tenantsByLease && tenantsByLease[lease.ID].length > 0
                        ? `${tenantsByLease[lease.ID][0].firstName} ${tenantsByLease[lease.ID][0].lastName}${
                            tenantsByLease[lease.ID].length > 1
                              ? ` + ${tenantsByLease[lease.ID].length - 1} Tenants`
                              : ""
                          }`
                        : "No Tenants"}
                    </Typography>
                    <Typography
                      variant="h6"
                      align="right"
                      noWrap
                      className={`${classes.leaseValue} ${classes.leaseSubValue}`}
                    >
                      {moment(lease.moveInDate).format("MMM D, YYYY")}
                      {lease.status == "movingIn" ? "" : " - "}
                      {lease.status == "movingIn"
                        ? ""
                        : lease.status == "active"
                        ? "present"
                        : moment(lease.moveOutDate).format("MMM D, YYYY")}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <Grid container className={classes.noLease}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={classes.noLeaseIcon}>
                <CloseIcon className={classes.leaseOverlayIcon} />
                <InsertDriveFileIcon style={{ fontSize: "90px" }} />
              </div>
            </div>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No Active Lease
              </Typography>
              <Typography variant="body1" align="center">
                Press the plus icon to add your first one
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {props.property["leases"].length > 1 ? (
        <div style={{ textAlign: "center" }}>
          <Button
            className={classes.moreBtn}
            variant="contained"
            onClick={() => {
              setHistoryExpanded((_historyExpanded) => {
                const currentPropertyPage = location.pathname.split("/")[2];
                if (!_historyExpanded && !currentPropertyPage.includes("lease"))
                  props.history.push(`/${props.property["ID"]}/lease`);

                return !_historyExpanded;
              });
            }}
          >
            {historyExpanded ? (
              <>
                View Current Lease <ExpandLessIcon className={classes.lessArrow} />
              </>
            ) : (
              <>
                Switch Lease <ExpandMoreIcon className={classes.moreArrow} />
              </>
            )}
          </Button>
        </div>
      ) : null}
    </>
  );
}

const mapState = (state) => {
  return {
    profile: state.authentication.profile,
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(LeaseHistory);
