import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ImageIcon from "@material-ui/icons/ImageRounded";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";

import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "65vh",
      margin: theme.spacing(0, 0, 3),
    },
  },
  menuTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuTitleText: {
    padding: theme.spacing(1),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
  },
  submit: {
    margin: theme.spacing(4, 0, 0),
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    borderWidth: "5px",
    borderStyle: "dashed",
    borderRadius: "var(--tsl-brand-border-radius)",
    width: "100%",
    padding: "80px",
    [`${theme.breakpoints.down("xs")}`]: {
      padding: "80px 30px",
    },
  },
  dropzoneBorderError: {
    borderColor: theme.palette.error.main,
  },
  dropzoneText: {
    padding: theme.spacing(0, 0, 1),
    align: "center",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  uploadIcon: {
    fontSize: "4.5em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "3em",
    },
  },
  dropzoneError: {
    color: theme.palette.error.main,
    margin: theme.spacing(2, 0, -1),
  },
  selectedFilesTitle: {
    padding: theme.spacing(2, 0, 1),
  },
  selectedFilesChip: {
    maxWidth: "100%",
    borderRadius: "30px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dropzone({ fileName, onFileUpload, dropzoneDialogOpen, setDropzoneDialogOpen }) {
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [files, setFiles] = useState(null);

  const setErrors = (bool) => {
    setTypeError(bool);
    setSizeError(bool);
    setMaxFilesError(bool);
  };

  const onDropAccepted = (acceptedFiles) => {
    // setFiles((oldFiles) => [...oldFiles, acceptedFiles]);
    //console.log(acceptedFiles);
    setFiles(acceptedFiles[0]);
    setErrors(false);
  };

  const onDropRejected = (fileRejections) => {
    const error = fileRejections[0].errors[0].code;
    //console.log(error);
    if (error === "file-invalid-type") setTypeError(true);
    else if (error === "file-too-large") setSizeError(true);
    else if (error === "too-many-files") setMaxFilesError(true);
  };

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: "image/jpeg, image/png",
    maxSize: 8000000,
    maxFiles: 1,
    multiple: false,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const handleClose = () => {
    setErrors(false);
    setDropzoneDialogOpen(false);
  };

  const handleSubmit = () => {
    console.log(files);
    setDropzoneDialogOpen(false);
    onFileUpload(files);
    setFiles(null);
  };

  const classes = useStyles();

  return (
    <>
      <Dialog open={dropzoneDialogOpen === true} onClose={handleClose} TransitionComponent={Transition} maxWidth="md">
        <DialogTitle disableTypography className={classes.menuTitle}>
          <Typography variant="h6" className={classes.menuTitleText}>
            Upload {fileName}
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.main}>
            <Grid container align="center" spacing={1}>
              <div
                {...getRootProps({
                  className: `${classes.dropzone} ${
                    (!files && (typeError || sizeError || maxFilesError)) || isDragReject
                      ? classes.dropzoneBorderError
                      : ""
                  }`,
                })}
              >
                <input {...getInputProps()} />
                <UploadIcon className={classes.uploadIcon} />
                <Typography variant="h6" className={classes.dropzoneText}>
                  Click to select an image file or drop it here
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {typeError && (
                <Typography variant="body2" className={classes.dropzoneError}>
                  File type must be jpeg, jpg, or png
                </Typography>
              )}
              {sizeError && !typeError && (
                <Typography variant="body2" className={classes.dropzoneError}>
                  File size must be smaller than 8MB
                </Typography>
              )}
              {!typeError && !sizeError && maxFilesError && (
                <Typography variant="body2" className={classes.dropzoneError}>
                  You can only upload one file
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.selectedFilesTitle}>
                Selected Files ({files ? 1 : 0} / 1):
              </Typography>
              {files && (
                <Chip
                  label={files.name}
                  icon={<ImageIcon />}
                  onDelete={() => setFiles(null)}
                  className={classes.selectedFilesChip}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!files || typeError || sizeError || maxFilesError}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Upload {fileName}
              </Button>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Dropzone;
