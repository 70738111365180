import { combineReducers, createStore, applyMiddleware } from "redux";
import AuthenticationReducer from "./reducers/authenticationReducer";
import BuildingReducer from "./reducers/buildingReducer";
import AlertsReducer from "./reducers/alertsReducer";
import LTBFormReducer from "./reducers/LTBFormReducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  authentication: AuthenticationReducer,
  building: BuildingReducer,
  alerts: AlertsReducer,
  LTBForm: LTBFormReducer,
});

const configureStore = () => createStore(rootReducer, applyMiddleware(thunk));

export default configureStore;
