import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoubleDialog from "../../components/DoubleDialog";
import EditProfileForm from "./EditProfileForm";

import { getConfig } from "../../../services/billingServices.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

function EditProfileDoubleDialog(props) {
  const classes = useStyles();

  const [stripe, setStripe] = useState(null);
  const getSripeConfig = async () => {
    const config = await getConfig();

    if (config) setStripe(await loadStripe(config.publishableKey));
  };

  useEffect(() => {
    getSripeConfig();
  }, []);

  const [confirmationData, setConfirmationData] = useState(false);

  //The function called if the confirm button is clicked and the callback function for confirm is successful
  const closeDialogSuccessfully = () => {
    props.addToast("Profile Successfully Edited", "success");
    setConfirmationData(false);
    props.setEditProfileDialog(false);
    props.updateUser();
    props.updateCustomer();
    if (props.form === "paymentMethod") props.updateBillingInfo();
  };

  //The function that sets up the text and callback for the confirmation dialog
  const setConfirmationDialog = (confirmCallback, deleteConfirmation) => {
    if (deleteConfirmation)
      setConfirmationData({
        title: "Are you sure you want to delete your signature?", //The title of the confirmation dialog
        body: "This action cannot be reverted", //The body of the confirmation dialog
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) closeDialogSuccessfully();
          else setConfirmationData(false);
        },
        primaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the primary button (You can also use secondaryBtnClass)
      });
    else
      setConfirmationData({
        title: "Please ensure the information you've entered is correct", //The title of the confirmation dialog
        body: "This information will be used on official government forms", //The body of the confirmation dialog
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) closeDialogSuccessfully();
          else setConfirmationData(false);
        },
        secondaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the secondary button (You can also use primaryBtnClass)
      });
  };

  return (
    <DoubleDialog
      mainTitle={"Edit Profile"}
      mainDialogOpen={props.editProfileDialog} //The condition for the main dialog to open
      closeMainDialog={() => props.setEditProfileDialog(false)} //A function to close the main dialog
      confirmationDialogData={confirmationData} //The title, body, and callback of the confirmation dialog
      closeConfirmationDialog={() => setConfirmationData(false)} //A function to close the confirmation dialog
      width={"sm"} //maxWidth prop for the main dialog
    >
      {stripe ? (
        <Elements stripe={stripe}>
          <EditProfileForm
            form={props.form}
            fields={props.fields}
            addToast={props.addToast}
            setConfirmationCallback={setConfirmationDialog}
          />
        </Elements>
      ) : null}
    </DoubleDialog>
  );
}

export default EditProfileDoubleDialog;
