import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { lightGreen, lightBlue } from "@material-ui/core/colors";

const breakpoints = createBreakpoints({});

const TSLBrand = createTheme({
  palette: {
    primary: {
      main: lightBlue[700],
    },
    secondary: {
      main: lightGreen["A700"],
    },
    background: {
      default: "#1b1b1b",
      paper: "#282828",
      mid: "#252525",
    },
    type: "dark",
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "var(--tsl-brand-border-radius)",
        "&:before, &:after": {
          content: '""',
          position: "absolute",
          left: "25px",
          right: "0px",
          zIndex: "999",
          height: "3px",
          width: "calc(100% - 50px)",
          borderRadius: "5px",
          backgroundPosition: "0% 0%",
          background:
            "linear-gradient(to right, rgba(255,255,255,0.6), rgba(255,255,255,0), rgba(255,255,255,0.9), rgba(255,255,255,0), rgba(255,255,255,0.6), rgba(255,255,255,0))",
          backgroundSize: "500% auto",
          animation: "gradient 4s linear infinite",
          maskImage: "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1), rgba(0,0,0,0))",
        },
        "&:before": {
          top: "-2px",
          left: "100px",
          width: "calc(100% - 135px)",
        },
        "&:after": {
          bottom: "0px",
        },
      },
    },
    MuiDialog: {
      container: {
        [breakpoints.down("xs")]: {
          alignItems: "flex-end",
        },
      },
      paper: {
        borderRadius: "var(--tsl-brand-border-radius)",
        [breakpoints.down("xs")]: {
          margin: 0,
          width: "100vw",
          borderRadius: "var(--tsl-brand-border-radius) var(--tsl-brand-border-radius) 0px 0px",
        },
      },
    },
    MuiButton: {
      root: {
        transition: "all var(--tsl-brand-transition-time)",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 2px",
        borderRadius: "var(--tsl-brand-border-radius)",
        "&:active": {
          boxShadow: "none !important",
          transform: "translate3d(0px, 1px, 0px)",
        },
        "&:hover": {
          backgroundPosition: "right center",
          backgroundColor: null,
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: null,
        },
      },
      containedPrimary: {
        background:
          "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.4), rgba(255,255,255,0.4), rgba(255,255,255,0))",
        backgroundSize: "500% 500%",
        backgroundPosition: "left center",
        transition: "all .3s ease-in-out",
        "&:hover": {
          backgroundColor: null,
        },
      },
      containedSecondary: {
        background:
          "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.4), rgba(255,255,255,0.4), rgba(255,255,255,0))",
        backgroundSize: "500% 500%",
        backgroundPosition: "left center",
        transition: "all .3s ease-in-out",
        "&:hover": {
          backgroundColor: null,
        },
      },
    },
    MuiCard: {
      root: {
        transition: "all var(--tsl-brand-transition-time)",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px",
        "&:hover": {
          boxShadow: "rgba(0, 0, 0, 0.22) 0px 19px 43px",
          transform: "translate3d(0px, -1px, 0px)",
        },
        borderRadius: "var(--tsl-brand-border-radius)",
      },
    },
    MuiCardContent: {
      root: {
        padding: "var(--tsl-brand-card-padding)",
      },
    },
    MuiInputLabel: {
      shrink: {
        marginLeft: "6px",
        "@supports (-webkit-touch-callout: none)": {
          marginLeft: "18px",
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderWidth: "2px",
      },
      legendNotched: {
        paddingRight: "10px",
        "@supports (-webkit-touch-callout: none)": {
          paddingRight: "20px",
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: "none",
      },
    },
  },
});

export default TSLBrand;
