import React from "react";
import { Switch, Route } from "react-router-dom";
import Forms from "./Forms";
import FormFiller from "./FormFiller";
import FormViewer from "./FormViewer";
import FormCheckout from "./FormCheckout";

function FormRouter(props) {
  return (
    <Switch>
      <Route path="/:property_id/forms/checkout" component={FormCheckout} />
      <Route path="/:property_id/forms/viewer" component={FormViewer} />
      <Route path="/:property_id/forms/:form_id" component={FormFiller} />
      <Route
        path="/:property_id/forms"
        render={() => (
          <Forms
            historyExpanded={props.historyExpanded}
            setHistoryExpanded={props.setHistoryExpanded}
            history={props.history}
          />
        )}
      />
    </Switch>
  );
}

export default FormRouter;
