import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { refreshUser } from "./services/authenticationServices";
import { getUser } from "./services/userServices";
import { setUser } from "./redux/actions/authenticationActions";
import Loading from "./pages/components/Loading";

import { hasPaidSubscription } from "./services/billingServices.js";

function AppRoute({ dispatch, user, loading, setUser, component: Component, ...rest }) {
  const [userRefreshed, setUserRefresh] = useState(false);
  const [userHasPaid, setUserHasPaid] = useState(null);

  useEffect(() => {
    getUser(dispatch);
  }, []);

  useEffect(() => {
    (async () => {
      if (!user && !loading) {
        await refreshUser(dispatch);
        setUserRefresh(true);
      }
      setUserHasPaid(await hasPaidSubscription());
    })();
  }, [user]);

  return loading || (!user && !userRefreshed) || userHasPaid == null ? (
    <Loading />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        user !== null && userHasPaid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/authentication",
              state: { referer: props.location },
            }}
          />
        )
      }
    />
  );
}

const mapState = (state) => {
  return {
    user: state.authentication.user,
    loading: state.authentication.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setUser: (user) => dispatch(setUser(user)),
  };
};
export default connect(mapState, mapDispatch)(AppRoute);
