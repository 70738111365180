import React, { useState, useEffect } from "react";

import { useForm, Controller, useFormState } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Collapse from "@material-ui/core/Collapse";

import Loading from "../../../components/Loading";
import { GooglePlacesAutocompleteField } from "../../../components/googlePlacesAutocomplete";

import { editProperty, deleteProperty } from "../../../../services/propertyServices.js";
import { deleteBuilding, editBuilding } from "../../../../services/buildingServices";
import { deleteSubscription } from "../../../../services/billingServices";

const errorMessages = {
  streetNum: {
    required: "Please enter the street #",
    pattern: "Must be between 0 and 9999999",
  },
  streetName: {
    required: "Please enter the street name",
    length: "The street name cannot exceed 90 characters",
  },
  streetType: {
    required: "Please enter the street type",
    length: "The street name cannot exceed 15 characters",
  },
  direction: {
    pattern: "If necessary please choose a direction from the dropdown menu",
  },
  unitNum: {
    pattern: "Must be between 0 and 9999999",
  },
  municipality: {
    required: "Please enter the municipality",
    length: "The street name cannot exceed 90 characters",
  },
  province: {
    required: "Please choose a province",
    pattern: "The province must be Ontario",
  },
  postalCode: {
    required: "Please enter the postal code",
    pattern: "The postal code must be in the correct format, e.g. A1B 2C3",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  form: {
    width: "100%",
  },
  autocomplete: {
    padding: theme.spacing(0, 0, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  deletePropertyButton: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
    margin: theme.spacing(3, 0, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(3, 0, 3),
    },
  },
}));

const initialAddressData = {
  streetNum: "",
  streetName: "",
  streetType: "",
  direction: "",
  municipality: "",
  province: "",
  postalCode: "",
};

function EditPropertyForm(props) {
  const {
    handleSubmit,
    setError,
    trigger,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const { isSubmitted } = useFormState({
    control,
  });

  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [propertyAddressData, setPropertyAddressData] = useState(null);

  const fillFields = (addressData) => {
    setValue("streetNum", addressData.streetNum);
    setValue("streetName", addressData.streetName);
    setValue("streetType", addressData.streetType);
    setValue("direction", addressData.direction);
    setValue("municipality", addressData.municipality);
    setValue("postalCode", addressData.postalCode);
  };

  const onAddressSelected = (isAddressSelected, addressData) => {
    setIsAddressSelected(isAddressSelected);

    if (isSubmitted) isAddressSelected ? trigger("address") : setError("address", { type: "chooseOption" });

    if (addressData) fillFields(addressData);
    else fillFields(initialAddressData);

    trigger(["streetNum", "streetName", "streetType", "municipality", "province", "postalCode"]);
  };

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  };

  const onSubmit = async (data) => {
    let confirmCallback = async () => {
      if (props.property) {
        let propertyData = {
          unitNum: data.unitNum,
          ID: props.property["ID"],
        };
        delete data.address;
        console.log("propertyData: ", propertyData);

        const propertyEdited = await editProperty(propertyData, handleErrors);
        if (!propertyEdited || propertyEdited === "alreadyExists") {
          props.addToast(
            !propertyEdited ? "Editing Property Failed" : "A Property With This Address And Unit # Already Exists",
            "error"
          );
          return false;
        }
      }

      delete data.unitNum;
      let buildingData = {
        ID: props.building["ID"],
        userID: props.building["userID"],
        ...data,
      };
      console.log("buildingData: ", buildingData);

      // only edit building if the user edited their property address other than the unitNum OR user edits the building address directly
      if (JSON.stringify(buildingData) !== JSON.stringify(props.building)) {
        delete buildingData.userID;
        const buildingEdited = await editBuilding(buildingData, handleErrors);
        if (!buildingEdited || buildingEdited.address === "alreadyExists") {
          props.addToast(
            !buildingEdited ? "Editing building Failed" : "A Building With This Address Already Exists",
            "error"
          );
          return false;
        }
      }

      return true;
    };

    props.setConfirmationCallback(confirmCallback, {
      currentAction: "Edit",
    });
  };

  let updatePropertyAddressData = () => {
    const address =
      `${props.property && props.property["unitNum"] ? `#${props.property["unitNum"]} ` : ""}${
        props.building["streetNum"]
      } ${props.building["streetName"]} ` +
      `${props.building["streetType"]}${props.building["direction"] ? ` ${props.building["direction"]}` : ""}, ` +
      `${props.building["municipality"]}, ${props.building["province"]} ${props.building["postalCode"]}`;

    setPropertyAddressData({
      address: address,
      streetNum: props.building["streetNum"],
      streetName: props.building["streetName"],
      streetType: props.building["streetType"],
      direction: props.building["direction"],
      unitNum: props.property ? props.property["unitNum"] : "",
      municipality: props.building["municipality"],
      province: props.building["province"],
      postalCode: props.building["postalCode"],
    });
  };

  useEffect(() => {
    updatePropertyAddressData();
  }, [props.property, props.building]);

  useEffect(() => {
    console.log(propertyAddressData);
    let field;
    for (field in propertyAddressData) {
      if (propertyAddressData[field]) setValue(field, propertyAddressData[field]);
    }
    setIsAddressSelected(true);
  }, [propertyAddressData]);

  const handleDeleteProperty = async () => {
    let confirmCallback = async () => {
      if (props.property) {
        const propertyDeleted = await deleteProperty({ ID: props.property["ID"] }, handleErrors, props.dispatch);
        if (propertyDeleted) {
          await deleteSubscription({ subscriptionID: props.property["subscriptionID"] });
          return true;
        }
      } else {
        const buildingDeleted = await deleteBuilding({ ID: props.building["ID"] }, handleErrors, props.dispatch);
        if (buildingDeleted) {
          for (let property of props.building.properties)
            await deleteSubscription({ subscriptionID: property["subscriptionID"] });
          return true;
        }
      }

      props.addToast(`Deleting ${props.property ? "Property" : "Building"} Failed`, "error");
      return false;
    };

    props.setConfirmationCallback(confirmCallback, {
      currentAction: "Delet",
    });
  };

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.main}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
      >
        <Grid container spacing={1} className={classes.autocomplete}>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              rules={{
                validate: { chooseOption: () => isAddressSelected },
              }}
              render={({ field: { value } }) =>
                value ? (
                  <GooglePlacesAutocompleteField
                    error={errors.address}
                    onAddressSelected={onAddressSelected}
                    defaultAddress={value}
                  />
                ) : null
              }
            />
          </Grid>
        </Grid>
        <Collapse in={isAddressSelected}>
          <Grid container spacing={1} style={{ visibility: isAddressSelected ? "visible" : "hidden" }}>
            <Grid item xs={12} sm={6} style={props.property ? {} : { display: "none" }}>
              <Controller
                name="unitNum"
                control={control}
                defaultValue=""
                rules={{
                  pattern: /^\d{0,7}$/,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="unitNum"
                    label="Unit #"
                    error={errors.unitNum && errors.unitNum !== null}
                    helperText={errors.unitNum ? errorMessages["unitNum"][errors.unitNum.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="streetNum"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^\d{0,7}$/,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="streetNum"
                    label="Street #"
                    error={errors.streetNum && errors.streetNum != null}
                    helperText={errors.streetNum ? errorMessages["streetNum"][errors.streetNum.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="streetName"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: {
                    length: (value) => value.length <= 90,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="streetName"
                    label="Street Name"
                    error={errors.streetName && errors.streetName != null}
                    helperText={errors.streetName ? errorMessages["streetName"][errors.streetName.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="streetType"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: {
                    length: (value) => value.length <= 15,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="streetType"
                    label="Street Type"
                    error={errors.streetType && errors.streetType != null}
                    helperText={errors.streetType ? errorMessages["streetType"][errors.streetType.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="direction"
                control={control}
                defaultValue=""
                rules={{
                  pattern: /^[NSEW]$/,
                }}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={errors.direction && errors.direction != null}
                  >
                    <InputLabel id="directionLabel">Direction</InputLabel>
                    <Select
                      {...field}
                      id="direction"
                      labelId="directionLabel"
                      label="Direction"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>none</em>
                      </MenuItem>
                      <MenuItem value="N">North</MenuItem>
                      <MenuItem value="S">South</MenuItem>
                      <MenuItem value="E">East</MenuItem>
                      <MenuItem value="W">West</MenuItem>
                    </Select>
                    {errors.direction && (
                      <FormHelperText>{errorMessages["direction"][errors.direction.type]}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="municipality"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: {
                    length: (value) => value.length <= 90,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="municipality"
                    label="Municipality"
                    error={errors.municipality && errors.municipality != null}
                    helperText={errors.municipality ? errorMessages["municipality"][errors.municipality.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="province"
                control={control}
                defaultValue="ON"
                rules={{
                  required: true,
                  pattern: /^ON$/,
                }}
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    error={errors.province && errors.province != null}
                  >
                    <InputLabel id="provinceLabel">Province</InputLabel>
                    <Select
                      {...field}
                      id="province"
                      labelId="provinceLabel"
                      label="Province *"
                      readOnly
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="ON">Ontario</MenuItem>
                    </Select>
                    {errors.province && (
                      <FormHelperText>{errorMessages["province"][errors.province.type]}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={props.property ? 6 : 12}>
              <Controller
                name="postalCode"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="postalCode"
                    label="Postal Code"
                    error={errors.postalCode && errors.postalCode != null}
                    helperText={errors.postalCode ? errorMessages["postalCode"][errors.postalCode.type] : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Edit {props.property ? "Property" : "Building"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className={classes.deletePropertyButton}
                onClick={() => handleDeleteProperty()}
              >
                Delete {props.property ? "Property" : "Building"}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </form>
    </div>
  );
}

export default EditPropertyForm;
