import React from "react";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Logo from "../../assets/logos/simpleLandlordFullsize.png";
import Button from "@material-ui/core/Button";
import { NoEncryption } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    top: "0",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  toolbar: {
    marginLeft: theme.spacing(1),
    marginBottom: "-100%",
    [`${theme.breakpoints.down("xs")}`]: {
      margin: "0",
      padding: "0",
    },
  },
  logoPicture: {
    height: 46,
    [`${theme.breakpoints.down("xs")}`]: {
      display: "none",
    },
  },
  menuButtons: {
    [`${theme.breakpoints.down("xs")}`]: {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
    },
  },
  callToAction: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(4)}px`,
    margin: theme.spacing(1),
    backgroundColor: "rgb(0, 71, 153)",
    textTransform: "none",
  },
  secondaryButton: {
    margin: theme.spacing(1),
    minWidth: "64px",
    textAlign: "center",
  },
  underlineHover: {
    fontWeight: "600",
    color: "white",
    textTransform: "none",
    textDecoration: "none",
    ["&:hover"]: {
      textDecoration: "none",
    },
  },
  desktopMenu: {
    display: "none",
    [`${theme.breakpoints.up("sm")}`]: {
      display: "flex",
    },
  },
  desktopMenuOptions: {
    minWidth: "auto",
    textTransform: "none",
    opacity: 0.75,
    padding: theme.spacing(0, 5, 0),
    [`${theme.breakpoints.down("sm")}`]: {
      padding: theme.spacing(0, 1.5, 0),
    },
    "&$selected": {
      color: "white",
      opacity: 1,
    },
  },
  selected: {},
}));

const Header = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Link component={RouterLink} to="/">
                <img src={Logo} className={classes.logoPicture} alt="The Simple Landlord Logo" />
              </Link>
            </Grid>
            <Grid item className={classes.menuButtons}>
              <Link
                component={RouterLink}
                to="/authentication/login"
                className={classes.secondaryButton}
                classes={{ underlineHover: classes.underlineHover }}
              >
                Log In
              </Link>
              <Link
                component={RouterLink}
                to="/authentication/signup"
                classes={{ underlineHover: classes.underlineHover }}
              >
                <Button className={classes.callToAction}>Sign Up</Button>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({
  dispatch: (data) => dispatch(data),
});

export default connect(mapState, mapDispatch)(Header);
