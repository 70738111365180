import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddPhotoIcon from "@material-ui/icons/AddAPhotoRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import MoreIcon from "@material-ui/icons/MoreHorizRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import Slide from "@material-ui/core/Slide";

import ScrollContainer from "react-indiana-drag-scroll";
import Container from "@material-ui/core/Container";
import Dropzone from "../../components/DropzoneDialog";
import ToastFactory from "../../components/ToastFactory";
import EditPropertyDoubleDialog from "./components/EditPropertyDoubleDialog";
import { getProperty } from "../../../services/propertyServices";
import { getPicture, addPicture, deletePicture } from "../../../services/property/pictureServices";
import { useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "./components/Alert";
import moment from "moment";
import { editUser } from "../../../services/userServices";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  addressSection: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "45px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginLeft: "35px",
    },
  },
  topAddressText: {
    fontSize: "1.6rem",
    marginBottom: "0px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2rem",
    },
  },
  bottomAddressText: {
    fontSize: "1.3rem",
    color: "lightgrey",
    marginBottom: "15px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.9rem",
    },
  },
  editIcon: {
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: "10px",
      fontSize: "0.9em",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  boldSecondary: {
    color: theme.palette.secondary.main,
  },
  listWrapper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflow: "auto",
    margin: "8px 0px",
  },
  listGrid: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    height: "33vh",
    margin: "8px 0px",
  },
  listImage: {
    borderRadius: "var(--tsl-brand-border-radius)",
    minWidth: "100%",
    height: "33vh",
    objectFit: "cover",
    zIndex: 10,
    [`${theme.breakpoints.down("xs")}`]: {
      maxWidth: "100%",
    },
  },
  propertyImageContainer: {
    position: "relative",
    marginLeft: 8,
    marginRight: 8,
    "&:first-of-type": {
      marginLeft: 0,
    },
    "&:last-of-type": {
      marginRight: 0,
    },
    [`${theme.breakpoints.up("sm")}`]: {
      "&:hover > div": {
        opacity: 1,
      },
    },
  },
  propertyImageOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "33vh",
    width: "100%",
    background: "rgba(0, 0, 0, 0.6)",
    borderRadius: "var(--tsl-brand-border-radius)",
    transition: "all var(--tsl-brand-transition-time)",
    opacity: 0,
  },
  propertyImageOverlayMobile: {
    position: "absolute",
    top: 0,
    display: "flex",
    [`${theme.breakpoints.up("sm")}`]: {
      display: "none",
    },
  },
  propertyImageDateContainer: {
    position: "absolute",
    display: "flex",
    bottom: 12,
    left: 10,
    [`${theme.breakpoints.down("xs")}`]: {
      left: 12,
    },
  },
  propertyImageTagContainer: {
    position: "absolute",
    display: "flex",
    bottom: 12,
    right: 10,
    [`${theme.breakpoints.down("xs")}`]: {
      right: 12,
    },
  },
  propertyImageDate: {
    background: "rgba(0, 0, 0, 0.6)",
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "5px 10px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  propertyImageIconContainer: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  propertyImageIcon: {
    fontSize: "1.8em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.6em",
    },
  },
  propertyImageIconMobile: {
    fontSize: "1.6em",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "var(--tsl-brand-border-radius)",
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  tipArrow: {
    "&:before": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tipBubble: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "10px 20px",
  },
  tooltipButton: {
    background: "none",
    boxShadow: "none",
    padding: "0px",
    fontWeight: "600",
    cursor: "pointer",
  },
}));

let propertyImageLock = 0;

function Property(props) {
  const classes = useStyles();
  const { property_id } = useParams();
  const [editPropertyDialog, setEditPropertyDialog] = useState(false);
  const [propertyImages, setPropertyImages] = useState(null);
  const [mobileOptions, setMobileOptions] = useState(0);
  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const [tips, setTips] = useState(props.profile?.tips ? JSON.parse(props.profile.tips) : {});

  const getPropertyAlerts = async () => {
    if (!props.alerts) {
      setAlerts(null);
      return;
    }

    let _alerts = props.alerts.filter(
      (alert) =>
        alert.propertyID == property_id &&
        [
          "rentDue",
          "depositDue",
          "conditionReport",
          "rentIncrease",
          "moveIn",
          "moveOut",
          "useRentDeposit",
          "formNextStep",
        ].includes(alert.type)
    );

    for (let _alert of _alerts)
      for (let lease of props.property["leases"]) if (_alert["leaseID"] == lease["ID"]) _alert["lease"] = lease;

    setAlerts(_alerts);
  };

  const getPropertyImages = async (lock) => {
    const images = JSON.parse(props.property.images);
    if (!images) {
      setPropertyImages([]);
      return;
    }

    let imageIndex = 0;
    for (let image of images) {
      const propertyImage = await getPicture({
        propertyID: property_id,
        imageIndex: imageIndex,
      });

      if (lock != propertyImageLock) return;

      setPropertyImages((_propertyImages) => {
        return imageIndex == 0
          ? [{ date: image.date, image: propertyImage, tag: images[imageIndex]?.tag }]
          : [..._propertyImages, { date: image.date, image: propertyImage, tag: images[imageIndex]?.tag }];
      });

      imageIndex++;
    }
  };

  useEffect(() => {
    propertyImageLock = 0;
    props.refreshProperty();
  }, []);

  useEffect(() => {
    getPropertyAlerts();
  }, [props.alerts, props.property]);

  useEffect(() => {
    setTips(props.profile?.tips ? JSON.parse(props.profile.tips) : {});
  }, [props.profile]);

  useEffect(() => {
    if (!props.property || Object.keys(props.property).length <= 0) return;
    propertyImageLock++;
    getPropertyImages(propertyImageLock);
  }, [props.property]);

  const deletePropertyImage = async (imageIndex) => {
    const pictureDeleted = await deletePicture({
      propertyID: property_id,
      imageIndex: imageIndex,
    });

    if (pictureDeleted) {
      setDeleteImageDialog(false);
      addToast("Property Image Successfully Deleted", "success");
      props.refreshProperty();
    } else addToast("Deleting Image Failed", "error");
  };

  const [propertyImageDialogOpen, setPropertyImageDialogOpen] = useState(false);
  const onPropertyImageUpload = async (propertyImage) => {
    const pictureAdded = await addPicture({
      propertyID: property_id,
      propertyImage: propertyImage,
    });

    if (pictureAdded) {
      addToast("Property Image Successfully Added", "success");
      props.refreshProperty();
    } else addToast("Adding Image Failed", "error");
  };

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Dropzone
        fileName={"Property Image"}
        onFileUpload={onPropertyImageUpload}
        dropzoneDialogOpen={propertyImageDialogOpen}
        setDropzoneDialogOpen={setPropertyImageDialogOpen}
      />
      <Dialog
        open={deleteImageDialog !== false}
        onClose={() => setDeleteImageDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Are you sure you want to delete this property image?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                This action cannot be reverted
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={() => deletePropertyImage(deleteImageDialog)}
                variant="contained"
                className={classes.deleteBtn}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth onClick={() => setDeleteImageDialog(false)} color="primary" variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <EditPropertyDoubleDialog
        addToast={addToast}
        dispatch={props.dispatch}
        history={props.history}
        property={props.property}
        building={props.building}
        refreshProperty={props.refreshProperty}
        editPropertyDialog={editPropertyDialog}
        setEditPropertyDialog={setEditPropertyDialog}
      />
      <Container maxWidth={propertyImages && propertyImages.length > 1 ? false : "lg"}>
        <ScrollContainer
          className={classes.listWrapper}
          vertical={false}
          hideScrollbars={false}
          onClick={() => setMobileOptions(0)}
        >
          <div className={classes.listGrid}>
            {propertyImages && propertyImages.length > 0 ? (
              propertyImages.map((propertyImage, index) => (
                <div className={classes.propertyImageContainer} key={"property-image-" + index}>
                  <img className={classes.listImage} src={propertyImage.image} alt={"property-image-" + index} />
                  <div
                    className={classes.propertyImageOverlay}
                    style={mobile ? (mobileOptions ? { opacity: 1 } : { display: "none" }) : {}}
                  >
                    <Grid container className={classes.propertyImageIconContainer}>
                      <Grid item xs={6}>
                        <Tooltip title="Add Image" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                          <IconButton onClick={() => setPropertyImageDialogOpen(true)}>
                            <AddPhotoIcon className={classes.propertyImageIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip title="Delete Image" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                          <IconButton onClick={() => setDeleteImageDialog(index)}>
                            <DeleteIcon className={classes.propertyImageIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.propertyImageDateContainer}>
                    <Typography variant="subtitle1" className={classes.propertyImageDate}>
                      {moment(propertyImage.date).format("MM/DD/YYYY")}
                    </Typography>
                  </div>
                  {propertyImage.tag ? (
                    <div className={classes.propertyImageTagContainer}>
                      <Typography variant="subtitle1" className={classes.propertyImageDate}>
                        {propertyImage.tag}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={classes.propertyImageOverlayMobile}>
                    <Grid container>
                      <Grid item xs={12}>
                        <IconButton onClick={() => setMobileOptions(!mobileOptions)}>
                          <MoreIcon className={classes.propertyImageIconMobile} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.propertyImageContainer} key={"property-image-default"}>
                <img
                  className={classes.listImage}
                  src={process.env.PUBLIC_URL + "/testImages/Default-Large.jpg"}
                  alt={"property-default"}
                />
                <div className={classes.propertyImageOverlay} style={{ opacity: 1 }}>
                  <Grid container className={classes.propertyImageIconContainer}>
                    <Grid item xs={12}>
                      <Tooltip title="Add Image" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                        <IconButton onClick={() => setPropertyImageDialogOpen(true)}>
                          <AddPhotoIcon className={classes.propertyImageIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </ScrollContainer>

        <Grid item xs={12}>
          <Paper className={classes.content}>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {props.property && props.building ? (
                  <div className={classes.addressSection}>
                    <div>
                      <Typography gutterBottom variant="h5" className={classes.topAddressText}>
                        <b>
                          {`${props.property["unitNum"] ? `#${props.property["unitNum"]} ` : ""}${
                            props.building["streetNum"]
                          } ${props.building["streetName"]} ` +
                            `${props.building["streetType"]}${
                              props.building["direction"] ? ` ${props.building["direction"]}` : ""
                            }`}
                        </b>
                      </Typography>
                      <Typography gutterBottom variant="h5" className={classes.bottomAddressText}>
                        {`${props.building["municipality"]}, ${props.building["province"]} ${props.building["postalCode"]}`}
                      </Typography>
                    </div>
                    <Tooltip title="Edit Property" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                      <IconButton
                        onClick={() => setEditPropertyDialog(true)}
                        style={{ marginBottom: 8, height: "59px" }}
                      >
                        <EditIcon fontSize="large" className={classes.editIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
              <Tooltip
                placement="top"
                arrow
                open={Boolean(props?.lease && !tips?.dashboardAlert)}
                classes={{
                  tooltip: classes.tipBubble,
                  arrow: classes.tipArrow,
                }}
                PopperProps={{
                  onClick: () => editUser({ tips: { ...tips, dashboardAlert: true } }, null, props.dispatch),
                  style: { pointerEvents: "auto", marginBottom: "-20px" },
                }}
                title={
                  <>
                    <Typography style={{ textAlign: "center" }}>
                      Important alerts and actions that need to be taken for your property are located in the dashboard
                    </Typography>
                    <div style={{ textAlign: "right" }}>
                      <Button className={classes.tooltipButton}>OK</Button>
                    </div>
                  </>
                }
              >
                <Grid item xs={12}></Grid>
              </Tooltip>
              {alerts ? (
                alerts.map((alert) => (
                  <Alert
                    key={alert.ID}
                    addToast={addToast}
                    alert={alert}
                    globals={{
                      building: props.building,
                      property: props.property,
                      lease: alert?.lease,
                    }}
                    data={JSON.parse(alert.data)}
                    dispatch={props.dispatch}
                  />
                ))
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </>
  );
}

const mapState = (state) => {
  return {
    alerts: state.alerts.alerts,
    profile: state.authentication.profile,
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Property);
