import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setLTBFormData } from "../../../redux/actions/LTBFormActions";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import Collapse from "@material-ui/core/Collapse";
import Slide from "@material-ui/core/Slide";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ToastFactory from "../../components/ToastFactory";
import EditLeaseDoubleDialog from "./components/EditLeaseDoubleDialog";
import TenantDoubleDialog from "./components/TenantDoubleDialog";
import TenantList from "./components/TenantList";

import updateTenantList from "../../../utils/updateTenantList.js";
import { getLease } from "../../../services/property/leaseServices.js";
import { editLease } from "../../../services/property/leaseServices.js";
import { calculateLedgerBalance } from "../../../utils/ledgerBalance.js";
import { getLedger, addLedger } from "../../../services/property/ledgerServices.js";
import { problemObj, createForm } from "./forms/problemList";
import { getForms } from "../../../services/property/form/formServices";
import moment from "moment";
import LeaseHistory from "../components/LeaseHistory";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 96px)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
    paddingTop: "60px",
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "10px",
  },
  infoCard: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  leaseItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 20px",
    marginBottom: "12px",
  },
  leaseField: {
    fontSize: "1.3em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  leaseValue: {
    color: "rgba(255, 255, 255, 0.4)",
    fontSize: "1.3em",
    maxWidth: "50vw",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
      maxWidth: "55vw",
    },
  },
  leaseRow: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "12px",

      [`${theme.breakpoints.down("xs")}`]: {
        paddingBottom: "0px",
      },
    },
    "& > h6": {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "12px 0px",
    },
  },
  editLeaseButton: {
    marginLeft: "20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "inherit",
      marginLeft: "10px",
    },
  },
  leaseTitle: {
    margin: theme.spacing(3, 0, 3),
  },
  leaseRowSwitch: {
    marginTop: "6px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginTop: "2px",
    },
  },
  switchRoot: {
    width: 28,
    height: 16,
    [theme.breakpoints.up("sm")]: {
      width: 42,
      height: 22,
    },
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$switchChecked": {
      transform: "translateX(12px)",
      [theme.breakpoints.up("sm")]: {
        transform: "translateX(19px)",
      },
      color: theme.palette.common.white,
      "& + $switchTrack": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  switchThumb: {
    width: 12,
    height: 12,
    [theme.breakpoints.up("sm")]: {
      width: 18,
      height: 18,
    },
    boxShadow: "none",
  },
  switchTrack: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 24 / 2,
    },
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  switchChecked: {},
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  moreBtn: {
    paddingLeft: "26px",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $moreArrow": {
        transform: "translateY(2px)",
      },
      "& $lessArrow": {
        transform: "translateY(-2px)",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  moreArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  lessArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  useDepositButton: {
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  useDepositDialogHeader: {
    margin: theme.spacing(4, 0, 1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      margin: theme.spacing(4, 0, 1),
    },
  },
  useDepositDialogSubmit: {
    margin: theme.spacing(2, 0, 2),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(-1, 0, 1),
    },
  },
}));

const rentInfoFields = [
  "rentPrice",
  "rentCycle",
  "leaseStartDate",
  "rentCollectionDay",
  "moveInDate",
  "moveOutDate",
  "NSFFee",
  "lastRentIncreaseDate",
];

const rentDepositFields = ["rentDeposit", "depositDate", "initialDeposit", "lastInterestPeriod", "keyDeposit"];

const leaseFieldNames = {
  rentPrice: "Rent Price",
  rentCycle: "Rent Cycle",
  leaseStartDate: "Lease Start Date",
  rentCollectionDay: "Rent Collection",
  moveInDate: "Move-In Date",
  moveOutDate: "Move-Out Date",
  NSFFee: "NSF Admin Fee",
  lastRentIncreaseDate: "Last Rent Increase Date",
  fixedTermDate: "Fixed-Term End Date",
  rentDeposit: "Rent Currently On Deposit",
  depositDate: "Rent Deposit Date",
  initialDeposit: "Initial Rent Deposit",
  lastInterestPeriod: "Last Interest Period",
  keyDeposit: "Key Deposit",
  leaseAgreement: "Lease Agreement",
};

function Lease(props) {
  const { property_id } = useParams();
  const [deleteLeaseSectionDialog, setDeleteLeaseSectionDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({});
  const [rentInfo, setRentInfo] = useState([]);
  const [fixedTerm, setFixedTerm] = useState([]);
  const [showFixedTerm, setShowFixedTerm] = useState(false);
  const [rentDeposit, setRentDeposit] = useState([]);
  const [showRentDeposit, setShowRentDeposit] = useState(false);
  const [leaseAgreement, setLeaseAgreement] = useState([]);
  const [leaseAgreementData, setLeaseAgreementData] = useState([]);
  const [editLeaseDialog, setEditLeaseDialog] = useState(false);
  const [ledgerBalance, setLedgerBalance] = useState(null);
  const [useDepositDialog, setUseDepositDialog] = useState(false);
  const [tenantFormData, setTenantFormData] = useState(false);
  const [tenantsByLease, setTenantsByLease] = useState({});

  const updateLease = async () => {
    let leaseReceived = await getLease({ ID: props.lease["ID"] });

    let rentInfo = [];
    let rentDepositInfo = [];
    let rentCycle, field;

    for (field in leaseReceived) {
      if (rentInfoFields.includes(field)) {
        if ((field === "rentPrice" || field === "NSFFee") && leaseReceived[field])
          leaseReceived[field] = "$" + leaseReceived[field];
        if (field === "rentCycle") rentCycle = leaseReceived[field];
        else if (field === "rentCollectionDay") {
          if (leaseReceived[field]?.charAt(0) === "0") leaseReceived[field] = leaseReceived[field]?.charAt(1);
          const daySuffix = moment.localeData().ordinal(leaseReceived[field]);
          rentInfo.push({
            key: field,
            field: leaseFieldNames[field],
            rentCycle: rentCycle,
            rentCollectionDay: leaseReceived[field],
            value: `${rentCycle} ${
              rentCycle === "Monthly"
                ? `on the ${daySuffix}`
                : rentCycle === "Weekly"
                ? `on ${leaseReceived[field]}s`
                : ""
            }`,
          });
        } else
          rentInfo.push({
            key: field,
            field: leaseFieldNames[field],
            value: leaseReceived[field],
          });
      } else if (rentDepositFields.includes(field)) {
        if (field === "rentDeposit" || field === "initialDeposit" || field === "keyDeposit") {
          if (leaseReceived[field]) {
            setShowRentDeposit(true);
            leaseReceived[field] = "$" + leaseReceived[field];
          } else leaseReceived[field] = null;
        }
        if (field === "lastInterestPeriod") {
          const interestPeriod = leaseReceived[field]?.split("/");
          rentDepositInfo.push({
            key: field,
            field: leaseFieldNames[field],
            interestStartDate: interestPeriod ? interestPeriod[0] : null,
            interestEndDate: interestPeriod ? interestPeriod[1] : null,
          });
        } else
          rentDepositInfo.push({
            key: field,
            field: leaseFieldNames[field],
            value: leaseReceived[field],
          });
      } else if (field === "fixedTermDate") {
        if (leaseReceived[field]) setShowFixedTerm(true);
        setFixedTerm({
          key: field,
          field: leaseFieldNames[field],
          value: leaseReceived[field],
        });
      } else if (field === "leaseAgreement") {
        setLeaseAgreement({
          key: field,
          field: leaseFieldNames[field],
          value: null,
        });
      }
    }
    setRentInfo(rentInfo);
    setRentDeposit(rentDepositInfo);
  };

  const updateLedgerBalance = async () => {
    const ledger = await getLedger({ leaseID: props.lease["ID"] });
    const newLedgerBalance = calculateLedgerBalance(ledger);
    console.log(newLedgerBalance);
    setLedgerBalance(newLedgerBalance);
  };

  useEffect(() => {
    if (!props.lease) return;
    updateLease();
    updateLedgerBalance();
  }, [props.lease]);

  const getLeaseAgreementData = async () => {
    const formsReceived = await getForms({ leaseID: props.lease["ID"] });
    const _leaseAgreementData = formsReceived.find((form) => form.type === "leaseAgreement");
    console.log(_leaseAgreementData);
    setLeaseAgreementData(_leaseAgreementData);
    if (!formsReceived) console.log("Failed to load formsReceived: ", formsReceived);
  };

  useEffect(() => {
    getLeaseAgreementData();
    updateTenantList(props.property["leases"], setTenantsByLease);
  }, []);

  const createEditLeaseDialog = (leaseData) => {
    let fields;
    if (leaseData.key === "rentCollectionDay")
      fields = {
        rentCycle: leaseData.rentCycle,
        rentCollectionDay: leaseData.rentCollectionDay,
      };
    else if (leaseData.key === "lastInterestPeriod")
      fields = {
        interestStartDate: leaseData.interestStartDate,
        interestEndDate: leaseData.interestEndDate,
      };
    else fields = { [leaseData.key]: leaseData.value };

    setEditLeaseDialog({
      form: leaseData.key,
      fields: fields,
    });
  };

  const deleteLeaseSection = async (section) => {
    let leaseData;
    if (section === "fixedTerm")
      leaseData = {
        ID: props.lease["ID"],
        fixedTermDate: "",
      };
    else if (section === "rentDeposit")
      leaseData = {
        ID: props.lease["ID"],
        rentDeposit: "",
        depositDate: "",
        initialDeposit: "",
        interestStartDate: "",
        interestEndDate: "",
      };
    console.log(leaseData);

    const leaseEdited = await editLease(leaseData, props.dispatch);

    if (leaseEdited) {
      setDeleteLeaseSectionDialog(false);
      if (section === "fixedTerm") setShowFixedTerm(false);
      else if (section === "rentDeposit") setShowRentDeposit(false);

      updateLease();
      props.refreshProperty();
      addToast("Lease Successfully Updated", "success");
    } else addToast("Updating Lease Failed", "error");
  };

  const handleUseDeposit = async (depositData) => {
    const useDepositTransaction = {
      leaseID: props.lease["ID"],
      ledgerType: "payment",
      ledgerReason: "Rent",
      ledgerDate: moment().format("YYYY-MM-DD"),
      ledgerAmount: depositData.difference > 0 ? ledgerBalance.balance : props?.lease?.rentDeposit,
      useDeposit: true,
    };
    console.log("useDepositTransaction: ", useDepositTransaction);
    const transactionAdded = await addLedger(useDepositTransaction);

    let leaseEdited = false;
    if (transactionAdded) {
      console.log("newRentDeposit: ", depositData.difference > 0 ? depositData.difference : 0);
      leaseEdited = await editLease(
        {
          ID: props.lease["ID"],
          rentDeposit: depositData.difference > 0 ? depositData.difference : 0,
        },
        props.dispatch
      );
    }

    if (transactionAdded && leaseEdited) {
      updateLease();
      updateLedgerBalance();
      props.refreshProperty();
      addToast("Ledger and Deposit Successfully Updated", "success");
    } else addToast("Unexpected Error Occured", "error");

    setUseDepositDialog(false);
  };

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const classes = useStyles();

  const confirmationDialogContent = (
    <Dialog
      open={deleteLeaseSectionDialog !== false}
      onClose={() => setDeleteLeaseSectionDialog(false)}
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.confirmationDialogContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" gutterBottom>
              Are you sure you want to delete all the
              {confirmationDialog.title} information?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              This action cannot be reverted
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              onClick={() => deleteLeaseSection(confirmationDialog.section)}
              variant="contained"
              className={classes.deleteBtn}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={() => setDeleteLeaseSectionDialog(false)} color="primary" variant="contained">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const [showMoreLeaseInfo, setShowMoreLeaseInfo] = useState(false);
  const importantLeaseInfoContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.leaseTitle} variant="h5" align="center">
          Lease Information
        </Typography>
        {rentInfo[0]?.value && (
          <Card
            key={rentInfo[0].key}
            className={`${classes.infoCard} ${classes.leaseItem}`}
            onClick={() => createEditLeaseDialog(rentInfo[0])}
          >
            <CardContent className={classes.leaseRow}>
              <Typography variant="h6" className={classes.leaseField}>
                {rentInfo[0].field}
              </Typography>
            </CardContent>

            <CardContent className={classes.leaseRow}>
              <Grid container>
                <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                  {rentInfo[0].value}
                </Typography>
                <EditIcon className={classes.editLeaseButton} />
              </Grid>
            </CardContent>
          </Card>
        )}
        {rentInfo[2]?.value && (
          <Card
            key={rentInfo[2].key}
            className={`${classes.infoCard} ${classes.leaseItem}`}
            onClick={() => createEditLeaseDialog(rentInfo[2])}
          >
            <CardContent className={classes.leaseRow}>
              <Typography variant="h6" className={classes.leaseField}>
                {rentInfo[2].field}
              </Typography>
            </CardContent>

            <CardContent className={classes.leaseRow}>
              <Grid container>
                <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                  {rentInfo[2].value}
                </Typography>
                <EditIcon className={classes.editLeaseButton} />
              </Grid>
            </CardContent>
          </Card>
        )}
        {fixedTerm?.value && (
          <Card
            key={fixedTerm.key}
            className={`${classes.infoCard} ${classes.leaseItem}`}
            onClick={() => createEditLeaseDialog(fixedTerm)}
          >
            <CardContent className={classes.leaseRow}>
              <Typography variant="h6" className={classes.leaseField}>
                {fixedTerm.field}
              </Typography>
            </CardContent>
            <CardContent className={classes.leaseRow}>
              <Grid container>
                <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                  {moment(fixedTerm.value).format("MMMM Do, YYYY")}
                </Typography>
                <EditIcon className={classes.editLeaseButton} />
              </Grid>
            </CardContent>
          </Card>
        )}
        {rentDeposit[0]?.value && (
          <Card
            key={rentDeposit[0].key}
            className={`${classes.infoCard} ${classes.leaseItem}`}
            onClick={() => createEditLeaseDialog(rentDeposit[0])}
          >
            <CardContent className={classes.leaseRow}>
              <Typography variant="h6" className={classes.leaseField}>
                {rentDeposit[0].field}
              </Typography>
            </CardContent>

            <CardContent className={classes.leaseRow}>
              <Grid container>
                <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                  {rentDeposit[0].value}
                </Typography>
                <EditIcon className={classes.editLeaseButton} />
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
      <div style={{ textAlign: "center" }}>
        <Button className={classes.moreBtn} variant="contained" onClick={() => setShowMoreLeaseInfo(true)}>
          Show More Information
          <ExpandMoreIcon className={classes.moreArrow} />
        </Button>
      </div>
    </>
  );

  const rentInfoContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.leaseTitle} variant="h5" align="center">
          Rent Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {[...rentInfo].map((rentInfoData) => {
          return (
            <Card
              key={rentInfoData.key}
              className={`${classes.infoCard} ${classes.leaseItem}`}
              onClick={() => createEditLeaseDialog(rentInfoData)}
            >
              <CardContent className={classes.leaseRow}>
                <Typography variant="h6" className={classes.leaseField}>
                  {rentInfoData.field}
                </Typography>
              </CardContent>

              <CardContent className={classes.leaseRow}>
                <Grid container>
                  <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                    {rentInfoData.value &&
                    (rentInfoData.key === "leaseStartDate" ||
                      rentInfoData.key === "moveInDate" ||
                      rentInfoData.key === "moveOutDate" ||
                      rentInfoData.key === "lastRentIncreaseDate")
                      ? moment(rentInfoData.value).format("MMMM Do, YYYY")
                      : rentInfoData.value}
                  </Typography>
                  <EditIcon className={classes.editLeaseButton} />
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </>
  );

  const toggleFixedTermSection = () => {
    if (fixedTerm.value) {
      setConfirmationDialog({ title: " fixed-term", section: "fixedTerm" });
      setDeleteLeaseSectionDialog(true);
    } else setShowFixedTerm(!showFixedTerm);
  };

  const fixedTermContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.leaseTitle} variant="h5" align="center">
          Fixed Term
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card className={`${classes.infoCard} ${classes.leaseItem}`} onClick={() => toggleFixedTermSection()}>
          <CardContent className={classes.leaseRow}>
            <Typography variant="h6" className={classes.leaseField}>
              Lease Ends On Fixed Date
            </Typography>
          </CardContent>

          <CardContent className={classes.leaseRow}>
            <Grid container>
              <Switch
                color="primary"
                className={classes.leaseRowSwitch}
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.switchThumb,
                  track: classes.switchTrack,
                  checked: classes.switchChecked,
                }}
                checked={showFixedTerm}
                onChange={() => toggleFixedTermSection()}
              />
            </Grid>
          </CardContent>
        </Card>
        <Collapse in={showFixedTerm}>
          <Card
            key={fixedTerm.key}
            className={`${classes.infoCard} ${classes.leaseItem}`}
            onClick={() => createEditLeaseDialog(fixedTerm)}
          >
            <CardContent className={classes.leaseRow}>
              <Typography variant="h6" className={classes.leaseField}>
                {fixedTerm.field}
              </Typography>
            </CardContent>

            <CardContent className={classes.leaseRow}>
              <Grid container>
                <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                  {fixedTerm?.value ? moment(fixedTerm.value).format("MMMM Do, YYYY") : ""}
                </Typography>
                <EditIcon className={classes.editLeaseButton} />
              </Grid>
            </CardContent>
          </Card>
        </Collapse>
      </Grid>
    </>
  );

  const toggleRentDepositSection = () => {
    if (rentDeposit[0].value) {
      setConfirmationDialog({ title: " rent deposit", section: "rentDeposit" });
      setDeleteLeaseSectionDialog(true);
    } else setShowRentDeposit(!showRentDeposit);
  };

  const useDepositDialogContent = (
    <Dialog
      open={useDepositDialog !== false}
      onClose={() => setUseDepositDialog(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle disableTypography style={{ fontSize: "1.2em", padding: "16px 32px", paddingBottom: "0px" }}>
        <Typography variant="h4" align="center" className={classes.useDepositDialogHeader}>
          Are you sure you want to use $
          {useDepositDialog?.difference > 0 ? useDepositDialog?.balance : props?.lease?.rentDeposit} from the rent
          deposit to cover your tenant's missed payment(s)?
        </Typography>
        <IconButton className={classes.menuTitleCloseButton} onClick={() => setUseDepositDialog(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "8px 32px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" align="center" className={classes.useDepositDialogHeader}>
              We strongly recommend leaving the deposit till the last rental period and filling out an LTB Notice for
              Non-Payment of Rent (N4) instead
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.useDepositDialogSubmit}
              onClick={() => {
                problemObj.history = props.history;
                problemObj.baseURL = `/${property_id}/forms`;
                problemObj.setLTBFormData = props.setLTBFormData;
                createForm("n4", 1);
                setUseDepositDialog(false);
              }}
            >
              Fill out N4
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleUseDeposit(useDepositDialog)}
              className={`${classes.useDepositDialogSubmit} ${classes.deleteBtn}`}
            >
              Use Rent Deposit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const rentDepositContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.leaseTitle} variant="h5" align="center">
          Deposits
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card className={`${classes.infoCard} ${classes.leaseItem}`} onClick={() => toggleRentDepositSection()}>
          <CardContent className={classes.leaseRow}>
            <Typography variant="h6" className={classes.leaseField}>
              Rent Deposit Collected
            </Typography>
          </CardContent>

          <CardContent className={classes.leaseRow}>
            <Grid container>
              <Switch
                color="primary"
                className={classes.leaseRowSwitch}
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.switchThumb,
                  track: classes.switchTrack,
                  checked: classes.switchChecked,
                }}
                checked={showRentDeposit}
                onChange={() => toggleRentDepositSection()}
              />
            </Grid>
          </CardContent>
        </Card>
        <Collapse in={showRentDeposit}>
          {[...rentDeposit].map((rentDepositData) => {
            return (
              <Card
                key={rentDepositData.key}
                className={`${classes.infoCard} ${classes.leaseItem}`}
                onClick={() => createEditLeaseDialog(rentDepositData)}
              >
                <CardContent className={classes.leaseRow}>
                  <Typography variant="h6" className={classes.leaseField}>
                    {rentDepositData.field}
                  </Typography>
                </CardContent>

                <CardContent className={classes.leaseRow}>
                  <Grid container>
                    <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                      {rentDepositData.interestStartDate
                        ? moment(rentDepositData.interestStartDate).format("MMMM Do, YYYY") +
                          "-" +
                          moment(rentDepositData.interestEndDate).format("MMMM Do, YYYY")
                        : rentDepositData.value && rentDepositData.key === "depositDate"
                        ? moment(rentDepositData.value).format("MMMM Do, YYYY")
                        : rentDepositData.value}
                    </Typography>
                    <EditIcon className={classes.editLeaseButton} />
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Collapse>
      </Grid>
      <div style={{ textAlign: "center" }}>
        <Button className={classes.moreBtn} variant="contained" onClick={() => setShowMoreLeaseInfo(false)}>
          Show Less Information
          <ExpandLessIcon className={classes.lessArrow} />
        </Button>
      </div>
    </>
  );

  const leaseAgreementContent = (
    <>
      <Grid item xs={12}>
        <Typography className={classes.leaseTitle} variant="h5" align="center">
          Lease Agreement
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card
          key={leaseAgreement.key}
          className={`${classes.infoCard} ${classes.leaseItem}`}
          onClick={() => {
            props.history.push(`${window.location.pathname}/../forms/viewer`);
            props.setLTBFormData({});
            if (getCookieValue("formID"))
              document.cookie = `formID=${leaseAgreementData.ID}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `formID=${leaseAgreementData.ID}; expires=${new Date(
              Date.now() + 86400 * 1000
            ).toUTCString()}; path=/;`;
            props.history.push(`${window.location.pathname}/../../forms/viewer`);
          }}
        >
          <CardContent className={classes.leaseRow}>
            <Typography variant="h6" className={classes.leaseField}>
              {leaseAgreement.field}
            </Typography>
          </CardContent>

          <CardContent className={classes.leaseRow}>
            <Grid container>
              <Typography variant="h6" align="right" noWrap className={classes.leaseValue}>
                {leaseAgreement.value}
              </Typography>
              <EditIcon className={classes.editLeaseButton} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Container className={classes.container} maxWidth="md">
        {confirmationDialogContent}
        {useDepositDialogContent}
        <Grid item xs={12} style={{ marginBottom: 30 }}>
          <Box component="div" display={{ xs: "none", sm: "block" }}>
            <Paper className={classes.content}>
              <LeaseHistory
                history={props.history}
                historyExpanded={props.historyExpanded}
                setHistoryExpanded={props.setHistoryExpanded}
                tenantsByLease={tenantsByLease}
              />
            </Paper>
          </Box>
          <Box component="div" display={{ xs: "block", sm: "none" }}>
            <LeaseHistory
              history={props.history}
              historyExpanded={props.historyExpanded}
              setHistoryExpanded={props.setHistoryExpanded}
              tenantsByLease={tenantsByLease}
              mobile
            />
          </Box>
        </Grid>
        {props.lease ? (
          <>
            <Grid item xs={12} style={{ marginBottom: 25 }}>
              <EditLeaseDoubleDialog
                addToast={addToast}
                dispatch={props.dispatch}
                leaseID={props.lease["ID"]}
                form={editLeaseDialog.form}
                fields={editLeaseDialog.fields}
                editLeaseDialog={editLeaseDialog}
                setEditLeaseDialog={setEditLeaseDialog}
                updateLease={updateLease}
                refreshProperty={props.refreshProperty}
              />
              <Box component="div" display={{ xs: "none", sm: "block" }}>
                <Paper className={classes.content}>
                  <Collapse in={!showMoreLeaseInfo}>{importantLeaseInfoContent}</Collapse>
                  <Collapse in={showMoreLeaseInfo}>
                    {rentInfoContent}
                    {fixedTermContent}
                    {rentDepositContent}
                    {!!leaseAgreementData && leaseAgreementContent}
                  </Collapse>
                </Paper>
              </Box>
              <Box component="div" display={{ xs: "block", sm: "none" }}>
                <Collapse in={!showMoreLeaseInfo}>{importantLeaseInfoContent}</Collapse>
                <Collapse in={showMoreLeaseInfo}>
                  {rentInfoContent}
                  {fixedTermContent}
                  {rentDepositContent}
                  {!!leaseAgreementData && leaseAgreementContent}
                </Collapse>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 25 }}>
              <TenantDoubleDialog
                addToast={addToast}
                leaseID={props.lease["ID"]}
                tenantFormData={tenantFormData}
                setTenantFormData={setTenantFormData}
                updateTenants={() => updateTenantList(props.property["leases"], setTenantsByLease)}
              />
              <Box component="div" display={{ xs: "none", sm: "block" }}>
                <Paper className={classes.content}>
                  <TenantList
                    tenants={tenantsByLease[props.lease["ID"]]}
                    setTenantFormData={setTenantFormData}
                    addToast={addToast}
                    updateLease={updateLease}
                    refreshProperty={props.refreshProperty}
                    lease={props.lease}
                    history={props.history}
                  />
                </Paper>
              </Box>
              <Box component="div" display={{ xs: "block", sm: "none" }}>
                <TenantList
                  tenants={tenantsByLease[props.lease["ID"]]}
                  setTenantFormData={setTenantFormData}
                  addToast={addToast}
                  updateLease={updateLease}
                  refreshProperty={props.refreshProperty}
                  lease={props.lease}
                  history={props.history}
                />
              </Box>
            </Grid>
          </>
        ) : null}
      </Container>
    </>
  );
}

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    LTBFormData: state.LTBForm.data,
    refreshProperty: state.building.refreshProperty,
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(Lease);
