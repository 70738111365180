import React, { useEffect, useState, useRef } from "react";

import { useForm, Controller, useFormState } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FulfilledIcon from "@material-ui/icons/CheckRounded";
import UnfulfilledIcon from "@material-ui/icons/CloseRounded";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Collapse from "@material-ui/core/Collapse";

import PhoneNumber from "../../components/PhoneNumber";
import { GooglePlacesAutocompleteField } from "../../components/googlePlacesAutocomplete";

import StripeInput from "../../components/StripeInput";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { updatePaymentMethod } from "../../../services/billingServices.js";

import SignaturePad from "react-signature-canvas";
import { editUser, addSignature, deleteSignature } from "../../../services/userServices.js";
import Loading from "../../components/Loading";

const errorMessages = {
  firstName: {
    required: "Please enter your first name",
    length: "Your first name cannot exceed 64 characters",
  },
  lastName: {
    required: "Please enter your last name",
    length: "Your last name cannot exceed 64 characters",
  },
  email: {
    required: "Please enter your email",
    pattern: "Your email must be in the correct format, e.g. username@domain.com",
    length: "Your email cannot exceed 254 characters",
    alreadyExists: "A User already exists with this email",
  },
  unitNum: {
    pattern: "Must be between 0 and 9999999",
  },
  address: {
    chooseOption: "Please choose your address from the given options",
  },
  dayPhone: {
    required: "Please enter your day phone number",
    pattern: "Your day phone number must be in the correct format, e.g. (123) 456-7890",
  },
  eveningPhone: {
    pattern: "Your evening phone number must be in the correct format, e.g. (123) 456-7890",
  },
  fax: {
    pattern: "Your fax number must be in the correct format, e.g. (123) 456-7890",
  },
  companyName: {
    length: "Your company name cannot exceed 64 characters",
  },
  oldPassword: {
    required: "Please enter your old password",
    incorrect: "Your old password does not match our records. Please try again",
  },
  password: {
    required: "Please enter your password",
    requirementsFulfilled: "Please make sure your password meets all of the requirements",
  },
  confirmPassword: {
    required: "Please enter a confirmation password",
    passwordEquality: "Please make sure the passwords you entered match",
  },
  cardholderName: {
    required: "Please enter your name exactly as it appears on your card",
    length: "The cardholder name cannot exceed 128 characters",
  },
  cardElement: {
    cardComplete: "Please enter all of your card details",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
      margin: theme.spacing(0, 0, 3),
    },
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(3, 0, 3),
    },
  },
  passwordRequirements: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.8em",
    },
  },
  passwordRequirementsList: {
    paddingLeft: "12px",
    ["& > li"]: {
      display: "flex",
      alignItems: "center",
    },
  },
  passwordRequirementsItem: {
    [`${theme.breakpoints.down(375)}`]: {
      fontSize: "1em",
    },
    [`${theme.breakpoints.between(375, 600)}`]: {
      fontSize: "1.3em",
    },
  },
  fulfilled: {
    color: "springgreen",
  },
  sigContainer: {
    width: "100%",
    height: "125px",
  },
  sigPad: {
    width: "100%",
    height: "100%",
    borderRadius: "var(--tsl-brand-border-radius)",
    background: "white",
  },
  sigButton: {
    margin: theme.spacing(3, 0, 0),
  },
  sigPadError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
    margin: theme.spacing(3, 0, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(3, 0, 3),
    },
  },
}));

const cardOptions = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      fontSize: "16px",
      color: "white",
      iconColor: "rgba(255, 255, 255, 0.7)",
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    invalid: {
      color: "#f44336",
      iconColor: "#f44336",
    },
  },
};

const initialAddressData = {
  streetNum: "",
  streetName: "",
  streetType: "",
  direction: "",
  municipality: "",
  province: "",
  postalCode: "",
};

function EditProfileForm(props) {
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const { isSubmitted } = useFormState({
    control,
  });

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  };

  const onSubmit = async (userData) => {
    let deleteConfirmation = false;
    if (props.form === "signature") {
      if (!SigPad.current) deleteConfirmation = true;
      else if (!SigPad.current.isEmpty())
        userData = {
          ...userData,
          signature: SigPad.current.toDataURL(),
        };
      else {
        setSignatureError(true);
        return;
      }
    }

    let confirmCallback = async () => {
      let addSig = false;

      if (userData.address) {
        const formattedAddress = `${addressData.streetNum}, ${addressData.streetName}, ${addressData.streetType}, ${addressData.direction}, ${userData.unitNum}, ${addressData.municipality}, ${addressData.province}, ${addressData.postalCode}`;
        userData = {
          ...userData,
          address: formattedAddress,
        };
      } else if (userData.confirmPassword) delete userData.confirmPassword;
      else if (userData.signature) addSig = true;
      console.log(userData);

      let userEdited = false;
      if (deleteConfirmation) userEdited = await deleteSignature(userData, handleErrors);
      else if (addSig) userEdited = await addSignature(userData, handleErrors);
      else if (userData.cardholderName && userData.customer) {
        const cardElement = elements.getElement(CardElement);
        const stripePayload = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: { name: userData.cardholderName },
        });
        console.log(stripePayload);
        userEdited = await updatePaymentMethod({
          customerID: userData.customer.ID,
          subscriptionIDs: userData.subscriptionList ? userData.subscriptionList : [userData.customer.subscriptionID],
          oldPaymentMethodID: userData.oldPaymentMethodID,
          paymentMethodID: stripePayload.paymentMethod.id,
          default: userData.oldPaymentMethodID === userData.customer.paymentMethodID,
        });
      } else userEdited = await editUser(userData, handleErrors);

      if (userEdited) return true;

      props.addToast("Updating Profile Failed", "error");
      return false;
    };

    props.setConfirmationCallback(confirmCallback, deleteConfirmation);
  };

  const classes = useStyles();

  const nameForm = (
    <>
      <Grid item xs={12} sm={6}>
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              length: (value) => value.length <= 64,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="firstName"
              label="First Name"
              error={errors.firstName && errors.firstName !== null}
              helperText={errors.firstName ? errorMessages["firstName"][errors.firstName.type] : null}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              length: (value) => value.length <= 64,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="lastName"
              label="Last Name"
              error={errors.lastName && errors.lastName !== null}
              helperText={errors.lastName ? errorMessages["lastName"][errors.lastName.type] : null}
            />
          )}
        />
      </Grid>
    </>
  );

  const emailForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\S+@\S+\.\S+$/,
            validate: {
              length: (value) => value.length <= 254,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="email"
              label="Email"
              autoComplete="email"
              error={errors.email && errors.email !== null}
              helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
            />
          )}
        />
      </Grid>
    </>
  );

  const [addressData, setAddressData] = useState(initialAddressData);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const onAddressSelected = (isAddressSelected, addressData) => {
    setIsAddressSelected(isAddressSelected);
    addressData ? setAddressData(addressData) : setAddressData(initialAddressData);

    if (isSubmitted) isAddressSelected ? trigger("address") : setError("address", { type: "chooseOption" });
  };

  const addressForm = (
    <>
      <Grid item xs={12} sm={3}>
        <Controller
          name="unitNum"
          control={control}
          defaultValue=""
          rules={{
            pattern: /^\d{0,7}$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              id="unitNum"
              label="Unit #"
              error={errors.unitNum && errors.unitNum !== null}
              helperText={errors.unitNum ? errorMessages["unitNum"][errors.unitNum.type] : null}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Controller
          name="address"
          control={control}
          rules={{
            validate: { chooseOption: () => isAddressSelected },
          }}
          render={({ field: { value } }) =>
            value ? (
              <GooglePlacesAutocompleteField
                error={errors.address}
                onAddressSelected={onAddressSelected}
                defaultAddress={value}
              />
            ) : null
          }
        />
      </Grid>
    </>
  );

  const dayPhoneForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="dayPhone"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="dayPhone"
              label="Day Phone"
              error={errors.dayPhone && errors.dayPhone !== null}
              helperText={errors.dayPhone ? errorMessages["dayPhone"][errors.dayPhone.type] : null}
              InputProps={{
                inputComponent: PhoneNumber,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const eveningPhoneForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="eveningPhone"
          control={control}
          defaultValue=""
          rules={{
            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              id="eveningPhone"
              label="Evening Phone"
              error={errors.eveningPhone && errors.eveningPhone !== null}
              helperText={errors.eveningPhone ? errorMessages["eveningPhone"][errors.eveningPhone.type] : null}
              InputProps={{
                inputComponent: PhoneNumber,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const faxForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="fax"
          control={control}
          defaultValue=""
          rules={{
            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              id="fax"
              label="Fax"
              error={errors.fax && errors.fax !== null}
              helperText={errors.fax ? errorMessages["fax"][errors.fax.type] : null}
              InputProps={{
                inputComponent: PhoneNumber,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const companyNameForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="companyName"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              length: (value) => value.length <= 64,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              id="companyName"
              label="Company Name"
              error={errors.companyName && errors.companyName !== null}
              helperText={errors.companyName ? errorMessages["companyName"][errors.companyName.type] : null}
            />
          )}
        />
      </Grid>
    </>
  );

  const [showPassword, setshowPassword] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const watchPassword = watch("password", "");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    hasNumber: false,
    hasUppercase: false,
    hasSpecial: false,
  });

  useEffect(() => {
    if (watchPassword) {
      let lengthCheck = false;

      let hasNumberCheck = false;
      const hasNumberRegex = /\d/;

      let hasUppercaseCheck = false;
      const hasUppercaseRegex = /[A-Z]/;

      let hasSpecialCheck = false;
      const hasSpecialRegex = /[$&+,:;=?@#|'<>.^*()%!{}-]/;

      if (watchPassword.length >= 8 && watchPassword.length <= 128) lengthCheck = true;
      if (hasNumberRegex.test(watchPassword)) hasNumberCheck = true;
      if (hasUppercaseRegex.test(watchPassword)) hasUppercaseCheck = true;
      if (hasSpecialRegex.test(watchPassword)) hasSpecialCheck = true;

      setPasswordRequirements({
        length: lengthCheck,
        hasNumber: hasNumberCheck,
        hasUppercase: hasUppercaseCheck,
        hasSpecial: hasSpecialCheck,
      });
      if (isSubmitted) trigger(["confirmPassword"]);
    }
  }, [watchPassword]);

  const passwordForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="oldPassword"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              label="Old Password"
              autoComplete="current-password"
              error={errors.oldPassword && errors.oldPassword !== null}
              helperText={errors.oldPassword ? errorMessages["oldPassword"][errors.oldPassword.type] : null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Show Password" PopperProps={{ style: { marginTop: "-8px" } }}>
                      <IconButton
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              requirementsFulfilled: () =>
                passwordRequirements.length &&
                passwordRequirements.hasNumber &&
                passwordRequirements.hasUppercase &&
                passwordRequirements.hasSpecial,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              onFocus={() => setShowPasswordRequirements(true)}
              onBlur={() => setShowPasswordRequirements(false)}
              onKeyUp={() => {
                if (isSubmitted) trigger("password");
              }}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              label="New Password"
              autoComplete="current-password"
              error={errors.password && errors.password !== null}
              helperText={errors.password ? errorMessages["password"][errors.password.type] : null}
            />
          )}
        />
      </Grid>
      <Collapse in={showPasswordRequirements}>
        <Grid item xs={12} className={classes.passwordRequirements}>
          <Typography variant="h6" align="left" style={{ paddingLeft: "14px" }}>
            Password Requirements:
          </Typography>
          <ul className={classes.passwordRequirementsList}>
            <li>
              {passwordRequirements.length ? (
                <FulfilledIcon className={classes.fulfilled} />
              ) : (
                <UnfulfilledIcon color="error" />
              )}
              <Typography className={classes.passwordRequirementsItem} variant="body1">
                &nbsp;Must be between 8 and 128 characters
              </Typography>
            </li>
            <li>
              {passwordRequirements.hasNumber ? (
                <FulfilledIcon className={classes.fulfilled} />
              ) : (
                <UnfulfilledIcon color="error" />
              )}
              <Typography className={classes.passwordRequirementsItem} variant="body1">
                &nbsp;Must have at least one number
              </Typography>
            </li>
            <li>
              {passwordRequirements.hasUppercase ? (
                <FulfilledIcon className={classes.fulfilled} />
              ) : (
                <UnfulfilledIcon color="error" />
              )}
              <Typography className={classes.passwordRequirementsItem} variant="body1">
                &nbsp;Must have at least one uppercase character
              </Typography>
            </li>
            <li>
              {passwordRequirements.hasSpecial ? (
                <FulfilledIcon className={classes.fulfilled} />
              ) : (
                <UnfulfilledIcon color="error" />
              )}
              <Typography className={classes.passwordRequirementsItem} variant="body1">
                &nbsp;Must have at least one special character
              </Typography>
            </li>
          </ul>
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              passwordEquality: (value) => value === getValues().password,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              label="Confirm New Password"
              error={errors.confirmPassword && errors.confirmPassword !== null}
              helperText={errors.confirmPassword ? errorMessages["confirmPassword"][errors.confirmPassword.type] : null}
            />
          )}
        />
      </Grid>
    </>
  );

  const stripe = useStripe();
  const elements = useElements();

  const [stripeError, setStripeError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  const paymentMethodForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="cardholderName"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              length: (value) => value.length <= 128,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="cardholderName"
              label="Cardholder Name"
              error={errors.cardholderName && errors.cardholderName !== null}
              helperText={errors.cardholderName ? errorMessages["cardholderName"][errors.cardholderName.type] : null}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="cardElement"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              stripeError: () => !stripeError,
              cardComplete: () => cardComplete,
            },
          }}
          render={() => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="cardElement"
              onChange={(e) => {
                setStripeError(e.error);
                setCardComplete(e.complete);
                if (e.complete) clearErrors("cardElement");
              }}
              error={(stripeError && stripeError !== null) || errors?.cardElement?.type === "cardComplete"}
              helperText={
                stripeError
                  ? stripeError.message
                  : null || errors?.cardElement?.type === "cardComplete"
                  ? errorMessages["cardElement"][errors.cardElement.type]
                  : null
              }
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardElement,
                  options: cardOptions,
                },
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>
    </>
  );

  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureError, setSignatureError] = useState(false);
  const SigPad = useRef(null);

  const signatureForm = (
    <>
      <Grid item xs={12} className={classes.sigContainer}>
        {signatureImage ? (
          <img src={signatureImage} className={classes.sigPad} style={{ objectFit: "contain" }} alt={"signature"} />
        ) : (
          <SignaturePad
            minDistance={2.5}
            maxWidth={2.5}
            canvasProps={{
              className: `${classes.sigPad} ${signatureError ? classes.sigPadError : ""}`,
            }}
            ref={SigPad}
            onBegin={() => {
              if (signatureError && isSubmitted) setSignatureError(false);
            }}
          />
        )}
      </Grid>
      {signatureError && (
        <Grid item xs={12} style={{ margin: "-3px 0px" }}>
          <Typography variant="body2" color="error">
            Your signature cannot be blank
          </Typography>
        </Grid>
      )}

      {!signatureImage && (
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.sigButton}
            onClick={() => {
              let data = SigPad.current.toData();
              if (data) {
                data.pop();
                SigPad.current.fromData(data);
              }
            }}
          >
            Undo
          </Button>
        </Grid>
      )}
      {!signatureImage && (
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.sigButton}
            onClick={() => SigPad.current.clear()}
          >
            Clear
          </Button>
        </Grid>
      )}

      {signatureImage && (
        <Grid item xs={12}>
          <Button fullWidth variant="contained" className={classes.deleteButton} onClick={() => onSubmit([])}>
            Delete Signature
          </Button>
        </Grid>
      )}
    </>
  );

  useEffect(() => {
    let field;
    console.log(props.fields);
    for (field in props.fields) {
      if (field === "address" && props.fields?.address) {
        setIsAddressSelected(true);
        setValue(field, props.fields.address);
      } else if (field === "addressData" && props.fields?.addressData) setAddressData(props.fields.addressData);
      else if (field === "signature" && props.fields?.signature) setSignatureImage(props.fields.signature);
      else if (props.fields[field]) setValue(field, props.fields[field]);
    }
  }, []);

  const forms = {
    name: nameForm,
    email: emailForm,
    address: addressForm,
    dayPhone: dayPhoneForm,
    eveningPhone: eveningPhoneForm,
    fax: faxForm,
    companyName: companyNameForm,
    password: passwordForm,
    paymentMethod: paymentMethodForm,
    signature: signatureForm,
  };

  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.main}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {forms[props.form]}
          {!signatureImage && (
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Save Changes
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default EditProfileForm;
