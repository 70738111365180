import moment from "moment";

export const calculateProratedRent = (leaseStartDate, rentCollectionDay, moveInDate, rentPrice) => {
  let _prorateData = {};
  let leaseStart = leaseStartDate;
  if (parseInt(moment(leaseStartDate).format("D")) > parseInt(rentCollectionDay))
    leaseStart = moment(leaseStartDate).add(1, "M").format("YYYY-MM-DD");

  let rentCollectionDayStr = parseInt(rentCollectionDay) < 10 ? "0" + rentCollectionDay : rentCollectionDay;

  _prorateData["firstPaymentDate"] = moment(moment(leaseStart).format("YYYY-MM") + "-" + rentCollectionDayStr).format(
    "MMM Do"
  );
  _prorateData["leaseMonth"] = moment(leaseStart).format("MMMM");
  _prorateData["numOfProratedDays"] = parseInt(
    moment(moment(leaseStart).format("YYYY-MM") + "-01").diff(moment(moveInDate), "days")
  );
  _prorateData["proratedDaysString"] =
    _prorateData["numOfProratedDays"] + (_prorateData["numOfProratedDays"] != 1 ? " days" : " day");
  _prorateData["proratedMonth"] = moment(moveInDate).format("MMMM");
  _prorateData["daysInProratedMonth"] = moment(moveInDate).daysInMonth();
  let proportionOfProratedMonth = parseFloat(_prorateData["numOfProratedDays"] / moment(moveInDate).daysInMonth());
  _prorateData["proportionOfProratedMonth"] = proportionOfProratedMonth.toFixed(5);
  _prorateData["percentOfProratedMonth"] = (proportionOfProratedMonth * 100).toFixed(3) + "%";
  _prorateData["proratedRent"] = parseFloat((proportionOfProratedMonth * rentPrice).toFixed(2));
  _prorateData["firstMonthsRent"] = (parseFloat(_prorateData["proratedRent"]) + rentPrice).toFixed(2);

  return _prorateData;
};
