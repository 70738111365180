import axios from "axios";
import setAxiosDefaultSettings from "../utils/axiosSettings.js";
import { getAlerts } from "./property/alertServices";

setAxiosDefaultSettings(axios);

export const getBuilding = async (buildingData = {}) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/?timestamp=${new Date().getTime()}`, {
      params: { ...buildingData, clonedUserID },
    })
    .then((response) => response.data)
    .catch(() => false);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addBuilding = async (buildingData, handleErrors) => {
  return await updateBuilding(axios.post, buildingData, handleErrors);
};

export const editBuilding = async (buildingData, handleErrors) => {
  return await updateBuilding(axios.put, buildingData, handleErrors);
};

export const deleteBuilding = async (buildingData, handleErrors, dispatch) => {
  const buildingDeleted = await updateBuilding(axios.delete, { data: buildingData }, handleErrors);
  await getAlerts(dispatch);
  return buildingDeleted;
};

const updateBuilding = async (axiosMethod, buildingData, handleErrors = null) => {
  let buildingReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/", buildingData)
    .then(async (response) => {
      if (response.config?.method === "delete") buildingReceived = true;
      else if (response.data) buildingReceived = response.data;
      else {
        responseError = response.data?.error || "Updating Building failed";
        console.log("No building", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Building Information";
      if (responseError?.address == "alreadyExists") buildingReceived = responseError;
    });

  if (handleErrors) handleErrors(responseError);

  return buildingReceived;
};
