import { makeStyles } from "@material-ui/core";
import { useEffect, useRef } from "react";

const useStyles = makeStyles((theme) => ({
  combContainer: {
    display: "flex",
  },
}));

let listenersSet = false;
function CombInput({ numInputs, value, onChange, inputProps, ...other }) {
  const classes = useStyles();
  const inputRefs = useRef([]);

  useEffect(() => {
    listenersSet = false;
  }, []);

  useEffect(() => {
    let inputNodes = inputRefs.current;
    if (!listenersSet && inputNodes.length == numInputs) {
      for (let i = 0; i < inputNodes.length; i++) {
        inputNodes[i].addEventListener("focus", (e) => {
          if (inputNodes[i].value == "" && inputNodes.length > i + 1 && inputNodes[i + 1].value == "") {
            inputNodes[i + 1].focus();
          }
        });
      }

      listenersSet = true;
    }
  }, [inputRefs]);

  return (
    <div {...other} className={classes.combContainer}>
      {[...Array(numInputs).keys()].map((index) => {
        return (
          <input
            {...inputProps}
            key={index}
            ref={(node) => {
              if (inputRefs.current.length < numInputs) inputRefs.current.push(node);
            }}
            value={value && index >= numInputs - value.length ? value.charAt(index - (numInputs - value.length)) : ""}
            onChange={(e) => {
              let nextValue = "";
              for (let inputNode of inputRefs.current) nextValue += inputNode.value.replaceAll(/[^\d-]/g, "");
              onChange(nextValue.substring(0, numInputs));
            }}
            onKeyDown={(e) => {
              let inputNodes = inputRefs.current;
              let currentValue = value.replaceAll(/[^\d-]/g, "");

              if (e.key === "ArrowLeft" && e.target.selectionStart == 0 && index > 0) {
                inputNodes[index - 1].focus();
              } else if (
                e.key === "ArrowRight" &&
                (e.target.selectionStart == 1 || e.target.value == "") &&
                index < numInputs - 1
              ) {
                inputNodes[index + 1].focus();
              } else if (e.key === "Backspace" && e.target.value.length > 0) {
                let charIndex = index - (numInputs - value.length);
                onChange(currentValue.substring(0, charIndex - 1) + currentValue.substring(charIndex, numInputs));
              } else if (e.key === "Delete" && (e.target.selectionStart == 1 || e.target.value == "")) {
                let charIndex = index - (numInputs - value.length);
                onChange(currentValue.substring(0, charIndex + 1) + currentValue.substring(charIndex + 2, numInputs));
                if (index < numInputs - 1) inputNodes[index + 1].focus();
              }
            }}
          />
        );
      })}
    </div>
  );
}

/*if (e.target.value.length > 1) {
  if (index > 0) {
    inputNodes[index-1].value += e.target.value.slice(0, e.target.value.length-1);
    e.target.value = e.target.value.charAt(e.target.value.length-1);
  }
}*/

export default CombInput;
