import { SET_BUILDING, SET_PROPERTY, SET_LEASE, SET_REFRESH_PROPERTY } from "../actionTypes/buildingActionTypes";

export const setBuilding = (building) => ({
  type: SET_BUILDING,
  building,
});

export const setProperty = (property) => ({
  type: SET_PROPERTY,
  property,
});

export const setLease = (lease) => ({
  type: SET_LEASE,
  lease,
});

export const setRefreshProperty = (refreshProperty) => ({
  type: SET_REFRESH_PROPERTY,
  refreshProperty,
});
