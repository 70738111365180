import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const getLedger = async (ledgerData, handleErrors = null) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/properties/leases/ledgers/?timestamp=${new Date().getTime()}`, {
      params: { ...ledgerData, clonedUserID },
      responseType: ledgerData.export ? "blob" : "json",
    })
    .then((response) => response.data);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addLedger = async (ledgerData, handleErrors) => {
  return await updateLedger(axios.post, ledgerData, handleErrors);
};

export const editLedger = async (ledgerData, handleErrors) => {
  return await updateLedger(axios.put, ledgerData, handleErrors);
};

export const deleteLedger = async (ledgerData, handleErrors) => {
  return await updateLedger(axios.delete, { data: ledgerData }, handleErrors);
};

const updateLedger = async (axiosMethod, ledgerData, handleErrors = null) => {
  let ledgerReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/leases/ledgers/", ledgerData)
    .then(async (response) => {
      if (response.config?.method === "delete") ledgerReceived = true;
      else if (response.data) ledgerReceived = response.data;
      else {
        responseError = response.data?.error || "Ledger Update failed";
        console.log("No ledger", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Ledger Information";
    });

  if (handleErrors) handleErrors(responseError);

  return ledgerReceived;
};
