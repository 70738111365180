import NumberFormat from "react-number-format";

function MoneyInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}

export default MoneyInput;
