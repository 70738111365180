import { setLoading, setError } from "../redux/actions/authenticationActions";

import axios from "axios";
import { setAxiosURL } from "../utils/axiosSettings.js";

setAxiosURL(axios);

export const postMailingList = async (dispatch, userData) => {
  let success = false;
  let responseError = null;

  dispatch(setLoading(true));
  await axios
    .post("/v2/mailing-list/", userData)
    .then(async (response) => {
      if (response.data) success = true;
      else {
        responseError = (response.data && response.data.error) || "Registration failed";
        console.log("Error", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Registration";
    });

  dispatch(setError(responseError));
  dispatch(setLoading(false));

  return success;
};
