import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoubleDialog from "../../../components/DoubleDialog";
import EditPropertyForm from "./EditPropertyForm";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

function EditPropertyDoubleDialog(props) {
  const classes = useStyles();

  const [confirmationData, setConfirmationData] = useState(false);

  //The function called if the confirm button is clicked and the callback function for confirm is successful
  const closeDialogSuccessfully = (currentAction) => {
    props.addToast(`${props.property ? "Property" : "Building"} Successfully ${currentAction}ed`, "success");
    setTimeout(() => {
      setConfirmationData(false);
      props.setEditPropertyDialog(false);
      props.refreshProperty();
    }, 1500);
  };

  //The function that sets up the text and callback for the confirmation dialog
  const setConfirmationDialog = (confirmCallback, callbackArgs) => {
    if (callbackArgs["currentAction"] === "Delet") {
      setConfirmationData({
        title: `Are you sure you want to delete this ${props.property ? "property" : "building"}?`, //The title of the confirmation dialog
        body: "This action cannot be reverted", //The body of the confirmation dialog
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) {
            props.history.push("/properties");
            closeDialogSuccessfully(callbackArgs["currentAction"]);
          } else setConfirmationData(false);
        },
        primaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the primary button (You can also use secondaryBtnClass)
      });
    } else
      setConfirmationData({
        title: "Please ensure the information you've entered is correct", //The title of the confirmation dialog
        body: "This information will be used on official government forms", //The body of the confirmation dialog
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) closeDialogSuccessfully(callbackArgs["currentAction"]);
          else setConfirmationData(false);
        },
        secondaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the secondary button (You can also use primaryBtnClass)
      });
  };

  return (
    <DoubleDialog
      mainTitle={props.property ? "Edit Property" : "Edit Building"}
      mainDialogOpen={props.editPropertyDialog} //The condition for the main dialog to open
      closeMainDialog={() => props.setEditPropertyDialog(false)} //A function to close the main dialog
      confirmationDialogData={confirmationData} //The title, body, and callback of the confirmation dialog
      closeConfirmationDialog={() => setConfirmationData(false)} //A function to close the confirmation dialog
      width={"md"} //maxWidth prop for the main dialog
    >
      <EditPropertyForm
        addToast={props.addToast}
        property={props.property}
        building={props.building}
        setConfirmationCallback={setConfirmationDialog}
        dispatch={props.dispatch}
      />
    </DoubleDialog>
  );
}

export default EditPropertyDoubleDialog;
