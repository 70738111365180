import React, { useState, useEffect } from "react";
import PropertyCheckoutForm from "./components/PropertyCheckoutForm";

import { getConfig } from "../../services/billingServices.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function PropertyCheckout(props) {
  useEffect(() => {
    if (
      ((!props.location.state?.propertyData || !props.location.state?.unitNums) && !props.location.state?.propertyID) ||
      props.location.state?.totalProperties < 0
    )
      props.history.push("/properties");
  });

  const [stripe, setStripe] = useState(null);
  const [price, setPrice] = useState(null);

  const getSripeConfig = async () => {
    const config = await getConfig();

    if (config) {
      setStripe(await loadStripe(config.publishableKey));
      setPrice(config.propertyPlanPrice);
    }
  };

  useEffect(() => {
    getSripeConfig();
  }, []);

  return (
    <>
      {stripe && price ? (
        <Elements stripe={stripe}>
          <PropertyCheckoutForm
            price={price}
            history={props?.history}
            buildingID={props.location.state?.buildingID}
            propertyID={props.location.state?.propertyID}
            propertyData={props.location.state.propertyData}
            unitNums={props.location.state.unitNums}
            totalProperties={props.location.state.totalProperties}
          />
        </Elements>
      ) : null}
    </>
  );
}

export default PropertyCheckout;
