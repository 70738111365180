import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddRounded";
import MenuIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/EditRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import NewestFirstIcon from "@material-ui/icons/ArrowUpwardRounded";
import OldestFirstIcon from "@material-ui/icons/ArrowDownwardRounded";
import FiltersIcon from "@material-ui/icons/FilterListRounded";
import LedgerTransactionIcon from "@material-ui/icons/ReceiptRounded";
import Toolbar from "@material-ui/core/Toolbar";
import ExportIcon from "@material-ui/icons/VerticalAlignBottomRounded";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ToastFactory from "../../components/ToastFactory";
import LedgerDoubleDialog from "./components/LedgerDoubleDialog";
import Alert from "./components/Alert";
import MoneyInput from "../../components/MoneyInput";
import { calculateLedgerBalance } from "../../../utils/ledgerBalance.js";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import updateTenantList from "../../../utils/updateTenantList.js";
import { getLedger, addLedger } from "../../../services/property/ledgerServices.js";
import { addAlert } from "../../../services/property/alertServices";
import { getTenants } from "../../../services/property/tenantServices";
import { editLease } from "../../../services/property/leaseServices.js";
import LeaseHistory from "../components/LeaseHistory";
import { CircularProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const errorMessages = {
  NSFBankFee: {
    required: "Please enter the amount you would like to charge your tenant as an NSF bank fee",
    pattern: "The NSF bank fee must be a positive number and can't have more than 2 decimals",
  },
  NSFBankFeeDate: {
    required: "Please enter the transaction date",
    pattern: "Please select a valid date, e.g. 2000-04-12",
  },
  NSFAdminFee: {
    maxFee: "The Landlord Tenant Board does not allow landlords to charge an NSF admin fee greater than $20",
    required: "Please enter the amount you would like to charge your tenant as an NSF admin fee",
    pattern: "The NSF admin fee must be a positive number and can't have more than 2 decimals",
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 96px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "40px",
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "30px",
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  ledgerItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 28px",
    marginBottom: "12px",
    cursor: "pointer",
  },
  ledgerItem: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  ledgerText: {
    fontSize: "1.3em",
    [`${theme.breakpoints.down(650)}`]: {
      fontSize: "1.1em",
    },
  },
  editIcon: {
    marginLeft: "20px",
    marginTop: "10px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginLeft: "10px",
      marginTop: "15px",
      marginRight: "-15px",
      fontSize: "1.4em",
    },
  },
  ledgerRow: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "0",
    },
    "& > h6": {
      marginBottom: "0",
      textTransform: "capitalize",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "12px 0px",
    },
  },
  ledgerColon: {
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  boldGreen: {
    color: theme.palette.secondary.main,
  },
  boldRed: {
    color: theme.palette.error.main,
  },
  caption: {
    color: theme.palette.text.disabled,
    fontSize: "0.8em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  ledgerTitle: {
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(6),
    },
  },
  emptyLedger: {
    textAlign: "center",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
  },
  emptyLedgerIcon: {
    fontSize: "4.5em",
  },
  menuTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuTitleText: {
    padding: theme.spacing(1),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  header: {
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: "1.2em",
    },
  },
  filtersChip: {
    transition: "all var(--tsl-brand-transition-time)",
    margin: theme.spacing(2, 0.5, 3),
    height: "40px",
    fontSize: "1em",
    borderRadius: "30px",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "45%",
    },
  },
  dateRange: {
    margin: theme.spacing(0, 0, 2),
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    fontSize: "1.4em",
    padding: theme.spacing(1.5, 2.5, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      padding: theme.spacing(1.5, 0, 1.5),
    },
  },
  NSFFeeDialogHeader: {
    marginTop: theme.spacing(-1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  NSFFeeDialogSubmit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + "-" + mm + "-" + dd;

function Ledger(props) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const [ledger, setLedger] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [ledgerBalance, setLedgerBalance] = useState({});
  const [missingPaymentAlert, setMissingPaymentAlert] = useState(null);
  const [paymentAdded, setPaymentAdded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [NSFBankFeeDialog, setNSFBankFeeDialog] = useState(false);
  const [NSFAdminFeeDialog, setNSFAdminFeeDialog] = useState(false);
  const [defaultNSFAdminFeeDialog, setDefaultNSFAdminFeeDialog] = useState(false);
  const [newestFirst, setNewestFirst] = useState(true);
  const [typeFilter, setTypeFilter] = useState([]);
  const [reasonFilter, setReasonFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [tenantFilter, setTenantFilter] = useState("");
  const [tenantsByLease, setTenantsByLease] = useState({});

  const updateLedger = async () => {
    const ledgerReceived = await getLedger({ leaseID: props.lease["ID"] });
    console.log("ledgerReceived: ", ledgerReceived);
    setLedger(ledgerReceived ? ledgerReceived : []);
  };

  const updateTenants = async () => {
    updateTenantList(props.property["leases"], setTenantsByLease);
    let tenantsReceived = await getTenants({ leaseID: props.lease["ID"] });
    if (tenantsReceived) setTenants(tenantsReceived);
  };

  const getLedgerAlerts = async () => {
    if (props.alerts)
      setMissingPaymentAlert(
        props.alerts.filter((alert) => alert.leaseID == props.lease["ID"] && alert.type == "missingPayment")[0]
      );
    else setMissingPaymentAlert(null);
  };

  const missingPaymentCheck = async (balance) => {
    console.log("DATA: ", {
      buildingID: props.building["ID"],
      propertyID: props.property["ID"],
      leaseID: props.lease["ID"],
      type: "missingPayment",
    });
    if (balance > 0)
      await addAlert(
        {
          buildingID: props.building["ID"],
          propertyID: props.property["ID"],
          leaseID: props.lease["ID"],
          type: "missingPayment",
        },
        props.dispatch
      );
    setPaymentAdded(false);
  };

  useEffect(() => {
    updateLedger();
    updateTenants();
  }, []);

  useEffect(() => {
    getLedgerAlerts();
  }, [props.alerts]);

  useEffect(() => {
    const ledgerBalance = calculateLedgerBalance(ledger);
    setLedgerBalance(ledgerBalance.newLedger);
    if (paymentAdded) missingPaymentCheck(Math.round((ledgerBalance.balance + Number.EPSILON) * 100) / 100);
  }, [ledger]);

  const updateTypeFilter = (type) => {
    if (!typeFilter.includes(type)) setTypeFilter([...typeFilter, type]);
    else setTypeFilter(typeFilter.filter((currType) => currType !== type));
  };

  const updateReasonFilter = (reason) => {
    if (!reasonFilter.includes(reason)) setReasonFilter([...reasonFilter, reason]);
    else setReasonFilter(reasonFilter.filter((currReason) => currReason !== reason));
  };

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [ledgerFormData, setLedgerFormData] = useState(false);

  const exportLedger = async () => {
    const excelFile = await getLedger({
      leaseID: props.lease["ID"],
      export: true,
    });
    console.log(excelFile);

    if (excelFile?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      const url = window.URL.createObjectURL(new Blob([excelFile]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Tenant Ledger.xlsx");
      document.body.appendChild(link);
      link.click();
      document.querySelector("body").removeChild(link);
      addToast("Ledger Successfully Exported", "success");
    } else addToast("Exporting Ledger Failed", "error");
  };

  const handleNSFPayment = (NSFPaymentData) => setNSFBankFeeDialog(NSFPaymentData);

  const handleNSFFeeSubmit = async (NSFFeeData) => {
    const NSFBankFee = (NSFFeeData.NSFBankFee + "").replace("$", "").replaceAll(",", "");
    if (NSFFeeData.NSFBankFee && NSFBankFee > 0) {
      const NSFBankFeeTransaction = {
        leaseID: props.lease["ID"],
        ledgerType: "charge",
        ledgerReason: "NSF Bank",
        ledgerAmount: NSFBankFee,
        ledgerDate: NSFFeeData.NSFBankFeeDate,
        ledgerTenant: null,
        NSF: false,
      };
      console.log("NSFBankFeeTransaction: ", NSFBankFeeTransaction);

      const NSFBankFeeTransactionAdded = await addLedger(NSFBankFeeTransaction);
      if (!NSFBankFeeTransactionAdded) addToast("Adding NSF Bank Charge Failed", "error");
    }

    const NSFAdminFee = (NSFFeeData.NSFAdminFee + "").replace("$", "").replaceAll(",", "");
    const NSFAdminFeeTransaction = {
      leaseID: props.lease["ID"],
      ledgerType: "charge",
      ledgerReason: "NSF Admin",
      ledgerAmount: NSFFeeData.NSFAdminFee ? NSFAdminFee : props.lease.NSFFee,
      ledgerDate: moment().format("YYYY-MM-DD"),
      ledgerTenant: NSFFeeData.ledgerTenant ? NSFFeeData.ledgerTenant : null,
      NSF: false,
    };
    console.log("NSFAdminFeeTransaction: ", NSFAdminFeeTransaction);

    const NSFAdminFeeTransactionAdded = await addLedger(NSFAdminFeeTransaction);
    if (!NSFAdminFeeTransactionAdded) addToast("Adding NSF Administration Charge Failed", "error");

    if (NSFFeeData.defaultNSFFee) {
      await editLease({ ID: props.lease["ID"], NSFFee: NSFAdminFee }, props.dispatch);
      props.refreshProperty();
    }

    if (NSFAdminFeeTransactionAdded) addToast("NSF Charges Successfully Added", "success");

    setDefaultNSFAdminFeeDialog(false);
    setNSFAdminFeeDialog(false);
    setValue("NSFBankFee", null);
    setValue("NSFAdminFee", null);
    updateLedger();
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const NSFBankFeeDialogContent = (
    <Dialog
      open={NSFBankFeeDialog !== false}
      onClose={() => setNSFBankFeeDialog(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setNSFBankFeeDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit((NSFBankFeeData) => {
              if (!props?.lease?.NSFFee) setNSFAdminFeeDialog({ ...NSFBankFeeDialog, ...NSFBankFeeData });
              else handleNSFFeeSubmit({ ...NSFBankFeeDialog, ...NSFBankFeeData });
              setNSFBankFeeDialog(false);
            })}
            onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.NSFFeeDialogHeader}>
                  How much did your bank charge you for the NSF cheque?
                </Typography>
                <Typography variant="body1" align="center">
                  This fee should be charged to the tenant and added to the ledger
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="NSFBankFee"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="NSFBankFee"
                      label="NSF Bank Fee"
                      error={errors.NSFBankFee && errors.NSFBankFee !== null}
                      helperText={errors.NSFBankFee ? errorMessages["NSFBankFee"][errors.NSFBankFee.type] : null}
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "18px" }}>
                <Controller
                  name="NSFBankFeeDate"
                  control={control}
                  defaultValue={props.ledgerItem ? props.ledgerItem.date : today}
                  rules={{
                    required: true,
                    pattern: /^\d{4}-\d{2}-\d{2}$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={value ? new Date(value + " 00:00:00") : value}
                        onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                        inputVariant="outlined"
                        label="NSF Bank Fee Date"
                        format="yyyy-MM-dd"
                        fullWidth
                        clearable
                        InputLabelProps={{ shrink: Boolean(value) }}
                        error={errors.NSFBankFeeDate && errors.NSFBankFeeDate != null}
                        helperText={
                          errors.NSFBankFeeDate ? errorMessages["NSFBankFeeDate"][errors.NSFBankFeeDate.type] : null
                        }
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.NSFFeeDialogSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );

  const defaultNSFAdminFeeDialogContent = (
    <Dialog
      open={defaultNSFAdminFeeDialog !== false}
      onClose={() => setDefaultNSFAdminFeeDialog(false)}
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.confirmationDialogContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" gutterBottom>
              Would you like to make this the default NSF Administration Fee that you charge?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              You will be able to update it later on the lease page
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              onClick={() => handleNSFFeeSubmit({ ...defaultNSFAdminFeeDialog, defaultNSFFee: true })}
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              className={classes.deleteBtn}
              onClick={() => handleNSFFeeSubmit(defaultNSFAdminFeeDialog)}
              color="primary"
              variant="contained"
            >
              No
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const NSFAdminFeeDialogContent = (
    <Dialog
      open={NSFAdminFeeDialog !== false}
      onClose={() => setNSFAdminFeeDialog(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setNSFAdminFeeDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit((NSFAdminFeeData) =>
              setDefaultNSFAdminFeeDialog({ ...NSFAdminFeeDialog, NSFAdminFee: NSFAdminFeeData.NSFAdminFee })
            )}
            onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.NSFFeeDialogHeader}>
                  Do you want to charge your tenant an NSF Administration Fee?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="NSFAdminFee"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
                    validate: {
                      maxFee: (value) => !value || value.replace("$", "") <= 20,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="NSFAdminFee"
                      label="NSF Admin Fee"
                      error={errors.NSFAdminFee && errors.NSFAdminFee !== null}
                      helperText={errors.NSFAdminFee ? errorMessages["NSFAdminFee"][errors.NSFAdminFee.type] : null}
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.NSFFeeDialogSubmit}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  className={`${classes.NSFFeeDialogSubmit} ${classes.deleteBtn}`}
                  onClick={() => setNSFAdminFeeDialog(false)}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );

  const filterDialog = (
    <Dialog
      open={filterDialogOpen === true}
      onClose={() => setFilterDialogOpen(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.menuTitle}>
        <Typography variant="h6" className={classes.menuTitleText}>
          Filter By
        </Typography>
        <IconButton className={classes.menuTitleCloseButton} onClick={() => setFilterDialogOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <Grid container spacing={1} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.header}>
                Type
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip
                label="Charge"
                variant={typeFilter.includes("charge") ? "default" : "outlined"}
                onClick={() => updateTypeFilter("charge")}
                onDelete={typeFilter.includes("charge") ? () => updateTypeFilter("charge") : ""}
                className={classes.filtersChip}
              />
              <Chip
                label="Payment"
                variant={typeFilter.includes("payment") ? "default" : "outlined"}
                onClick={() => updateTypeFilter("payment")}
                onDelete={typeFilter.includes("payment") ? () => updateTypeFilter("payment") : ""}
                className={classes.filtersChip}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.header}>
                Reason
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip
                label="Rent"
                variant={reasonFilter.includes("Rent") ? "default" : "outlined"}
                onClick={() => updateReasonFilter("Rent")}
                onDelete={reasonFilter.includes("Rent") ? () => updateReasonFilter("Rent") : ""}
                className={classes.filtersChip}
              />
              <Chip
                label="Utilities"
                variant={reasonFilter.includes("Utilities") ? "default" : "outlined"}
                onClick={() => updateReasonFilter("Utilities")}
                onDelete={reasonFilter.includes("Utilities") ? () => updateReasonFilter("Utilities") : ""}
                className={classes.filtersChip}
              />
              <Chip
                label="Parking"
                variant={reasonFilter.includes("Parking") ? "default" : "outlined"}
                onClick={() => updateReasonFilter("Parking")}
                onDelete={reasonFilter.includes("Parking") ? () => updateReasonFilter("Parking") : ""}
                className={classes.filtersChip}
              />
              <Chip
                label="NSF Admin"
                variant={reasonFilter.includes("NSF Admin") ? "default" : "outlined"}
                onClick={() => updateReasonFilter("NSF Admin")}
                onDelete={reasonFilter.includes("NSF Admin") ? () => updateReasonFilter("NSF Admin") : ""}
                className={classes.filtersChip}
              />
              <Chip
                label="Other"
                variant={reasonFilter.includes("Other") ? "default" : "outlined"}
                onClick={() => updateReasonFilter("Other")}
                onDelete={reasonFilter.includes("Other") ? () => updateReasonFilter("Other") : ""}
                className={classes.filtersChip}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.header}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.dateRange}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={dateFilter.startDate ? new Date(dateFilter.startDate + " 00:00:00") : dateFilter.startDate}
                  onChange={(date) =>
                    setDateFilter({
                      ...dateFilter,
                      startDate: date ? format(date, "yyyy-MM-dd") : null,
                    })
                  }
                  inputVariant="outlined"
                  name="startDate"
                  label="Start Date"
                  format="yyyy-MM-dd"
                  clearable
                  InputLabelProps={{ shrink: Boolean(dateFilter) }}
                />
              </MuiPickersUtilsProvider>
              <Typography variant="body1" align="center" className={classes.dateRangeSeperator}>
                to
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={dateFilter.endDate ? new Date(dateFilter.endDate + " 00:00:00") : dateFilter.endDate}
                  onChange={(date) =>
                    setDateFilter({
                      ...dateFilter,
                      endDate: date ? format(date, "yyyy-MM-dd") : null,
                    })
                  }
                  inputVariant="outlined"
                  name="endDate"
                  label="End Date"
                  format="yyyy-MM-dd"
                  clearable
                  InputLabelProps={{ shrink: Boolean(dateFilter) }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.header}>
                Tenant
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="ledgerTenant">Tenant</InputLabel>
                <Select
                  value={tenantFilter}
                  onChange={(event) => setTenantFilter(event.target.value)}
                  id="ledgerTenant"
                  labelId="ledgerTenantLabel"
                  label="Tenant"
                  style={{ textAlign: "left" }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem value="">&#8203;</MenuItem>
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant["ID"]} value={tenant["ID"]}>
                      {tenant["firstName"] + " " + tenant["lastName"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  setFilters(true);
                  setFilterDialogOpen(false);
                }}
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.submit} ${classes.deleteBtn}`}
                onClick={() => {
                  setFilters(false);
                  setNewestFirst(true);
                  setReasonFilter([]);
                  setDateFilter({
                    startDate: null,
                    endDate: null,
                  });
                  setTenantFilter("");
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );

  const ledgerContent = (
    <>
      <LedgerDoubleDialog
        addToast={addToast}
        leaseID={props.lease["ID"]}
        ledgerFormData={ledgerFormData}
        leaseData={props.lease}
        tenants={tenants}
        setLedgerFormData={setLedgerFormData}
        updateLedger={updateLedger}
        setPaymentAdded={setPaymentAdded}
        handleNSFPayment={handleNSFPayment}
      />

      {missingPaymentAlert && (
        <Alert
          alert={missingPaymentAlert}
          addToast={addToast}
          type={"missingPayment"}
          globals={{
            property: props.property,
            lease: missingPaymentAlert?.lease,
          }}
          data={JSON.parse(missingPaymentAlert.data)}
          dispatch={props.dispatch}
        />
      )}

      <Grid container spacing={2} style={{ minHeight: "100%", flexDirection: "column" }}>
        <Grid item xs={12} style={{ padding: "8px 0px" }}>
          <Toolbar>
            <Box component="div" visibility={{ xs: "visibile", sm: "hidden" }}>
              <IconButton onClick={() => setLedgerFormData({ ledgerItem: null, action: "Add" })}>
                <AddIcon />
              </IconButton>
            </Box>
            <Typography className={classes.ledgerTitle} variant="h5" align="center">
              Tenant Ledger
            </Typography>
            <Box component="div" display={{ xs: "none", sm: "block" }}>
              <Tooltip title="Add Transaction" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                <IconButton onClick={() => setLedgerFormData({ ledgerItem: null, action: "Add" })}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                onClick={() => {
                  setNewestFirst(!newestFirst);
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={!newestFirst ? "Newest First" : "Oldest First"} />

                <ListItemSecondaryAction>
                  {!newestFirst ? <NewestFirstIcon /> : <OldestFirstIcon />}
                </ListItemSecondaryAction>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterDialogOpen(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Filters"} />
                <ListItemSecondaryAction>
                  <FiltersIcon />
                </ListItemSecondaryAction>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportLedger();
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={"Export"} />
                <ListItemSecondaryAction>
                  <ExportIcon />
                </ListItemSecondaryAction>
              </MenuItem>
            </Menu>
            <Tooltip title="More Options" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Grid>

        {ledger?.length > 0 ? (
          <Grid item xs={12}>
            {(newestFirst ? [...ledger].reverse() : [...ledger]).map((ledgerItem) => {
              if (
                !filters ||
                (filters &&
                  (typeFilter.length === 0 || typeFilter.includes(ledgerItem.type)) &&
                  (reasonFilter.length === 0 || reasonFilter.includes(ledgerItem.reason)) &&
                  (!dateFilter.startDate || moment(ledgerItem.date).isSameOrAfter(dateFilter.startDate, "day")) &&
                  (!dateFilter.endDate || moment(ledgerItem.date).isSameOrBefore(dateFilter.endDate, "day")) &&
                  (!tenantFilter || ledgerItem.tenantID === tenantFilter))
              ) {
                return (
                  <Card
                    key={ledgerItem["ID"]}
                    className={`${classes.infoCard} ${classes.ledgerItemContainer}`}
                    onClick={() =>
                      setLedgerFormData({
                        ledgerItem: ledgerItem,
                        action: "Edit",
                      })
                    }
                  >
                    <div className={classes.ledgerItem}>
                      <CardContent
                        className={classes.ledgerRow}
                        style={ledgerItem.NSF ? { textDecoration: "line-through red 4px" } : {}}
                      >
                        <Typography variant="h6" gutterBottom className={classes.ledgerText}>
                          {`${ledgerItem.reason} ${ledgerItem.type}${ledgerItem.useDeposit ? " (using deposit)" : ""}`}
                          <span className={classes.ledgerColon}>: </span>
                        </Typography>
                        <Typography variant="h6" gutterBottom className={classes.ledgerText}>
                          <span className={classes.boldGreen}>
                            <b>${ledgerItem.amount}</b>
                          </span>{" "}
                          <span style={{ whiteSpace: "nowrap" }} className={classes.caption}>
                            {moment(ledgerItem.date).format("MM/DD/YYYY")}
                          </span>
                        </Typography>
                      </CardContent>
                      <CardContent className={classes.ledgerRow}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          className={classes.ledgerText}
                          style={{ textAlign: "right" }}
                        >
                          Balance
                          <span className={classes.ledgerColon}>: </span>
                        </Typography>

                        <Typography
                          variant="h6"
                          className={
                            ledgerItem.type === "payment" && ledgerBalance[ledgerItem.ID] > 0
                              ? `${classes.ledgerText} ${classes.boldRed}`
                              : classes.ledgerText
                          }
                          align="right"
                          gutterBottom
                        >
                          <b>
                            {ledgerBalance[ledgerItem.ID] < 0 ? "-" : ""}${Math.abs(ledgerBalance[ledgerItem.ID])}
                          </b>
                        </Typography>
                      </CardContent>
                    </div>
                    <EditIcon className={classes.editIcon} />
                  </Card>
                );
              } else {
                return <div key={ledgerItem["ID"]}></div>;
              }
            })}
          </Grid>
        ) : ledger ? (
          <Grid container className={classes.emptyLedger}>
            <Grid item xs={12}>
              <LedgerTransactionIcon className={classes.emptyLedgerIcon} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No Ledger Transactions
              </Typography>
              <Typography variant="body1" align="center">
                Press the plus icon to add your first one
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />
      {NSFBankFeeDialogContent}
      {defaultNSFAdminFeeDialogContent}
      {NSFAdminFeeDialogContent}
      {filterDialog}
      <Container className={classes.container} maxWidth="md">
        {props.property["leases"].length > 1 && props.lease.status != "active" && (
          <Grid item xs={12} style={{ marginBottom: 0 }}>
            <Box component="div" display={{ xs: "none", sm: "block" }}>
              <Paper className={classes.content}>
                <LeaseHistory
                  history={props.history}
                  historyExpanded={props.historyExpanded}
                  setHistoryExpanded={props.setHistoryExpanded}
                  tenantsByLease={tenantsByLease}
                />
              </Paper>
            </Box>
            <Box component="div" display={{ xs: "block", sm: "none" }}>
              <LeaseHistory
                history={props.history}
                historyExpanded={props.historyExpanded}
                setHistoryExpanded={props.setHistoryExpanded}
                tenantsByLease={tenantsByLease}
                mobile
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box component="div" display={{ xs: "none", sm: "block" }}>
            <Paper style={{ height: ledger?.length > 0 ? "" : "72vh" }} className={classes.content}>
              {ledgerContent}
            </Paper>
          </Box>
          <Box component="div" display={{ xs: "block", sm: "none" }}>
            {ledgerContent}
          </Box>
        </Grid>
      </Container>
    </>
  );
}

const mapState = (state) => {
  return {
    alerts: state.alerts.alerts,
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Ledger);
