import React, { useState, useEffect } from "react";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import NotificationsIcon from "@material-ui/icons/NotificationsRounded";
import PropertyIcon from "@material-ui/icons/HomeRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import Logo from "../../../assets/logos/simpleLandlordFullsize.png";

import { getAlerts } from "../../../services/property/alertServices";
import Alert from "../property/components/Alert";
import ToastFactory from "../../components/ToastFactory";
import moment from "moment";
import { getBuilding } from "../../../services/buildingServices";
import PropertyMenu from "./PropertyMenu";
import { editUser } from "../../../services/userServices";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginLeft: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0 0 var(--tsl-brand-border-radius) var(--tsl-brand-border-radius)",
  },
  logoPicture: {
    height: 46,
    [`${theme.breakpoints.down("xs")}`]: {
      height: 38,
    },
  },
  headerIcons: {
    fontSize: "1.2em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.1em",
    },
  },
  alertMenu: {
    maxWidth: "500px",
  },
  alertMenuProperty: {
    maxWidth: "400px",
    [`${theme.breakpoints.between(376, 426)}`]: {
      maxWidth: "320px",
    },
    [`${theme.breakpoints.down(376)}`]: {
      maxWidth: "280px",
    },
    [`${theme.breakpoints.down(326)}`]: {
      maxWidth: "260px",
    },
  },
  alertMenuAddress: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  accordion: {
    width: "100%",
  },
  menuArrow: {
    "&:before": {
      content: '"■"',
      display: "block",
      position: "absolute",
      top: 32,
      right: 3,
      width: 10,
      height: 10,
      color: "#424242",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
      [`${theme.breakpoints.down("xs")}`]: {
        display: "none",
      },
    },
  },
  tipArrow: {
    "&:before": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tipBubble: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "10px 20px",
  },
  tooltipButton: {
    background: "none",
    boxShadow: "none",
    padding: "0px",
    fontWeight: "600",
    cursor: "pointer",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const [tips, setTips] = useState(props.profile?.tips ? JSON.parse(props.profile.tips) : {});

  const updateAlerts = async () => {
    if (!props.alerts) return;
    //console.log("ALERTS: ", props.alerts);

    let buildingsList = [];
    let nestedAlerts = {};
    let count = 0;

    const buildingsReceived = await getBuilding({
      nestProperties: true,
      nestLeases: true,
    });

    for (let alert of props.alerts) {
      const data = JSON.parse(alert.data);
      const alertType = alert.type;

      if (
        (alertType === "rentDue" && moment().isSameOrAfter(data.date, "day")) ||
        (alertType === "depositDue" && moment().isSameOrAfter(data.depositDate, "day")) ||
        (alertType === "moveIn" && moment().isSameOrAfter(data.date, "day")) ||
        (alertType === "moveOut" && moment().isSameOrAfter(data.date, "day")) ||
        alertType === "useRentDeposit" ||
        alertType === "missingPayment" ||
        alertType === "formNextStep" ||
        alertType === "conditionReport"
      ) {
        if (!(alert.buildingID in nestedAlerts)) {
          nestedAlerts[alert.buildingID] = { alerts: [], properties: {} };
        }

        if (alert.leaseID) {
          if (!(alert.propertyID in nestedAlerts[alert.buildingID]["properties"]))
            nestedAlerts[alert.buildingID]["properties"][alert.propertyID] = {
              alerts: [],
              leases: { [alert.leaseID]: [{ ...alert, data }] },
            };
          else if (alert.leaseID in nestedAlerts[alert.buildingID]["properties"][alert.propertyID]["leases"])
            nestedAlerts[alert.buildingID]["properties"][alert.propertyID]["leases"][alert.leaseID].push({
              ...alert,
              data,
            });
          else
            nestedAlerts[alert.buildingID]["properties"][alert.propertyID]["leases"][alert.leaseID] = [
              { ...alert, data },
            ];
        } else if (alert.propertyID) {
          if (alert.propertyID in nestedAlerts[alert.buildingID]["properties"])
            nestedAlerts[alert.buildingID]["properties"][alert.propertyID]["alerts"].push({ ...alert, data });
          else
            nestedAlerts[alert.buildingID]["properties"][alert.propertyID] = {
              alerts: [{ ...alert, data }],
              leases: {},
            };
        } else {
          nestedAlerts[alert.buildingID]["alerts"].push({ ...alert, data });
        }
        count++;
      }
    }

    if (Object.keys(nestedAlerts).length > 0) {
      for (let building of buildingsReceived) {
        if (!(building.ID in nestedAlerts)) continue;

        const address =
          `${building["streetNum"]} ${building["streetName"]} ` +
          `${building["streetType"]}${building["direction"] ? ` ${building["direction"]}` : ""}, ` +
          `${building["municipality"]}, ${building["province"]} ${building["postalCode"]}`;

        let properties = [];

        for (let property of building["properties"]) {
          if (!(property.ID in nestedAlerts[building.ID]["properties"])) continue;

          const address =
            `${property["unitNum"] ? `#${property["unitNum"]} ` : ""}${building["streetNum"]} ${
              building["streetName"]
            } ` +
            `${building["streetType"]}${building["direction"] ? ` ${building["direction"]}` : ""}, ` +
            `${building["municipality"]}, ${building["province"]} ${building["postalCode"]}`;

          let leases = [];

          for (let lease of property["leases"]) {
            if (!(lease.ID in nestedAlerts[building.ID]["properties"][property.ID]["leases"])) continue;
            leases.push({ ...lease, alerts: nestedAlerts[building.ID]["properties"][property.ID]["leases"][lease.ID] });
          }

          properties.push({
            ...property,
            address,
            alerts: nestedAlerts[building.ID]["properties"][property.ID]["alerts"],
            leases,
          });
        }
        buildingsList.push({ ...building, address, alerts: nestedAlerts[building.ID]["alerts"], properties });
      }
    }

    setBuildings(buildingsList);
    setAlertCount(count);
  };

  useEffect(() => {
    getAlerts(props.dispatch);
  }, []);

  useEffect(() => {
    updateAlerts();
  }, [props.alerts]);

  useEffect(() => {
    setTips(props.profile?.tips ? JSON.parse(props.profile.tips) : {});
  }, [props.profile]);

  useEffect(() => {
    if (anchorEl) setTimeout(() => setMenuArrowClass(classes.menuArrow), 50);
    else setMenuArrowClass(null);
  }, [anchorEl]);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [menuArrowClass, setMenuArrowClass] = useState(null);

  return (
    <>
      <ToastFactory toasts={toasts} />
      <AppBar
        position="absolute"
        className={classes.appBar}
        {...(location.pathname.includes("/forms/viewer") ? { style: { borderRadius: "0px" } } : {})}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Link component={RouterLink} to="/home">
                <Tooltip title="Home">
                  <img src={Logo} className={classes.logoPicture} alt="The Simple Landlord Logo" />
                </Tooltip>
              </Link>
            </Grid>

            {location.pathname !== "/" &&
              location.pathname !== "/properties" &&
              location.pathname !== "/home" &&
              location.pathname !== "/settings" && (
                <Grid item>
                  <PropertyMenu />
                </Grid>
              )}
            <Grid item>
              <Tooltip title="My Properties" PopperProps={{ style: { marginTop: "-8px" } }}>
                <IconButton component={RouterLink} to="/properties">
                  <PropertyIcon className={classes.headerIcons} />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="bottom"
                arrow
                open={Boolean(tips?.dashboardAlert && !tips?.menuAlert)}
                classes={{
                  tooltip: classes.tipBubble,
                  arrow: classes.tipArrow,
                }}
                PopperProps={{
                  onClick: () => editUser({ tips: { ...tips, menuAlert: true } }, null, props.dispatch),
                  style: { pointerEvents: "auto" },
                }}
                title={
                  <>
                    <Typography style={{ textAlign: "center" }}>
                      All important alerts for your properties will also appear in the notification center
                    </Typography>
                    <div style={{ textAlign: "right", marginTop: "5px" }}>
                      <Button className={classes.tooltipButton}>OK</Button>
                    </div>
                  </>
                }
              >
                <span>
                  <Tooltip title="Notifications" PopperProps={{ style: { marginTop: "-8px" } }}>
                    <IconButton
                      onClick={(e) => {
                        editUser({ tips: { ...tips, menuAlert: true } }, null, props.dispatch);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <Badge badgeContent={alertCount} color="error" overlap="rectangular" className={menuArrowClass}>
                        <NotificationsIcon className={classes.headerIcons} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </span>
              </Tooltip>
              <Tooltip title="Settings" PopperProps={{ style: { marginTop: "-8px" } }}>
                <IconButton component={RouterLink} to="/settings">
                  <SettingsIcon className={classes.headerIcons} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {buildings && (
        <Menu
          anchorEl={anchorEl}
          marginThreshold={0}
          PaperProps={{
            style: mobile
              ? {
                  maxWidth: "100%",
                  maxHeight: "92%",
                  left: 0,
                  right: 0,
                }
              : {},
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          getContentAnchorEl={null}
          className={classes.alertMenu}
          MenuListProps={{ disablePadding: true }}
        >
          {alertCount > 0 && buildings.length > 0 ? (
            buildings.map((building) => (
              <div key={building.ID} style={{ padding: "1px 0px" }}>
                {building["alerts"].length > 0 && (
                  <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.alertMenuProperty}>
                        <Typography align="center" noWrap className={classes.alertMenuAddress}>
                          {building.address}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4} alignItems="center" justifyContent="center">
                        {building["alerts"].map((alert) => {
                          return (
                            <Grid key={alert.ID} item xs={12}>
                              <Alert
                                addToast={addToast}
                                alert={alert}
                                data={alert.data}
                                globals={{
                                  building,
                                  alertMenu: setAnchorEl,
                                }}
                                dispatch={props.dispatch}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
                {building["properties"].length > 0 &&
                  building["properties"].map((property) => (
                    <Accordion key={property.ID} defaultExpanded className={classes.accordion}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.alertMenuProperty}>
                          <Typography align="center" noWrap className={classes.alertMenuAddress}>
                            {property.address}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={4} alignItems="center" justifyContent="center">
                          {property["alerts"].map((propertyAlert) => (
                            <Grid key={propertyAlert.ID} item xs={12}>
                              <Alert
                                addToast={addToast}
                                alert={propertyAlert}
                                data={propertyAlert.data}
                                globals={{
                                  building,
                                  property,
                                  alertMenu: setAnchorEl,
                                }}
                                dispatch={props.dispatch}
                              />
                            </Grid>
                          ))}
                          {property["leases"].map((lease) =>
                            lease["alerts"].map((leaseAlert) => (
                              <Grid key={leaseAlert.ID} item xs={12}>
                                <Alert
                                  addToast={addToast}
                                  alert={leaseAlert}
                                  data={leaseAlert.data}
                                  globals={{
                                    building,
                                    property,
                                    lease,
                                    alertMenu: setAnchorEl,
                                  }}
                                  dispatch={props.dispatch}
                                />
                              </Grid>
                            ))
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </div>
            ))
          ) : (
            <MenuItem
              disableRipple
              style={{
                backgroundColor: "transparent",
                cursor: "default",
                textAlign: "center",
              }}
            >
              <ListItemText primaryTypographyProps={{ noWrap: true }} primary="You're all caught up!" />
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    profile: state.authentication.profile,
    alerts: state.alerts.alerts,
    lease: state.building.lease,
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Header);
