import axios from "axios";
import setAxiosDefaultSettings from "../../../utils/axiosSettings.js";
import { getAlerts } from "../alertServices";

setAxiosDefaultSettings(axios);

export const getForms = async (LTBFormData) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await updateForm(axios.get, {
    params: { ...LTBFormData, clonedUserID },
  });
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addForm = async (LTBFormData, handleErrors, dispatch) => {
  const formData = new FormData();
  Object.keys(LTBFormData).forEach((field) => formData.append(field, LTBFormData[field]));

  return await updateForm(axios.post, formData, handleErrors, dispatch);
};

export const editForm = async (LTBFormData) => {
  return await updateForm(axios.put, LTBFormData);
};

export const deleteForm = async (LTBFormData, dispatch) => {
  return await updateForm(axios.delete, { data: LTBFormData }, null, dispatch);
};

const updateForm = async (axiosMethod, LTBFormData, handleErrors = null, dispatch = null) => {
  let formReceived = false;
  let responseError = null;

  await axiosMethod(`/v2/buildings/properties/leases/forms/?timestamp=${new Date().getTime()}`, LTBFormData)
    .then(async (response) => {
      if (response.config?.method === "delete") formReceived = true;
      else if (response.data) formReceived = response.data;
      else {
        responseError = response.data?.error || "Form Update failed";
        console.log("No form", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Form Information";
    });

  if (handleErrors) handleErrors(responseError);
  if (dispatch && formReceived) await getAlerts(dispatch);

  return formReceived;
};
