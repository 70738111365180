import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";
import { getAlerts } from "./alertServices";

setAxiosDefaultSettings(axios);

export const getLease = async (leaseData) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/properties/leases/?timestamp=${new Date().getTime()}`, {
      params: { ...leaseData, clonedUserID },
    })
    .then((response) => response.data);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addLease = async (leaseData, dispatch, handleErrors) => {
  const formData = new FormData();
  Object.keys(leaseData).forEach((field) => formData.append(field, leaseData[field]));

  return await updateLease(axios.post, formData, dispatch, handleErrors);
};

export const editLease = async (leaseData, dispatch, handleErrors) => {
  return await updateLease(axios.put, leaseData, dispatch, handleErrors);
};

export const deleteLease = async (leaseData, dispatch) => {
  return await updateLease(axios.delete, { data: leaseData }, dispatch);
};

const updateLease = async (axiosMethod, leaseData, dispatch, handleErrors = null) => {
  let leaseReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/leases/", leaseData)
    .then(async (response) => {
      if (response.config && response.config.method === "delete") leaseReceived = true;
      else if (response && response.data) leaseReceived = response.data;
      else {
        responseError = (response.data && response.data.error) || "Updating Lease failed";
        console.log("No lease", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Lease Information";
    });

  if (handleErrors) handleErrors(responseError);
  if (dispatch && leaseReceived) await getAlerts(dispatch);

  return leaseReceived;
};
