import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";
import { setAlerts } from "../../redux/actions/alertsActions";

setAxiosDefaultSettings(axios);

export const getAlerts = async (dispatch, alertData = null) => {
  let alerts = null;
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  await axios
    .get(`/v2/buildings/properties/alerts/?timestamp=${new Date().getTime()}`, {
      params: { ...alertData, clonedUserID },
    })
    .then((response) => {
      if (dispatch) dispatch(setAlerts(response.data));
      alerts = response.data;
    });
  return alerts;
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addAlert = async (alertData, dispatch, handleErrors = null) => {
  return await updateAlert(axios.post, alertData, dispatch, handleErrors);
};

export const editAlert = async (alertData, dispatch, handleErrors = null) => {
  return await updateAlert(axios.put, alertData, dispatch, handleErrors);
};

export const deleteAlert = async (alertData, dispatch) => {
  return await updateAlert(axios.delete, { data: alertData }, dispatch);
};

const updateAlert = async (axiosMethod, alertData, dispatch, handleErrors = null) => {
  let alertReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/alerts/", alertData)
    .then(async (response) => {
      if (response.config && response.config.method === "delete") alertReceived = true;
      else if (response && response.data) alertReceived = response.data;
      else {
        responseError = (response.data && response.data.error) || "Updating Alert failed";
        console.log("No alert", { responseError });
      }
    })
    .catch(async (error) => {
      responseError = (error.response.data && error.response.data.error) || "Incorrect Alert Information";
      console.log("Server", { responseError });
    });

  if (handleErrors) handleErrors(responseError);
  if (dispatch && alertReceived) await getAlerts(dispatch);

  return alertReceived;
};
