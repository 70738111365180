import firebase from "firebase/app";
import "firebase/messaging";
import { editUser } from "../services/userServices.js";

const firebaseConfig = {
  apiKey: "AIzaSyB59qsy3nSjaIJXTLgYRWG0JwnBCKm2w_A",
  authDomain: "the-simple-landlord-cb33d.firebaseapp.com",
  projectId: "the-simple-landlord-cb33d",
  storageBucket: "the-simple-landlord-cb33d.appspot.com",
  messagingSenderId: "169048271781",
  appId: "1:169048271781:web:6aba0c32bea59c3e55d64c",
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
else firebase.app(); // if already initialized, use that one

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

// if (messaging)
//   messaging.onMessage((payload) => {
//     console.log("Notification Received: ", JSON.stringify(payload, null, 2));
//   });

const getToken = async () => {
  // get registration token. Initially this makes a network call, once retrieved subsequent calls to getToken will return from cache.
  if (messaging) {
    const timeout = new Promise((resolve) => setTimeout(() => resolve(null), 5000));
    const permission = await Promise.race([Notification.requestPermission(), timeout]);
    if (permission === "granted")
      return await messaging
        .getToken({
          vapidKey: "BJqpIuXSUkzyjTOAWExAIePC2Aq5yfnxpdR_FDgLzTHyN7_5SybD2SH2586rAzx_BCckjCTSvwfgL0ELiW9xzYk",
        })
        .then((token) => {
          if (token) console.log("token: ", token);
          else console.log("No registration token available");
          return token;
        })
        .catch((err) => console.log("An error occurred while retrieving token ", err));
  }
};

const deleteToken = async (token, setDeviceToken, addToast) => {
  if (messaging) {
    await messaging
      .deleteToken()
      .then(async () => {
        const userEdited = await editUser({ deviceToken: token });
        if (userEdited) {
          if (setDeviceToken) setDeviceToken(false);
          addToast("Notifications Disabled", "success");
        } else console.log("Server error encountered when deleting token");
      })
      .catch(async (err) => {
        console.log("Unable to delete token", err);
        const userEdited = await editUser({ deviceToken: token });
        if (userEdited) {
          if (setDeviceToken) setDeviceToken(false);
          addToast("Notifications Disabled", "success");
        } else console.log("Server error encountered when deleting token");
      });
  }
};

const addToken = async (token, setDeviceToken, addToast) => {
  const userEdited = await editUser({ deviceToken: token });
  if (userEdited) {
    if (setDeviceToken) setDeviceToken(true);
    addToast("Notifications Enabled", "success");
  } else console.log("Server error encountered when adding token");
};

export { getToken, addToken, deleteToken };
