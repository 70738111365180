import axios from "axios";
import setAxiosDefaultSettings from "../../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);
axios.defaults.timeout = 1000;

export const getSuggestedSpelling = async (searchTerm) => {
  let dataReceived = false;
  let responseError = null;

  await axios
    .get(`https://testsetup.ml/api/v1/services/suggested-spelling/?timestamp=${new Date().getTime()}`, {
      params: { searchTerm },
    })
    .then(async (response) => {
      if (response.data) dataReceived = response.data;
      else {
        responseError = response.data?.error || "Failed to get spelling suggestions";
        console.log("No spelling suggestions: ", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
    });

  return dataReceived;
};
