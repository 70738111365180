import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { setClonedUser } from "../../redux/actions/authenticationActions";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import UserIcon from "@material-ui/icons/Person";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import PropertyIcon from "@material-ui/icons/HomeRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { DataGrid } from "@mui/x-data-grid";

import { getUser, getAnnouncements, askQuestion } from "../../services/userServices.js";
import { getAlerts } from "../../services/property/alertServices.js";

import ToastFactory from "../components/ToastFactory";
import Loading from "../components/Loading";
import moment from "moment";

const errorMessages = {
  question: {
    required: "Please enter your question",
    length: "Your question cannot exceed 500 characters",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: "40px",
    maxHeight: "20vh",
    maxWidth: "100%",
  },
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: "700",
  },
  greetingHeader: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2.2em",
    },
  },
  announcementHeader: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2.0em",
    },
  },
  announcementDate: {
    marginTop: "-10px",
    color: "rgba(255, 255, 255, 0.5)",
    [`${theme.breakpoints.down("xs")}`]: {
      marginTop: "0px",
      color: "rgba(255, 255, 255, 0.6)",
      fontSize: "1em",
    },
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
  },
  infoCard: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.light,
  },
  cardActionIcons: {
    fontSize: "7em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "4.5em",
    },
  },
  cardActionTitle: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.4em",
    },
  },
  cardActions: {
    minWidth: "500px",
    minHeight: "60px",
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      minWidth: "290px",
      minHeight: "50px",
      fontSize: "1.2em",
    },
  },
  myPropertiesGrid: {
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: "30px",
    },
  },
  rentDueInfoCard: {
    height: "100%",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  conditionReportCardContent: {
    position: "relative",
    [`${theme.breakpoints.down("xs")}`]: {
      paddingTop: theme.spacing(6),
    },
  },
  infoText: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(-3, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  form: {
    width: "100%",
  },
  dialogHeader: {
    margin: theme.spacing(4, 4, 1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  dialogSubmit: {
    margin: theme.spacing(3, 0, 0),
  },
  adminButton: { fontSize: "1.2em" },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

function Home(props) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [rows, setRows] = useState([]);
  const [adminView, setAdminView] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [askQuestionDialog, setAskQuestionDialog] = useState(false);

  const updateUser = async () => {
    const userReceived = await getUser();
    setUser(userReceived);
  };

  const updateAdminView = async () => {
    if (user.isAdmin) {
      let users = await getUser(null, { admin: true });
      if (users?.length > 1) {
        for (let user of users) delete Object.assign(user, { ["id"]: user["ID"] })["ID"];
        setRows(users);
      }
    }
    setAdminView(true);
  };

  const updateAnnouncements = async () => {
    const announcementsReceived = await getAnnouncements();
    if (announcementsReceived) setAnnouncements(announcementsReceived);
  };

  useEffect(() => {
    updateUser();
    updateAnnouncements();
  }, []);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const theme = useTheme();
  const betweenSmLg = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const onSubmit = async (questionData) => {
    const serverError = await askQuestion(questionData);
    if (!serverError) {
      setAskQuestionDialog(false);
      setValue("question", null);
      addToast("Email Sent. We Will Get Back To You Shortly!", "success");
    } else {
      addToast("Unexpected Error Occured. Please Try Again Later", "error");
    }
  };

  if (props.loading) return <Loading />;

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 1.5 },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "dayPhone",
      headerName: "Phone",
      flex: 1,
    },
  ];

  const askAQuestionDialog = (
    <Dialog
      open={askQuestionDialog !== false}
      onClose={() => setAskQuestionDialog(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setAskQuestionDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.dialogHeader}>
                  Ask us a question about your properties or the app, and we'll reach out to you with a response as soon
                  as possible!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="question"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: {
                      length: (value) => value.length <= 500,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      multiline
                      minRows={6}
                      maxRows={6}
                      id="question"
                      label="Question"
                      error={errors.question && errors.question !== null}
                      helperText={
                        errors.question
                          ? errorMessages["question"][errors.question.type]
                          : field?.value?.length
                          ? `${field.value.length} / 500`
                          : "0 / 500"
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.dialogSubmit}>
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );

  const userActions = (
    <>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={12} style={{ maxWidth: "500px" }}>
          <img className={classes.logo} src="/LandingPage/SI Landlord.png" />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h3" align="center" className={`${classes.header} ${classes.greetingHeader}`}>
          Welcome {user?.firstName}
        </Typography>
      </Grid>

      {user?.isAdmin ? (
        <Grid item xs={12} style={{ paddingBottom: "30px" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.cardActions}
            onClick={() => updateAdminView()}
          >
            Admin View {"\u00A0"} <AdminIcon style={{ marginBottom: "2px" }} />
          </Button>
        </Grid>
      ) : null}

      <Grid item xs={12} style={{ paddingBottom: "30px" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.cardActions}
          onClick={() => props.history.push(`/properties`)}
        >
          My Properties {"\u00A0"} <PropertyIcon style={{ marginBottom: "2px" }} />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.cardActions}
          onClick={() => setAskQuestionDialog(true)}
        >
          GOT A Question {"\u00A0"} <QuestionIcon style={{ marginBottom: "2px" }} />
        </Button>
      </Grid>
    </>
  );

  const announcementSection = (
    <Grid container spacing={2} style={{ justifyContent: "center" }}>
      {announcements.map((announcement) => (
        <Grid key={announcement.ID} item xs={12} md={12}>
          <Card className={classes.rentDueInfoCard}>
            <CardContent className={classes.conditionReportCardContent}>
              <Typography
                variant="h5"
                align="center"
                className={classes.infoText}
                style={{ padding: betweenSmLg ? "0px 20px" : null }}
              >
                {announcement.data}
              </Typography>
              <Typography variant="subtitle1" align="right" className={classes.announcementDate}>
                {moment(announcement.date).format("MMM D, YYYY")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <Grid container spacing={1} style={{ textAlign: "center", marginBottom: "20px" }}>
        <ToastFactory toasts={toasts} />
        {adminView ? (
          <Grid container style={{ justifyContent: "center", marginTop: "50px" }}>
            <Grid item xs={12} style={{ paddingBottom: "25px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.adminButton}
                style={{ marginRight: "10px" }}
                onClick={() => setAdminView(false)}
              >
                Home View
                <PropertyIcon style={{ marginLeft: "5px", marginBottom: "2px" }} />
              </Button>
              {props.clonedUser ? (
                <Button
                  type="submit"
                  variant="contained"
                  className={`${classes.adminButton} ${classes.deleteBtn}`}
                  onClick={() => {
                    props.dispatch(setClonedUser(null));
                    document.cookie = `clonedUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                    getAlerts(props.dispatch);
                    addToast("User Successfully Reset", "success");
                  }}
                >
                  Reset User
                  <UserIcon style={{ marginLeft: "5px", marginBottom: "2px" }} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={selectedUser.length !== 1}
                  variant="contained"
                  color="primary"
                  className={classes.adminButton}
                  onClick={() => {
                    const userObject = {
                      id: selectedUser[0].id,
                      firstName: selectedUser[0].firstName,
                      lastName: selectedUser[0].lastName,
                    };
                    props.dispatch(setClonedUser(userObject));
                    document.cookie = `clonedUser=${JSON.stringify(userObject)}; expires=${new Date(
                      Date.now() + 86400 * 1000
                    ).toUTCString()}; path=/;`;
                    getAlerts(props.dispatch);
                    addToast("User Successfully Selected", "success");
                  }}
                >
                  Select User
                  <UserIcon style={{ marginLeft: "5px", marginBottom: "2px" }} />
                </Button>
              )}
            </Grid>
            <Grid item xs={12} style={{ width: "90vw", height: "80vh" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight={true}
                onSelectionModelChange={(ids) => setSelectedUser(ids.map((id) => rows.find((row) => row.id === id)))}
                disableColumnMenu
                checkboxSelection
                hideFooter
              />
            </Grid>
          </Grid>
        ) : (
          <>
            {askAQuestionDialog}
            {userActions}
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                align="center"
                className={`${classes.header} ${classes.announcementHeader}`}
              >
                Announcements
              </Typography>
              <Box component="div" display={{ xs: "none", sm: "block" }}>
                <Paper className={classes.content}>{announcementSection}</Paper>
              </Box>
              <Box component="div" display={{ xs: "block", sm: "none" }}>
                {announcementSection}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

const mapState = (state) => {
  return {
    clonedUser: state.authentication.clonedUser,
    alerts: state.alerts.alerts,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Home);
