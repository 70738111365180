import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";

import ListIcon from "@material-ui/icons/ListRounded";
import ViewComfyIcon from "@material-ui/icons/ViewComfyRounded";
import MenuIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/CloseRounded";
import TrendingUpIcon from "@material-ui/icons/ArrowUpwardRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import moment from "moment";
import Alert from "../property/components/Alert";
import ToastFactory from "../../components/ToastFactory";
import EditPropertyDoubleDialog from "../property/components/EditPropertyDoubleDialog";
import ScrollContainer from "react-indiana-drag-scroll";
import AddPropertyDoubleDialog from "./AddPropertyDoubleDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  propertyCard: {
    height: "100%",
  },
  addLeaseIcon: {
    display: "none",
  },
  addLeaseText: {
    display: "none",
  },
  crossLeaseIcon: {
    display: "block",
  },
  unitCount: {
    fontWeight: "700",
  },
  noLeaseText: {
    display: "block",
  },
  cardContent: {
    textAlign: "center",
    minHeight: "55%",
  },
  media: {
    paddingTop: "45%",
  },
  mediaLarge: {
    paddingTop: "66%",
  },
  addressSection: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "45px",
    [`${theme.breakpoints.down("xs")}`]: {
      marginLeft: "35px",
    },
  },
  listWrapper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflow: "auto",
    margin: `${theme.spacing(2)}px 0px`,
  },
  listGrid: {
    display: "flex",
    minHeight: "150px",
  },
  buildingAlert: {
    margin: "0px 8px",
    minWidth: "50%",
    [`${theme.breakpoints.down("xs")}`]: {
      minWidth: "85%",
    },
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    justifyContent: "space-around",
    [`${theme.breakpoints.down("xs")}`]: {
      justifyContent: "space-between",
    },
  },
  detailText: {
    fontSize: "1.1rem",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1rem",
    },
  },
  buildingBtns: {
    color: theme.palette.grey[500],
    position: "absolute",
    top: "10px",
    right: "15px",
  },
  noLeaseDiv: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  noLeaseIcon: {
    position: "relative",
    display: "inline-block",
    color: "white",
  },
  leaseOverlayIcon: {
    fontSize: "40px",
    left: "50%",
    top: "58%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.background.paper,
    position: "absolute",
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  rentDueDialogHeader: {
    margin: theme.spacing(2, 0, 1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      margin: theme.spacing(4, 0, 1),
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  genericDialogSubmit: {
    margin: theme.spacing(2, 0, 2),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(-1, 0, 1),
    },
  },
}));

let addressTop = null;
let addressBottom = null;
function PropertyCard({
  buildings,
  setBuildings,
  building,
  propertyImages,
  propertyTenants,
  propertyROIData,
  totalProperties,
  isLoaded,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const breakpointsDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointsDownMD = useMediaQuery(theme.breakpoints.down("md"));

  let property = null;
  if (props.property) property = props.property;
  else property = building["properties"].length > 0 ? building["properties"][0] : null;

  const listColumns = () => {
    if (props?.propertyView != "List") return 0;

    if (breakpointsDownXS) return 3;
    if (breakpointsDownSM) return 4;
    if (breakpointsDownMD) return 5;
    return 6;
  };

  const [buildingOpen, setBuildingOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [propertyView, setPropertyView] = useState("Grid");
  const [addPropertyDialog, setAddPropertyDialog] = useState(false);
  const [editPropertyDialog, setEditPropertyDialog] = useState(false);
  const [renewSubscriptionDialog, setRenewSubscriptionDialog] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [alerts, setAlerts] = useState(null);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const isBuildingCard = () => building["properties"].length != 1 && !props.property;
  const isNestedPropertyCard = () => !!props.property;

  const getBuildingAlerts = async () => {
    if (!props.alerts || !isBuildingCard()) {
      setAlerts(null);
      return;
    }

    let _alerts = props.alerts.filter((alert) => {
      const data = JSON.parse(alert.data);
      return (
        alert.buildingID == building["ID"] &&
        !alert.propertyID &&
        "leaseIDs" in data &&
        ["rentDue"].includes(alert.type)
      );
    });

    // console.log("buildingAlerts: ", _alerts);
    setAlerts(_alerts);
  };

  useEffect(() => {
    getBuildingAlerts();
  }, [props.alerts]);

  const handlePropertyClick = useCallback((_building, _property) => {
    if (_building && isBuildingCard()) {
      setBuildings((_buildings) => {
        let index = buildings.indexOf(building);
        _buildings.splice(index - (index % 3), 0, _buildings.splice(index, 1)[0]);
        return _buildings;
      });
      setTimeout(() => {
        setBuildingOpen((_buildingOpen) => !_buildingOpen);
      }, 100);
      return;
    }

    if (!_property) return;
    if (_property["status"] !== "paid" && _property["subscriptionID"])
      setRenewSubscriptionDialog({ propertyID: _property["ID"], currentPeriodEnd: _property["currentPeriodEnd"] });
    else props.history.push(`/${_property["ID"]}`);
  }, []);

  addressTop =
    `${!isBuildingCard() && property["unitNum"] ? `#${property["unitNum"]} ` : ""}${building["streetNum"]} ${
      building["streetName"]
    } ` + `${building["streetType"]}${building["direction"] ? ` ${building["direction"]}` : ""}`;

  addressBottom = `${building["municipality"]}, ${building["province"]} ${building["postalCode"]}`;

  const renewASubscriptionDialog = (
    <>
      <Dialog
        open={renewSubscriptionDialog}
        onClose={() => setRenewSubscriptionDialog(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Attention!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setRenewSubscriptionDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                We attempted to charge the card on your file for this property's subscription fee on{" "}
                {moment(renewSubscriptionDialog.currentPeriodEnd).format("MM/DD/YYYY")} but the payment failed to go
                through. You'll need to proceed to checkout to regain access to this property.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={() =>
                  props.history.push({
                    pathname: "/properties/checkout",
                    state: { propertyID: renewSubscriptionDialog.propertyID, totalProperties },
                  })
                }
              >
                Renew Subscription
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setRenewSubscriptionDialog(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  let propertyCard = (
    <Card className={buildingOpen ? "" : classes.propertyCard}>
      {buildingOpen ? (
        <CardContent
          className={classes.cardContent}
          style={{
            padding: breakpointsDownXS ? "32px 15px" : "32px",
            position: "relative",
          }}
        >
          <div className={classes.addressSection} style={{ margin: "30px 0px" }}>
            <div>
              <Typography gutterBottom variant="h5" style={{ fontSize: "1.6rem", marginBottom: "0px" }}>
                <b>{addressTop}</b>
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                style={{
                  fontSize: "1.3rem",
                  color: "lightgrey",
                }}
              >
                {addressBottom}
              </Typography>
            </div>
            <Tooltip title="Edit Building" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton onClick={() => setEditPropertyDialog(true)} style={{ marginBottom: 8, height: "59px" }}>
                <EditIcon fontSize="large" className={classes.editIcon} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.buildingBtns}>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                onClick={() => {
                  setAddPropertyDialog(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary="Add Units" />
                <ListItemSecondaryAction style={{ transform: "translateY(-40%)" }}>
                  <AddIcon />
                </ListItemSecondaryAction>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPropertyView(propertyView == "Grid" ? "List" : "Grid");
                  setAnchorEl(null);
                }}
              >
                <ListItemText
                  primary={propertyView == "Grid" ? "View Properties As List" : "View Properties As Grid"}
                />
                <ListItemSecondaryAction style={{ transform: "translateY(-40%)" }}>
                  {propertyView == "Grid" ? <ListIcon /> : <ViewComfyIcon />}
                </ListItemSecondaryAction>
              </MenuItem>
            </Menu>
            <Tooltip title="More Options" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close Building" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton onClick={() => handlePropertyClick(building, property)}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          {alerts && alerts.length > 0 && (
            <ScrollContainer className={classes.listWrapper} vertical={false} hideScrollbars={false}>
              <div className={classes.listGrid} style={alerts.length == 1 ? { justifyContent: "center" } : {}}>
                {alerts.map((alert) => (
                  <Alert
                    key={alert.ID}
                    addToast={addToast}
                    alert={alert}
                    data={JSON.parse(alert.data)}
                    globals={{
                      building: building,
                    }}
                    dispatch={props.dispatch}
                    className={classes.buildingAlert}
                  />
                ))}
              </div>
            </ScrollContainer>
          )}
          <Grid container spacing={1}>
            {building["properties"].map((_property) => (
              <PropertyCard
                key={_property["ID"]}
                buildings={buildings}
                building={building}
                property={_property}
                propertyImages={propertyImages}
                propertyTenants={propertyTenants}
                propertyROIData={propertyROIData}
                propertyView={propertyView}
                totalProperties={totalProperties}
                isLoaded={isLoaded}
                history={props.history}
              />
            ))}
          </Grid>
        </CardContent>
      ) : (
        <CardActionArea onClick={() => handlePropertyClick(building, property)} style={{ height: "100%" }}>
          {listColumns() ? (
            <></>
          ) : (
            <CardMedia
              image={
                property && propertyImages[property["ID"]]
                  ? propertyImages[property["ID"]]
                  : process.env.PUBLIC_URL + "/testImages/Default-Large.jpg"
              }
              title={addressTop + ", " + addressBottom}
              className={buildings.length > 2 ? classes.media : classes.mediaLarge}
            />
          )}
          <CardContent
            className={classes.cardContent}
            style={isNestedPropertyCard() ? { backgroundColor: "rgb(30 30 30)", padding: "18px" } : {}}
          >
            {listColumns() ? (
              <></>
            ) : (
              <>
                <Typography gutterBottom variant="h5" style={{ fontSize: "1.6rem", marginBottom: "0px" }}>
                  <b>{addressTop}</b>
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{
                    fontSize: "1.3rem",
                    color: "lightgrey",
                    marginBottom: "15px",
                  }}
                >
                  {addressBottom}
                </Typography>
              </>
            )}
            {isBuildingCard() ||
            ((isLoaded["images"] || property["ID"] in propertyImages) &&
              (isLoaded["ROI"] || property["ID"] in propertyROIData) &&
              (isLoaded["tenants"] || property["ID"] in propertyTenants)) ? (
              isBuildingCard() ? (
                <Typography color="secondary" className={classes.unitCount} variant="h5">
                  {building["properties"].length} <span style={{ color: "white" }}>Units</span>
                </Typography>
              ) : property["activeLease"] ? (
                <>
                  <div className={classes.details} style={listColumns() ? { marginTop: "0px" } : {}}>
                    {listColumns() ? (
                      <div style={listColumns() ? { flex: "1" } : {}}>
                        <Typography className={classes.detailText}>Unit&nbsp;</Typography>
                        <Typography color="secondary" className={classes.detailText}>
                          {property["unitNum"] == "" ? "N/A" : property["unitNum"]}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    <div style={listColumns() ? { flex: "1" } : {}}>
                      <Typography className={classes.detailText}>Tenants&nbsp;</Typography>
                      <Typography color="secondary" className={classes.detailText}>
                        {property["ID"] in propertyTenants ? <b>{propertyTenants[property["ID"]].length}</b> : "?"}
                      </Typography>
                    </div>
                    <div style={listColumns() ? { flex: "1" } : {}}>
                      <Typography className={classes.detailText}>
                        {property["activeLease"]["rentCycle"]} Rent&nbsp;
                      </Typography>
                      <Typography color="secondary" className={classes.detailText}>
                        <b>
                          $
                          {parseFloat(property["activeLease"]["rentPrice"]).toLocaleString("en", {
                            useGrouping: true,
                          })}
                        </b>
                      </Typography>
                    </div>
                    {(!listColumns() || listColumns() > 3) &&
                    propertyROIData[property["ID"]] &&
                    propertyROIData[property["ID"]]?.quarterlyROI?.length > 0 &&
                    !isNaN(propertyROIData[property["ID"]]["quarterlyROI"].slice(-1)[0]?.return) ? (
                      <div style={listColumns() ? { flex: "1" } : {}}>
                        <Typography className={classes.detailText}>
                          <b>R.O.I&nbsp;</b>
                        </Typography>
                        <Typography color="secondary" className={classes.detailText}>
                          <b>
                            $
                            {parseFloat(
                              propertyROIData[property["ID"]]["quarterlyROI"].slice(-1)[0]?.return
                            ).toLocaleString("en", {
                              useGrouping: true,
                            })}
                          </b>
                          <TrendingUpIcon style={{ marginBottom: "-4px" }} />
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div className={classes.details} style={listColumns() ? { marginTop: "0px" } : {}}>
                  {listColumns() ? (
                    <div>
                      <Typography className={classes.detailText}>Unit&nbsp;</Typography>
                      <Typography color="secondary" className={classes.detailText}>
                        {property["unitNum"]}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={classes.noLeaseDiv}>
                    <Typography className={classes.noLeaseText} variant="h6">
                      No Active Lease
                    </Typography>
                  </div>
                </div>
              )
            ) : (
              <CircularProgress />
            )}
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  );

  return (
    <Grid
      item
      key={building["ID"]}
      xs={12}
      md={
        !buildingOpen &&
        !listColumns() &&
        ((!isBuildingCard() && building["properties"].length > 1) || buildings.length > 1)
          ? 6
          : null
      }
      lg={
        !buildingOpen &&
        !listColumns() &&
        ((!isBuildingCard() && building["properties"].length > 2) || buildings.length > 2)
          ? 4
          : null
      }
      style={isNestedPropertyCard() ? { padding: "10px 4px" } : {}}
    >
      <ToastFactory toasts={toasts} />
      {renewASubscriptionDialog}
      <AddPropertyDoubleDialog
        addToast={addToast}
        addPropertyDialog={addPropertyDialog}
        buildingID={building.ID}
        setAddPropertyDialog={setAddPropertyDialog}
        updateProperties={props.refreshProperty}
        totalProperties={totalProperties}
      />
      <EditPropertyDoubleDialog
        addToast={addToast}
        dispatch={props.dispatch}
        history={props.history}
        building={building}
        refreshProperty={props.refreshProperty}
        editPropertyDialog={editPropertyDialog}
        setEditPropertyDialog={setEditPropertyDialog}
      />
      {buildings.length > 1 || buildingOpen || listColumns() ? (
        propertyCard
      ) : (
        <Container maxWidth="sm" style={{ height: "100%" }}>
          {propertyCard}
        </Container>
      )}
    </Grid>
  );
}

const mapState = (state) => {
  return {
    alerts: state.alerts.alerts,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(PropertyCard);
