import React, { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Paper from "@material-ui/core/Paper";

import { connect } from "react-redux";
import { login } from "../../services/authenticationServices.js";

import ToastFactory from "../components/ToastFactory";
import Loading from "../components/Loading";

import Logo from "../../assets/logos/simpleLandlordFullsize.png";

const errorMessages = {
  email: {
    required: "Please enter your email",
  },
  password: {
    required: "Please enter your password",
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 56px)",
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "82px",
  },
  logo: {
    width: "100%",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  form: {
    width: "100%",
    marginTop: "16px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bannerImage: {
    position: "relative",
    maxHeight: "100vh",
    maxWidth: "900px",
    width: "100%",
    textAlign: "center",
  },
  bannerPhone: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  bannerProperty: {
    position: "absolute",
    top: "15%",
    left: "50%",
    maxWidth: "100%",
    maxHeight: "45%",
    filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
    animation: "$float 6s ease-in-out infinite",
  },
  bannerROI: {
    position: "absolute",
    bottom: "10%",
    left: "30%",
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "35%",
    filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
    animation: "$float 6s ease-in-out infinite",
  },
  "@keyframes float": {
    "0%": {
      filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
      transform: "translate(0px, 0px)",
    },
    "50%": {
      filter: "drop-shadow(-10px 25px 15px rgba(0,0,0,0.2))",
      transform: "translate(5px, -7px)",
    },
    "100%": {
      filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
      transform: "translate(0px, 0px)",
    },
  },
  gridItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
  stickyPhone: {
    top: "0px",
    position: "sticky",
  },
  formDiv: {
    width: "100%",
    height: "calc(100% - 56px)",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
}));

function Login(props) {
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [showPassword, setshowPassword] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);
  const handleRememberMeChange = (event) => setRememberMe(event.target.checked);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  useEffect(() => {
    const errors = props.error;
    if (typeof errors === "string") {
      addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  }, [props.error]);

  const onSubmit = async (loginData) => {
    const loggedIn = await login(props.dispatch, loginData, rememberMe);
    if (!loggedIn) addToast("The Credentials You Entered Do Not Match Our Records", "error");
    else props.history.push(`/home`);
  };

  const classes = useStyles();

  if (props.loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ display: breakpointsDownXS ? "none" : "block" }}
        >
          <div className={`${classes.bannerImage} ${classes.stickyPhone}`}>
            <img className={classes.bannerPhone} src="/LandingPage/Banner Phone Reverse.png" />
            <img className={classes.bannerProperty} src="/LandingPage/Banner Property Reverse.png" />
            <img className={classes.bannerROI} src="/LandingPage/Banner ROI Reverse.png" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <div className={classes.formDiv}>
            <div style={{ maxWidth: "450px", textAlign: "center" }}>
              <img src={Logo} className={classes.logo} alt="The Simple Landlord Logo" />
              <Typography variant="h4" style={{ fontWeight: "700" }}>
                Log In
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          autoComplete="email"
                          autoFocus
                          error={errors.email && errors.email !== null}
                          helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          type={showPassword ? "text" : "password"}
                          id="password"
                          label="Password"
                          autoComplete="current-password"
                          error={errors.password && errors.password !== null}
                          helperText={errors.password ? errorMessages["password"][errors.password.type] : null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Show Password" PopperProps={{ style: { marginTop: "-8px" } }}>
                                  <IconButton
                                    onClick={() => {
                                      setshowPassword(!showPassword);
                                    }}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                        />
                      }
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                      Log In
                    </Button>
                  </Grid>
                  <Grid container>
                    <Grid item xs style={{ textAlign: "left" }}>
                      <Link href="/authentication/forgot-password" variant="body2">
                        <b>Forgot password?</b>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/authentication/signup" variant="body2">
                        <b>Don't have an account? Sign Up</b>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const mapState = (state) => {
  return {
    user: state.authentication.user,
    error: state.authentication.error,
    loading: state.authentication.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Login);
