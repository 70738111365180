import axios from "axios";
import setAxiosDefaultSettings from "../utils/axiosSettings.js";
import { setProfile } from "../redux/actions/authenticationActions";

setAxiosDefaultSettings(axios);

export const askQuestion = async (questionData) => {
  let serverError = false;

  await axios
    .post("/v2/users/questions/", questionData)
    .then(async (response) => {
      if (response.data) console.log(response.data);
    })
    .catch(async (error) => {
      console.log("Server", { error });
      serverError = true;
    });

  return serverError;
};

export const getAnnouncements = async () => {
  return await axios
    .get(`/v2/users/announcements/?timestamp=${new Date().getTime()}`)
    .then((response) => response.data);
};

export const getSignature = async (clonedUserID = null) => {
  return await axios
    .get(`/v2/users/signature/?timestamp=${new Date().getTime()}`, {
      params: { clonedUserID },
    })
    .then((response) => response.data)
    .catch(() => null);
};

export const addSignature = async (signatureData, handleErrors) => {
  return await updateSignature(axios.post, signatureData, handleErrors);
};

export const deleteSignature = async (handleErrors) => {
  return await updateSignature(axios.delete, handleErrors);
};

const updateSignature = async (axiosMethod, signatureData, handleErrors) => {
  let signatureReceived = false;
  let responseError = null;

  await axiosMethod("/v2/users/signature/", signatureData)
    .then(async (response) => {
      if (response.config?.method === "delete") signatureReceived = true;
      else if (response.data) signatureReceived = response.data;
      else {
        responseError = response.data?.error || "Updating Signature failed";
        console.log("No Signature", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect User Information";
    });

  if (handleErrors) handleErrors(responseError);

  return signatureReceived;
};

export const getUser = async (dispatch = null, userData = null) => {
  return await updateUser(
    axios.get,
    {
      params: { ...userData },
    },
    null,
    dispatch
  );
};

export const editUser = async (userData, handleErrors, dispatch) => {
  return await updateUser(axios.put, userData, handleErrors, dispatch);
};

const updateUser = async (axiosMethod, userData, handleErrors, dispatch) => {
  let userReceived = false;
  let responseError = null;

  await axiosMethod(userData?.params ? `/v2/users/?timestamp=${new Date().getTime()}` : "/v2/users/", userData)
    .then(async (response) => {
      if (response.data) userReceived = response.data;
      else {
        responseError = response.data?.error || "Updating User failed";
        console.log("No User", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect User Information";
    });

  if (handleErrors) handleErrors(responseError);

  if (dispatch) dispatch(setProfile(userReceived));

  return userReceived;
};
