import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export let problemObj = {
  history: null,
  baseURL: "",
  setLTBFormData: null,
};

export function createForm(formType, problemID, parentID = null, title = null) {
  console.log(problemObj);
  if (!problemObj.history || problemObj.baseURL == "" || !problemObj.setLTBFormData) return;

  let currentProblem = null;
  for (let problem of problemList) {
    if (problem.id == problemID) currentProblem = problem;
  }
  if (!currentProblem && !title) return;

  problemObj.setLTBFormData({
    problemID: problemID,
    problemTitle: title ? title : currentProblem.title,
    ...(parentID ? { parentID: parentID } : {}),
  });
  problemObj.history.push(`${problemObj.baseURL}/${formType}`);
}

const problemList = [
  {
    id: "1",
    title: "My tenant did not pay their rent on the date it's due",
    explanation: (
      <Typography component={"span"}>
        If your tenant did not pay their rent on the due date you have two options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n4", 1, -1, "")}
          >
            Give the tenant a <b>Notice for Non-payment of Rent (N4)</b>
          </Button>
        </div>
        <p className="helperText">
          In this case, the tenant can either move out or pay the rent they owe you. Otherwise, you can apply to the LTB
          for an order to evict the tenant and collect the rent they owe.
        </p>
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("l9", 1, -1, "")}
          >
            Submit an <b>Application to Collect Rent the Tenant Owes (L9)</b> to the LTB
          </Button>
        </div>
        <p className="helperText">
          (The issue with this option is that if the LTB issues an order on an L9 application and the tenant still does
          not pay, you cannot use that order to evict the tenant without first doing option A)
        </p>
      </Typography>
    ),
    keywords: [
      "N4",
      "L9",
      "not pay",
      "skip",
      "rent",
      "overdue",
      "not paid",
      "didnt pay",
      "pay",
      "paying",
      "paid",
      "hasnt paid",
      "owe",
      "late",
      "on time",
      "evict",
      "kick out",
    ],
    relevance: 0,
  },
  {
    id: "2",
    title: "My tenant interfered with my or another tenant's enjoyment of the residential complex or lawful rights",
    explanation: (
      <Typography component={"span"}>
        You have two options, if tenant, the tenant’s guest or another occupant of the rental unit substantially
        interfered with your or another tenant’s:
        <ul>
          <li>reasonable enjoyment of the residential complex, or</li>
          <li>interfered with another lawful right, privilege or interest of the landlord or other tenants.</li>
        </ul>
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n7", 2, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy for Causing Serious Problems (N7)</b>
          </Button>
        </div>
        <p className="helperText">
          You can only use this notice if you live in the same building as the tenant and the building has three or
          fewer residential units. This form cannot be voided and has a shorter notice period than option B.
        </p>
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n5", 2, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy For Interfering with Others (N5)</b>
          </Button>
        </div>
        <p className="helperText">Use this form if the conditions of option A don't apply to you.</p>
      </Typography>
    ),
    keywords: [
      "N5",
      "N7",
      "interfere",
      "law",
      "illegal",
      "enjoyment",
      "lawful right",
      "privilege",
      "my right",
      "their right",
      "someones right",
      "evict",
      "kick out",
    ],
    relevance: 0,
  },
  {
    id: "3",
    title: "My tenant wilfully or negligently damaged the rental unit",
    explanation: (
      <Typography component={"span"}>
        If your tenant, the tenant’s guest or another occupant of the rental unit wilfully or negligently damaged the
        rental unit or the residential complex you have two options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n7", 3, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy for Causing Serious Problems (N7)</b>
          </Button>
        </div>
        <p className="helperText">
          You can only use this notice if you believe you can prove the damage was caused wilfully at the LTB hearing
          (in other words, the person caused the damage deliberately). This form cannot be voided and has a shorter
          notice period than option B.
        </p>
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n5", 3, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy For Damage (N5)</b>
          </Button>
        </div>
        <p className="helperText">
          Use this form if you are unsure whether you can prove to the LTB that the damage was done wilfully.
        </p>
      </Typography>
    ),
    keywords: [
      "N5",
      "N7",
      "damage",
      "broke",
      "break",
      "wilfully",
      "on purpose",
      "negligently",
      "accident",
      "ruin",
      "tear",
      "tore",
      "torn",
      "rip",
      "shred",
      "hole",
      "evict",
      "kick out",
    ],
    relevance: 0,
  },
  {
    id: "4",
    title: "There are more people living in my rental unit than permitted by health, safety or property standards",
    explanation: (
      <Typography component={"span"}>
        If the number of people living in your rental unit is more than permitted by health, safety or property
        standards:
        <div className="buttonContainer">
          <Button color="primary" variant="contained" className="optionBtn" onClick={() => createForm("n5", 4, -1, "")}>
            {" "}
            Give the tenant a <b>Notice to End your Tenancy For Overcrowding (N5)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N5", "too many", "overcrowd", "a lot", "alot", "too much"],
    relevance: 0,
  },
  {
    id: "5",
    title: "My tenant commited an illegal act or is carrying on an illegal business in my property",
    explanation: (
      <Typography component={"span"}>
        If you found out that your tenant or another occupant of your rental unit has committed an illegal act or is
        carrying on an illegal business the very first step you have to do is:
        <div className="buttonContainer">
          <Button color="primary" variant="contained" className="optionBtn" onClick={() => createForm("n6", 5, -1, "")}>
            {" "}
            Give the tenant a <b>Notice to End your Tenancy For Illegal Acts (N6)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: [
      "N6",
      "illegal",
      "law",
      "broke the law",
      "legal",
      "crime",
      "criminal",
      "drug",
      "traffick",
      "fraud",
      "evict",
      "kick out",
    ],
    relevance: 0,
  },
  {
    id: "6",
    title: "My tenant misrepresented their income in a rent-geared-to-income rental unit",
    explanation: (
      <Typography component={"span"}>
        If you have a rent-geared-to-income rental unit, and your tenant misrepresented theirs or their family member's
        income living in your property then you have three options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("l9", 6, -1, "")}
          >
            {" "}
            Submit an <b>Application to Collect Rent the Tenant Owes (L9)</b> to the LTB
          </Button>
        </div>
        This is the best option if you don't want to evict the tenant but want to collect the additional rent they owe
        you. Keep in mind that, if the LTB issues an order on an L9 application and the tenant still does not pay, you
        cannot use that order to evict the tenant without first doing option C
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n6", 6, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy For Misrepresenting Income (N6)</b>
          </Button>
        </div>
        <p className="helperText">
          Use this form if you care more about evicting the tenant than collecting the additional rent they you. This
          form can't be voided and allows you to apply to the LTB to evict the tenant faster than option C.
        </p>
        <div className="buttonContainer">
          {" "}
          C){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n4", 6, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice for Non-payment of Rent (N4)</b>
          </Button>
        </div>
        <p className="helperText">
          In this case, the tenant can either move out or pay the rent they owe you. Otherwise, you can apply to the LTB
          for an order to evict the tenant and collect the rent they owe.
        </p>
      </Typography>
    ),
    keywords: [
      "N6",
      "L9",
      "N4",
      "lie",
      "liar",
      "evict",
      "misrepresent",
      "income",
      "salary",
      "deceive",
      "money they make",
      "fraud",
      "earnings",
      "payroll",
      "wage",
      "Rent Geared to Income",
      "Rent-Geared-to-Income",
      "RGI",
    ],
    relevance: 0,
  },
  {
    id: "7",
    title: "My tenant has seriously impaired the safety of another person on my property",
    explanation: (
      <Typography component={"span"}>
        If your tenant, their guest or another occupant of the rental unit has seriously impaired the safety of another
        person, and this event happened in the residential complex then you should:
        <div className="buttonContainer">
          <Button color="primary" variant="contained" className="optionBtn" onClick={() => createForm("n7", 7, -1, "")}>
            {" "}
            Give the tenant a <b>Notice to End your Tenancy for Causing Serious Problems (N7)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N7", "evict", "injure", "injury", "safe", "unsafe", "safety", "harm", "abuse"],
    relevance: 0,
  },
  {
    id: "8",
    title:
      "My tenant used my property in a way that's unreasonable for a residential premises and has caused or can cause serious damage",
    explanation: (
      <Typography component={"span"}>
        If your tenant, their guest or another occupant of your property has used the rental unit or the residential
        complex in a way that's inconsistent with its use as residential premises and this has caused or can be expected
        to cause serious damage then you should:
        <div className="buttonContainer">
          <Button color="primary" variant="contained" className="optionBtn" onClick={() => createForm("n7", 8, -1, "")}>
            {" "}
            Give the tenant a <b>Notice to End your Tenancy for Causing Serious Problems (N7)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: [
      "N7",
      "evict",
      "safe",
      "unsafe",
      "illegal",
      "law",
      "damage",
      "broke",
      "break",
      "accident",
      "ruin",
      "tear",
      "tore",
      "torn",
      "rip",
      "shred",
      "hole",
    ],
    relevance: 0,
  },
  {
    id: "9",
    title: "My tenant's rent is constantly late and I want to end their tenancy at the end of the term",
    explanation: (
      <Typography component={"span"}>
        If your tenant has been persistently late in paying the rent, and the term is almost coming to an end then you
        should:
        <div className="buttonContainer">
          <Button color="primary" variant="contained" className="optionBtn" onClick={() => createForm("n8", 9, -1, "")}>
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N8)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N8", "end", "end of the term", "end of term", "term", "late", "pay", "paying", "paid", "late payment"],
    relevance: 0,
  },
  {
    id: "10",
    title: "My tenant no longer qualifies to live in public or subsidized housing",
    explanation: (
      <Typography component={"span"}>
        If you have public or subsidized housing and your tenant doesn't qualify to live there anymore you have two
        options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n8", 10, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N8)</b>
          </Button>
        </div>
        <p className="helperText">
          You can't use this notice to terminate the tenancy because your tenant no longer qualifies for
          rent-geared-to-income (RGI) assistance. In that case, option B would work.
        </p>
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n4", 10, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice for Non-payment of Rent (N4)</b>
          </Button>
        </div>
        <p className="helperText">
          Only use this form if your tenant no longer qualifies for RGI assistance and does not pay the new lawful rent.
        </p>
      </Typography>
    ),
    keywords: [
      "N8",
      "N4",
      "public",
      "subsidize",
      "qualify",
      "qualifies",
      "doesnt qualify",
      "not quailify",
      "Rent Geared to Income",
      "Rent-Geared-to-Income",
      "RGI",
    ],
    relevance: 0,
  },
  {
    id: "11",
    title: "My property was made available to the tenant as a condition of employment, and their employment has ended",
    explanation: (
      <Typography component={"span"}>
        If your rental unit was made available to the tenant as a condition of employment and the employment has ended
        you have two options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n8", 11, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N8)</b>
          </Button>
        </div>
        <p className="helperText">
          You can't use this notice to terminate the tenancy of a superintendent when their employment has ended. In
          that case, option B would work.
        </p>
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("l2", 11, -1, "")}
          >
            {" "}
            Submit an <b>Application to End a Tenancy and Evict a Tenant (L2)</b> to the LTB
          </Button>
        </div>
        <p className="helperText">
          If you have terminated a superintendent’s employment and the superintendent has not moved out of the rental
          unit within seven days, you can apply to the Landlord Tenant Board (LTB) to terminate the tenancy and evict
          the superintendent by submitting this form.
        </p>
      </Typography>
    ),
    keywords: ["N8", "L2", "employer", "employee", "employment", "superintendent", "job"],
    relevance: 0,
  },
  {
    id: "12",
    title:
      "My tenancy was created as a result of an Agreement of Purchase and Sale, and the agreement has been terminated",
    explanation: (
      <Typography component={"span"}>
        If your tenancy was created in good faith as a result of an Agreement of Purchase and Sale for a proposed
        condominium unit, and the agreement has been terminated, you should:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n8", 12, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N8)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: [
      "N8",
      "agreement of purchase and sale",
      "sales agreement",
      "agreement of purchase",
      "agreement of sale",
      "APS",
      "terminate",
    ],
    relevance: 0,
  },
  {
    id: "13",
    title:
      "The tenant is occupying my property specifically to receive rehabilitative/therapeutic care and the period of tenancy agreed to has ended",
    explanation: (
      <Typography component={"span"}>
        If The tenant is occupying the unit specifically to receive rehabilitative or therapeutic care and the period of
        tenancy agreed to has ended you should:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n8", 13, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N8)</b>
          </Button>
        </div>
        <p className="helperText">
          Keep in mind, you can only give this notice for this reason if no other tenant receiving these types of
          services is allowed to live in the complex for more than four years.
        </p>
      </Typography>
    ),
    keywords: ["N8", "rehab", "rehabilitative", "rehabilitation", "therapy", "therapeutic", "mental", "health"],
    relevance: 0,
  },
  {
    id: "14",
    title: "The tenant and I have agreed to end the tenancy",
    explanation: (
      <Typography component={"span"}>
        If you have had a discussion with the tenant and both agreed to end your tenancy on a certain date:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n11", 14, -1, "")}
          >
            {" "}
            Ask the tenant to sign an <b>Agreement to End the Tenancy (N11)</b>
          </Button>
        </div>
        <p className="helperText">
          Keep in mind, you can not <b>require</b> the tenant to sign this agreement as a condition to rent the unit
          unless:
        </p>
        <ul>
          <li>
            You have another agreement with a post-secondary school to provide accomodation to your tenant who is a
            student
          </li>
          <li>
            Your property is a rental unit in a rehabilitative or therapeutic care home, and all of the following is
            true:
            <ul>
              <li>the tenant agreed to occupy your property for no more than 4 years</li>
              <li>
                the tenancy agreement set out that the tenant can be evicted when the objectives of providing the care
                services have been met or will not be met
              </li>
              <li>
                the rental unit is provided to the tenant under an agreement between the landlord and a service manager
                under the <b>Housing Services Act, 2011</b>
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
    ),
    keywords: [
      "N11",
      "end",
      "agree",
      "both",
      "discuss",
      "talk",
      "rehab",
      "rehabilitative",
      "rehabilitation",
      "therapy",
      "therapeutic",
      "mental",
      "health",
    ],
    relevance: 0,
  },
  {
    id: "15",
    title: "I want to end the tenancy because either I or my family member needs the property",
    explanation: (
      <Typography component={"span"}>
        If you, a member of your immediate family, or a person who'll provide care services to one of you wants to move
        into your property and occupy it for at least a year:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n12", 15, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N12)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N12", "family", "live", "move", "move in", "live in", "evict", "end"],
    relevance: 0,
  },
  {
    id: "16",
    title:
      "I want to end the tenancy because someone purchased my property and either they or their family member needs the property",
    explanation: (
      <Typography component={"span"}>
        If the purchaser, a member of their immediate family, or a person who'll provide care services to one of them
        wants to move into your property and occupy it for at least a year and,
        <ul>
          <li>your complex contains no more than three residential units, and</li>
          <li>you have entered into an agreement of purchase and sale of the complex then:</li>
        </ul>
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n12", 16, -1, "")}
          >
            Give the tenant a <b>Notice to End your Tenancy at the End of the Term (N12)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: [
      "N12",
      "family",
      "live",
      "move",
      "move in",
      "live in",
      "evict",
      "end",
      "purchase",
      "bought",
      "purchase",
      "sold",
    ],
    relevance: 0,
  },
  {
    id: "17",
    title: "I want to end the tenancy because I will be demolishing the property",
    explanation: (
      <Typography component={"span"}>
        If you, need your tenant to leave the property because you plan on demolishing it:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n13", 17, -1, "")}
          >
            Give the tenant a{" "}
            <b>Notice to End your Tenancy Because the Landlord Wants to Demolish the Rental Unit (N13)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: [
      "N13",
      "destroy",
      "demolish",
      "take down",
      "demolition",
      "wreck",
      "excavate",
      "excavator",
      "evict",
      "end",
      "vacate",
    ],
    relevance: 0,
  },
  {
    id: "18",
    title: "I need to vacate the property to make major repairs or renovations",
    explanation: (
      <Typography component={"span"}>
        If you need your tenant to leave the property because you're planning to make extensive repairs or renovations:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n13", 18, -1, "")}
          >
            Give the tenant a <b>Notice to End your Tenancy Because the Landlord Wants to Rapair It (N13)</b>
          </Button>
        </div>
        <p className="helperText">
          Keep in mind, that in this case, the tenant can choose to move back into your property after you finish
          renovating as long as they inform you in writing before moving out. They would also need to keep you updated
          in writing about any change in address.
        </p>
      </Typography>
    ),
    keywords: ["N13", "end", "vacate", "repair", "renovate", "renovation"],
    relevance: 0,
  },
  {
    id: "19",
    title: "I want to end the tenancy because I want to convert the property to non-residential use",
    explanation: (
      <Typography component={"span"}>
        If you need your tenant to leave the property because you plan on converting it to non-residential use:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n13", 19, -1, "")}
          >
            Give the tenant a{" "}
            <b>Notice to End your Tenancy Because the Landlord Wants to Convert it to Another Use (N13)</b>
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N13", "different", "convert", "nonresidential", "non-residential", "use", "end", "vacate"],
    relevance: 0,
  },
  {
    id: "20",
    title: "I want to increase my tenant's rent",
    explanation: (
      <Typography component={"span"}>
        When increasing rent, you generally need to give your tenant a <b>Notice of Rent Increase (N1)</b>. However,
        there are some special cases where you need to fill out a different form. We will let you know if any exceptions
        apply to you during the N1 process.
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n1", 20, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice of Rent Increase (N1)</b>
          </Button>
        </div>
      </Typography>
      /*<Typography component={"span"}>
        When increasing rent, option D is most likely the form you will have to fill out, but there are some exceptions.
        Here are all four options:
        <div className="buttonContainer">
          {" "}
          A){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n2", 20, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice of Rent Increase (N2)</b> excempt from RTA
          </Button>
        </div>
        Use this form if your rental unit is exempt from the rules under the Residential Tenancies Act, 2006 (RTA)
        limiting the amount of the rent increase.
        <div className="buttonContainer">
          {" "}
          B){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n3", 20, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice to Increase the Rent and/or Charges for Care Services and Meals (N3)</b>
          </Button>
        </div>
        <p className="helperText">Use this form if your rental unit is in a care home.</p>
        <div className="buttonContainer">
          {" "}
          C){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n10", 20, -1, "")}
          >
            {" "}
            Give the tenant an <b>Agreement to Increase the Rent Above the Guideline (N10)</b>
          </Button>
        </div>
        <p className="helperText">
          Use this form if you and the tenant have agreed to increase the rent above the guideline because you have
          done/will do capital work, or have provided/will provide a new or additional service.
        </p>
        <div className="buttonContainer">
          {" "}
          D){" "}
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            style={{ marginLeft: "10px" }}
            onClick={() => createForm("n1", 20, -1, "")}
          >
            {" "}
            Give the tenant a <b>Notice of Rent Increase (N1)</b>
          </Button>
        </div>
        <p className="helperText">
          If none of the previous special cases apply, use this form. Make sure at least 12 months have passed since the
          date of the last rent increase or since the date the tenant moved into the rental unit.
        </p>
    </Typography>*/
    ),
    keywords: [
      "N1",
      "N2",
      "N3",
      "N10",
      "rent increase",
      "increase rent",
      "increase",
      "raise",
      "bring up",
      "bump up",
      "guideline",
      "rent",
    ],
    relevance: 0,
  },
  {
    id: "21",
    title: "My tenant wants to move out",
    explanation: (
      <Typography component={"span"}>
        Your tenant is required to give you <b>Notice to End the Tenancy (N9)</b> a specific number of days in advance
        if they want to move out and end the lease:
        <div className="buttonContainer">
          <Button
            color="primary"
            variant="contained"
            className="optionBtn"
            onClick={() => createForm("n9", 21, -1, "")}
          >
            Take a look at the <b>Notice to End the Tenancy (N9)</b> process
          </Button>
        </div>
      </Typography>
    ),
    keywords: ["N9", "move", "out", "moveout", "end", "lease", "leave", "left"],
    relevance: 0,
  },
];

export default problemList;
