import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setLTBFormData } from "../../../../redux/actions/LTBFormActions";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import CrossCircleIcon from "@material-ui/icons/CancelRounded";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import EventNoteRoundedIcon from "@material-ui/icons/EventNoteRounded";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import { editAlert, addAlert, deleteAlert, getAlerts } from "../../../../services/property/alertServices";
import { addLedger, getLedger } from "../../../../services/property/ledgerServices";
import { editLease } from "../../../../services/property/leaseServices.js";

import { getForms, deleteForm, addForm } from "../../../../services/property/form/formServices";
import problemList, { problemObj, createForm } from "../forms/problemList";
import MoneyInput from "../../../components/MoneyInput";
import { calculateProratedRent } from "../../../../utils/proratedRent";
import moment from "moment";
import RescheduleMoveOutForm from "./RescheduleMoveOutForm";
import RescheduleMoveInForm from "./RescheduleMoveInForm";
import { moveOutTenants } from "./MoveOutForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const errorMessages = {
  amountReceived: {
    required: "Please enter the amount your tenant(s) paid",
    pattern: "The amount must be a positive number and can't have more than 2 decimals",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(-3, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  form: {
    width: "100%",
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  infoText: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  infoAmount: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.8em",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  confirmIconButton: {
    "&:hover": {
      background: "none",
      cursor: "default",
    },
  },
  confirmButton: {
    opacity: "0.8",
    fontSize: "2.2em",
    marginTop: "-10px",
    marginBottom: "-20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.8em",
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  boldSecondary: {
    color: theme.palette.secondary.main,
  },
  menuTitleCloseButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  genericDialogSubmit: {
    margin: theme.spacing(2, 0, 2),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(-1, 0, 1),
    },
  },
  // rentDue styling
  rentDueDialogHeader: {
    margin: theme.spacing(2, 0, 1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      margin: theme.spacing(4, 0, 1),
    },
  },
  unitLabel: {
    ["& .MuiInputLabel-shrink"]: {
      fontSize: "1.4em",
    },
    ["& .MuiInputLabel-shrink ~ div legend"]: {
      paddingRight: "45px",
    },
  },
  rentDueDialogSubmit: {
    margin: theme.spacing(2, 0, -2),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(2, 0, 0),
    },
  },
  rentDueInfoCard: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  successIconContainer: {
    "&:hover": {
      background: "none",
      cursor: "default",
    },
  },
  successIcon: {
    opacity: "0.8",
    fontSize: "2.2em",
    marginTop: "-10px",
    marginBottom: "-20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.8em",
    },
  },
  //conditionReport styling
  conditionReportCardContent: {
    position: "relative",
    [`${theme.breakpoints.down("xs")}`]: {
      paddingTop: theme.spacing(6),
    },
  },
  conditionReportSubmit: {
    margin: theme.spacing(3, 0, 0),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(3, 0, 3),
    },
  },
  conditionReportQuestionIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    [`${theme.breakpoints.down("xs")}`]: {
      right: "5px",
      top: "5px",
    },
  },
  // missingPayment styling
  missingPaymentInfoCard: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  missingPaymentInfoCardMobile: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  infoCardContent: {
    padding: "24px",
  },
  // formNextStep styling
  formCardContent: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [`${theme.breakpoints.down("xs")}`]: {
      paddingTop: theme.spacing(6),
    },
  },
  formIconButton: {
    fontSize: "2.2em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.8em",
    },
  },
  formCheckoutDialogHeader: {
    margin: theme.spacing(4, 0, 1),
    fontSize: "1.4em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  accordion: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  instructionsHeader: {
    marginTop: theme.spacing(2),
    fontSize: "1.6em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.3em",
    },
  },
  instructionsSubHeader: {
    fontSize: "1.1em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  instructionsList: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  instructionsItem: {
    fontSize: "1.3em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  instructionsExtra: {
    color: "rgba(255, 255, 255, 0.4)",
  },
  buttonLink: {
    margin: theme.spacing(1, 0, 1),
  },
}));

const rentCycleTimeUnit = {
  Daily: "days",
  Weekly: "weeks",
  Monthly: "months",
};

let _parentForm = null;
function Alert({ addToast, alert, data, globals = {}, dispatch, setLTBFormData, ...props }) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [dialogType, setDialogType] = useState(null);
  const [thisMonthsRent, setThisMonthsRent] = useState(0);
  const [units, setUnits] = useState(null);
  const [unitRents, setUnitRents] = useState(null);
  const [parentForm, setParentForm] = useState(null);
  const [lastRentDueDate, setLastRentDueDate] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const getThisMonthsRent = (lease) => {
    if (!lease || alert["type"] != "rentDue") return;
    if (
      lease["moveInDate"] == null ||
      lease["leaseStartDate"] == null ||
      lease["rentCollectionDay"] == null ||
      lease["rentPrice"] == null
    ) {
      return parseFloat(lease["rentPrice"] == null ? 0 : lease["rentPrice"]);
    }
    if (moment(moment().format("YYYY-MM")).isAfter(moment(lease["leaseStartDate"]).format("YYYY-MM"))) {
      return parseFloat(lease["rentPrice"]);
    }

    return parseFloat(
      calculateProratedRent(
        lease["leaseStartDate"],
        lease["rentCollectionDay"],
        lease["moveInDate"],
        lease["rentPrice"]
      )["firstMonthsRent"]
    );
  };

  const getLastRentDueDate = async () => {
    let moveOutAlert = await getAlerts(null, {
      buildingID: globals.building?.ID,
      propertyID: globals.property?.ID,
      leaseID: globals.lease?.ID,
      type: "moveOut",
    });
    moveOutAlert = moveOutAlert[0];

    if (moveOutAlert) {
      const moveOutData = JSON.parse(moveOutAlert.data);
      const isLastRentDueDate = moment(data.date)
        .add(1, rentCycleTimeUnit[globals.lease?.rentCycle])
        .isSameOrAfter(moveOutData.date);
      return setLastRentDueDate(isLastRentDueDate ? true : false);
    } else setLastRentDueDate(false);
  };

  useEffect(() => {
    if (!globals.lease || alert["type"] != "rentDue") return;
    // console.log("_prorateData: ", _prorateData);
    setThisMonthsRent(getThisMonthsRent(globals.lease));
    getLastRentDueDate();
  }, [globals.lease]);

  useEffect(() => {
    if (data.parentID) updateParentForm();
    if (isBuildingAlert()) {
      let _units = [];
      let _unitRents = {};
      for (let property of globals.building["properties"]) {
        for (let lease of property["leases"]) {
          if (data.leaseIDs.includes(lease["ID"])) {
            _units.push({ property, lease });
            _unitRents[lease.ID] = lease?.rentPrice;
          }
        }
      }
      //console.log("_unitRents: ", _unitRents);
      setUnits(_units);
      setUnitRents(_unitRents);
    }
  }, []);

  const isBuildingAlert = () => !alert?.propertyID && data?.leaseIDs;

  const updateParentForm = async () => {
    if (!globals.lease) return;

    _parentForm = await getForms({
      leaseID: globals.lease["ID"],
      formID: data.parentID,
      dataOnly: true,
    });

    //console.log("Got parentForm: ", _parentForm, "for lease: ", globals.lease);
    if (_parentForm["data"]) _parentForm["data"] = JSON.parse(_parentForm["data"]);
    if (!parentForm && _parentForm) setParentForm(_parentForm);
  };

  const getNewRentDueDate = (rentDueDate, rentCycle) => {
    let newRentDueDate = rentDueDate;

    newRentDueDate = moment(rentDueDate).add(1, rentCycleTimeUnit[rentCycle]);
    return newRentDueDate.format("YYYY-MM-DD");
  };

  const handleRentDueSubmit = async (rentDueDate, amountReceived, n4 = false, unit = null) => {
    let alertEdited = null;
    if (!unit) {
      let newRentDueDate = getNewRentDueDate(rentDueDate, globals?.lease?.rentCycle);
      console.log("Editing single alert");
      alertEdited = await editAlert(
        {
          ID: alert["ID"],
          data: {
            date: newRentDueDate,
          },
        },
        dispatch
      );
    }

    let rentChargeTransaction = await getLedger({
      leaseID: unit ? unit.lease["ID"] : globals.lease["ID"],
      type: "charge",
      reason: "Rent",
      date: rentDueDate,
    });
    if (!rentChargeTransaction)
      rentChargeTransaction = await addLedger({
        leaseID: unit ? unit.lease["ID"] : globals.lease["ID"],
        ledgerType: "charge",
        ledgerReason: "Rent",
        ledgerDate: rentDueDate,
        ledgerAmount: unit ? unit.lease["rentPrice"] : thisMonthsRent,
      });
    console.log("rentChargeTransaction: ", rentChargeTransaction);

    const paymentTransaction = await addLedger({
      leaseID: unit ? unit.lease["ID"] : globals.lease["ID"],
      ledgerType: "payment",
      ledgerReason: "Rent",
      ledgerDate: rentDueDate,
      ledgerAmount: `${amountReceived}`,
    });

    if (amountReceived < (unit ? unit.lease["rentPrice"] : thisMonthsRent))
      await addAlert(
        {
          buildingID: globals.building["ID"],
          propertyID: unit ? unit.property["ID"] : globals.property["ID"],
          leaseID: unit ? unit.lease["ID"] : globals.lease["ID"],
          type: "missingPayment",
        },
        dispatch
      );

    if (unit) {
      if (rentChargeTransaction && paymentTransaction) addToast("Ledger Successfully Updated", "success");

      if (n4) {
        data = {
          deliveryMethod: "4",
          deliveryDate: moment().add(5, "days").format("YYYY-MM-DD"),
          leaseID: unit.lease["ID"],
          formType: "n4",
          problemID: problemList[0]["id"],
          problemTitle: problemList[0]["title"],
        };

        const LTBFormAdded = await addForm(data, null, dispatch);
        if (LTBFormAdded && LTBFormAdded["ID"]) addToast("N4 Successfully Created", "success");
        else addToast("An Error Occured While Creating Form", "error");
      }
    } else {
      if (alertEdited && rentChargeTransaction && paymentTransaction) {
        addToast("Ledger Successfully Updated", "success");

        if (n4) {
          problemObj.history = history;
          problemObj.baseURL = `/${globals.property["ID"]}/forms`;
          problemObj.setLTBFormData = setLTBFormData;
          createForm("n4", 1);
          if (globals.alertMenu) globals.alertMenu(null);
        }
      }
      setDialogType(false);
    }
  };

  const handleBuildingRentDueSubmit = async (rentDueDate, n4 = false) => {
    for (let unit of units) {
      if (!isNaN(unitRents[unit.lease?.ID]) && parseInt(unitRents[unit.lease?.ID]) >= parseInt(unit.lease?.rentPrice))
        await handleRentDueSubmit(
          rentDueDate,
          !isNaN(unitRents[unit.lease?.ID]) ? unitRents[unit.lease?.ID] : 0,
          false,
          unit
        );
      else
        await handleRentDueSubmit(
          rentDueDate,
          !isNaN(unitRents[unit.lease?.ID]) ? unitRents[unit.lease?.ID] : 0,
          n4,
          unit
        );
    }

    let newRentDueDate = getNewRentDueDate(rentDueDate, units[0]?.lease?.rentCycle);
    console.log("Editing bulk alert");
    const alertEdited = await editAlert(
      {
        ID: alert["ID"],
        data: {
          date: newRentDueDate,
          leaseIDs: data?.leaseIDs,
        },
      },
      dispatch
    );
    setDialogType(false);
  };

  const betweenSmLg = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const watchAmountReceived = watch("amountReceived", "");
  const rentDueDialog = (
    <Dialog
      open={alert["type"] == "rentDue" && dialogType == "lease"}
      onClose={() => setDialogType(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit((formData) =>
              handleRentDueSubmit(data?.date, formData.amountReceived.replace("$", "").replaceAll(",", ""))
            )}
            onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                  How much did your tenant(s) pay you this rent period?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="amountReceived"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="amountReceived"
                      label="Amount Received"
                      error={errors.amountReceived && errors.amountReceived !== null}
                      helperText={
                        errors.amountReceived ? errorMessages["amountReceived"][errors.amountReceived.type] : null
                      }
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  )}
                />
              </Grid>
              {!watchAmountReceived ||
              parseInt(watchAmountReceived?.replace("$", "")?.replaceAll(",", ""), 10) < thisMonthsRent ? (
                <>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                      Would you like to auto-fill a Notice for Non-Payment of Rent (N4)?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.genericDialogSubmit}
                      onClick={() => {
                        handleSubmit((formData) =>
                          handleRentDueSubmit(
                            data?.date,
                            formData.amountReceived.replace("$", "").replaceAll(",", ""),
                            true
                          )
                        )();
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={`${classes.rentDueDialogSubmit} ${classes.deleteBtn}`}
                    >
                      No
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.rentDueDialogSubmit}
                    onClick={() => {
                      handleSubmit((formData) =>
                        handleRentDueSubmit(
                          data?.date,
                          formData.amountReceived.replace("$", "").replaceAll(",", ""),
                          true
                        )
                      )();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );

  const rentDueContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5" align="center" className={classes.infoText}>
          {moment().isSameOrAfter(data?.date, "day")
            ? `Was your rent paid on 
          ${moment(data?.date).format("MMMM Do, YYYY")}?`
            : globals?.lease?.status !== "active"
            ? `The first rent payment is due on ${moment(data?.date).format("MMMM Do, YYYY")}`
            : `Rent was fully paid on ${moment(data?.date)
                .subtract(1, rentCycleTimeUnit[globals?.lease?.rentCycle])
                .format("MMMM Do, YYYY")}`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.date, "day") ? (
              <>
                <IconButton
                  color="secondary"
                  size="medium"
                  onClick={async () =>
                    handleRentDueSubmit(
                      data?.date,
                      lastRentDueDate
                        ? thisMonthsRent - (data?.rentDeposit ? data?.rentDeposit : globals.lease?.rentDeposit)
                        : thisMonthsRent
                    )
                  }
                >
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <Typography variant="h4" color="secondary" className={classes.infoAmount}>
                  $
                  {lastRentDueDate
                    ? thisMonthsRent - (data?.rentDeposit ? data?.rentDeposit : globals.lease?.rentDeposit)
                    : thisMonthsRent}
                </Typography>
                <IconButton className={classes.cancelButton} size="medium" onClick={() => setDialogType("lease")}>
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <IconButton
                color={globals?.lease?.status !== "active" ? "default" : "secondary"}
                className={classes.confirmIconButton}
                disableFocusRipple
                disableRipple
              >
                {globals?.lease?.status !== "active" ? (
                  <EventNoteRoundedIcon className={classes.confirmButton} />
                ) : (
                  <VerifiedUserRoundedIcon className={classes.confirmButton} />
                )}
              </IconButton>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const rentDueBuildingDialog = (
    <Dialog
      open={alert["type"] == "rentDue" && dialogType == "building"}
      onClose={() => setDialogType(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justifyContent="flex-end">
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.main}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit((formData) => handleBuildingRentDueSubmit(data?.date))}
            onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                  How much did your tenants pay for each unit?
                </Typography>
              </Grid>
              {units &&
                units.map((unit) => (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="amountReceived"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
                        }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={unitRents[unit.lease?.ID]}
                            onChange={(e) => {
                              setUnitRents({
                                ...unitRents,
                                [unit.lease?.ID]: e.target.value.replace("$", "").replace(",", ""),
                              });
                              onChange(e);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            id="amountReceived"
                            label={`Unit #${unit.property?.unitNum} Amount Received`}
                            className={classes.unitLabel}
                            error={errors.amountReceived && errors.amountReceived !== null}
                            helperText={
                              errors.amountReceived ? errorMessages["amountReceived"][errors.amountReceived.type] : null
                            }
                            InputProps={{
                              inputComponent: MoneyInput,
                              endAdornment:
                                !isNaN(unitRents[unit.lease?.ID]) &&
                                parseInt(unitRents[unit.lease?.ID]) >= parseInt(unit.lease?.rentPrice) ? (
                                  <CheckIcon style={{ color: theme.palette.secondary.main }} />
                                ) : (
                                  <CloseIcon style={{ color: theme.palette.error.main }} />
                                ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ))}
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                  Would you like to auto-fill a Notice for Non-Payment of Rent (N4) for the tenants who missed their
                  rent?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.rentDueDialogSubmit}
                  onClick={() => {
                    handleSubmit((formData) => handleBuildingRentDueSubmit(data?.date, true))();
                  }}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={`${classes.rentDueDialogSubmit} ${classes.deleteBtn}`}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );

  const rentDueBuildingContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5" align="center" className={classes.infoText}>
          {moment().isSameOrAfter(data?.date, "day")
            ? `There are ${data?.leaseIDs?.length} units in this building with rent due on
          ${moment(data?.date).format("MMMM Do, YYYY")}. Were they all paid fully?`
            : `Rent was paid on ${moment(data?.date)
                .subtract(1, rentCycleTimeUnit[globals?.lease?.rentCycle])
                .format("MMMM Do, YYYY")} for ${data?.leaseIDs?.length} units in this building`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.date, "day") ? (
              <>
                <IconButton color="secondary" size="medium" onClick={() => handleBuildingRentDueSubmit(data?.date)}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <IconButton className={classes.cancelButton} size="medium" onClick={() => setDialogType("building")}>
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <IconButton
                color={globals?.lease?.status !== "active" ? "default" : "secondary"}
                className={classes.confirmIconButton}
                disableFocusRipple
                disableRipple
              >
                {globals?.lease?.status !== "active" ? (
                  <EventNoteRoundedIcon className={classes.confirmButton} />
                ) : (
                  <VerifiedUserRoundedIcon className={classes.confirmButton} />
                )}
              </IconButton>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const moveInDialog = (
    <>
      <Dialog
        open={alert["type"] == "moveIn" && dialogType == "didMoveIn"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The status of your lease will now be updated
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  const leaseEdited = await editLease(
                    {
                      ID: globals.lease["ID"],
                      status: "active",
                    },
                    dispatch
                  );
                  if (leaseEdited) {
                    addToast("Lease Successfully Updated", "success");
                    await deleteAlert(
                      {
                        ID: alert["ID"],
                      },
                      dispatch
                    );
                    if (props.refreshProperty) await props.refreshProperty();
                  } else addToast("Unexpected Error Occured", "error");
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={alert["type"] == "moveIn" && dialogType == "didntMoveIn"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <RescheduleMoveInForm
          alert={alert}
          building={globals.building}
          property={globals.property}
          lease={globals.lease}
          refreshProperty={props.refreshProperty}
          addToast={addToast}
          callback={() => setDialogType(false)}
        />
      </Dialog>
    </>
  );

  const moveInContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5" align="center" className={classes.infoText}>
          {moment().isSameOrAfter(data?.date, "day")
            ? `Did your tenant(s) move in by ${moment(data?.date).format("MMMM Do, YYYY")}?`
            : `Your tenant(s) are scheduled to move in on ${moment(data?.date).format("MMMM Do, YYYY")}`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.date, "day") ? (
              <>
                <IconButton color="secondary" size="medium" onClick={() => setDialogType("didMoveIn")}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <IconButton className={classes.cancelButton} size="medium" onClick={() => setDialogType("didntMoveIn")}>
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <div style={{ display: "block", textAlign: "center" }}>
                <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
                  {<EventNoteRoundedIcon className={classes.confirmButton} />}
                </IconButton>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.conditionReportSubmit}
                    onClick={() => setDialogType("didntMoveIn")}
                  >
                    Cancel Move-In
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const moveOutDialog = (
    <>
      <Dialog
        open={alert["type"] == "moveOut" && dialogType == "didMoveOut"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The status of your lease will now be updated
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  const leaseEdited = await moveOutTenants(
                    dispatch,
                    data?.date,
                    globals.building,
                    globals.property,
                    globals.lease,
                    props.refreshProperty,
                    addToast
                  );

                  if (leaseEdited) {
                    addToast("Lease Ended Successfully", "success");
                    await deleteAlert(
                      {
                        ID: alert["ID"],
                      },
                      dispatch
                    );
                    if (props.refreshProperty) await props.refreshProperty();
                  } else addToast("Unexpected Error Occured", "error");
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={alert["type"] == "moveOut" && dialogType == "didntMoveOut"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <RescheduleMoveOutForm
          alert={alert}
          building={globals.building}
          property={globals.property}
          lease={globals.lease}
          refreshProperty={props.refreshProperty}
          addToast={addToast}
          callback={() => setDialogType(false)}
        />
      </Dialog>
    </>
  );

  const moveOutContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5" align="center" className={classes.infoText}>
          {moment().isSameOrAfter(data?.date, "day")
            ? `Did your tenant(s) move out by ${moment(data?.date).format("MMMM Do, YYYY")}?`
            : `Your tenant(s) are scheduled to move out on ${moment(data?.date).format("MMMM Do, YYYY")}${
                isNaN(globals.lease?.keyDeposit) ? "" : ". Be sure to return their key deposit."
              }`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.date, "day") ? (
              <>
                <IconButton color="secondary" size="medium" onClick={() => setDialogType("didMoveOut")}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <IconButton
                  className={classes.cancelButton}
                  size="medium"
                  onClick={() => setDialogType("didntMoveOut")}
                >
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <div style={{ display: "block", textAlign: "center" }}>
                <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
                  {<EventNoteRoundedIcon className={classes.confirmButton} />}
                </IconButton>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.conditionReportSubmit}
                    onClick={() => setDialogType("didntMoveOut")}
                  >
                    Cancel Move-Out
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const useRentDepositDialog = (
    <>
      <Dialog
        open={alert["type"] == "useRentDeposit" && dialogType == "useRentDeposit"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The rent deposit transaction will be added to the ledger
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  let rentChargeTransaction = await getLedger({
                    leaseID: globals.lease["ID"],
                    type: "charge",
                    reason: "Rent",
                    date: moment(data?.date).format("YYYY-MM-DD"),
                  });
                  if (!rentChargeTransaction)
                    rentChargeTransaction = await addLedger({
                      leaseID: globals.lease["ID"],
                      ledgerType: "charge",
                      ledgerReason: "Rent",
                      ledgerDate: moment(data?.date).format("YYYY-MM-DD"),
                      ledgerAmount: globals.lease?.rentPrice,
                    });
                  console.log(rentChargeTransaction);

                  const paymentTransaction = await addLedger({
                    leaseID: globals.lease["ID"],
                    ledgerType: "payment",
                    ledgerReason: "Rent",
                    ledgerDate: moment(data?.date).format("YYYY-MM-DD"),
                    ledgerAmount: globals.lease?.rentDeposit,
                    useDeposit: 1,
                  });

                  let leaseEdited = false;
                  if (paymentTransaction) {
                    leaseEdited = await editLease(
                      {
                        ID: globals.lease["ID"],
                        rentDeposit: 0,
                      },
                      dispatch
                    );
                  }

                  if (leaseEdited && paymentTransaction) {
                    addToast("Ledger Successfully Updated", "success");
                    await deleteAlert(
                      {
                        ID: alert["ID"],
                      },
                      dispatch
                    );

                    let rentDueAlert = await getAlerts(null, {
                      buildingID: globals.building?.ID,
                      propertyID: globals.property?.ID,
                      leaseID: globals.lease?.ID,
                      type: "rentDue",
                    });
                    rentDueAlert = rentDueAlert[0];

                    await editAlert(
                      {
                        ID: alert["ID"],
                        data: {
                          date: rentDueAlert?.date,
                          rentDeposit: globals.lease?.rentDeposit,
                        },
                      },
                      dispatch
                    );
                  } else addToast("Unexpected Error Occured", "error");
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={alert["type"] == "useRentDeposit" && dialogType == "dontUseRentDeposit"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Not Using Rent Deposit
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The rent deposit will not be used for the last rental period.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await deleteAlert(
                    {
                      ID: alert["ID"],
                    },
                    dispatch
                  );
                  setDialogType(false);
                }}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  const useRentDepositContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5" align="center" className={classes.infoText}>
          {moment().isSameOrAfter(data?.date, "day")
            ? `Would you like to use the rent deposit in the ledger for the last rent payment on ${moment(
                data?.date
              ).format("MMMM Do, YYYY")}?`
            : `Your last rent for the active lease is due on ${moment(data?.date).format("MMMM Do, YYYY")}. ${
                globals.lease?.rentDeposit >= globals.lease?.rentPrice
                  ? `You do not need to collect rent from your tenant on this date since the rent deposit will cover it.`
                  : `You will only need to collect $${
                      globals.lease?.rentPrice - globals.lease?.rentDeposit
                    } from your tenant on this date since the rent deposit will cover the rest.`
              }`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.date, "day") ? (
              <>
                <IconButton color="secondary" size="medium" onClick={() => setDialogType("useRentDeposit")}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <IconButton
                  className={classes.cancelButton}
                  size="medium"
                  onClick={() => setDialogType("dontUseRentDeposit")}
                >
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
                {<EventNoteRoundedIcon className={classes.confirmButton} />}
              </IconButton>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const depositDueDialog = (
    <>
      <Dialog
        open={alert["type"] == "depositDue" && dialogType == "depositReceived"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The rent deposit transaction will be added to the ledger
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  const chargeTransaction = await addLedger({
                    leaseID: globals.lease["ID"],
                    ledgerType: "charge",
                    ledgerReason: "Rent Deposit",
                    ledgerDate: moment(data?.depositDate).format("YYYY-MM-DD"),
                    ledgerAmount: globals.lease?.rentDeposit,
                  });
                  const paymentTransaction = await addLedger({
                    leaseID: globals.lease["ID"],
                    ledgerType: "payment",
                    ledgerReason: "Rent Deposit",
                    ledgerDate: moment(data?.depositDate).format("YYYY-MM-DD"),
                    ledgerAmount: globals.lease?.rentDeposit,
                  });

                  if (chargeTransaction && paymentTransaction) {
                    addToast("Ledger Successfully Updated", "success");
                    await deleteAlert(
                      {
                        ID: alert["ID"],
                      },
                      dispatch
                    );
                  } else addToast("Unexpected Error Occured", "error");
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={alert["type"] == "depositDue" && dialogType == "depositPastDue"}
        onClose={() => setDialogType(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Attention!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                {`We strongly recommend not giving the tenant(s) the keys to your unit until you have received the ${
                  globals.lease?.rentDeposit ? "rent" : ""
                } ${globals.lease?.rentDeposit && globals.lease?.keyDeposit ? " and " : ""} ${
                  globals.lease?.keyDeposit ? "key" : ""
                } deposit.`}
                {globals.lease?.rentDeposit &&
                  ` The moment your tenant(s) moves into the unit, they are no longer required to pay the rent deposit, and if they
                    do, they can apply to the LTB to get it back at anytime.`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={() => setDialogType(false)}
              >
                Wait For Deposit(s)
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={async () => {
                  await deleteAlert(
                    {
                      ID: alert["ID"],
                    },
                    dispatch
                  );
                  setDialogType(false);
                }}
              >
                Give Keys Anyway
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  const depositDueContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          {moment().isSameOrAfter(data?.depositDate, "day")
            ? `Did you receive the ${globals.lease?.rentDeposit ? "rent" : ""} ${
                globals.lease?.rentDeposit && globals.lease?.keyDeposit ? " and " : ""
              } ${globals.lease?.keyDeposit ? "key" : ""} deposit from your tenant(s) on ${moment(
                data?.depositDate
              ).format("MMMM Do, YYYY")}?`
            : `Make sure to only give your tenant(s) the keys to your property after you get the ${
                globals.lease?.rentDeposit ? "rent" : ""
              } ${globals.lease?.rentDeposit && globals.lease?.keyDeposit ? " and " : ""} ${
                globals.lease?.keyDeposit ? "key" : ""
              } deposit on
            ${moment(data?.depositDate).format("MMMM Do, YYYY")}`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            {moment().isSameOrAfter(data?.depositDate, "day") ? (
              <>
                <IconButton color="secondary" size="medium" onClick={() => setDialogType("depositReceived")}>
                  <CheckCircleIcon fontSize="large" />
                </IconButton>
                <IconButton
                  className={classes.cancelButton}
                  size="medium"
                  onClick={() => setDialogType("depositPastDue")}
                >
                  <CrossCircleIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
                <EventNoteRoundedIcon className={classes.confirmButton} />
              </IconButton>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const conditionReportContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.conditionReportCardContent}>
        <IconButton size="medium" className={classes.conditionReportQuestionIcon}>
          <QuestionIcon fontSize="medium" />
        </IconButton>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          Before your tenant(s) move in on {moment(globals.lease?.moveInDate).format("MMMM Do, YYYY")}, we recommend
          walking through your property with them, noting down the condition of each unit, and getting their signature
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.conditionReportSubmit}
            onClick={() => {
              history.push(`${globals.property["ID"]}/condition-report`);
              if (globals.alertMenu) globals.alertMenu(null);
            }}
          >
            Fill Out Condition Report
          </Button>
        </div>
      </CardContent>
    </Card>
  );

  const missingPaymentContent = (
    <Card
      className={`${classes.missingPaymentInfoCard} ${
        !globals.alertMenu ? `${classes.missingPaymentInfoCardMobile}` : ""
      }`}
    >
      <CardContent className={classes.infoCardContent}>
        <Typography variant="h5" align="center">
          Missing Payment!
        </Typography>
        <Typography variant="body1" align="center">
          Would you like to give your tenant(s) a Notice for Non-Payment of Rent (N4)?
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            <IconButton
              color="secondary"
              size="medium"
              onClick={async () => {
                await deleteAlert(
                  {
                    ID: alert["ID"],
                  },
                  dispatch
                );
                problemObj.history = history;
                problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                problemObj.setLTBFormData = setLTBFormData;
                createForm("n4", 1);
                if (globals.alertMenu) globals.alertMenu(null);
              }}
            >
              <CheckCircleIcon fontSize="large" />
            </IconButton>
            <IconButton
              className={classes.cancelButton}
              size="medium"
              onClick={async () => {
                await deleteAlert(
                  {
                    ID: alert["ID"],
                  },
                  dispatch
                );
              }}
            >
              <CrossCircleIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const rentIncreaseContent = (
    <Card className={classes.rentDueInfoCard}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          {`Good news! You can now fill out a notice to increase your tenant's rent on ${moment(data?.date).format(
            "MMMM Do, YYYY"
          )}. Make sure to
            do this by ${moment(data?.date)
              .subtract(90, "days")
              .format(
                "MMMM Do, YYYY"
              )}, as it's required that you give your tenant(s) the notice at least 90 days in advance`}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
              <EventNoteRoundedIcon className={classes.confirmButton} />
            </IconButton>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.conditionReportSubmit}
            onClick={() => {
              problemObj.history = history;
              problemObj.baseURL = `/${globals.property["ID"]}/forms`;
              problemObj.setLTBFormData = setLTBFormData;
              createForm("n1", 1);
              if (globals.alertMenu) globals.alertMenu(null);
            }}
          >
            Fill Out Rent Increase Form
          </Button>
        </div>
      </CardContent>
    </Card>
  );

  const formStepDialogOptions = {
    GenerateCertificateOfService: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The next step is to auto-fill a Certificate of Service to let the LTB know you've delivered the notice
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await editAlert(
                    {
                      ID: alert["ID"],
                      data: {
                        ...data,
                        step: data.step + 1,
                      },
                    },
                    dispatch
                  );
                  problemObj.history = history;
                  problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                  problemObj.setLTBFormData = setLTBFormData;
                  createForm("cs", parentForm.problemID, data.parentID);
                  if (globals.alertMenu) globals.alertMenu(null);
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    RedoForm: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Generate New{" "}
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{` ${parentForm?.type.toUpperCase()} `}</span>
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                Unfortunately, the termination date on your current
                <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{` ${parentForm?.type.toUpperCase()} `}</span>
                no longer applies. So, we have to generate a new
                <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{` ${parentForm?.type.toUpperCase()} `}</span>
                with the new date. Don't worry, this will only take a minute!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await deleteForm({ formID: parentForm?.ID, leaseID: globals.lease["ID"] }, dispatch);
                  await deleteAlert(
                    {
                      ID: alert["ID"],
                    },
                    dispatch
                  );
                  problemObj.history = history;
                  problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                  problemObj.setLTBFormData = setLTBFormData;
                  createForm(parentForm?.type, parentForm?.problemID);
                  if (globals.alertMenu) globals.alertMenu(null);
                  setDialogType(false);
                }}
              >
                Generate New {parentForm?.type.toUpperCase()}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    N4NoFurtherAction: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                No further action is required on your part. We'll update your tenant ledger for you!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  const paymentTransaction = await addLedger({
                    leaseID: globals.lease["ID"],
                    ledgerType: "payment",
                    ledgerReason: "Rent",
                    ledgerDate: moment().format("YYYY-MM-DD"),
                    ledgerAmount: parentForm?.data?.OweMeAmount,
                  });
                  if (paymentTransaction) {
                    addToast("Ledger Successfully Updated", "success");
                    await deleteAlert(
                      {
                        ID: alert["ID"],
                      },
                      dispatch
                    );
                  } else addToast("Unexpected Error Occured", "error");
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    GenerateL1: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Attention!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The next step is to auto-fill an Application for Non-Payment of Rent (L1) and submit it to the LTB
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await editAlert(
                    {
                      ID: alert["ID"],
                      data: {
                        ...data,
                        step: data.step + 1,
                      },
                    },
                    dispatch
                  );
                  problemObj.history = history;
                  problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                  problemObj.setLTBFormData = setLTBFormData;
                  createForm("l1", parentForm.problemID, data.parentID);
                  if (globals.alertMenu) globals.alertMenu(null);
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    NoFurtherAction: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                No further action is required on your part!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await deleteAlert(
                    {
                      ID: alert["ID"],
                    },
                    dispatch
                  );
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    GenerateL2: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Attention!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                The next step is to auto-fill an Application to End a Tenancy and Evict a Tenant (L2) and submit it to
                the LTB
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await editAlert(
                    {
                      ID: alert["ID"],
                      data: {
                        ...data,
                        step: data.step + 1,
                      },
                    },
                    dispatch
                  );
                  problemObj.history = history;
                  problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                  problemObj.setLTBFormData = setLTBFormData;
                  createForm("l2", parentForm.problemID, data.parentID);
                  if (globals.alertMenu) globals.alertMenu(null);
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    FormCheckoutConfirmation: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.formCheckoutDialogHeader}>
            Are you sure you don't want us to submit the application for you?
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                Submitting an application to the LTB is a time-consuming and complicated process. This is why we are
                offering to do all the hard work for you in order to save you time! However, if you prefer to submit the
                application yourself, we can give you instructions to help you do that
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={() => setDialogType("FormApplicationInstructions")}
              >
                Yes, I'll do it myself
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                I changed my mind
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    FormApplicationInstructions: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Form Application Instructions
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.instructionsHeader}>
                What to include in your application
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" align="center" className={classes.instructionsSubHeader}>
                You must include the following items when filing your application:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List disablePadding className={classes.instructionsList}>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6", style: { fontSize: mobile ? "1.1em" : "1.3em" } }}
                    primary={`• The completed ${parentForm?.type == "n4" ? "L1" : "L2"} application form`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6", style: { fontSize: mobile ? "1.1em" : "1.3em" } }}
                    primary={`• A copy of the ${parentForm?.type.toUpperCase()} form`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6", style: { fontSize: mobile ? "1.1em" : "1.3em" } }}
                    primary={`• The Certificate of Service showing when and how you gave the
                    ${parentForm?.type.toUpperCase()} form to your tenant `}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6", style: { fontSize: mobile ? "1.1em" : "1.3em" } }}
                    primary="• The application fee associated with how you file your application"
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="center" className={classes.instructionsSubHeader}>
                Your application will be refused if any of the items above are missing
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.instructionsHeader}>
                How to file your application
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" align="center" className={classes.instructionsSubHeader}>
                You can use one of these four ways to file your application (click to expand for more info):
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" align="center" noWrap className={classes.instructionsItem}>
                    1. e-File
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} style={{ marginTop: "-10px" }}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Application Fee: $186
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Payment Methods: Credit Card or Debit Card
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        You can visit the LTB website to complete and pay for your application online using LTB e-File.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.buttonLink}
                        onClick={() => (window.location.href = "https://tribunalsontario.ca/ltb/e-file/")}
                      >
                        Visit LTB Website
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" align="center" noWrap className={classes.instructionsItem}>
                    2. In Person
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} style={{ marginTop: "-10px" }}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Application Fee: $201
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Payment Methods: Cash, Debit Card, Certified Cheque, Money Order, Visa or MasterCard
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        You can file your application in person at the nearest LTB office
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        To find a list of LTB office locations visit the LTB website. You can also call the LTB at
                        416-645-8080 or 1-888-332-3234 (toll-free)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.buttonLink}
                        onClick={() => (window.location.href = "https://tribunalsontario.ca/ltb")}
                      >
                        Visit LTB Website
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" align="center" noWrap className={classes.instructionsItem}>
                    3. By Mail
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} style={{ marginTop: "-10px" }}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Application Fee: $201
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Payment Methods: Certified Cheque, Money Order, Visa or MasterCard
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        You can mail your application to the nearest LTB office
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        To find a list of LTB office locations visit the LTB website. You can also call the LTB at
                        416-645-8080 or 1-888-332-3234 (toll-free)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.buttonLink}
                        onClick={() => (window.location.href = "https://tribunalsontario.ca/ltb")}
                      >
                        Visit LTB Website
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion} style={{ marginBottom: mobile ? "30px" : "0px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" align="center" noWrap className={classes.instructionsItem}>
                    4. By Fax
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} style={{ marginTop: "-10px" }}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Application Fee: $201
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="center"
                        className={`${classes.instructionsItem} ${classes.instructionsExtra}`}
                      >
                        Payment Methods: Visa or MasterCard
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        You can fax your application to the nearest LTB office
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" className={classes.instructionsItem}>
                        To find a list of LTB office fax numbers visit the LTB website. You can also call the LTB at
                        416-645-8080 or 1-888-332-3234 (toll-free)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.buttonLink}
                        onClick={() => (window.location.href = "https://tribunalsontario.ca/ltb")}
                      >
                        Visit LTB Website
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} style={{ marginTop: mobile ? "-30px" : "0px", paddingBottom: mobile ? "20px" : "0px" }}>
              <Typography
                gutterBottom
                variant="body1"
                align="center"
                className={classes.instructionsSubHeader}
                style={{ paddingBottom: data.step !== 2 ? "20px" : "0px" }}
              >
                Certified cheques and money orders must be made payable to the Minister of Finance
              </Typography>
            </Grid>
            {data.step === 2 && (
              <>
                <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.genericDialogSubmit}
                    onClick={async () => {
                      await editAlert(
                        {
                          ID: alert["ID"],
                          data: {
                            ...data,
                            step: data.step + 1,
                          },
                        },
                        dispatch
                      );
                      setDialogType(false);
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                    onClick={() => setDialogType(false)}
                  >
                    I changed my mind
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </>
    ),
    RentIncreaseFormDelivered: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Great!
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                We will update the rent price {`${globals.lease?.rentDeposit ? "and rent deposit" : ""}`} on your lease
                on {moment(parentForm?.data?.StartDate, "DD/MM/YYYY").format("MMMM Do, YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await editAlert(
                    {
                      ID: alert["ID"],
                      data: {
                        ...data,
                        step: data.step + 1,
                      },
                    },
                    dispatch
                  );
                  setDialogType(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
    RedoRentIncreaseForm: (
      <>
        <DialogTitle disableTypography style={{ fontSize: "1.25rem", paddingBottom: "0" }}>
          <Typography variant="h4" align="center" className={classes.rentDueDialogHeader}>
            Generate New{" "}
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{` ${parentForm?.type.toUpperCase()} `}</span>
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setDialogType(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" align="center" className={classes.rentDueDialogHeader}>
                Since the LTB requires a 90 day notice to increase rent, we will have to generate a new{" "}
                <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{` ${parentForm?.type.toUpperCase()} `}</span>{" "}
                with an updated rent increase date before you can deliver it to your tenant. Don't worry, this will only
                take a minute!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.genericDialogSubmit}
                onClick={async () => {
                  await deleteForm({ formID: parentForm?.ID, leaseID: globals.lease["ID"] }, dispatch);
                  await deleteAlert(
                    {
                      ID: alert["ID"],
                    },
                    dispatch
                  );
                  problemObj.history = history;
                  problemObj.baseURL = `/${globals.property["ID"]}/forms`;
                  problemObj.setLTBFormData = setLTBFormData;
                  createForm(parentForm?.type, parentForm?.problemID);
                  if (globals.alertMenu) globals.alertMenu(null);
                  setDialogType(false);
                }}
              >
                Generate New {parentForm?.type.toUpperCase()}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.genericDialogSubmit} ${classes.deleteBtn}`}
                onClick={() => setDialogType(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    ),
  };

  // This applies to all the N forms that can lead to having an L2 submitted immediately after
  // Every N form except the N4, N5, and rent increase forms
  const genericFormSteps = [
    // Step 0
    moment().isSameOrBefore(moment(parentForm?.data?.LastDeliveryDate, "YYYY-MM-DD"), "day") ? (
      <Card className={classes.rentDueInfoCard}>
        <CardContent className={classes.conditionReportCardContent}>
          <Typography
            variant="h5"
            align="center"
            className={classes.infoText}
            style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
          >
            {(() => {
              let daysToDeliver =
                moment(parentForm?.data?.LastDeliveryDate).diff(moment().format("YYYY-MM-DD"), "days") + 1;
              return daysToDeliver + " day" + (daysToDeliver > 1 ? "s" : "");
            })()}{" "}
            to deliver the
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}> {parentForm?.type.toUpperCase()} </span>
            to the tenant at this property
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.conditionReportSubmit}
              onClick={() => setDialogType("GenerateCertificateOfService")}
            >
              I've Delivered It!
            </Button>
          </div>
        </CardContent>
      </Card>
    ) : (
      <Card className={classes.rentDueInfoCard}>
        <CardContent className={classes.formCardContent}>
          <Typography
            variant="h5"
            align="center"
            className={classes.infoText}
            style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
          >
            Were you able to deliver the{" "}
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
            to the tenant at this property by {moment(parentForm?.data?.LastDeliveryDate).format("MMMM Do, YYYY")}?
          </Typography>
          <div style={{ maxWidth: "300px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <IconButton color="secondary" onClick={() => setDialogType("GenerateCertificateOfService")}>
              <CheckCircleIcon fontSize="large" />
            </IconButton>
            <IconButton className={classes.cancelButton} onClick={() => setDialogType("RedoForm")}>
              <CrossCircleIcon fontSize="large" />
            </IconButton>
          </div>
        </CardContent>
      </Card>
    ),
    // Step 1
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.formCardContent}>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          The <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
          allows you to immediatley apply to the LTB to evict the tenant by filling out an L2
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.conditionReportSubmit}
            onClick={() => setDialogType("GenerateL2")}
          >
            Get Started
          </Button>
        </div>
      </CardContent>
    </Card>,
    // Step 2
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.formCardContent}>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          It is required that you file an application for your{" "}
          <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type == "n4" ? "L1" : "L2"} </span>
          to the LTB. Would you like us to submit it for you?
        </Typography>
        <div style={{ maxWidth: "300px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
          <IconButton
            color="secondary"
            onClick={() => {
              setLTBFormData({
                dispatch,
                alertID: alert["ID"],
                leaseID: globals.lease["ID"],
                parentID: data.parentID,
              });
              history.push(`${globals.property["ID"]}/forms/checkout`);
              if (globals.alertMenu) globals.alertMenu(null);
            }}
          >
            <CheckCircleIcon fontSize="large" />
          </IconButton>
          <IconButton className={classes.cancelButton} onClick={() => setDialogType("FormCheckoutConfirmation")}>
            <CrossCircleIcon fontSize="large" />
          </IconButton>
        </div>
      </CardContent>
    </Card>,
    // Step 3
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.conditionReportCardContent}>
        <IconButton
          size="medium"
          className={classes.conditionReportQuestionIcon}
          onClick={() => setDialogType("FormApplicationInstructions")}
        >
          <QuestionIcon fontSize="medium" />
        </IconButton>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          Have you submitted the application for your{" "}
          <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type == "n4" ? "L1" : "L2"} </span> to the
          LTB?
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.conditionReportSubmit}
            onClick={async () =>
              await deleteAlert(
                {
                  ID: alert["ID"],
                },
                dispatch
              )
            }
          >
            I've submitted the application!
          </Button>
        </div>
      </CardContent>
    </Card>,
  ];

  const rentIncreaseFormDeliveryAlert =
    moment(parentForm?.data?.StartDate, "DD/MM/YYYY").diff(moment().format("YYYY-MM-DD"), "days") - 90 > 0 ? (
      <Card className={classes.rentDueInfoCard}>
        <CardContent className={classes.conditionReportCardContent}>
          <Typography
            variant="h5"
            align="center"
            className={classes.infoText}
            style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
          >
            Have you delivered the{" "}
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
            to your tenant? You have until{" "}
            {moment(parentForm?.data?.StartDate, "DD/MM/YYYY").subtract(90, "days").format("MMMM Do, YYYY")} to deliver
            the form before it becomes invalid
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.conditionReportSubmit}
              onClick={() => setDialogType("RentIncreaseFormDelivered")}
            >
              I've Delivered It!
            </Button>
          </div>
        </CardContent>
      </Card>
    ) : (
      <Card className={classes.rentDueInfoCard}>
        <CardContent className={classes.formCardContent}>
          <Typography
            variant="h5"
            align="center"
            className={classes.infoText}
            style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
          >
            Were you able to deliver the{" "}
            <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
            to your tenant before{" "}
            {moment(parentForm?.data?.StartDate, "DD/MM/YYYY").subtract(90, "days").format("MMMM Do, YYYY")}?
          </Typography>
          <div style={{ maxWidth: "300px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <IconButton color="secondary" onClick={() => setDialogType("RentIncreaseFormDelivered")}>
              <CheckCircleIcon fontSize="large" />
            </IconButton>
            <IconButton className={classes.cancelButton} onClick={() => setDialogType("RedoRentIncreaseForm")}>
              <CrossCircleIcon fontSize="large" />
            </IconButton>
          </div>
        </CardContent>
      </Card>
    );

  const rentIncreaseFormAlert = moment().isSameOrAfter(moment(parentForm?.data?.StartDate, "DD/MM/YYYY"), "day") ? (
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.formCardContent}>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          {!globals.lease?.rentDeposit || globals.lease?.rentDeposit === globals.lease?.rentPrice
            ? `The rent price ${globals.lease?.rentDeposit ? "and rent deposit" : ""} on your lease can now be
            increased to $${parentForm?.data?.RentIncAmount1}!`
            : `The rent price can now be increased to $${parentForm?.data?.RentIncAmount1} and the rent deposit can now be increased to $${parentForm?.data?.newRentDeposit}!`}
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.conditionReportSubmit}
            onClick={async () => {
              let newRentDeposit = null;
              if (globals.lease?.rentDeposit) {
                newRentDeposit =
                  globals.lease?.rentDeposit !== globals.lease?.rentPrice
                    ? parentForm?.data?.newRentDeposit
                    : parentForm?.data?.RentIncAmount1;
              }

              const leaseEdited = await editLease(
                {
                  ID: globals.lease["ID"],
                  rentPrice: parentForm?.data?.RentIncAmount1,
                  rentDeposit: newRentDeposit,
                  // lastRentIncreaseDate: parentForm?.data?.StartDate,
                },
                dispatch
              );
              if (leaseEdited) {
                addToast("Lease Successfully Updated", "success");
                await deleteAlert(
                  {
                    ID: alert["ID"],
                  },
                  dispatch
                );
              } else addToast("Unexpected Error Occured", "error");
            }}
          >
            Increase Rent
          </Button>
        </div>
      </CardContent>
    </Card>
  ) : (
    <Card className={classes.rentDueInfoCard}>
      <CardContent className={classes.conditionReportCardContent}>
        <Typography
          variant="h5"
          align="center"
          className={classes.infoText}
          style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
        >
          The rent price {`${globals.lease?.rentDeposit ? "and rent deposit" : ""}`} on your lease will be updated on{" "}
          {moment(parentForm?.data?.StartDate, "DD/MM/YYYY").format("MMMM Do, YYYY")}
        </Typography>
        <div className={classes.center} style={{ marginTop: 8 }}>
          <div className={classes.row}>
            <IconButton className={classes.confirmIconButton} disableFocusRipple disableRipple>
              <EventNoteRoundedIcon className={classes.confirmButton} />
            </IconButton>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  // Steps that are special for certain forms
  // Stored as an object of {step numbers that are special: {forms that the step is special for: jsx for the step alert}}
  const specialFormSteps = {
    // Special Step 0
    0: {
      n1: rentIncreaseFormDeliveryAlert,
      n2: rentIncreaseFormDeliveryAlert,
      n3: rentIncreaseFormDeliveryAlert,
      n10: rentIncreaseFormDeliveryAlert,
    },
    // Special Step 1
    1: {
      n1: rentIncreaseFormAlert,
      n2: rentIncreaseFormAlert,
      n3: rentIncreaseFormAlert,
      n10: rentIncreaseFormAlert,
      n4: moment().isSameOrBefore(moment(parentForm?.data?.PayDate, "DD/MM/YYYY"), "day") ? (
        <Card className={classes.rentDueInfoCard}>
          <CardContent className={classes.conditionReportCardContent}>
            <Typography
              variant="h5"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              {(() => {
                let daysToPay = moment(parentForm?.data?.PayDate, "DD/MM/YYYY").diff(
                  moment().format("YYYY-MM-DD"),
                  "days"
                );
                return daysToPay + " day" + (daysToPay > 1 ? "s" : "");
              })()}{" "}
              left for the tenant at this property to make the payments required in the
              <span style={{ color: "#1FBFE3", fontWeight: "bold" }}> {parentForm?.type.toUpperCase()} </span>
              you gave them
            </Typography>
            <Typography
              variant="h6"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              If the payment is not made in full by{" "}
              {moment(parentForm?.data?.PayDate, "DD/MM/YYYY").format("MMMM Do, YYYY") + " "}
              we will guide you through the next steps
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.conditionReportSubmit}
                onClick={() => setDialogType("N4NoFurtherAction")}
              >
                They've Already Made The Full Payment!
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.rentDueInfoCard}>
          <CardContent className={classes.formCardContent}>
            <Typography
              variant="h5"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              The termination date on the{" "}
              <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
              for this property has passed. Was your tenant able to pay the rent owed by{" "}
              {moment(parentForm?.data?.PayDate, "DD/MM/YYYY").format("MMMM Do, YYYY")}?
            </Typography>
            <div style={{ maxWidth: "300px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
              <IconButton color="secondary" onClick={() => setDialogType("N4NoFurtherAction")}>
                <CheckCircleIcon fontSize="large" />
              </IconButton>
              <IconButton className={classes.cancelButton} onClick={() => setDialogType("GenerateL1")}>
                <CrossCircleIcon fontSize="large" />
              </IconButton>
            </div>
          </CardContent>
        </Card>
      ),
      n5: moment().isSameOrBefore(moment(parentForm?.data?.LastCorrectionDate, "DD/MM/YYYY"), "day") ? (
        <Card className={classes.rentDueInfoCard}>
          <CardContent className={classes.conditionReportCardContent}>
            <Typography
              variant="h5"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              {(() => {
                let daysToPay =
                  moment(parentForm?.data?.LastCorrectionDate, "DD/MM/YYYY").diff(
                    moment().format("YYYY-MM-DD"),
                    "days"
                  ) + 1;
                return daysToPay + " day" + (daysToPay > 1 ? "s" : "");
              })()}{" "}
              left for the tenant at this property to stop the activities or correct the behaviour you described in the
              <span style={{ color: "#1FBFE3", fontWeight: "bold" }}> {parentForm?.type.toUpperCase()} </span>
              you gave them
            </Typography>
            <Typography
              variant="h6"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              If they have not stopped or corrected their behaviour by
              {" " + moment(parentForm?.data?.LastCorrectionDate, "DD/MM/YYYY").format("MMMM Do, YYYY") + " "}
              we will guide you through the next steps
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.conditionReportSubmit}
                onClick={() => setDialogType("NoFurtherAction")}
              >
                They've Corrected It!
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.rentDueInfoCard}>
          <CardContent className={classes.formCardContent}>
            <Typography
              variant="h5"
              align="center"
              className={classes.infoText}
              style={{ padding: betweenSmLg ? "0px 20px" : null, fontSize: globals.alertMenu ? "1.4em" : null }}
            >
              The termination date on the{" "}
              <span style={{ color: "#1FBFE3", fontWeight: "bold" }}>{parentForm?.type.toUpperCase()} </span>
              for this property has passed. Was your tenant able to stop the activities or correct the behaviour you
              described?
            </Typography>
            <div style={{ maxWidth: "300px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
              <IconButton color="secondary" onClick={() => setDialogType("NoFurtherAction")}>
                <CheckCircleIcon fontSize="large" />
              </IconButton>
              <IconButton className={classes.cancelButton} onClick={() => setDialogType("GenerateL2")}>
                <CrossCircleIcon fontSize="large" />
              </IconButton>
            </div>
          </CardContent>
        </Card>
      ),
    },
  };

  const formStepDialog = (
    <Dialog
      open={alert["type"] == "formNextStep" && Boolean(dialogType)}
      onClose={() => setDialogType(false)}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      {formStepDialogOptions[dialogType]}
    </Dialog>
  );

  const dialog = {
    lease: {
      rentDue: rentDueDialog,
      depositDue: depositDueDialog,
      moveIn: moveInDialog,
      moveOut: moveOutDialog,
      useRentDeposit: useRentDepositDialog,
      formNextStep: formStepDialog,
    },
    building: {
      rentDue: rentDueBuildingDialog,
    },
  };

  const content = {
    lease: {
      rentDue: rentDueContent,
      depositDue: depositDueContent,
      conditionReport: conditionReportContent,
      missingPayment: missingPaymentContent,
      rentIncrease: rentIncreaseContent,
      moveIn: moveInContent,
      moveOut: moveOutContent,
      useRentDeposit: useRentDepositContent,
      formNextStep:
        parentForm && "type" in parentForm && "step" in data
          ? data.step in specialFormSteps && parentForm.type in specialFormSteps[data.step]
            ? specialFormSteps[data.step][parentForm.type]
            : genericFormSteps[data.step]
          : "",
    },
    building: {
      rentDue: rentDueBuildingContent,
    },
  };

  return (
    <>
      {dialog[isBuildingAlert() ? "building" : "lease"][alert["type"]]}
      {(alert["type"] != "rentDue" || !(lastRentDueDate && thisMonthsRent - globals.lease?.rentDeposit == 0)) &&
        (alert["type"] != "rentIncrease" ||
          moment().isSameOrAfter(moment(data?.date).subtract(120, "days"), "day")) && (
          <Grid
            item
            xs={12}
            md={globals.alertMenu || ["conditionReport", "missingPayment"].includes(alert["type"]) ? 12 : 6}
            className={props?.className}
          >
            {content[isBuildingAlert() ? "building" : "lease"][alert["type"]]}
          </Grid>
        )}
    </>
  );
}

const mapState = (state) => {
  return {
    LTBFormData: state.LTBForm.data,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(Alert);
