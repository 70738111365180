const apiURL = "https://api.thesimplelandlord.com";

export function setAxiosURL(axios) {
  axios.defaults.baseURL = apiURL;
}

export default function setAxiosDefaultSettings(axios) {
  const TOKEN_STORAGE_KEY = "userToken";
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  setAxiosURL(axios);
  axios.defaults.timeout = 10000;
}
