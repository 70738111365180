import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import MultiStepForm from "../../components/MutliStepForm";
import RadioOption from "../../components/RadioOption";
import RadioOptions from "../../components/RadioOptions";
import MoneyInput from "../../components/MoneyInput";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { connect } from "react-redux";

import { editROIData } from "../../../services/property/roiServices";

const errorMessages = {
  isMortgaged: {
    required: "Please select whether or not you currently have a mortgage on the property",
    pattern: "Please select whether or not you currently have a mortgage on the property",
  },
  mortgageStartDate: {
    required: "Please select the date that this mortgage term started on",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  initialMortgage: {
    required: "Please enter the mortgage balance at the start of this term",
    pattern: "The mortgage balance must be a positive number and can't have more than 2 decimals",
  },
  mortgagePayment: {
    required: "Please enter your monthly mortgage payment",
    pattern: "The mortgage payment must be a positive number and can't have more than 2 decimals",
  },
  interestRate: {
    required: "Please enter the current interest rate on the mortgage",
    max: "The interest rate can't be greater than 20%",
    min: "The interest rate can't be less than 0%",
    pattern: "The mortgage payment must be a positive number and can't have more than 2 decimals",
  },
  purchaseDate: {
    required: "Please select the purchase date",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  initialPropertyValue: {
    required: "Please enter how much your property was appraised for initially",
    pattern: "The initial property appraisal must be a positive number and can't have more than 2 decimals",
  },
  isAppraised: {
    required: "Please select whether you've gotten another appraisal or not",
    pattern: "Please select whether you've gotten another appraisal or not",
  },
  appraisalDate: {
    required: "Please select the date of your most recent appraisal",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  propertyValue: {
    required: "Please enter how much the property was appraised for",
    pattern: "The most recent appraisal must be a positive number and can't have more than 2 decimals",
  },
};

const useStyles = makeStyles((theme) => ({
  question: {
    fontWeight: "600",
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(4),
      fontSize: "1.65rem",
    },
  },
  answer: {
    marginBottom: theme.spacing(4),
  },
  dateRange: {
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    padding: theme.spacing(2),
    [`${theme.breakpoints.up("sm")}`]: {
      paddingBottom: "0px",
    },
  },
}));

let onSubmit = null;
function ROISetup({ refreshProperty, refreshROIData, history, ...props }) {
  const { property_id } = useParams();
  useEffect(() => {
    if (props.property && props.property["initialMortgage"]) history.push(`/${property_id}`);
  }, [props.property]);

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [formData, setFormData] = useState({});
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});

  const errorAndHelperText = (fieldName) => {
    return {
      error: errors && errors[fieldName] && errors[fieldName] !== null,
      helperText: errors && errors[fieldName] ? errorMessages[fieldName][errors[fieldName].type] : null,
    };
  };

  let formSteps = [
    {
      section: "Mortgage",
      fieldNames: "isMortgaged",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Do you currently have a mortgage on the property?
          </Typography>
          <Controller
            name="isMortgaged"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => {
                  //console.log("Value changing to: ", e.target.value);
                  onChange(e.target.value);
                }}
                onMouseUp={(e) => {
                  //console.log("Mouse clicked: ", e.target.value);
                  handleSubmit(onSubmit)();
                }}
                {...errorAndHelperText("isMortgaged")}
              >
                <RadioOption value="1" label="Yes" className={classes.answer} />
                <RadioOption value="0" label="No" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Mortgage",
      fieldNames: "mortgageStartDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When did this mortgage <span style={{ whiteSpace: "nowrap" }}>term start?</span>
          </Typography>
          <Controller
            name="mortgageStartDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Mortgage Start Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("mortgageStartDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Mortgage",
      fieldNames: "initialMortgage",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What was your mortgage balance at the start of <span style={{ whiteSpace: "nowrap" }}>this term?</span>
          </Typography>
          <Controller
            name="initialMortgage"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,4}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Initial Mortgage Balance"
                className={classes.answer}
                {...errorAndHelperText("initialMortgage")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Cash Flow",
      fieldNames: "mortgagePayment",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much is your monthly <span style={{ whiteSpace: "nowrap" }}>mortgage payment?</span>
          </Typography>
          <Controller
            name="mortgagePayment"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Mortgage Payment"
                className={classes.answer}
                {...errorAndHelperText("mortgagePayment")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Cash Flow",
      fieldNames: "interestRate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What is the current interest rate on <span style={{ whiteSpace: "nowrap" }}>your mortgage?</span>
          </Typography>
          <Controller
            name="interestRate"
            control={control}
            rules={{
              required: true,
              max: 20,
              min: 0,
              pattern: /^(\d{1,2})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                label="Interest Rate"
                className={classes.answer}
                {...errorAndHelperText("interestRate")}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Appraisal",
      fieldNames: "purchaseDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When did you initially purchase <span style={{ whiteSpace: "nowrap" }}>this property?</span>
          </Typography>
          <Controller
            name="purchaseDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Purchase Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("purchaseDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Appraisal",
      fieldNames: "initialPropertyValue",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much was your property appraised for{" "}
            {formData && formData["isMortgaged"] === "0" ? (
              <>
                when you <span style={{ whiteSpace: "nowrap" }}>bought it?</span>
              </>
            ) : (
              <>
                at the start of this <span style={{ whiteSpace: "nowrap" }}>mortgage term?</span>
              </>
            )}
          </Typography>
          <Controller
            name="initialPropertyValue"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,4}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Initial Property Appraisal"
                className={classes.answer}
                {...errorAndHelperText("initialPropertyValue")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Appraisal",
      fieldNames: "isAppraised",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Have you gotten another appraisal on the property since{" "}
            {formData && formData["isMortgaged"] === "0" ? (
              <>
                you <span style={{ whiteSpace: "nowrap" }}>bought it?</span>
              </>
            ) : (
              <>
                the start of <span style={{ whiteSpace: "nowrap" }}>this term?</span>
              </>
            )}
          </Typography>
          <Controller
            name="isAppraised"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("isAppraised")}
              >
                <RadioOption value="1" label="Yes" className={classes.answer} />
                <RadioOption value="0" label="No" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Appraisal",
      fieldNames: "appraisalDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When did you get your most <span style={{ whiteSpace: "nowrap" }}>recent appraisal?</span>
          </Typography>
          <Controller
            name="appraisalDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Most Recent Appraisal Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("appraisalDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Appraisal",
      fieldNames: "propertyValue",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much was the property <span style={{ whiteSpace: "nowrap" }}>appraised for?</span>
          </Typography>
          <Controller
            name="propertyValue"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,4}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Most Recent Appraisal"
                className={classes.answer}
                {...errorAndHelperText("propertyValue")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!formData) return;

    let skipping = [];

    if (formData["isMortgaged"] != null && formData["isMortgaged"] == "0")
      skipping.push("mortgageStartDate", "initialMortgage", "mortgagePayment", "interestRate");

    if (formData["isAppraised"] !== null && formData["isAppraised"] == "0")
      skipping.push("appraisalDate", "propertyValue");

    setStepsToSkip(skipping);
  }, [formData]);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
      setErrorReceived(errors);
    }
  };

  const submitForm = async (roiData, callback = null) => {
    roiData = {
      ...roiData,
    };
    roiData["ID"] = property_id;
    for (let dollarValue of [
      "initialMortgage",
      "mortgagePayment",
      "interestRate",
      "initialPropertyValue",
      "propertyValue",
    ]) {
      if (dollarValue in roiData) roiData[dollarValue] = (roiData[dollarValue] + "").replace("$", "").replace(",", "");
    }

    console.log("roiData: ", roiData);
    const roiInfo = await editROIData(roiData, handleErrors);
    if (roiInfo) {
      addToast("ROI Set up Successfully", "success");
      if (callback != null) callback();
      refreshProperty();
      refreshROIData();
      history.push(`/${property_id}/roi`);
      return true;
    }

    addToast("ROI Setup Failed", "error");
    if (callback != null) callback();
    return false;
  };

  return (
    <MultiStepForm
      formSteps={formSteps}
      stepsToSkip={stepsToSkip}
      formData={formData}
      setFormData={setFormData}
      submitForm={submitForm}
      handleSubmit={handleSubmit}
      setOnSubmit={(func) => (onSubmit = func)}
      errorReceived={errorReceived}
      toasts={toasts}
    />
  );
}

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(ROISetup);
