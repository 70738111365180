import { getTenants } from "../services/property/tenantServices.js";

const updateTenantList = async (leases, setTenantsByLease) => {
  let tenants = {};
  for (let lease of leases) {
    let tenantData = {
      leaseID: lease["ID"],
    };
    const tenantsReceived = await getTenants(tenantData);
    console.log("tenantsReceived: ", tenantsReceived);
    if (tenantsReceived) {
      tenantsReceived.forEach((tenant) => {
        if (tenant.mailingAddress) {
          let address = tenant.mailingAddress.split(", ");
          let unitNum = address[4];
          tenant.unitNum = unitNum;

          let addressData = {
            streetNum: address[0],
            streetName: address[1],
            streetType: address[2],
            direction: address[3],
            municipality: address[5],
            province: address[6],
            postalCode: address[7],
          };
          tenant.addressData = addressData;

          let direction = address[3] ? " " + address[3] : "";
          let unit = address[4] ? " #" + address[4] : "";
          let mailingAddress =
            "" +
            address[0] +
            " " +
            address[1] +
            " " +
            address[2] +
            direction +
            unit +
            ", " +
            address[5] +
            ", " +
            address[6] +
            " " +
            address[7];
          tenant.mailingAddress = mailingAddress;
        }
      });

      tenants[lease["ID"]] = tenantsReceived;
    }
  }
  setTenantsByLease(tenants);
};

export default updateTenantList;
