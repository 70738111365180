import React, { useEffect, useState, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import GoogleLogo from "../../../assets/logos/powered_by_google_on_non_white.png";

import { initializeGoogleApi, getAddressData } from "./googlePlacesAutocompleteHooks";

const errorMessages = {
  chooseOption: "Please choose your address from the given options, e.g. 123 Maple Drive, Toronto, ON",
  alreadyExists: "This property already exists, you cannot add the same property more than once",
};

const MyPopper = function (props) {
  return (
    <Popper
      {...props}
      placement="bottom"
      modifiers={{
        flip: {
          enabled: false,
        },
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const autocompleteService = { current: null };
const placesService = { current: null };
let OntarioLatLngBounds = null;

function GooglePlacesAutocompleteField({
  error,
  onAddressSelected,
  defaultAddress = null,
  value = null,
  onChange = null,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(defaultAddress);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [address, setAddress] = useState("");

  useEffect(() => {
    initializeGoogleApi();
  }, []);

  const getPredictions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getPlaceDetails = useMemo(
    () =>
      throttle((request, callback) => {
        placesService.current.getDetails(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google)
      autocompleteService.current = new window.google.maps.places.AutocompleteService();

    if (!autocompleteService.current) return;

    if (address === "") {
      setAutocompleteOptions(autocompleteValue ? [autocompleteValue] : []);
      return;
    }

    if (!OntarioLatLngBounds) {
      const southWest = new window.google.maps.LatLng(41.66, -95.16);
      const northEast = new window.google.maps.LatLng(56.86, -74.34);
      OntarioLatLngBounds = new window.google.maps.LatLngBounds(southWest, northEast);
    }

    getPredictions(
      {
        input: address,
        componentRestrictions: { country: "ca" },
        locationRestriction: OntarioLatLngBounds,
        types: ["address"],
      },
      (predictionResults) => {
        if (active) {
          let newOptions = [];

          if (autocompleteValue) {
            newOptions = [autocompleteValue];

            if (!isAddressSelected) {
              if (!placesService.current && window.google) {
                placesService.current = new window.google.maps.places.PlacesService(document.createElement("div"));
              }

              if (!placesService.current || !autocompleteValue.place_id) return;

              getPlaceDetails(
                {
                  placeId: autocompleteValue.place_id,
                  fields: ["address_components"],
                },
                (placeResults) => {
                  if (placeResults) {
                    setIsAddressSelected(true);
                    onAddressSelected(true, getAddressData(placeResults.address_components));
                  }
                }
              );
            }
          }

          if (predictionResults) {
            predictionResults = predictionResults.filter(
              (prediction) =>
                !prediction.types.includes("route") &&
                (prediction.types.includes("street_address") || prediction.types.includes("premise"))
            );

            newOptions = [...newOptions, ...predictionResults];
          }
          setAutocompleteOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [autocompleteValue, address, getPredictions, getPlaceDetails]);

  const classes = useStyles();

  return (
    <Autocomplete
      PopperComponent={MyPopper}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      options={autocompleteOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText={"No addresses found"}
      value={value ? value : autocompleteValue}
      onChange={(event, newValue, reason) => {
        setAutocompleteOptions(newValue ? [newValue, ...autocompleteOptions] : autocompleteOptions);
        setAutocompleteValue(newValue);
        if (reason === "clear") {
          setIsAddressSelected(false);
          onAddressSelected(false, null);
        }
        if (onChange) onChange(newValue);
      }}
      onInputChange={(event, newAddress) => {
        setAddress(newAddress);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="address"
          label="Address"
          error={error && error !== null}
          helperText={error ? errorMessages[error.type] : null}
        />
      )}
      renderOption={(option) => {
        let matches, parts;
        if (option.structured_formatting) {
          matches = option.structured_formatting.main_text_matched_substrings;
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
        }
        const logo = option === autocompleteOptions[autocompleteOptions.length - 1];

        return (
          matches &&
          parts && (
            <div>
              <Grid
                container
                alignItems="center"
                style={{
                  paddingBottom: logo ? "15px" : null,
                }}
              >
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
              {logo && (
                <Grid
                  item
                  xs
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 2,
                  }}
                >
                  <img src={GoogleLogo} alt="Powered By Google Logo" />
                </Grid>
              )}
            </div>
          )
        );
      }}
    />
  );
}

export default GooglePlacesAutocompleteField;
