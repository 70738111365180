import React, { useEffect, useState } from "react";

import { useForm, Controller, useFormState } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import { GooglePlacesAutocompleteField } from "../../components/googlePlacesAutocomplete";
import MultiUnitSelector from "./MultiUnitSelector";

import RadioOption from "../../components/RadioOption";
import RadioOptions from "../../components/RadioOptions";
import MultiStepForm from "../../components/MutliStepForm";
import ToastFactory from "../../components/ToastFactory";

import { addBuilding } from "../../../services/buildingServices.js";
import { addProperty } from "../../../services/propertyServices.js";

const errorMessages = {
  streetNum: {
    required: "Please enter the street #",
    pattern: "Must be between 0 and 9999999",
  },
  streetName: {
    required: "Please enter the street name",
    length: "The street name cannot exceed 90 characters",
  },
  streetType: {
    required: "Please enter the street type",
    length: "The street name cannot exceed 15 characters",
  },
  direction: {
    pattern: "If necessary please select a direction from the dropdown menu",
  },
  unitNum: {
    pattern: "Must be between 0 and 9999999",
  },
  municipality: {
    required: "Please enter the municipality",
    length: "The street name cannot exceed 90 characters",
  },
  province: {
    required: "Please choose a province",
    pattern: "The province must be Ontario",
  },
  postalCode: {
    required: "Please enter the postal code",
    pattern: "The postal code must be in the correct format, e.g. A1B 2C3",
  },
  multipleUnits: {
    required: "Please select whether you are adding one or multiple units",
    pattern: "Please select whether you are adding one or multiple units",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  form: {
    width: "100%",
  },
  header: {
    margin: theme.spacing(1, 0, 1),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: "1.2em",
    },
  },
  subHeader: {
    margin: theme.spacing(1, 0, 1),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: "1em",
    },
  },
  autocomplete: {
    margin: theme.spacing(0, 0, 4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  radioOption: {
    width: "150px",
    margin: "0px 10px",
    maxWidth: "45%",
    [`${theme.breakpoints.down("xs")}`]: {
      margin: "0px 2.5%",
      maxWidth: "45%",
    },
  },
}));

const initialAddressData = {
  streetNum: "",
  streetName: "",
  streetType: "",
  direction: "",
  municipality: "",
  province: "",
  postalCode: "",
};

let onSubmit = null;
function AddPropertyForm(props) {
  const {
    handleSubmit,
    setError,
    trigger,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { isSubmitted } = useFormState({
    control,
  });

  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isMultiUnit, setIsMultiUnit] = useState(props.buildingID ? "1" : "0");
  useEffect(() => {
    setValue("multipleUnits", "0");
  }, []);

  const [formData, setFormData] = useState({});
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});
  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const classes = useStyles();
  const history = useHistory();

  const fillFields = (addressData) => {
    setValue("streetNum", addressData.streetNum);
    setValue("streetName", addressData.streetName);
    setValue("streetType", addressData.streetType);
    setValue("direction", addressData.direction);
    setValue("municipality", addressData.municipality);
    setValue("postalCode", addressData.postalCode);
  };

  const onAddressSelected = (isAddressSelected, addressData) => {
    setIsAddressSelected(isAddressSelected);

    if (isSubmitted) isAddressSelected ? trigger("address") : setError("address", { type: "chooseOption" });

    if (addressData) fillFields(addressData);
    else fillFields(initialAddressData);

    trigger(["streetNum", "streetName", "streetType", "municipality", "province", "postalCode"]);
  };

  const errorAndHelperText = (fieldName, _errorMessages = null) => {
    if (!_errorMessages) _errorMessages = errorMessages;

    let currentError = errors[fieldName];
    let currentErrorMessages = _errorMessages[fieldName];
    if (Array.isArray(fieldName)) {
      currentError = errors;
      currentErrorMessages = _errorMessages;
      for (let field of fieldName) {
        if (field in currentError) currentError = currentError[field];
        else currentError = {};
        if (field in currentErrorMessages) currentErrorMessages = currentErrorMessages[field];
        else currentErrorMessages = {};
      }
      if (Object.keys(currentError).length === 0) currentError = null;
      if (Object.keys(currentErrorMessages).length === 0) currentErrorMessages = null;
    }

    return {
      error: errors && currentError && currentError !== null,
      helperText:
        currentError && currentErrorMessages && "type" in currentError ? currentErrorMessages[currentError.type] : null,
    };
  };

  let formSteps = [
    ...(!props.buildingID
      ? [
          {
            section: "Add Property",
            fieldNames: "unitNum address multipleUnits",
            step: (
              <Grid container spacing={1} className={classes.autocomplete}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" align="center" className={classes.header}>
                    Please type your address
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={Number(isMultiUnit) ? { display: "none" } : {}}>
                  <Controller
                    name="unitNum"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: /^\d{0,7}$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="unitNum"
                        label="Unit #"
                        error={errors.unitNum && errors.unitNum !== null}
                        helperText={errors.unitNum ? errorMessages["unitNum"][errors.unitNum.type] : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={Number(isMultiUnit) ? 12 : 9}>
                  <Controller
                    name="address"
                    control={control}
                    rules={{
                      validate: { chooseOption: () => isAddressSelected },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <GooglePlacesAutocompleteField
                        value={value}
                        onChange={onChange}
                        error={errors.address}
                        onAddressSelected={onAddressSelected}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" align="center" className={classes.header}>
                    Do you want to add multiple units to this building?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="multipleUnits"
                    control={control}
                    rules={{
                      required: true,
                      pattern: /^[10]$/,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <RadioOptions
                        value={isMultiUnit}
                        onChange={(e) => {
                          onChange(e.target.value);
                          setIsMultiUnit(e.target.value);
                        }}
                        GroupStyle={{ position: "relative", flexDirection: "row", justifyContent: "center" }}
                        {...errorAndHelperText("multipleUnits")}
                      >
                        <RadioOption value="1" label="Yes" className={classes.radioOption} />
                        <RadioOption value="0" label="No" className={classes.radioOption} />
                      </RadioOptions>
                    )}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
    {
      section: "Add Property",
      fieldNames: "multiUnitSelector",
      step: (
        <Grid container spacing={1} style={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" align="center" className={classes.header}>
              Add units to this building
            </Typography>
            <Typography gutterBottom variant="h6" align="center" className={classes.subHeader}>
              Units can be added individually or as a range
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MultiUnitSelector
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              errorAndHelperText={errorAndHelperText}
            />
          </Grid>
        </Grid>
      ),
    },
    ...(!props.buildingID
      ? [
          {
            section: "Add Property",
            fieldNames:
              "confirmAddress unitNum streetNum streetName streetType direction municipality province postalCode",
            step: (
              <Grid container spacing={1} className={classes.autocomplete}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" align="center" className={classes.header}>
                    Please confirm that we got the building address correct
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={Number(isMultiUnit) ? { display: "none" } : {}}>
                  <Controller
                    name="unitNum"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: /^\d{0,7}$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="unitNum"
                        label="Unit #"
                        error={errors.unitNum && errors.unitNum !== null}
                        helperText={errors.unitNum ? errorMessages["unitNum"][errors.unitNum.type] : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="streetNum"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^\d{0,7}$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="streetNum"
                        label="Street #"
                        error={errors.streetNum && errors.streetNum !== null}
                        helperText={errors.streetNum ? errorMessages["streetNum"][errors.streetNum.type] : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="streetName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      validate: {
                        length: (value) => value.length <= 90,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="streetName"
                        label="Street Name"
                        error={errors.streetName && errors.streetName !== null}
                        helperText={errors.streetName ? errorMessages["streetName"][errors.streetName.type] : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="streetType"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      validate: {
                        length: (value) => value.length <= 15,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="streetType"
                        label="Street Type"
                        error={errors.streetType && errors.streetType !== null}
                        helperText={errors.streetType ? errorMessages["streetType"][errors.streetType.type] : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="direction"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: /^[NSEW]$/,
                    }}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={errors.direction && errors.direction !== null}
                      >
                        <InputLabel id="directionLabel">Direction</InputLabel>
                        <Select
                          {...field}
                          id="direction"
                          labelId="directionLabel"
                          label="Direction"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          <MenuItem value="">
                            <em>none</em>
                          </MenuItem>
                          <MenuItem value="N">North</MenuItem>
                          <MenuItem value="S">South</MenuItem>
                          <MenuItem value="E">East</MenuItem>
                          <MenuItem value="W">West</MenuItem>
                        </Select>
                        {errors.direction && (
                          <FormHelperText>{errorMessages["direction"][errors.direction.type]}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="municipality"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      validate: {
                        length: (value) => value.length <= 90,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="municipality"
                        label="Municipality"
                        error={errors.municipality && errors.municipality !== null}
                        helperText={
                          errors.municipality ? errorMessages["municipality"][errors.municipality.type] : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="province"
                    control={control}
                    defaultValue="ON"
                    rules={{
                      required: true,
                      pattern: /^ON$/,
                    }}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        error={errors.province && errors.province !== null}
                      >
                        <InputLabel id="provinceLabel">Province</InputLabel>
                        <Select
                          {...field}
                          id="province"
                          labelId="provinceLabel"
                          label="Province *"
                          readOnly
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                        >
                          <MenuItem value="ON">Ontario</MenuItem>
                        </Select>
                        {errors.province && (
                          <FormHelperText>{errorMessages["province"][errors.province.type]}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={Number(isMultiUnit) ? 12 : 6}>
                  <Controller
                    name="postalCode"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="postalCode"
                        label="Postal Code"
                        error={errors.postalCode && errors.postalCode !== null}
                        helperText={errors.postalCode ? errorMessages["postalCode"][errors.postalCode.type] : null}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!formData) return;
    // console.log("FormData: ", formData, "React Form Hooks: ", getValues());

    let skipping = [];

    if (formData?.multipleUnits === "0") skipping.push("multiUnitSelector");

    setStepsToSkip(skipping);
  }, [formData]);

  const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

  const submitForm = async (propertyData) => {
    let confirmCallback = async () => {
      if (getCookieValue("clonedUser")) {
        addToast("Creating Property Failed", "error");
        return false;
      }

      delete propertyData.address;
      let unitNums = [propertyData.unitNum];
      if (isMultiUnit == "1") unitNums = propertyData.unitNums;
      delete propertyData.unitNum;
      delete propertyData.unitNums;
      delete propertyData.multipleUnits;

      let units = [];
      for (let unitObj of unitNums) {
        let unit = [unitObj];
        if (Array.isArray(unitObj))
          unit = Array.from(
            { length: parseInt(unitObj[1]) - parseInt(unitObj[0]) + 1 },
            (_, i) => i + parseInt(unitObj[0])
          );
        for (let unitNum of unit) units.push(unitNum);
      }

      if (props.totalProperties === 0 && units.length === 1) {
        const buildingAdded = await addBuilding(propertyData);
        if (!buildingAdded) {
          addToast("Error While Creating Property. Please Try Again", "error");
          return false;
        }
        for (let unitNum of unitNums) {
          const propertyAdded = await addProperty({ buildingID: buildingAdded["ID"], unitNum: unitNum });
          if (!propertyAdded || propertyAdded === "alreadyExists") {
            addToast(
              !propertyAdded
                ? "Error While Creating Property. Please Try Again"
                : "A Property With This Address And Unit # Already Exists. Please Try Again",
              "error"
            );
            return false;
          }
        }
      } else
        history.push({
          pathname: "/properties/checkout",
          state: {
            buildingID: props.buildingID,
            propertyData,
            unitNums: units,
            totalProperties: props.totalProperties,
          },
        });

      return true;
    };

    props.setConfirmationCallback(confirmCallback);
  };

  return (
    <div className={classes.main}>
      <ToastFactory toasts={toasts} />
      <MultiStepForm
        formSteps={formSteps}
        stepsToSkip={stepsToSkip}
        formData={formData}
        setFormData={setFormData}
        submitForm={submitForm}
        handleSubmit={handleSubmit}
        setOnSubmit={(func) => (onSubmit = func)}
        errorReceived={errorReceived}
        toasts={toasts}
        hideStepper
        classes={{
          container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
          mobilePadding: { padding: "0px" },
        }}
      />
    </div>
  );
}

export default AddPropertyForm;
