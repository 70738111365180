import React, { useState, useEffect } from "react";

import { useForm, Controller, useFormState } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import Collapse from "@material-ui/core/Collapse";

import { addTenant, editTenant, deleteTenant } from "../../../../services/property/tenantServices.js";

import RadioOption from "../../../components/RadioOption";
import RadioOptions from "../../../components/RadioOptions";
import Loading from "../../../components/Loading";
import PhoneNumber from "../../../components/PhoneNumber";
import Dropzone from "../../../components/DropzoneDialog";
import { GooglePlacesAutocompleteField } from "../../../components/googlePlacesAutocomplete";
import TenantList from "./TenantList.jsx";

const errorMessages = {
  firstName: {
    required: "Please enter your first name",
    length: "Your first name cannot exceed 64 characters",
  },
  lastName: {
    required: "Please enter your last name",
    length: "Your last name cannot exceed 64 characters",
  },
  email: {
    required: "Please enter your email",
    pattern: "Your email must be in the correct format, e.g. username@domain.com",
    length: "Your email cannot exceed 254 characters",
  },
  dayPhone: {
    required: "Please enter your day phone number",
    pattern: "Your day phone number must be in the correct format, e.g. (123) 456-7890",
  },
  eveningPhone: {
    pattern: "Your evening phone number must be in the correct format, e.g. (123) 456-7890",
  },
  fax: {
    pattern: "Your fax number must be in the correct format, e.g. (123) 456-7890",
  },
  unitNum: {
    pattern: "Must be between 0 and 9999999",
  },
  driversLicense: {
    required: "Please upload an image file of the tenant's drivers license",
    type: "File type must be jpeg, jpg, or png",
    size: "File size must be smaller than 8MB",
    error: "Unexpected error occured, please upload the file again",
  },
};

const initialAddressData = {
  streetNum: "",
  streetName: "",
  streetType: "",
  direction: "",
  municipality: "",
  province: "",
  postalCode: "",
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "32vh",
    },
  },
  form: {
    width: "100%",
  },
  radioOption: {
    width: "150px",
    margin: "0px 10px",
    maxWidth: "45%",
    [`${theme.breakpoints.down("xs")}`]: {
      margin: "0px 2.5%",
      maxWidth: "45%",
    },
  },
  mailingAddressLabel: {
    margin: theme.spacing(1, 0, 1),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: "1.2em",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

let currentAction = null;

function EditTenantForm(props) {
  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const { isSubmitted } = useFormState({
    control,
  });

  useEffect(() => {
    currentAction = props.action;

    let field;
    for (field in props.tenantItem) {
      if (field === "mailingAddress" && props.tenantItem.mailingAddress) {
        setIsAddressSelected(true);
        setShowMailingAddress(true);
        setValue(field, props.tenantItem.mailingAddress);
      } else if (field === "addressData" && props.tenantItem.addressData) {
        setAddressData(props.tenantItem.addressData);
        if (props.setTenants) {
          let addressData = props.tenantItem.addressData;
          let mailingAddress =
            `${addressData["streetNum"]} ${addressData["streetName"]} ` +
            `${addressData["streetType"]}${addressData["direction"] ? ` ${addressData["direction"]}` : ""}, ` +
            `${addressData["municipality"]}, ${addressData["province"]} ${addressData["postalCode"]}`;

          setValue("mailingAddress", mailingAddress);
        }
      } else if (field !== "ID" && field !== "leaseID" && props.tenantItem[field])
        setValue(field, props.tenantItem[field]);
    }
  }, []);

  const [showMailingAddress, setShowMailingAddress] = useState(false);

  const [addressData, setAddressData] = useState(initialAddressData);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const onAddressSelected = (isAddressSelected, addressData) => {
    setIsAddressSelected(isAddressSelected);
    addressData ? setAddressData(addressData) : setAddressData(initialAddressData);

    if (isSubmitted)
      isAddressSelected ? trigger("mailingAddress") : setError("mailingAddress", { type: "chooseOption" });
  };

  const [driversLicenseDialogOpen, setDriversLicenseDialogOpen] = useState(false);
  const [driversLicense, setDriversLicense] = useState(null);

  const onDriversLicenseUpload = (files) => {
    setDriversLicense(files);
    clearErrors("driversLicense");
    setValue("driversLicense", files.name);
    props.addToast("Drivers License Uploaded", "success");
  };

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  };

  const onSubmit = (tenantData) => {
    let formattedAddress = "";
    if (showMailingAddress)
      formattedAddress = `${addressData.streetNum}, ${addressData.streetName}, ${addressData.streetType}, ${addressData.direction}, ${tenantData.unitNum}, ${addressData.municipality}, ${addressData.province}, ${addressData.postalCode}`;

    let confirmCallback = async (leaseID = null) => {
      tenantData = {
        ...tenantData,
        mailingAddress: formattedAddress,
        driversLicense: driversLicense,
      };
      tenantData["leaseID"] = leaseID ? leaseID : props.leaseID;
      if (props.tenantItem) tenantData["ID"] = props.tenantItem.ID;

      console.log(tenantData);

      let tenantEdited = false;
      if (currentAction === "Add") tenantEdited = await addTenant(tenantData, handleErrors);
      else if (currentAction === "Edit") tenantEdited = await editTenant(tenantData, handleErrors);
      else if (currentAction === "Delet") tenantEdited = await deleteTenant(tenantData, handleErrors);

      if (tenantEdited) return true;

      props.addToast(`${currentAction}ing Tenant Failed`, "error");
      return false;
    };

    if (props.setTenants) {
      confirmCallback = () => {
        props.setTenants((_tenants) => {
          tenantData = {
            ...tenantData,
            ...(addressData
              ? {
                  mailingAddress: formattedAddress,
                  addressData: addressData,
                }
              : {}),
            driversLicense: driversLicense,
          };

          if (currentAction === "Add") {
            let maxID = 0;
            for (let tenant of _tenants) if (tenant["ID"] > maxID) maxID = tenant["ID"];

            tenantData["ID"] = maxID + 1;
            return [..._tenants, tenantData];
          } else if (props.tenantItem) {
            tenantData["ID"] = props.tenantItem.ID;

            for (let i = 0; i < _tenants.length; i++)
              if (_tenants[i]["ID"] == tenantData["ID"]) {
                if (currentAction === "Edit") _tenants[i] = tenantData;
                else _tenants.splice(i, 1);
              }
          }
          return _tenants;
        });
        return true;
      };
    }

    props.setConfirmationCallback(confirmCallback, {
      currentAction: currentAction,
    });
  };

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.main}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: {
                  length: (value) => value.length <= 64,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="firstName"
                  label="First Name"
                  autoFocus
                  error={errors.firstName && errors.firstName !== null}
                  helperText={errors.firstName ? errorMessages["firstName"][errors.firstName.type] : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: {
                  length: (value) => value.length <= 64,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="lastName"
                  label="Last Name"
                  error={errors.lastName && errors.lastName !== null}
                  helperText={errors.lastName ? errorMessages["lastName"][errors.lastName.type] : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
                validate: {
                  length: (value) => value.length <= 254,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="email"
                  label="Email"
                  autoComplete="email"
                  error={errors.email && errors.email !== null}
                  helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="dayPhone"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="dayPhone"
                  label="Day Phone"
                  name="dayPhone"
                  error={errors.dayPhone && errors.dayPhone !== null}
                  helperText={errors.dayPhone ? errorMessages["dayPhone"][errors.dayPhone.type] : null}
                  InputProps={{
                    inputComponent: PhoneNumber,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="eveningPhone"
              control={control}
              defaultValue=""
              rules={{
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="eveningPhone"
                  label="Evening Phone"
                  name="eveningPhone"
                  error={errors.eveningPhone && errors.eveningPhone !== null}
                  helperText={errors.eveningPhone ? errorMessages["eveningPhone"][errors.eveningPhone.type] : null}
                  InputProps={{
                    inputComponent: PhoneNumber,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="fax"
              control={control}
              defaultValue=""
              rules={{
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fax"
                  label="Fax"
                  name="fax"
                  error={errors.fax && errors.fax !== null}
                  helperText={errors.fax ? errorMessages["fax"][errors.fax.type] : null}
                  InputProps={{
                    inputComponent: PhoneNumber,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" align="center" className={classes.mailingAddressLabel}>
              Is the tenant's mailing address different from the property address?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="showMailingAddress"
              control={control}
              rules={{
                pattern: /^[10]$/,
              }}
              render={({ field: { onChange, value } }) => (
                <RadioOptions
                  value={showMailingAddress ? "1" : "0"}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setShowMailingAddress(e.target.value == "1" ? true : false);
                  }}
                  GroupStyle={{ position: "relative", flexDirection: "row", justifyContent: "center" }}
                >
                  <RadioOption value="1" label="Yes" className={classes.radioOption} />
                  <RadioOption value="0" label="No" className={classes.radioOption} />
                </RadioOptions>
              )}
            />
          </Grid>
          <Collapse in={showMailingAddress} style={{ marginLeft: "5px", width: "98%" }}>
            <Grid container spacing={1} style={{ visibility: showMailingAddress ? "visible" : "hidden" }}>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="unitNum"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: /^\d{0,7}$/,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="unitNum"
                      label="Unit #"
                      error={errors.unitNum && errors.unitNum !== null}
                      helperText={errors.unitNum ? errorMessages["unitNum"][errors.unitNum.type] : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Controller
                  name="mailingAddress"
                  control={control}
                  rules={{
                    validate: {
                      chooseOption: () => !showMailingAddress || (showMailingAddress && isAddressSelected),
                    },
                  }}
                  render={({ field: { value } }) =>
                    !props.tenantItem?.mailingAddress ? (
                      <GooglePlacesAutocompleteField
                        error={errors.mailingAddress}
                        onAddressSelected={onAddressSelected}
                      />
                    ) : value ? (
                      <GooglePlacesAutocompleteField
                        error={errors.mailingAddress}
                        onAddressSelected={onAddressSelected}
                        defaultAddress={value}
                      />
                    ) : null
                  }
                />
              </Grid>
            </Grid>
          </Collapse>
          <Dropzone
            fileName={"Tenant's Drivers License"}
            onFileUpload={onDriversLicenseUpload}
            dropzoneDialogOpen={driversLicenseDialogOpen}
            setDropzoneDialogOpen={setDriversLicenseDialogOpen}
          />
          {!props.tenantItem && (
            <Grid item xs={12}>
              <Controller
                name="driversLicense"
                control={control}
                defaultValue="Upload An Image File"
                rules={{
                  validate: {
                    required: () => driversLicense !== null,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onClick={() => setDriversLicenseDialogOpen(true)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="driversLicense"
                    label="Drivers License"
                    error={errors.driversLicense && errors.driversLicense !== null}
                    helperText={
                      errors.driversLicense ? errorMessages["driversLicense"][errors.driversLicense.type] : null
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Upload File" PopperProps={{ style: { marginTop: "-8px" } }}>
                            <IconButton onClick={() => setDriversLicenseDialogOpen(true)}>
                              <UploadIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ style: { cursor: "pointer" } }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                currentAction = props.action;
              }}
            >
              {props.action} Tenant
            </Button>
          </Grid>
          {props.action === "Edit" ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.submit} ${classes.deleteBtn}`}
                onClick={() => {
                  currentAction = "Delet";
                  onSubmit([]);
                }}
              >
                Delete Tenant
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </form>
    </div>
  );
}

export default EditTenantForm;
