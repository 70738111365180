import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect, useState } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

function RadioOptions(props) {
  const useStyles = makeStyles((theme) => ({
    helperText: {
      color: props.error ? theme.palette.error.main : "",
      margin: "10px 14px 0px",
    },
  }));

  const classes = useStyles();

  let [selected, setSelected] = useState(false);
  let [mouseUp, setMouseUp] = useState(false);

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  const childrenWithProps = React.Children.map(props.children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        selected: selected,
        error: props.error,
        setMouseUp: setMouseUp,
        onMouseUp: props.onMouseUp,
      });
    }
    return child;
  });

  return (
    <div className={props.className}>
      <RadioGroup
        name={props.name}
        onChange={(e) => {
          props.onChange(e);
          if (mouseUp && props.onMouseUp) props.onMouseUp(e);
          setMouseUp(false);
        }}
        {...(props.GroupStyle ? { style: props.GroupStyle } : {})}
      >
        {childrenWithProps}
      </RadioGroup>
      <FormHelperText className={classes.helperText}>
        {props.helperText !== null ? props.helperText : ""}
      </FormHelperText>
    </div>
  );
}

export default RadioOptions;
