import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import * as pdfjsLib from "pdfjs-dist";

import { connect } from "react-redux";
import { setLTBFormData } from "../../../../redux/actions/LTBFormActions";

import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import Chip from "@material-ui/core/Chip";

import ToastFactory from "../../../components/ToastFactory";
import CircularProgress from "@material-ui/core/CircularProgress";

import PinchZoom from "../../../../utils/pinchZoom";
import NumberCombInput from "./components/NumberCombInput";

import QuickPinchZoom, { make3dTransformValue, isTouch } from "react-quick-pinch-zoom";
import OtpInput from "react-otp-input";
import SignaturePad from "react-signature-canvas";
import { getFlattenedForm } from "../../../../services/property/form/flattenServices";
import { emailForm } from "../../../../services/property/form/emailServices";

import { getForms, editForm } from "../../../../services/property/form/formServices";
import { addSignature, getUser, getSignature } from "../../../../services/userServices.js";
import { getTenants } from "../../../../services/property/tenantServices";

const errorMessages = {
  email: {
    pattern: "Your email must be in the correct format, e.g. username@domain.com",
    length: "Your email cannot exceed 254 characters",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let nextPageNumber = 1;
let pinchZoomIsSet = false;
let PDFPinchZoom = null;
const defaultDocWidth = 960;

function FormViewer({ history, LTBFormData, setLTBFormData, ...props }) {
  const { property_id } = useParams();

  const {
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);
  const [docWidth, setDocWidth] = useState(defaultDocWidth);
  const [scale, setScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  //Regular PDF States
  const [numPages, setNumPages] = useState(null);
  const [PDFDoc, setPDFDoc] = useState(null);
  const [PDFPage, setPDFPage] = useState(null);
  const [PDFFields, setPDFFields] = useState(null);

  //Schedule of Parties States
  const [numSPPages, setNumSPPages] = useState(null);
  const [SPPDFDoc, setSPPDFDoc] = useState(null);
  const [SPPDFPage, setSPPDFPage] = useState(null);
  const [SPPDFFields, setSPPDFFields] = useState(null);

  const [fieldInfo, setFieldInfo] = useState(
    LTBFormData?.data
      ? LTBFormData.type == "conditionReport"
        ? flattenJSON(JSON.parse(LTBFormData.data))
        : JSON.parse(LTBFormData.data)
      : {}
  );

  const [emailingPDF, setEmailingPDF] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [emails, setEmails] = useState([]);

  const useStyles = makeStyles((theme) => ({
    container: {
      minHeight: "calc(100vh - 96px)",
      padding: "0",
      paddingTop: "40px",
      touchAction: "pan-x pan-y",
      display: "flex",
      flexDirection: "column",
      [`${theme.breakpoints.down("xs")}`]: {
        paddingTop: "0px",
      },
    },
    toolBar: {
      position: "sticky",
      top: "0",
      display: "flex",
      justifyContent: "space-between",
      background: theme.palette.background.default,
      zIndex: "1",
    },
    toolBarIcons: {
      fontSize: "1.2em",
      [`${theme.breakpoints.down("xs")}`]: {
        fontSize: "1em",
      },
    },
    pdfContainer: {
      maxWidth: defaultDocWidth + "px",
      flex: "1",
      "& .kvfysmfp": {
        overflow: "visible!important",
      },
      "& .pinch-zoom-container": {
        overflow: "visible!important",
      },
    },
    pdfPage: {
      "& canvas": {
        width: "100%!important",
        height: "auto!important",
      },
    },
    pdfCheckbox: {
      position: "absolute",
      "& .MuiSvgIcon-root": {
        width: scale + "em",
        height: scale + "em",
      },
    },
    pdfRadio: {
      "& .MuiIconButton-label > div > svg:first-child": {
        background: "white",
      },
    },
    pdfTextFieldInput: {
      color: "black",
      paddingTop: "0",
      paddingBottom: "0",
      height: "100%",
    },
    pdfTextFieldLabel: {
      borderRadius: "0",
      borderColor: "transparent!important",
    },
    menuTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    menuTitleText: {
      padding: theme.spacing(1),
      [`${theme.breakpoints.down("xs")}`]: {
        fontSize: "1em",
      },
    },
    dialogCloseBtn: {
      marginLeft: "-20px",
      position: "absolute",
      top: "10px",
      right: "10px",
      [theme.breakpoints.down("xs")]: {
        right: "0px",
      },
    },
    menuTitleCloseButton: {
      color: theme.palette.grey[500],
    },
    confirmationDialogContainer: {
      padding: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        width: 375,
      },
    },
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(0, 0, 6),
      [`${theme.breakpoints.down("xs")}`]: {
        minHeight: "48vh",
        margin: theme.spacing(0, 0, 3),
      },
    },
    form: {
      width: "100%",
    },
    submit: {
      margin: theme.spacing(3, 0, 0),
      [`${theme.breakpoints.down("xs")}`]: {
        margin: theme.spacing(3, 0, 3),
      },
    },
    emailDialog: {
      padding: theme.spacing(3),
      [`${theme.breakpoints.down("xs")}`]: {
        padding: theme.spacing(2),
      },
    },
    emailsChip: {
      transition: "all var(--tsl-brand-transition-time)",
      margin: theme.spacing(1, 0, 1),
      height: "40px",
      fontSize: "1.2em",
      borderRadius: "30px",
      [`${theme.breakpoints.down("xs")}`]: {
        fontSize: "0.8em",
      },
    },
    addIcon: {
      fontSize: "14px",
      position: "absolute",
      transform: "translateX(40px)",
      transition: "transform .2s ease-in-out",
    },
    emailDialogSubmitBtn: {
      margin: theme.spacing(2, 0, 0),
    },
    deleteBtn: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      "&:hover": {
        backgroundColor: "#aa2e25",
      },
    },
    sigContainer: {
      width: "100%",
      height: "125px",
    },
    sigPad: {
      width: "100%",
      height: "100%",
      borderRadius: "var(--tsl-brand-border-radius)",
      background: "white",
    },
    sigButton: {
      margin: theme.spacing(3, 0, 0),
    },
    sigPadError: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    clearSig: {
      color: "black",
      transition: "transform .2s ease-in-out",
      "&:hover": {
        color: theme.palette.error.main,
      },
    },
  }));
  const classes = useStyles();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    nextPageNumber = 1;
    pinchZoomIsSet = false;
    PDFPinchZoom = null;
    if (!LTBFormData?.templateData) getCachedForm();
    setDefaultEmails();
    document.querySelectorAll("meta")[1].content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";

    return () => {
      document.querySelectorAll("meta")[1].content = "width=device-width, initial-scale=1.0";
    };
  }, []);

  const setDefaultEmails = async () => {
    const userReceived = await getUser(null, {
      admin: props.clonedUser?.id ? true : null,
      clonedUserID: props.clonedUser?.id,
    });
    if (userReceived.signature) userReceived.signature = await getSignature(props.clonedUser?.id);
    setUserSignature(userReceived.signature);

    let emailArray = [userReceived["email"]];
    const tenantsReceived = await getTenants({ leaseID: props.lease["ID"] });
    for (let tenant of tenantsReceived) emailArray.push(tenant["email"]);
    setEmails(emailArray);
  };

  function download(filename, text) {
    var pom = document.createElement("a");
    pom.setAttribute("href", "data:application/pdf;base64," + encodeURIComponent(text));
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  //------------------- Get Cached Form If Applicable ---------------------//
  const getCachedForm = async () => {
    const LTBFormReceived = await getForms({
      leaseID: props.lease["ID"],
      formID: getCookieValue("formID"),
    });
    if (LTBFormReceived) {
      console.log("LTBFormReceived: ", LTBFormReceived);
      setLTBFormData(LTBFormReceived);
      if (LTBFormReceived.signatures) setFormSignatures(JSON.parse(LTBFormReceived.signatures));
    } else {
      console.log("Failed to load LTBFormReceived: ", LTBFormReceived);
      document.cookie = "formID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      history.push(`/${property_id}/forms`);
    }
  };

  //------------------- Get Field Values To Populate PDF Form ---------------------//
  useEffect(() => {
    console.log("LTBFormData effect", LTBFormData);
    if (LTBFormData?.data) {
      let _fieldInfo = JSON.parse(LTBFormData.data);
      console.log("fieldInfo: ", _fieldInfo);

      if (!fieldInfo || Object.keys(fieldInfo).length === 0) {
        if (LTBFormData.type == "conditionReport") setFieldInfo(flattenJSON(_fieldInfo));
        else setFieldInfo({ ..._fieldInfo });
      }

      if (!numSPPages) {
        if (_fieldInfo?.ScheduleOfParties) setNumSPPages(_fieldInfo.ScheduleOfParties.length);
        else setNumSPPages(0);
      }
    }
  }, [LTBFormData]);

  useEffect(() => {
    if (!PDFDoc && numPages) {
      setupPDF(LTBFormData?.templateData, numPages);
      if (LTBFormData?.spTemplateData) setupPDF(LTBFormData?.spTemplateData, 1, true);
    }
  }, [numPages]);

  const setupPDF = async (templateData, _numPages, isScheduleOfParties = false) => {
    let PDFDoc_ = await pdfjsLib.getDocument(templateData ? "data:image/png;base64, " + templateData : "").promise;
    let PDFFields_ = [];
    for (let i = 1; i <= _numPages; i++) {
      let PDFPage_ = await PDFDoc_.getPage(i);
      let pageAnnotations = await PDFPage_.getAnnotations();
      PDFFields_.push(pageAnnotations.slice());
    }

    if (!isScheduleOfParties) {
      setPDFFields(PDFFields_);
      setPDFDoc(PDFDoc_);
      updatePage(PDFDoc_);
    } else {
      console.log("SPPDFFields: ", PDFFields_[0]);
      setSPPDFFields(PDFFields_[0]);
      setSPPDFDoc(PDFDoc_);
      updateSPPage(PDFDoc_);
    }
  };

  useEffect(() => {
    if (PDFDoc && numPages) {
      if (pageNumber <= numPages) updatePage(PDFDoc);
    }
  }, [numPages, pageNumber]);

  const updatePage = async (PDFDoc_) => setPDFPage(await PDFDoc_.getPage(pageNumber));
  const updateSPPage = async (PDFDoc_) => setSPPDFPage(await PDFDoc_.getPage(1));

  //------------------- PDF Scaling ---------------------//
  const onZoomUpdate = (object) => {
    if (!object || !object.el) return;
    //console.log("PDFPinchZoom", PDFPinchZoom);
    let pinchZoomContainer = object.el.closest(".pinch-zoom-container");
    //console.log("Attempting to change ", pinchZoomContainer.style.height, " to ", object.el.getBoundingClientRect().height);
    if (!pinchZoomContainer) return;
    pinchZoomContainer.style.height = object.el.getBoundingClientRect().height + "px";
    setDocWidth(pinchZoomContainer.offsetWidth);
    //console.log("Setting scale to: ", pinchZoomContainer.offsetWidth, "/", defaultDocWidth, "=", pinchZoomContainer.offsetWidth/defaultDocWidth)
    setScale(pinchZoomContainer.offsetWidth / defaultDocWidth);
    //console.log("Change made: ", pinchZoomContainer.style.height, "Reality: ", pinchZoomContainer.offsetHeight);
  };

  const onDoubleTap = (object) => {
    object.el.style.transform.replace(/(translate(?:3d)?\([^,]*,)([^,]*)((?:,[^,]*)?\))/, "$1 0px$3");
  };

  const onPinchUpdate = useCallback(({ x, y, scale }) => {
    if (!PDFPinchZoom || !PDFPinchZoom?.el || !isTouch()) return;

    const value = make3dTransformValue({ x, y, scale });

    PDFPinchZoom.el.style.setProperty("transform", value);
  }, []);

  const [userSignature, setUserSignature] = useState(null);
  const [formSignatures, setFormSignatures] = useState({});
  const [signatureError, setSignatureError] = useState(false);
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [clearSignatureDialog, setClearSignatureDialog] = useState(false);
  const [signatureConfirmationDialog, setSignatureConfirmationDialog] = useState(false);
  const SigPad = useRef(null);

  const updateSignature = async (type, fieldName, signature) => {
    if (type === "user") {
      await addSignature({
        signature: signature,
      });
      setUserSignature(signature);
      if (formSignatures[fieldName])
        await editForm({
          ID: LTBFormData.ID,
          leaseID: props.lease["ID"],
          data: { signature: { [fieldName]: "" } },
        });
    } else if (type === "form") {
      await editForm({
        ID: LTBFormData.ID,
        leaseID: props.lease["ID"],
        data: { signature: { [fieldName]: signature } },
      });

      let newFormSignatures = { ...formSignatures };
      newFormSignatures[fieldName] = signature;
      setFormSignatures(newFormSignatures);
    }

    setSignatureDialogOpen(false);
    setSignatureConfirmationDialog(false);
    setClearSignatureDialog(false);
    addToast("Signature Successfully Updated", "success");
  };

  //-------------------- Save PDF Button -----------------------//
  const handleSavePDF = async (showSuccessToast = true) => {
    let data = {
      ID: LTBFormData.ID,
      leaseID: props.lease["ID"],
      data: fieldInfo,
    };

    const LTBFormEdited = await editForm(data);
    if (!LTBFormEdited || !LTBFormEdited["ID"]) {
      addToast("An Error Occured While Saving This Form", "error");
      return false;
    }

    if (showSuccessToast) addToast("Changes Saved Successfully", "success");
    console.log("LTBFormEdited:", LTBFormEdited);
    return true;
  };

  const signatureDialog = (
    <>
      <Dialog
        open={signatureConfirmationDialog !== false}
        onClose={() => setSignatureConfirmationDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Do you want to
                {!userSignature ? " save this signature to your profile" : " update the signature on your profile"}?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                {!userSignature
                  ? "All the forms you create in the future will have your signature auto filled for you"
                  : "This signature will overwrite the current signature on your profile and be used on all future forms"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={() => updateSignature("user", signatureConfirmationDialog, SigPad.current.toDataURL())}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={() => updateSignature("form", signatureConfirmationDialog, SigPad.current.toDataURL())}
                color="white"
                variant="contained"
              >
                Only use for this form
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={signatureDialogOpen !== false}
        onClose={() => setSignatureDialogOpen(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle disableTypography className={classes.menuTitle}>
          <Typography variant="h6" className={classes.menuTitleText}>
            Edit Signature
          </Typography>
          <IconButton className={classes.menuTitleCloseButton} onClick={() => setSignatureDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.main}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.sigContainer}>
                <SignaturePad
                  minDistance={2.5}
                  maxWidth={2.5}
                  canvasProps={{
                    className: `${classes.sigPad} ${signatureError ? classes.sigPadError : ""}`,
                  }}
                  ref={SigPad}
                  onBegin={() => {
                    if (signatureError) setSignatureError(false);
                  }}
                />
              </Grid>
              {signatureError && (
                <Grid item xs={12} style={{ margin: "-3px 0px" }}>
                  <Typography variant="body2" color="error">
                    Your signature cannot be blank
                  </Typography>
                </Grid>
              )}

              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.sigButton}
                  onClick={() => {
                    let data = SigPad.current.toData();
                    if (data) {
                      data.pop();
                      SigPad.current.fromData(data);
                    }
                  }}
                >
                  Undo
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.sigButton}
                  onClick={() => SigPad.current.clear()}
                >
                  Clear
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    if (!SigPad?.current?.isEmpty()) {
                      if (!signatureDialogOpen.toLowerCase().includes("tenant"))
                        setSignatureConfirmationDialog(signatureDialogOpen);
                      else updateSignature("form", signatureDialogOpen, SigPad.current.toDataURL());
                    } else setSignatureError(true);
                  }}
                >
                  Edit Signature
                </Button>
              </Grid>

              {userSignature && formSignatures[signatureDialogOpen] && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      updateSignature("form", signatureDialogOpen, "");
                    }}
                  >
                    Use Profile Signature
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={clearSignatureDialog !== false}
        onClose={() => setClearSignatureDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Are you sure you want to clear this signature?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                This signature field will be left blank
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={() => updateSignature("form", clearSignatureDialog, "clear")}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={() => setClearSignatureDialog(false)}
                variant="contained"
                className={classes.deleteBtn}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />

      <Dialog
        classes={{ paper: classes.emailDialog }}
        open={emailDialog !== false}
        onClose={() => setEmailDialog(false)}
        TransitionComponent={Transition}
        PaperProps={{
          ...(emailingPDF
            ? {
                style: {
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "40px",
                },
              }
            : {}),
        }}
      >
        {emailingPDF ? (
          <CircularProgress style={{ width: "80px", height: "80px" }} />
        ) : (
          <>
            <DialogTitle disableTypography className={classes.menuTitle}>
              <Typography
                variant="h6"
                className={classes.menuTitleText}
                style={{ textAlign: "center", marginBottom: "-8px" }}
              >
                Do you want this form to be sent to the following email addresses?
              </Typography>
              <IconButton
                className={`${classes.menuTitleCloseButton} ${classes.dialogCloseBtn}`}
                onClick={() => setEmailDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1} style={{ textAlign: "center", marginBottom: "20px" }} justifyContent="center">
                {emails.map((email, index) => (
                  <Grid key={`email${index}`} item xs={12}>
                    <Chip
                      label={email}
                      variant="default"
                      onDelete={() => setEmails(emails.filter((currEmail) => currEmail !== email))}
                      className={classes.emailsChip}
                    />
                  </Grid>
                ))}

                <Grid item xs={12} sm={9} style={{ padding: "0px 14px" }}>
                  <form noValidate>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        pattern: /^\S+@\S+\.\S+$/,
                        validate: {
                          length: (value) => value.length <= 254,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          autoComplete="email"
                          autoFocus
                          error={errors.email && errors.email !== null}
                          helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
                        />
                      )}
                    />
                    <Button
                      fullWidth
                      onClick={() => {
                        handleSubmit((formData) => {
                          if (formData.email) {
                            setEmails([...emails, formData.email]);
                            setValue("email", "");
                            clearErrors("email");
                          }
                        })();
                      }}
                      color="white"
                      variant="contained"
                      style={{ marginTop: "8px" }}
                    >
                      Add Email
                      <AddIcon className={classes.addArrow} />
                    </Button>
                  </form>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} style={{ padding: "0px 10px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.emailDialogSubmitBtn}
                    onClick={async () => {
                      setEmailingPDF(true);

                      await handleSavePDF(false);
                      const flattenedLTBForm = JSON.parse(
                        await getFlattenedForm({
                          emails: emails,
                          formID: LTBFormData.ID,
                          leaseID: props.lease["ID"],
                        })
                      );

                      if (!flattenedLTBForm || !("success" in flattenedLTBForm))
                        addToast("An Error Occured While Emailing This Form", "error");

                      addToast("Form Was Emailed Successfully", "success");
                      console.log("flattenedLTBForm: ", flattenedLTBForm);
                      // download(LTBFormData.type.toUpperCase() + " Form.pdf", flattenedLTBForm);

                      setEmailingPDF(false);
                      setEmailDialog(false);
                    }}
                  >
                    Confirm &amp; Send
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: "0px 10px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={`${classes.emailDialogSubmitBtn} ${classes.deleteBtn}`}
                    onClick={() => {
                      setEmailDialog(false);
                    }}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Container
        className={classes.container}
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <div className={classes.toolBar}>
          <div>
            <Tooltip title="Prev Page" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton
                onClick={() => {
                  let _nextPageNumber = pageNumber;

                  if (nextPageNumber > 1) {
                    nextPageNumber--;
                    _nextPageNumber = nextPageNumber;

                    setTimeout(() => {
                      if (nextPageNumber == _nextPageNumber) setPageNumber(nextPageNumber);
                    }, 200);
                  }
                }}
              >
                <ArrowBackIosRoundedIcon className={classes.toolBarIcons} />
              </IconButton>
            </Tooltip>
            <Typography variant="body1" component={"span"}>
              Page {pageNumber} of {numSPPages ? numPages + numSPPages : numPages}
            </Typography>
            <Tooltip title="Next Page" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton
                onClick={() => {
                  let _nextPageNumber = pageNumber;

                  if (nextPageNumber < (numSPPages ? numPages + numSPPages : numPages)) {
                    nextPageNumber++;
                    _nextPageNumber = nextPageNumber;

                    setTimeout(() => {
                      if (nextPageNumber == _nextPageNumber) setPageNumber(nextPageNumber);
                    }, 200);
                  }
                }}
              >
                <ArrowForwardIosRoundedIcon className={classes.toolBarIcons} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Save Form" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton onClick={() => handleSavePDF()}>
                <SaveRoundedIcon className={classes.toolBarIcons} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Form" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton
                onClick={async () => {
                  await handleSavePDF(false);
                  const flattenedLTBForm = await getFlattenedForm({
                    formID: LTBFormData.ID,
                    leaseID: props.lease["ID"],
                  });

                  if (!flattenedLTBForm) addToast("An Error Occured While Downloading This Form", "error");

                  addToast("Form Will Begin Downloading Momentarily", "success");
                  //console.log("flattenedLTBForm: ", flattenedLTBForm);
                  download(LTBFormData.type.toUpperCase() + " Form.pdf", flattenedLTBForm);
                }}
              >
                <GetAppRoundedIcon className={classes.toolBarIcons} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Email Form" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
              <IconButton
                onClick={() => {
                  setEmailDialog(true);
                }}
              >
                <EmailRoundedIcon className={classes.toolBarIcons} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className={classes.pdfContainer}>
          <QuickPinchZoom onUpdate={onPinchUpdate}>
            <div
              ref={(node) => {
                if (node) {
                  if (!pinchZoomIsSet) {
                    let pinchZoomContainer = node.closest(".pinch-zoom-container");
                    if (pinchZoomContainer) {
                      pinchZoomContainer.parentNode.appendChild(node);
                      pinchZoomContainer.remove();
                    }
                    PDFPinchZoom = new PinchZoom(node, {
                      onZoomUpdate: (object) => onZoomUpdate(object),
                      onDoubleTap: (object) => onDoubleTap(object),
                    });
                  }
                  pinchZoomIsSet = true;
                  onZoomUpdate({ el: node });
                  window.addEventListener("resize", () => onZoomUpdate({ el: node }));
                }
              }}
            >
              <Document
                file={
                  LTBFormData?.templateData
                    ? "data:image/png;base64, " +
                      (pageNumber <= numPages || !numPages ? LTBFormData.templateData : LTBFormData.spTemplateData)
                    : ""
                }
                onLoadSuccess={pageNumber <= numPages || !numPages ? onDocumentLoadSuccess : null}
                loading=""
                noData=""
              >
                <Page
                  pageNumber={pageNumber <= numPages ? pageNumber : 1}
                  renderTextLayer={false}
                  width={defaultDocWidth > window.screen.width ? defaultDocWidth : window.screen.width}
                  loading=""
                  className={classes.pdfPage}
                  onLoadSuccess={() => onZoomUpdate(PDFPinchZoom)}
                  noData=""
                />
              </Document>

              {(() => {
                /*console.log("PDFPage: ", PDFPage, " PDFFields: ", PDFFields)*/
              })()}
              {PDFPage && PDFFields ? (
                <div style={{ position: "absolute", top: "0" }}>
                  {(pageNumber <= numPages ? PDFFields[pageNumber - 1] : SPPDFFields).map((field) => {
                    if (!field || !field["fieldName"] || field["fieldName"].toLowerCase().includes("office"))
                      return <div key={Math.random().toString(36).substr(2, 5)}></div>;

                    if (field["fieldType"] === "Tx") {
                      let mobileShift = docWidth < 770 ? (docWidth - 770) * 0.012 : 0;
                      let fieldLeft = (field["rect"][0] * 1.57 - 5) * scale + mobileShift;
                      let fieldTop =
                        (PDFPage["_pageInfo"]["view"][3] * 1.57 - field["rect"][3] * 1.57 - 2) * scale + mobileShift;
                      let fieldWidth = (field["rect"][2] - field["rect"][0]) * 1.6 * scale;
                      let fieldHeight = (field["rect"][3] - field["rect"][1]) * 1.52 * scale;
                      //console.log(field["fieldName"] + ": " + fieldInfo[field["fieldName"]]);

                      if (
                        includesPhrase(field["fieldName"], ["signature", "signname"]) &&
                        !includesPhrase(field["fieldName"], ["date"])
                      ) {
                        const fieldName = field["fieldName"].toLowerCase();
                        if (fieldName === "signature") {
                          if (LTBFormData?.type === "n1" || LTBFormData?.type === "n2") {
                            fieldTop -= 2;
                            fieldHeight += 8;
                            fieldWidth += 1;
                          } else if (LTBFormData?.type === "n12" || LTBFormData?.type === "n13") {
                            fieldTop -= 7;
                            fieldHeight *= 1.5;
                            fieldWidth += 9;
                          } else if (LTBFormData?.type === "conditionReport") {
                            fieldTop += 18;
                            fieldLeft += 16;
                            fieldWidth += 9;
                          } else {
                            fieldTop -= 13;
                            fieldHeight *= 1.71;
                            fieldWidth += 9;
                          }
                          fieldTop -= mobileShift * 2;
                        } else if (fieldName === "signname") {
                          fieldTop += 12 * scale;
                          fieldHeight -= 15 * scale;
                          fieldLeft += 5;
                          fieldWidth -= 10;
                          fieldTop -= mobileShift;
                        } else if (fieldName === "cardholdersignature") {
                          fieldTop += 1;
                          fieldHeight += 6;
                          fieldWidth -= 2;
                        }

                        const formSignatureImage = formSignatures[field["fieldName"]];
                        return (
                          <div
                            key={field["fieldName"]}
                            style={{
                              position: "absolute",
                              left: fieldLeft,
                              top: fieldTop,
                              width: fieldWidth,
                              height: fieldHeight,
                            }}
                          >
                            {signatureDialog}
                            {(userSignature &&
                              formSignatureImage !== "clear" &&
                              !includesPhrase(field["fieldName"], ["tenant", "signature2"])) ||
                            (formSignatureImage && formSignatureImage !== "clear") ? (
                              <>
                                <Tooltip
                                  title="Clear Signature"
                                  placement="top"
                                  PopperProps={{ style: { marginBottom: "-8px" } }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: -10 * scale + "px",
                                    }}
                                    onClick={() => setClearSignatureDialog(field["fieldName"])}
                                  >
                                    <CloseIcon
                                      className={classes.clearSig}
                                      style={{
                                        fontSize: Math.round(16 * scale) + "px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <div
                                  onClick={() => setSignatureDialogOpen(field["fieldName"])}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src={formSignatureImage ? formSignatureImage : userSignature}
                                    alt={"signature"}
                                    role="button"
                                    style={{
                                      maxWidth: fieldWidth,
                                      height: fieldHeight,
                                      objectFit: "contain",
                                      ...(LTBFormData?.type === "conditionReport"
                                        ? {
                                            position: "relative",
                                            top: 15 + "px",
                                          }
                                        : {}),
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <div
                                onClick={() => setSignatureDialogOpen(field["fieldName"])}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        );
                      } else if (
                        field["comb"] &&
                        !includesPhrase(field["fieldName"], ["date"]) &&
                        includesPhrase(field["fieldName"], ["owe", "paid", "charge", "amount", "fee", "pay", "cost"])
                      ) {
                        fieldTop += 2 - mobileShift / 1.5;
                        fieldLeft += 5 - mobileShift / 2;
                        fieldWidth -= fieldWidth * 0.02;
                        return (
                          <NumberCombInput
                            key={field["fieldName"]}
                            numInputs={field["maxLen"]}
                            inputProps={{
                              style: {
                                height: "100%",
                                width: fieldWidth / field["maxLen"],
                                padding: "0",
                                fontSize: Math.round(16 * scale) + "px",
                                textAlign: "center",
                                background: "transparent",
                                outline: "none",
                                borderColor: "black",
                                borderWidth: "1px",
                                borderRadius: "0px",
                              },
                            }}
                            style={{
                              position: "absolute",
                              left: fieldLeft,
                              top: fieldTop,
                              width: fieldWidth,
                              height: fieldHeight,
                            }}
                            name={field["fieldName"] ? field["fieldName"] : ""}
                            value={
                              field["fieldName"]
                                ? pageNumber <= numPages
                                  ? fieldInfo[field["fieldName"]]
                                  : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]
                                : ""
                            }
                            onChange={(value) => {
                              let _fieldInfo = fieldInfo;
                              if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = value;
                              else _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] = value;
                              setFieldInfo({ ..._fieldInfo });
                            }}
                          />
                        );
                      } else if (field["comb"]) {
                        fieldTop += 2 - mobileShift / 1.5;
                        fieldLeft += 5 - mobileShift / 2;
                        fieldWidth -= fieldWidth * 0.02;
                        return (
                          <OtpInput
                            key={field["fieldName"]}
                            numInputs={field["maxLen"]}
                            inputStyle={{
                              height: "100%",
                              width: fieldWidth / field["maxLen"],
                              padding: "0",
                              fontSize: Math.round(16 * scale) + "px",
                              textAlign: "center",
                              background: "white",
                              outline: "none",
                              borderColor: "black",
                              borderWidth: "1px",
                              borderRadius: "0px",
                            }}
                            containerStyle={{
                              position: "absolute",
                              left: fieldLeft,
                              top: fieldTop,
                              width: fieldWidth,
                              height: fieldHeight,
                            }}
                            name={field["fieldName"] ? field["fieldName"] : ""}
                            value={
                              field["fieldName"]
                                ? pageNumber <= numPages
                                  ? fieldInfo[field["fieldName"]]
                                  : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]
                                : ""
                            }
                            onChange={(value) => {
                              let _fieldInfo = fieldInfo;
                              if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = value;
                              else _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] = value;
                              console.log("Updating fieldInfo: ", _fieldInfo);
                              setFieldInfo({ ..._fieldInfo });
                            }}
                          />
                        );
                      }

                      fieldWidth += -fieldWidth * 0.02 + 9;
                      if (LTBFormData?.type == "conditionReport") {
                        fieldTop += (9 * (fieldTop / 370) + 4) * scale - mobileShift * 1.2;
                        fieldLeft += 13 * scale - mobileShift / 1.5;
                        fieldWidth -= 2 / scale;
                      }

                      return (
                        <TextField
                          key={field["fieldName"]}
                          variant="outlined"
                          multiline={isMultiline(field)}
                          minRows={fieldHeight > 150 ? Math.floor(fieldHeight / 20) : null}
                          inputProps={{
                            className: classes.pdfTextFieldInput,
                            style: {
                              maxHeight: fieldHeight,
                              fontSize: Math.round(16 * scale) + "px",
                              lineHeight: 1 + 0.43 * (isMultiline(field) ? scale : 1),
                            },
                          }}
                          InputProps={{
                            style: { height: "100%" },
                            classes: {
                              notchedOutline: classes.pdfTextFieldLabel,
                              focused: classes.pdfTextFieldLabel,
                            },
                          }}
                          style={{
                            position: "absolute",
                            left: fieldLeft,
                            top: fieldTop,
                            width: fieldWidth,
                            height: fieldHeight,
                          }}
                          name={field["fieldName"] ? field["fieldName"] : ""}
                          value={
                            field["fieldName"]
                              ? pageNumber <= numPages
                                ? fieldInfo[field["fieldName"]]
                                : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]
                              : ""
                          }
                          onChange={(e) => {
                            let _fieldInfo = fieldInfo;
                            if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = e.target.value;
                            else
                              _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] =
                                e.target.value;
                            setFieldInfo({ ..._fieldInfo });
                          }}
                        />
                      );
                    } else if (field["fieldType"] === "Btn" && field["checkBox"]) {
                      let mobileShift = docWidth < 770 ? (docWidth - 770) * 0.015 : 0;
                      let fieldLeft = (field["rect"][0] * 1.57 - 12.5) * scale + mobileShift;
                      let fieldTop =
                        (PDFPage["_pageInfo"]["view"][3] * 1.57 - field["rect"][3] * 1.57 - 12.5) * scale + mobileShift;
                      return (
                        <Checkbox
                          key={field["fieldName"]}
                          color="primary"
                          style={{ left: fieldLeft, top: fieldTop }}
                          className={classes.pdfCheckbox}
                          checked={Boolean(
                            pageNumber <= numPages
                              ? fieldInfo[field["fieldName"]]
                              : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]
                          )}
                          onChange={(e) => {
                            let _fieldInfo = fieldInfo;
                            if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = e.target.checked;
                            else
                              _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] =
                                e.target.checked;
                            setFieldInfo({ ..._fieldInfo });
                          }}
                        />
                      );
                    } else if (field["fieldType"] === "Btn" && field["radioButton"]) {
                      let mobileShift = docWidth < 770 ? (docWidth - 770) * 0.015 : 0;
                      let fieldLeft = (field["rect"][0] * 1.57 - 12.5) * scale + mobileShift;
                      let fieldTop =
                        (PDFPage["_pageInfo"]["view"][3] * 1.57 - field["rect"][3] * 1.57 - 12.5) * scale + mobileShift;

                      if (LTBFormData.type == "leaseAgreement" || LTBFormData?.type.charAt(0).toUpperCase() === "L")
                        return (
                          <Checkbox
                            key={field["id"]}
                            color="primary"
                            style={{ left: fieldLeft, top: fieldTop }}
                            className={classes.pdfCheckbox}
                            checked={
                              (pageNumber <= numPages
                                ? fieldInfo[field["fieldName"]]
                                : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]) ==
                              field["buttonValue"]
                            }
                            onChange={(e) => {
                              let _fieldInfo = fieldInfo;
                              if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = field["buttonValue"];
                              else
                                _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] =
                                  field["buttonValue"];
                              setFieldInfo({ ..._fieldInfo });
                            }}
                          />
                        );

                      return (
                        <Radio
                          key={field["id"]}
                          color="primary"
                          style={{ left: fieldLeft, top: fieldTop }}
                          className={classes.pdfCheckbox}
                          classes={{ checked: classes.pdfRadio }}
                          checked={
                            (pageNumber <= numPages
                              ? fieldInfo[field["fieldName"]]
                              : fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]]) ===
                            field["buttonValue"]
                          }
                          onChange={(e) => {
                            let _fieldInfo = fieldInfo;
                            if (pageNumber <= numPages) _fieldInfo[field["fieldName"]] = field["buttonValue"];
                            else
                              _fieldInfo.ScheduleOfParties[pageNumber - numPages - 1][field["fieldName"]] =
                                field["buttonValue"];
                            setFieldInfo({ ..._fieldInfo });
                          }}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </QuickPinchZoom>
        </div>
      </Container>
    </>
  );
}

const isMultiline = (field) => {
  return (
    field["multiLine"] &&
    (field["fieldName"].toLowerCase().includes("datetime") || !field["fieldName"].toLowerCase().includes("date"))
  );
};

const includesPhrase = (str, strArray) => {
  for (let substr of strArray) {
    if (str.toLowerCase().includes(substr)) return true;
  }
  return false;
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const flattenJSON = (items) => {
  if (typeof items == "object") {
    let flattenedItems = {};
    for (let [key, value] of Object.entries(items)) {
      let flattenedValue = flattenJSON(value);

      if (typeof flattenedValue == "object") {
        for (let [flatkey, flatvalue] of Object.entries(flattenedValue)) {
          flattenedItems[
            key +
              "[" +
              flatkey.split("[")[0] +
              "]" +
              (flatkey.indexOf("[") >= 0 ? flatkey.substring(flatkey.indexOf("[")) : "")
          ] = flatvalue;
        }
      } else if (typeof flattenedValue == "array") {
        for (let [flatkey, flatvalue] of flattenedValue.entries()) {
          flattenedItems[
            key +
              "[" +
              flatkey.split("[")[0] +
              "]" +
              (flatkey.indexOf("[") >= 0 ? flatkey.substring(flatkey.indexOf("[")) : "")
          ] = flatvalue;
        }
      } else flattenedItems[key] = flattenedValue;
    }

    return flattenedItems;
  } else if (typeof items == "array") {
    let flattenedItems = {};
    for (let [key, value] of items.entries()) {
      let flattenedValue = flattenJSON(value);

      if (typeof flattenedValue == "object") {
        for (let [flatkey, flatvalue] of Object.entries(flattenedValue)) {
          flattenedItems[
            key +
              "[" +
              flatkey.split("[")[0] +
              "]" +
              (flatkey.indexOf("[") >= 0 ? flatkey.substring(flatkey.indexOf("[")) : "")
          ] = flatvalue;
        }
      } else if (typeof flattenedValue == "array") {
        for (let [flatkey, flatvalue] of flattenedValue.entries()) {
          flattenedItems[
            key +
              "[" +
              flatkey.split("[")[0] +
              "]" +
              (flatkey.indexOf("[") >= 0 ? flatkey.substring(flatkey.indexOf("[")) : "")
          ] = flatvalue;
        }
      } else flattenedItems[key] = flattenedValue;
    }

    return flattenedItems;
  } else return items;
};

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    LTBFormData: state.LTBForm.data,
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(FormViewer);
