import { useEffect, useState } from "react";
import * as basicScroll from "basicscroll";

function ReactBasicScrollPro({ children, configs, ...props }) {
  const [refElement, setRefElement] = useState(null);
  const [depth, setDepth] = useState(props.depth ? props.depth : 0);
  const [animationInstance, setAnimationInstance] = useState(null);

  useEffect(() => {
    let _refElement = props.refElement ? props.refElement : refElement;
    if (!_refElement || configs.length <= depth) return;

    let _currentConfig = { ...configs[depth] };

    let refBoundingBox = _refElement.getBoundingClientRect();
    let bodyBoundingBox = document.body.getBoundingClientRect();

    if ("relativeFrom" in _currentConfig) {
      _currentConfig.from = `${Math.round(
        _currentConfig.relativeFrom * window.innerHeight + (refBoundingBox.top - bodyBoundingBox.top)
      )}px`;
      delete _currentConfig.relativeFrom;
    }
    if ("relativeTo" in _currentConfig) {
      _currentConfig.to = `${Math.round(
        _currentConfig.relativeTo * window.innerHeight + (refBoundingBox.bottom - bodyBoundingBox.top)
      )}px`;
      delete _currentConfig.relativeTo;
    }

    if (animationInstance) animationInstance.destroy();
    //console.log("refElement: ", _refElement, "_currentConfig", _currentConfig);
    let instance = basicScroll.create(_currentConfig);
    setAnimationInstance(instance);
    instance.start();
  }, [props.refElement ? props.refElement : refElement]);

  useEffect(() => {
    return () => {
      if (animationInstance) animationInstance.destroy();
    };
  }, []);

  return configs.length > depth ? (
    <ReactBasicScrollPro
      configs={configs}
      refElement={props.refElement ? props.refElement : refElement}
      setRefElement={props.setRefElement ? props.setRefElement : setRefElement}
      depth={depth + 1}
    >
      {(newRef) => children(newRef)}
    </ReactBasicScrollPro>
  ) : (
    children((node) => {
      //console.log("REF Function Running: ", node);
      if (props.setRefElement) props.setRefElement(node);
      else setRefElement(node);
    })
  );
}

export default ReactBasicScrollPro;
