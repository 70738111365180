import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import AssessmentRoundedIcon from "@material-ui/icons/AssessmentRounded";
import { Container, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { getROIData } from "../../../services/property/roiServices";

import { connect } from "react-redux";

import ToastFactory from "../../components/ToastFactory";
import ROISetup from "./ROISetup";

import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 96px)",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
    paddingTop: "40px",
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "30px",
    paddingTop: "20px",
  },
  tabsGrid: {
    marginBottom: "60px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "40px",
    },
  },
  tabs: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "20px",
  },
  sectionTitle: {
    fontWeight: "600",
  },
  sectionNumber: {
    fontWeight: "500",
    color: "#64dc16",
    paddingBottom: "10px",
  },
  arrowIcon: {
    marginBottom: "-4px",
  },
  roiChart: {
    "& .recharts-tooltip-wrapper": {
      borderRadius: "20px",
    },
    "& .recharts-default-tooltip": {
      background: theme.palette.background.default + "!important",
      border: "none!important",
      borderRadius: "20px",
    },
  },
  noROIData: {
    textAlign: "center",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "30px",
  },
  noROIDataIcon: {
    position: "relative",
    display: "inline-block",
    color: "white",
  },
}));

const maxBarsInChart = 8;
function ROI({ history, ...props }) {
  const classes = useStyles();

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [currentChart, setCurrentChart] = useState("ROI");

  const [quarterlyROI, setQuarterlyROI] = useState([]);
  const [ROIDomain, setROIDomain] = useState([]);

  const [monthlyValue, setMonthlyValue] = useState([]);
  const [valueDomain, setValueDomain] = useState([]);

  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [amortizationDomain, setAmortizationDomain] = useState([]);

  const [monthlyCashflow, setMonthlyCashflow] = useState([]);
  const [cashflowDomain, setCashflowDomain] = useState([]);
  useEffect(() => {
    if (props.property?.initialPropertyValue) setupROIData();
  }, []);

  const setupROIData = async () => {
    let ROIData_ = await getROIData(props.building, props.property, props.lease);
    console.log("ROIData: ", ROIData_);

    if (ROIData_ && ROIData_.quarterlyROI) {
      let lastROIPeriod =
        ROIData_.quarterlyROI.length > maxBarsInChart
          ? ROIData_.quarterlyROI.slice(-maxBarsInChart)
          : ROIData_.quarterlyROI;
      let quarterlyROI_ = [];

      let minValue = Infinity;
      let maxValue = -Infinity;

      for (let ROIPoint of lastROIPeriod) {
        if (parseFloat(ROIPoint.return) < minValue) minValue = parseFloat(ROIPoint.return);
        if (parseFloat(ROIPoint.return) > maxValue) maxValue = parseFloat(ROIPoint.return);

        quarterlyROI_.push({
          Date: "Q" + moment(ROIPoint.date).quarter() + " " + moment(ROIPoint.date).format("YY"),
          Return: ROIPoint.return,
        });
      }
      setROIDomain([Math.floor(minValue * 0.2), Math.ceil(maxValue + 50)]);
      setQuarterlyROI(quarterlyROI_);
    }

    if (ROIData_ && ROIData_.monthlyValue) {
      let lastValuePeriod =
        ROIData_.monthlyValue.length > maxBarsInChart
          ? ROIData_.monthlyValue.slice(-maxBarsInChart)
          : ROIData_.monthlyValue;
      let monthlyValue_ = [];

      let minValue = Infinity;
      let maxValue = -Infinity;

      for (let valuePoint of lastValuePeriod) {
        if (parseFloat(valuePoint.propertyValue) < minValue) minValue = parseFloat(valuePoint.propertyValue);
        if (parseFloat(valuePoint.propertyValue) > maxValue) maxValue = parseFloat(valuePoint.propertyValue);

        monthlyValue_.push({
          Date: "Q" + moment(valuePoint.date).quarter() + " " + moment(valuePoint.date).format("YY"),
          Value: valuePoint.propertyValue,
        });
      }
      setValueDomain([Math.floor(minValue * 0.9), Math.ceil(maxValue + 50)]);
      setMonthlyValue(monthlyValue_);
    }

    if (ROIData_ && ROIData_.amortizationSchedule) {
      let lastAmoritizationPeriod =
        ROIData_.amortizationSchedule.length > maxBarsInChart
          ? ROIData_.amortizationSchedule.slice(-maxBarsInChart)
          : ROIData_.amortizationSchedule;
      let amortizationSchedule_ = [];

      let minBalance = Infinity;
      let maxBalance = -Infinity;

      for (let amoritizationPoint of lastAmoritizationPeriod) {
        if (parseFloat(amoritizationPoint.mortgageBalance) < minBalance)
          minBalance = parseFloat(amoritizationPoint.mortgageBalance);
        if (parseFloat(amoritizationPoint.mortgageBalance) > maxBalance)
          maxBalance = parseFloat(amoritizationPoint.mortgageBalance);

        amortizationSchedule_.push({
          Date: moment(amoritizationPoint.date).format("MMM YY"),
          Balance: amoritizationPoint.mortgageBalance,
        });
      }
      setAmortizationDomain([Math.floor(minBalance * 0.95), Math.ceil(maxBalance + 50)]);
      setAmortizationSchedule(amortizationSchedule_);
    }

    if (ROIData_ && ROIData_.monthlyCashflow) {
      let lastCashflowPeriod =
        ROIData_.monthlyCashflow.length > maxBarsInChart
          ? ROIData_.monthlyCashflow.slice(-maxBarsInChart)
          : ROIData_.monthlyCashflow;
      let monthlyCashflow_ = [];

      let minCashflow = Infinity;
      let maxCashflow = -Infinity;
      for (let cashflowPoint of lastCashflowPeriod) {
        if (parseFloat(cashflowPoint.totalCashflow) < minCashflow)
          minCashflow = parseFloat(cashflowPoint.totalCashflow);
        if (parseFloat(cashflowPoint.totalCashflow) > maxCashflow)
          maxCashflow = parseFloat(cashflowPoint.totalCashflow);

        monthlyCashflow_.push({
          Date: moment(cashflowPoint.date).format("MMM YY"),
          Balance: cashflowPoint.totalCashflow,
        });
      }
      setCashflowDomain([Math.floor(minCashflow - 100), Math.ceil(maxCashflow + 50)]);
      setMonthlyCashflow(monthlyCashflow_);
    }
  };

  if (!props.property?.initialPropertyValue) return <ROISetup history={history} refreshROIData={setupROIData} />;

  const missingData = (
    <Grid container className={classes.noROIData}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={classes.noROIDataIcon}>
          <AssessmentRoundedIcon style={{ fontSize: "90px" }} />
        </div>
      </div>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          Estimates Not Yet Available
        </Typography>
        <Typography variant="body1" align="center">
          There's not enough data to generate an estimate for this property's {currentChart} yet.
          <br />
          But as time passes, you'll start seeing your estimate on this page.
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Container className={classes.container} maxWidth="md">
        <Grid item xs={12}>
          <Paper className={classes.content}>
            <Grid item xs={12} className={classes.tabsGrid}>
              <Tabs
                className={classes.tabs}
                value={currentChart}
                onChange={(e, newValue) => setCurrentChart(newValue)}
                {...(smallDevice
                  ? { variant: "scrollable", scrollButtons: "auto", allowScrollButtonsMobile: true }
                  : { centered: true })}
              >
                <Tab label="ROI" value="ROI" />
                <Tab label="Value" value="value" />
                {props.property?.initialMortgage && <Tab label="Mortgage" value="mortgage" />}
                <Tab label="Cashflow" value="cashflow" />
              </Tabs>
            </Grid>

            {currentChart == "ROI" &&
              (quarterlyROI?.length > 1 ? (
                <>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Estimated ROI
                  </Typography>
                  <Typography className={classes.sectionNumber} variant="h6">
                    {quarterlyROI.length > 1
                      ? "$" +
                        (
                          parseFloat(quarterlyROI.slice(-1)[0].Return) - parseFloat(quarterlyROI.slice(-2)[0].Return)
                        ).toFixed(2)
                      : ""}
                    <ArrowUpwardRoundedIcon className={classes.arrowIcon} />{" "}
                    <span style={{ color: "lightgray", fontSize: "0.8em" }}>this quarter</span>
                  </Typography>
                  <div style={{ width: "100%", height: 350 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={quarterlyROI}
                        className={classes.roiChart}
                        margin={{ top: 40, right: 0, left: 0, bottom: 30 }}
                      >
                        <defs>
                          <linearGradient id="colorReturn" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#64dc16" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#000000" stopOpacity={0.4} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="Date" tick={{ fill: "white" }} />
                        <YAxis tick={{ fill: "white" }} domain={ROIDomain} />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="Return"
                          stroke="#64dc16"
                          fillOpacity={1}
                          fill="url(#colorReturn)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </>
              ) : (
                missingData
              ))}

            {currentChart == "value" &&
              (monthlyValue?.length > 1 ? (
                <>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Property Value
                  </Typography>
                  <Typography className={classes.sectionNumber} variant="h6">
                    {monthlyValue.length > 1 &&
                      "$" +
                        (
                          parseFloat(monthlyValue.slice(-1)[0].Value) - parseFloat(monthlyValue.slice(-2)[0].Value)
                        ).toFixed(2)}
                    <ArrowUpwardRoundedIcon className={classes.arrowIcon} />{" "}
                    <span style={{ color: "lightgray", fontSize: "0.8em" }}>this quarter</span>
                  </Typography>
                  <div style={{ width: "100%", height: 350 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={monthlyValue}
                        className={classes.roiChart}
                        margin={{ top: 40, right: 0, left: 0, bottom: 30 }}
                      >
                        <defs>
                          <linearGradient id="colorReturn" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#64dc16" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#000000" stopOpacity={0.4} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="Date" tick={{ fill: "white" }} />
                        <YAxis tick={{ fill: "white" }} domain={valueDomain} />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="Value"
                          stroke="#64dc16"
                          fillOpacity={1}
                          fill="url(#colorReturn)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </>
              ) : (
                missingData
              ))}

            {currentChart == "mortgage" &&
              (amortizationSchedule?.length > 1 ? (
                <>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Mortgage Paydown
                  </Typography>
                  <Typography className={classes.sectionNumber} variant="h6">
                    {amortizationSchedule.length > 1 &&
                      "$" +
                        (
                          parseFloat(amortizationSchedule.slice(-2)[0].Balance) -
                          parseFloat(amortizationSchedule.slice(-1)[0].Balance)
                        ).toFixed(2)}
                    <ArrowDownwardRoundedIcon className={classes.arrowIcon} />{" "}
                    <span style={{ color: "lightgray", fontSize: "0.8em" }}>this month</span>
                  </Typography>
                  <div style={{ width: "100%", height: 350 }}>
                    <ResponsiveContainer width="100%" height="100%" className={classes.roiChart}>
                      <BarChart margin={{ top: 40, right: 0, left: 0, bottom: 30 }} data={amortizationSchedule}>
                        <defs>
                          <linearGradient id="colorReturn" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#64dc16" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#000000" stopOpacity={0.4} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="Date" tick={{ fill: "white" }} />
                        <YAxis tick={{ fill: "white" }} domain={amortizationDomain} />
                        <Tooltip />
                        <Bar dataKey="Balance" fill="url(#colorReturn)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              ) : (
                missingData
              ))}

            {currentChart == "cashflow" &&
              (monthlyCashflow?.length > 1 ? (
                <>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Total Cashflow
                  </Typography>
                  <Typography className={classes.sectionNumber} variant="h6">
                    {monthlyCashflow.length > 1 &&
                      "$" +
                        (
                          parseFloat(monthlyCashflow.slice(-1)[0].Balance) -
                          parseFloat(monthlyCashflow.slice(-2)[0].Balance)
                        ).toFixed(2)}
                    <ArrowUpwardRoundedIcon className={classes.arrowIcon} />{" "}
                    <span style={{ color: "lightgray", fontSize: "0.8em" }}>this month</span>
                  </Typography>
                  <div style={{ width: "100%", height: 350 }}>
                    <ResponsiveContainer width="100%" height="100%" className={classes.roiChart}>
                      <BarChart margin={{ top: 40, right: 0, left: 0, bottom: 30 }} data={monthlyCashflow}>
                        <defs>
                          <linearGradient id="colorReturn" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#64dc16" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#000000" stopOpacity={0.4} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="Date" tick={{ fill: "white" }} />
                        <YAxis tick={{ fill: "white" }} domain={cashflowDomain} />
                        <Tooltip />
                        <Bar dataKey="Balance" fill="url(#colorReturn)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              ) : (
                missingData
              ))}
          </Paper>
        </Grid>
      </Container>
    </>
  );
}

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(ROI);
