import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { TextField, useTheme } from "@material-ui/core";
import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";
import { verifyUser } from "../../services/userServices.js";

setAxiosDefaultSettings(axios);

const useStyles = makeStyles((theme) => ({
  main: {
    background: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url("/LandingPage/iStock-1146103884-blurred.jpg")`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px",
    [`${theme.breakpoints.down("xs")}`]: {
      padding: "40px",
    },
  },
  logo: {
    maxWidth: "100%",
    width: "500px",
  },
  header: {
    fontSize: "30px",
    textAlign: "center",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "25px",
    },
  },
  notifyButton: {
    padding: "5px 50px",
    fontSize: "18px",
    fontWeight: "600",
  },
  marginRight: {
    margin: "20px 0px",
  },
}));

function PasswordPage({ setToken }) {
  const classes = useStyles();
  const theme = useTheme();

  const [verificationCode, setVerificationCode] = useState("");

  const handleVerification = async () => {
    return await axios
      .post("/v2/users/verification/", { verificationCode: verificationCode })
      .then((response) => {
        if (response.data) {
          setToken(response.data?.token);
          document.cookie = `token=${response.data?.token}; expires=${new Date(
            Date.now() + 86400 * 1000
          ).toUTCString()}; path=/;`;
        }
      })
      .catch(() => false);
  };

  return (
    <div className={classes.main}>
      <div
        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", flex: "1" }}
      >
        <img className={classes.logo} src="/LandingPage/SI Landlord.png" />
        <TextField
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          variant="outlined"
          margin="normal"
          label="Verification"
          classes={{ root: classes.marginRight }}
        />
        <Button onClick={handleVerification} variant="contained" className={classes.notifyButton}>
          Verify
        </Button>
      </div>
    </div>
  );
}

export default PasswordPage;
