import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddRounded";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/styles";
import { Grid, Tooltip } from "@material-ui/core";

let errorMessages = {
  unitNums: [
    {
      required: "Please enter the unit number",
      pattern: "Must be between 0 and 9999999",
      reverseRange: "The first unit # must be lower than the last unit #, e.g. 12-23",
    },
  ],
};

function MultiUnitSelector({ control, getValues, setValue, watch, errorAndHelperText }) {
  const useStyles = makeStyles((theme) => ({
    addBtn: {
      marginBottom: theme.spacing(8),
      [`${theme.breakpoints.down("xs")}`]: {
        marginBottom: theme.spacing(4),
      },
      "&:hover": {
        "& $addIcon": {
          transform: "scale(1.1)",
        },
      },
    },
    addIcon: {
      fontSize: "14px",
      position: "absolute",
      transform: "translateX(40px)",
      transition: "transform .2s ease-in-out",
    },
    marginBtm: {
      marginBottom: theme.spacing(2),
    },
    unitRangeGrid: {
      [`${theme.breakpoints.down("xs")}`]: {
        padding: "20px 0px",
      },
    },
  }));

  const classes = useStyles();
  const [numUnits, setNumUnits] = useState(1);
  const [fieldIsRange, setFieldIsRange] = useState({ 0: false });

  useEffect(() => {
    let formData = getValues();
    let _fieldIsRange = fieldIsRange;
    for (let i = 0; i < formData?.unitNums?.length; i++) {
      if (Array.isArray(formData.unitNums[i])) _fieldIsRange[i] = true;
      else _fieldIsRange[i] = false;
    }
    setFieldIsRange(_fieldIsRange);
    setNumUnits(formData?.unitNums?.length > 1 ? formData?.unitNums?.length : 1);
  }, []);

  useEffect(() => {
    let formData = getValues();
    errorMessages = { unitNums: [] };
    for (let i = 0; i < formData?.unitNums?.length; i++) {
      if (Array.isArray(formData.unitNums[i]))
        errorMessages.unitNums.push([
          {
            required: "Please enter the unit number",
            pattern: "Must be between 0 and 9999999",
            reverseRange: "The first unit # must be lower, e.g. 12-23",
          },
          {
            required: "Please enter the unit number",
            pattern: "Must be between 0 and 9999999",
            reverseRange: "The last unit # must be higher, e.g. 12-23",
          },
        ]);
      else
        errorMessages.unitNums.push({
          required: "Please enter the unit number",
          pattern: "Must be between 0 and 9999999",
        });
    }
  }, [numUnits, fieldIsRange]);

  return (
    <>
      {[...Array(numUnits).keys()].map((index) => (
        <div key={index} style={{ display: "flex", gap: "1%", alignItems: "center" }} className={classes.marginBtm}>
          {(fieldIsRange && index in fieldIsRange && fieldIsRange[index]) ||
          (getValues()?.unitNums?.length > 0 && Array.isArray(getValues()?.unitNums[index])) ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <Controller
                  name={`unitNums[${index}][0]`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: /^\d{0,7}$/,
                    validate: {
                      reverseRange: () => {
                        const firstUnit = watch(`unitNums[${index}][0]`, "");
                        const lastUnit = watch(`unitNums[${index}][1]`, "");
                        return !firstUnit || !lastUnit || parseInt(firstUnit, 10) < parseInt(lastUnit, 10);
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="First Unit #"
                      {...errorAndHelperText(["unitNums", index, 0], errorMessages)}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                -
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  name={`unitNums[${index}][1]`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: /^\d{0,7}$/,
                    validate: {
                      reverseRange: () => {
                        const firstUnit = watch(`unitNums[${index}][0]`, "");
                        const lastUnit = watch(`unitNums[${index}][1]`, "");
                        return !firstUnit || !lastUnit || parseInt(firstUnit, 10) < parseInt(lastUnit, 10);
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="Last Unit #"
                      {...errorAndHelperText(["unitNums", index, 1], errorMessages)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Controller
              name={`unitNums[${index}]`}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\d{0,7}$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  variant="outlined"
                  fullWidth
                  label="Unit #"
                  {...errorAndHelperText(["unitNums", index], errorMessages)}
                />
              )}
            />
          )}
          <Tooltip title="Remove Unit" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
            <CloseRoundedIcon
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                visibility: numUnits > 1 && index === numUnits - 1 ? "visible" : "hidden",
              }}
              onClick={() => {
                let formData = getValues();
                formData.unitNums.pop();
                setValue("unitNums", formData.unitNums);
                setNumUnits(numUnits - 1);
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              fieldIsRange && index in fieldIsRange && fieldIsRange[index]
                ? "Switch To Single Unit"
                : "Switch To Range Of Units"
            }
            placement="top"
            PopperProps={{ style: { marginBottom: "-8px" } }}
          >
            <CompareArrowsIcon
              style={{
                marginLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                let formData = getValues();
                if (Array.isArray(formData.unitNums[index])) formData.unitNums[index] = formData.unitNums[index][0];
                else formData.unitNums[index] = [formData.unitNums[index], ""];
                setFieldIsRange((_fieldIsRange) => {
                  return {
                    ..._fieldIsRange,
                    [index]: !_fieldIsRange[index],
                  };
                });
              }}
            />
          </Tooltip>
        </div>
      ))}

      <Button fullWidth variant="contained" className={classes.addBtn} onClick={() => setNumUnits(numUnits + 1)}>
        Add Unit <AddIcon className={classes.addArrow} />
      </Button>
    </>
  );
}

export default MultiUnitSelector;
