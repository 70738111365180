import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Slide from "@material-ui/core/Slide";

import ToastFactory from "./ToastFactory";
import QontoStepIcon from "./QontoStepIcon";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    position: "relative",
  },
  container: {
    minHeight: "calc(100vh - 264px)",
    display: "flex",
    padding: "40px 0px",
    flexDirection: "column",
    justifyContent: "center",
  },
  mobilePadding: {
    [`${theme.breakpoints.down("xs")}`]: {
      padding: `0px ${theme.spacing(4)}px`,
    },
  },
  btnGrid: {
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column-reverse",
    },
  },
  backBtnGrid: {
    marginBottom: theme.spacing(2),
    [`${theme.breakpoints.up("sm")}`]: {
      paddingRight: "5%",
    },
  },
  nextBtnGrid: {
    marginBottom: theme.spacing(2),
    [`${theme.breakpoints.up("sm")}`]: {
      paddingLeft: "5%",
    },
  },
  backBtn: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $arrowBack": {
        transform: "translate(-30px, 50%)",
      },
    },
  },
  arrowBack: {
    fontSize: "14px",
    position: "absolute",
    bottom: "50%",
    transform: "translate(-20px, 50%)",
    transition: "transform .2s ease-in-out",
  },
  nextBtn: {
    "&:hover": {
      "& $arrowForward": {
        transform: "translateX(50px)",
      },
    },
  },
  arrowForward: {
    fontSize: "14px",
    position: "absolute",
    transform: "translateX(40px)",
    transition: "transform .2s ease-in-out",
  },
  exitTransition: {
    position: "absolute",
    top: "0",
  },
  stepper: {
    backgroundColor: "transparent",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  stepAltLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  stepActive: {
    "& $stepLine": {
      borderColor: theme.palette.primary.main,
    },
  },
  stepCompleted: {
    "& $stepLine": {
      borderColor: theme.palette.primary.main,
    },
  },
  stepLine: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  stepLabelPointer: {
    cursor: "pointer",
  },
}));

let nextClicked = false;
let submitting = false;
let submitted = false;
let formSections = [];
function MultiStepForm({
  formSteps,
  formData,
  formProps = null,
  setFormData,
  stepsToSkip,
  submitForm,
  handleSubmit,
  setOnSubmit,
  errorReceived,
  toasts,
  hideStepper,
  dynamicStepper,
  classes,
}) {
  const useStyles = makeStyles((theme) => ({
    form:
      classes && "form" in classes
        ? classes.form
        : {
            width: "100%",
            position: "relative",
          },
    container:
      classes && "container" in classes
        ? classes.container
        : {
            minHeight: `calc(100vh - ${hideStepper ? 100 : 264}px)`,
            display: "flex",
            padding: "40px 0px",
            flexDirection: "column",
            justifyContent: "center",
          },
    mobilePadding:
      classes && "mobilePadding" in classes
        ? classes.mobilePadding
        : {
            [`${theme.breakpoints.down("xs")}`]: {
              padding: `0px ${theme.spacing(4)}px`,
            },
          },
    btnGrid:
      classes && "btnGrid" in classes
        ? classes.btnGrid
        : {
            [`${theme.breakpoints.down("xs")}`]: {
              flexDirection: "column-reverse",
            },
          },
    backBtnGrid:
      classes && "backBtnGrid" in classes
        ? classes.backBtnGrid
        : {
            marginBottom: theme.spacing(2),
            [`${theme.breakpoints.up("sm")}`]: {
              paddingRight: "5%",
            },
          },
    nextBtnGrid:
      classes && "nextBtnGrid" in classes
        ? classes.nextBtnGrid
        : {
            marginBottom: theme.spacing(2),
            [`${theme.breakpoints.up("sm")}`]: {
              paddingLeft: "5%",
            },
          },
    backBtn: {
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        "& $arrowBack": {
          transform: "translate(-30px, 50%)",
        },
      },
    },
    arrowBack: {
      fontSize: "14px",
      position: "absolute",
      bottom: "50%",
      transform: "translate(-20px, 50%)",
      transition: "transform .2s ease-in-out",
    },
    nextBtn: {
      "&:hover": {
        "& $arrowForward": {
          transform: "translateX(50px)",
        },
      },
    },
    arrowForward: {
      fontSize: "14px",
      position: "absolute",
      transform: "translateX(40px)",
      transition: "transform .2s ease-in-out",
    },
    exitTransition: {
      position: "absolute",
      top: "0",
    },
    stepper: {
      backgroundColor: "transparent",
      width: "100%",
    },
    stepAltLabel: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    stepActive: {
      "& $stepLine": {
        borderColor: theme.palette.primary.main,
      },
    },
    stepCompleted: {
      "& $stepLine": {
        borderColor: theme.palette.primary.main,
      },
    },
    stepLine: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
    stepLabelPointer: {
      cursor: "pointer",
    },
  }));
  const subClasses = useStyles();

  const [transitionDir, setTransitionDir] = useState(1);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    nextClicked = false;
    submitting = false;
    submitted = false;
  }, []);

  useEffect(
    () => {
      formSections = [];
      for (let formStep of formSteps) {
        if (!formSections.includes(formStep["section"])) formSections.push(formStep["section"]);
      }
    },
    dynamicStepper ? [formData] : []
  );

  useEffect(() => {
    if (!submitted || !errorReceived || Object.keys(errorReceived).length === 0) return;
    console.log("errorReceived: ", errorReceived);

    let index = 0;
    let errorFound = false;
    for (let formStep of formSteps) {
      let fieldNames = formStep["fieldNames"] ? formStep["fieldNames"].split(" ") : [];
      for (let fieldName of fieldNames) {
        if (!Object.keys(errorReceived).includes(fieldName)) continue;
        setTransitionDir(-1);
        setStep(index);
        errorFound = true;
        break;
      }
      if (errorFound) break;
      index++;
    }

    submitted = false;
  }, [errorReceived]);

  const nextStep = (direction, debug = false) => {
    if (debug) {
      console.log("current step: ", step);
      console.log("stepsToSkip: ", stepsToSkip);
    }
    let tempStep = step + direction;
    while (formSteps[tempStep] && stepsToSkip.includes(formSteps[tempStep]["fieldNames"].split(" ")[0]))
      tempStep += direction;
    if (debug) console.log("selected step: ", step);
    return tempStep;
  };

  const addToStep = (direction) => {
    setTransitionDir(direction);
    //console.log("Direction: ", direction);
    let tempStep = nextStep(direction, false);
    if (tempStep > formSteps.length - 1) return false;
    setStep(tempStep);
    return true;
  };

  const stepperHandleClick = (label) => {
    let firstStepInSection = null;
    for (let i = 0; i < formSteps.length; i++) {
      if (formSteps[i]["section"] === label) {
        firstStepInSection = i;
        break;
      }
    }

    if (firstStepInSection != null && firstStepInSection < step) {
      setTransitionDir(-1);
      setStep(firstStepInSection);
    }
  };

  const updateFormData = (data) => {
    if (Object.keys(data).length >= Object.keys(formData).length) {
      setFormData(data);
      return;
    }
    let changedValues = {};
    for (let key in formData) {
      if (key in data && formData[key] !== data[key]) changedValues[key] = data[key];
    }
    //console.log("Setting changed data: ", data);
    setFormData({ ...formData, ...changedValues });
  };

  const onSubmit = (rawData) => {
    updateFormData(rawData);
    submitting = true;
    submitted = true;
  };

  useEffect(() => {
    if (!submitting) return;
    if (!addToStep(1) && nextClicked) {
      setLoading(true);
      submitForm(formData, (mounted) => (mounted && mounted[0] ? setLoading(false) : ""));
    }
    nextClicked = false;
    submitting = false;

    let newFormData = formData;
    for (let step of stepsToSkip) if (formData[step]) delete newFormData[step];
    setFormData(newFormData);
  }, [stepsToSkip]);

  useEffect(() => {
    if (setOnSubmit != null) setOnSubmit(onSubmit);
  }, []);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  // if (loading) return <Loading />;

  return (
    <>
      <ToastFactory toasts={toasts} />
      {!hideStepper ? (
        <div style={{ width: "100%", overflowX: "auto", transform: "rotateX(180deg)" }}>
          <Stepper
            alternativeLabel
            activeStep={formSections.indexOf(formSteps[step]["section"])}
            className={subClasses.stepper}
            style={{ transform: "rotateX(180deg)" }}
            connector={
              <StepConnector
                classes={{
                  alternativeLabel: subClasses.stepAltLabel,
                  active: subClasses.stepActive,
                  completed: subClasses.stepCompleted,
                  line: subClasses.stepLine,
                }}
              />
            }
          >
            {formSections.map((label) => (
              <Step key={label}>
                <StepLabel
                  onClick={() => stepperHandleClick(label)}
                  StepIconComponent={QontoStepIcon}
                  classes={{
                    completed: subClasses.stepLabelPointer,
                    active: subClasses.stepLabelPointer,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      ) : (
        ""
      )}
      <Container maxWidth="sm" className={subClasses.mobilePadding}>
        <form className={subClasses.form} noValidate onSubmit={handleSubmit(onSubmit)} {...formProps}>
          {formSteps.map((formStep, index) => {
            if (index > step + 1) return <div key={index}></div>;
            return (
              <Slide
                key={index}
                timeout={{ enter: 400, exit: 200 }}
                mountOnEnter
                unmountOnExit
                in={step === index}
                direction={
                  step === index ? (transitionDir === 1 ? "left" : "right") : transitionDir === 1 ? "right" : "left"
                }
              >
                <Container
                  maxWidth="sm"
                  className={subClasses.container}
                  classes={step !== index ? { root: subClasses.exitTransition } : {}}
                >
                  {formStep["step"]}
                  <Grid
                    container
                    className={subClasses.btnGrid}
                    style={
                      formStep["nextBtnProps"] && !formStep["nextBtnProps"]["enabled"]
                        ? { justifyContent: "center" }
                        : {}
                    }
                  >
                    <Grid item xs={12} sm={6} className={subClasses.backBtnGrid}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={subClasses.backBtn}
                        style={{ display: index === 0 ? "none" : "block" }}
                        onClick={() => {
                          if (index !== 0) addToStep(-1);
                        }}
                      >
                        <ArrowBackIosIcon className={subClasses.arrowBack} /> Back
                      </Button>
                    </Grid>
                    {!formStep["nextBtnProps"] || formStep["nextBtnProps"]["enabled"] ? (
                      <Grid item xs={12} sm={6} className={subClasses.nextBtnGrid}>
                        <Button
                          type={formStep?.nextBtnProps?.type ? formStep.nextBtnProps.type : "submit"}
                          fullWidth
                          variant="contained"
                          color={
                            !formStep?.nextBtnProps?.label && nextStep(1) > formSteps.length - 1
                              ? "secondary"
                              : "primary"
                          }
                          className={subClasses.nextBtn}
                          onClick={
                            formStep?.nextBtnProps?.onClick
                              ? formStep?.nextBtnProps?.onClick
                              : () => (nextClicked = true)
                          }
                        >
                          {formStep?.nextBtnProps?.label ? (
                            formStep?.nextBtnProps?.label
                          ) : (
                            <>
                              {nextStep(1) > formSteps.length - 1 ? "Submit" : "Next"}
                              <ArrowForwardIosIcon className={subClasses.arrowForward} />
                            </>
                          )}
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Container>
              </Slide>
            );
          })}
        </form>
      </Container>
    </>
  );
}

export default MultiStepForm;
