export const calculateLedgerBalance = (ledger) => {
  let balance = 0;
  let newLedger = {};

  if (ledger) {
    for (let ledgerItem of ledger) {
      if (!ledgerItem.NSF) balance += ledgerItem.amount * (ledgerItem.type === "payment" ? -1 : 1);
      newLedger[ledgerItem.ID] = Math.round((balance + Number.EPSILON) * 100) / 100;
    }
  }

  return { balance, newLedger };
};
