import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import QuestionIcon from "@material-ui/icons/HelpRounded";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import { addLedger, editLedger, deleteLedger } from "../../../../services/property/ledgerServices.js";

import Loading from "../../../components/Loading";
import MoneyInput from "../../../components/MoneyInput";

const errorMessages = {
  ledgerType: {
    required: "Please enter the transaction type",
    pattern: "Please choose the transaction type from the dropdown menu",
  },
  ledgerReason: {
    required: "Please enter the transaction reason",
    pattern: "Please choose the transaction reason from the dropdown menu",
  },
  ledgerAmount: {
    required: "Please enter a transaction amount",
    pattern: "The amount must be a positive number and can't have more than 2 decimals",
  },
  ledgerDate: {
    required: "Please enter the transaction date",
    pattern: "Please select a valid date, e.g. 2000-04-12",
  },
  ledgerTenant: {
    pattern: "If applicable, select the tenant associated with this transaction from the dropdown menu",
  },
  providerName: {
    required: "Please enter the name of the utility provider",
    length: "The provider name cannot exceed 32 characters",
  },
  coverageStartDate: {
    required: "Please enter the start date of the period covered by the utility bill",
    pattern: "Please select a valid date, e.g. 2000-04-12",
  },
  coverageEndDate: {
    required: "Please enter the end date of the period covered by the utility bill",
    pattern: "Please select a valid date, e.g. 2000-04-12",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "32vh",
    },
  },
  form: {
    width: "100%",
  },
  header: {
    margin: theme.spacing(1, 0, 1),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 1),
      fontSize: "1em",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  tooltipFont: {
    fontSize: "0.8em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.9em",
    },
  },
  questionTooltip: {
    position: "relative",
    top: "4px",
    marginLeft: "10px",
    fontSize: "1.1em",
  },
  checkboxLabel: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.9em",
    },
  },
  dateRange: {
    margin: theme.spacing(2, 0, 2),
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    fontSize: "1.4em",
    padding: theme.spacing(1.5, 2.5, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      padding: theme.spacing(1.5, 0, 1.5),
    },
  },
}));

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + "-" + mm + "-" + dd;

let currentAction = null;

function EditLedgerForm(props) {
  const {
    handleSubmit,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const [isNSF, setIsNSF] = useState(false);
  const watchLedgerType = watch("ledgerType", "");
  const watchLedgerReason = watch("ledgerReason", "");
  const watchLedgerDate = watch("ledgerDate", "");

  useEffect(() => {
    currentAction = props.action;
    setIsNSF(props?.ledgerItem?.NSF ? true : false);
  }, []);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  };

  const onSubmit = (ledgerData) => {
    let confirmCallback = async () => {
      ledgerData = {
        ...ledgerData,
      };
      ledgerData["leaseID"] = props.leaseID;
      ledgerData["NSF"] = isNSF;
      if (props.ledgerItem) ledgerData["ID"] = props.ledgerItem.ID;
      if (ledgerData["ledgerReason"] !== "Utilities") {
        delete ledgerData.providerName;
        delete ledgerData.coverageStartDate;
        delete ledgerData.coverageEndDate;
      }
      if (ledgerData["ledgerAmount"])
        ledgerData["ledgerAmount"] = (ledgerData["ledgerAmount"] + "").replace("$", "").replaceAll(",", "");
      if (!ledgerData["ledgerTenant"]) ledgerData["ledgerTenant"] = null;
      console.log(ledgerData);

      let ledgerEdited = false;
      if (currentAction === "Add" || currentAction === "AddPayment") {
        ledgerEdited = await addLedger(ledgerData, handleErrors);
      } else if (currentAction === "Edit") {
        ledgerEdited = await editLedger(ledgerData, handleErrors);
      } else if (currentAction === "Delet") ledgerEdited = await deleteLedger(ledgerData, handleErrors);

      if (ledgerEdited) return true;

      props.addToast(`${currentAction}ing Transaction Failed`, "error");
      return false;
    };

    if (currentAction === "Add" && ledgerData.ledgerType === "payment") currentAction = "AddPayment";

    props.setConfirmationCallback(confirmCallback, {
      currentAction: currentAction,
      NSFPaymentData: { NSF: isNSF, ledgerTenant: ledgerData["ledgerTenant"] },
    });
  };

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.main}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="ledgerType"
              control={control}
              defaultValue={props.ledgerItem ? props.ledgerItem.type : ""}
              rules={{
                required: true,
                pattern: /^charge$|^payment$/,
              }}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={errors.ledgerType && errors.ledgerType != null}
                >
                  <InputLabel id="ledgerType">Transaction Type</InputLabel>
                  <Select
                    {...field}
                    id="ledgerType"
                    labelId="ledgerTypeLabel"
                    label="Transaction Type"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="charge">Charge</MenuItem>
                    <MenuItem value="payment">Payment</MenuItem>
                  </Select>
                  {errors.ledgerType && (
                    <FormHelperText>{errorMessages["ledgerType"][errors.ledgerType.type]}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="ledgerReason"
              control={control}
              defaultValue={props.ledgerItem ? props.ledgerItem.reason : ""}
              rules={{
                required: true,
                pattern: /^Rent$|^Utilities$|^Parking$|^NSF Admin$|^Other$/,
              }}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={errors.ledgerReason && errors.ledgerReason != null}
                >
                  <InputLabel id="ledgerReason">Reason</InputLabel>
                  <Select
                    {...field}
                    id="ledgerReason"
                    labelId="ledgerReasonLabel"
                    label="Reason"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="Rent">Rent</MenuItem>
                    <MenuItem value="Utilities">Utilities</MenuItem>
                    <MenuItem value="Parking">Parking</MenuItem>
                    <MenuItem value="NSF Admin">NSF Admin</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {errors.ledgerReason && (
                    <FormHelperText>{errorMessages["ledgerReason"][errors.ledgerReason.type]}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          {(watchLedgerReason === "Utilities" || props?.ledgerItem?.reason === "Utilities") &&
            (watchLedgerType === "charge" || props?.ledgerItem?.type === "charge") && (
              <Grid item xs={12}>
                <Controller
                  name="providerName"
                  control={control}
                  defaultValue={props.data.providerName ? props.data.providerName : ""}
                  rules={{
                    required: true,
                    validate: {
                      length: (value) => value.length <= 32,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="providerName"
                      label="Provider Name"
                      error={errors.providerName && errors.providerName !== null}
                      helperText={errors.providerName ? errorMessages["providerName"][errors.providerName.type] : null}
                    />
                  )}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Controller
              name="ledgerAmount"
              control={control}
              defaultValue={
                props.ledgerItem
                  ? props.ledgerItem.amount
                  : props.leaseData && props.leaseData.rentPrice
                  ? props.leaseData.rentPrice
                  : ""
              }
              rules={{
                required: true,
                pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  id="ledgerAmount"
                  label="Amount"
                  error={errors.ledgerAmount && errors.ledgerAmount !== null}
                  helperText={errors.ledgerAmount ? errorMessages["ledgerAmount"][errors.ledgerAmount.type] : null}
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                />
              )}
            />
          </Grid>
          {(watchLedgerType === "payment" || props?.ledgerItem?.type === "payment") && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isNSF}
                    onChange={(event) => setIsNSF(event.target.checked)}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                  />
                }
                label={<Typography className={classes.checkboxLabel}>Non-Sufficient Funds (NSF)</Typography>}
              />
              <Tooltip
                title="Check this box if your tenant made a payment by cheque and it resulted in a lack of sufficient funds"
                placement="top"
                PopperProps={{ style: { marginBottom: "-10px" } }}
                classes={{ tooltip: classes.tooltipFont }}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
              >
                <QuestionIcon className={classes.questionTooltip} />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: "16px", marginBottom: "8px" }}>
            <Controller
              name="ledgerDate"
              control={control}
              defaultValue={props.ledgerItem ? props.ledgerItem.date : today}
              rules={{
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              }}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={value ? new Date(value + " 00:00:00") : value}
                    onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                    inputVariant="outlined"
                    label={watchLedgerReason === "Utilities" ? "Billing Date" : "Date"}
                    format="yyyy-MM-dd"
                    fullWidth
                    clearable
                    InputLabelProps={{ shrink: Boolean(value) }}
                    error={errors.ledgerDate && errors.ledgerDate != null}
                    helperText={errors.ledgerDate ? errorMessages["ledgerDate"][errors.ledgerDate.type] : null}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          {(watchLedgerReason === "Utilities" || props?.ledgerItem?.reason === "Utilities") &&
            (watchLedgerType === "charge" || props?.ledgerItem?.type === "charge") && (
              <Grid item xs={12} className={classes.dateRange}>
                <Controller
                  name="coverageStartDate"
                  control={control}
                  defaultValue={props.data.coverageStartDate ? props.data.coverageStartDate : watchLedgerDate}
                  rules={{
                    required: true,
                    pattern: /^\d{4}-\d{2}-\d{2}$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={value ? new Date(value + " 00:00:00") : value}
                        onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                        inputVariant="outlined"
                        label="Coverage Start Date"
                        format="yyyy-MM-dd"
                        fullWidth
                        clearable
                        InputLabelProps={{ shrink: Boolean(value) }}
                        error={errors.coverageStartDate && errors.coverageStartDate != null}
                        helperText={
                          errors.coverageStartDate
                            ? errorMessages["coverageStartDate"][errors.coverageStartDate.type]
                            : null
                        }
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
                <Typography variant="body1" align="center" className={classes.dateRangeSeperator}>
                  to
                </Typography>
                <Controller
                  name="coverageEndDate"
                  control={control}
                  defaultValue={
                    props.data.coverageEndDate
                      ? props.data.coverageEndDate
                      : moment(watchLedgerDate).add(1, "months").format("YYYY-MM-DD")
                  }
                  rules={{
                    required: true,
                    pattern: /^\d{4}-\d{2}-\d{2}$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={value ? new Date(value + " 00:00:00") : value}
                        onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                        inputVariant="outlined"
                        label="Coverage End Date"
                        format="yyyy-MM-dd"
                        fullWidth
                        clearable
                        InputLabelProps={{ shrink: Boolean(value) }}
                        error={errors.coverageEndDate && errors.coverageEndDate != null}
                        helperText={
                          errors.coverageEndDate ? errorMessages["coverageEndDate"][errors.coverageEndDate.type] : null
                        }
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
            )}
          <Grid item xs={12} style={{ marginTop: "-8px" }}>
            <Controller
              name="ledgerTenant"
              control={control}
              defaultValue={props.ledgerItem?.tenantID ? props.ledgerItem.tenantID : ""}
              rules={{
                pattern: /^[1-9]\d*$/,
              }}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={errors.ledgerTenant && errors.ledgerTenant != null}
                >
                  <InputLabel id="ledgerTenant">Tenant (optional)</InputLabel>
                  <Select
                    {...field}
                    id="ledgerTenant"
                    labelId="ledgerTenantLabel"
                    label="Tenant (optional)"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="">&#8203;</MenuItem>
                    {props.tenants.map((tenant) => (
                      <MenuItem key={tenant["ID"]} value={tenant["ID"]}>
                        {tenant["firstName"] + " " + tenant["lastName"]}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.ledgerTenant && (
                    <FormHelperText>{errorMessages["ledgerTenant"][errors.ledgerTenant.type]}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                currentAction = props.action;
              }}
            >
              {props.action} Ledger Transaction
            </Button>
          </Grid>
          {props.action === "Edit" ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.submit} ${classes.deleteBtn}`}
                onClick={() => {
                  currentAction = "Delet";
                  onSubmit([]);
                }}
              >
                Delete Ledger Transaction
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </form>
    </div>
  );
}

export default EditLedgerForm;
