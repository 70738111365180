import React, { useState, useEffect } from "react";
import SubscriptionCheckoutForm from "./components/SubscriptionCheckoutForm";

import { connect } from "react-redux";
import { getConfig } from "../../services/billingServices.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

function SubscriptionCheckout(props) {
  useEffect(() => {
    if (!props.user) props.history.push(`/authentication`);
  });

  const [stripe, setStripe] = useState(null);
  const [price, setPrice] = useState(null);

  const getSripeConfig = async () => {
    const config = await getConfig();

    if (config) {
      setStripe(await loadStripe(config.publishableKey));
      setPrice(config.simplePlanPrice);
    }
  };

  useEffect(() => {
    getSripeConfig();
  }, []);

  return (
    <>
      {stripe && price ? (
        <Elements stripe={stripe}>
          <SubscriptionCheckoutForm price={price} history={props?.history} />
        </Elements>
      ) : null}
    </>
  );
}

const mapState = (state) => {
  return {
    user: state.authentication.user,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};
export default connect(mapState, mapDispatch)(SubscriptionCheckout);
