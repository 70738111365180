import React from "react";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(80vh - 96px)",
    justifyContent: "center",
  },
  spinner: {
    margin: theme.spacing(5),
  },
  text: {
    margin: theme.spacing(5),
    fontWeight: "500",
  },
}));

function Loading() {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography color="primary" variant="h2" className={classes.text}>
        Loading
      </Typography>
      <CircularProgress className={classes.spinner} />
    </Container>
  );
}

export default Loading;
