import axios from "axios";
import setAxiosDefaultSettings from "../../utils/axiosSettings.js";

setAxiosDefaultSettings(axios);

export const getPicture = async (pictureData) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/properties/pictures/?timestamp=${new Date().getTime()}`, {
      params: { ...pictureData, clonedUserID },
    })
    .then((response) => response.data);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addPicture = async (pictureData, handleErrors) => {
  const formData = new FormData();
  Object.keys(pictureData).forEach((field) => formData.append(field, pictureData[field]));

  return await updatePicture(axios.post, formData, handleErrors);
};

export const deletePicture = async (pictureData, handleErrors) => {
  return await updatePicture(axios.delete, { data: pictureData }, handleErrors);
};

const updatePicture = async (axiosMethod, pictureData, handleErrors = null) => {
  let pictureReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/pictures/", pictureData)
    .then(async (response) => {
      if (response.config?.method === "delete") pictureReceived = true;
      else if (response.data) pictureReceived = response.data;
      else {
        responseError = response.data?.error || "Picture Update failed";
        console.log("No picture", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Picture Information";
    });

  if (handleErrors) handleErrors(responseError);

  return pictureReceived;
};
