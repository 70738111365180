import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useForm, Controller, useFormState } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FulfilledIcon from "@material-ui/icons/CheckRounded";
import UnfulfilledIcon from "@material-ui/icons/CloseRounded";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Collapse from "@material-ui/core/Collapse";

import { connect } from "react-redux";
import { resetPassword } from "../../services/authenticationServices.js";

import ToastFactory from "../components/ToastFactory";
import Loading from "../components/Loading";

import Logo from "../../assets/logos/simpleLandlordFullsize.png";

const errorMessages = {
  password: {
    required: "Please enter your password",
    requirementsFulfilled: "Please make sure your password meets all of the requirements",
  },
  confirmPassword: {
    required: "Please enter a confirmation password",
    passwordEquality: "Please make sure the passwords you entered match",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "82px",
  },
  logo: {
    width: "100%",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  form: {
    width: "100%",
    marginTop: "16px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bannerImage: {
    position: "relative",
    maxHeight: "100vh",
    maxWidth: "900px",
    width: "100%",
    textAlign: "center",
  },
  bannerPhone: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  bannerProperty: {
    position: "absolute",
    top: "15%",
    right: "7%",
    transform: "translateX(-50%)",
    maxWidth: "100%",
    maxHeight: "45%",
  },
  bannerROI: {
    position: "absolute",
    bottom: "10%",
    left: "30%",
    transform: "translateX(-50%)",
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "35%",
  },
  gridItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
  stickyPhone: {
    top: "0px",
    position: "sticky",
  },
  paperForm: {
    width: "100%",
    height: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
  fulfilled: {
    color: "springgreen",
  },
  passwordRequirements: {
    margin: theme.spacing(1, 0, -2),
  },
  passwordRequirementsList: {
    paddingLeft: "12px",
    ["& > li"]: {
      display: "flex",
      alignItems: "center",
    },
  },
  passwordRequirementsItem: {
    [`${theme.breakpoints.down(400)}`]: {
      fontSize: "0.9em",
    },
    [`${theme.breakpoints.between(400, 600)}`]: {
      fontSize: "1.1em",
    },
    [`${theme.breakpoints.between(600, 730)}`]: {
      fontSize: "0.9em",
    },
    [`${theme.breakpoints.between(730, 815)}`]: {
      fontSize: "1em",
    },
  },
}));

function ResetPassword(props) {
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!query.get("token")) props.history.push(`/authentication/login`);
  }, []);

  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));

  const { isSubmitted } = useFormState({
    control,
  });

  const [showPassword, setshowPassword] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const watchPassword = watch("password", "");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    hasNumber: false,
    hasUppercase: false,
    hasSpecial: false,
  });

  useEffect(() => {
    let lengthCheck = false;

    let hasNumberCheck = false;
    const hasNumberRegex = /\d/;

    let hasUppercaseCheck = false;
    const hasUppercaseRegex = /[A-Z]/;

    let hasSpecialCheck = false;
    const hasSpecialRegex = /[$&+,:;=?@#|'<>.^*()%!{}-]/;

    if (watchPassword.length >= 8 && watchPassword.length <= 128) lengthCheck = true;
    if (hasNumberRegex.test(watchPassword)) hasNumberCheck = true;
    if (hasUppercaseRegex.test(watchPassword)) hasUppercaseCheck = true;
    if (hasSpecialRegex.test(watchPassword)) hasSpecialCheck = true;

    setPasswordRequirements({
      length: lengthCheck,
      hasNumber: hasNumberCheck,
      hasUppercase: hasUppercaseCheck,
      hasSpecial: hasSpecialCheck,
    });
    if (isSubmitted) trigger(["confirmPassword"]);
  }, [watchPassword]);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  useEffect(() => {
    const errors = props.error;
    if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
      if (errors) addToast("Resetting Password Failed", "error");
    } else if (typeof errors === "string") {
      addToast("Invalid Password Reset Link. Please Request Another One", "error");
    }
  }, [props.error]);

  const onSubmit = async (forgotPasswordData) => {
    const passwordChanged = await resetPassword(props.dispatch, {
      password: forgotPasswordData.password,
      token: query.get("token"),
    });
    if (passwordChanged) {
      addToast("Password Successfully Changed", "success");
      setValue("password", "");
      setValue("confirmPassword", "");
      setTimeout(() => props.history.push(`/authentication/login`), 1200);
    }
  };

  const classes = useStyles();

  if (props.loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Grid container style={{ minHeight: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ display: breakpointsDownXS ? "none" : "block" }}
        >
          <div className={`${classes.bannerImage} ${classes.stickyPhone}`}>
            <img className={classes.bannerPhone} src="/LandingPage/Banner Phone Reverse.png" />
            <img
              className={classes.bannerProperty}
              style={{ transform: "translate(-50%, var(--bannedPropertyTranslateY))" }}
              src="/LandingPage/Banner Property Reverse.png"
            />
            <img
              className={classes.bannerROI}
              style={{ transform: "translate(-50%, var(--bannedROITranslateY))" }}
              src="/LandingPage/Banner ROI Reverse.png"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Paper
            className={classes.paperForm}
            style={breakpointsDownXS ? { background: theme.palette.background.default } : {}}
          >
            <div style={{ maxWidth: "450px", textAlign: "center" }}>
              <img src={Logo} className={classes.logo} alt="The Simple Landlord Logo" />
              <Typography variant="h4" style={{ fontWeight: "700" }}>
                Enter Your New Password
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: {
                          requirementsFulfilled: () =>
                            passwordRequirements.length &&
                            passwordRequirements.hasNumber &&
                            passwordRequirements.hasUppercase &&
                            passwordRequirements.hasSpecial,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onFocus={() => setShowPasswordRequirements(true)}
                          onBlur={() => setShowPasswordRequirements(false)}
                          onKeyUp={() => {
                            if (isSubmitted) trigger("password");
                          }}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          id="password"
                          label="Password"
                          autoComplete="current-password"
                          error={errors.password && errors.password !== null}
                          helperText={errors.password ? errorMessages["password"][errors.password.type] : null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Show Password" PopperProps={{ style: { marginTop: "-8px" } }}>
                                  <IconButton
                                    onClick={() => {
                                      setshowPassword(!showPassword);
                                    }}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Collapse in={showPasswordRequirements}>
                    <Grid item xs={12} className={classes.passwordRequirements}>
                      <Typography variant="h6" align="left" style={{ paddingLeft: "14px" }}>
                        Password Requirements:
                      </Typography>
                      <ul className={classes.passwordRequirementsList}>
                        <li>
                          {passwordRequirements.length ? (
                            <FulfilledIcon className={classes.fulfilled} />
                          ) : (
                            <UnfulfilledIcon color="error" />
                          )}
                          <Typography className={classes.passwordRequirementsItem} variant="body1">
                            &nbsp;Must be between 8 and 128 characters
                          </Typography>
                        </li>
                        <li>
                          {passwordRequirements.hasNumber ? (
                            <FulfilledIcon className={classes.fulfilled} />
                          ) : (
                            <UnfulfilledIcon color="error" />
                          )}
                          <Typography className={classes.passwordRequirementsItem} variant="body1">
                            &nbsp;Must have at least one number
                          </Typography>
                        </li>
                        <li>
                          {passwordRequirements.hasUppercase ? (
                            <FulfilledIcon className={classes.fulfilled} />
                          ) : (
                            <UnfulfilledIcon color="error" />
                          )}
                          <Typography className={classes.passwordRequirementsItem} variant="body1">
                            &nbsp;Must have at least one uppercase character
                          </Typography>
                        </li>
                        <li>
                          {passwordRequirements.hasSpecial ? (
                            <FulfilledIcon className={classes.fulfilled} />
                          ) : (
                            <UnfulfilledIcon color="error" />
                          )}
                          <Typography className={classes.passwordRequirementsItem} variant="body1">
                            &nbsp;Must have at least one special character
                          </Typography>
                        </li>
                      </ul>
                    </Grid>
                  </Collapse>
                  <Grid item xs={12}>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: {
                          passwordEquality: (value) => value === getValues().password,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          id="confirmPassword"
                          label="Confirm Password"
                          error={errors.confirmPassword && errors.confirmPassword !== null}
                          helperText={
                            errors.confirmPassword
                              ? errorMessages["confirmPassword"][errors.confirmPassword.type]
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                      Change Password
                    </Button>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Link href="/authentication/forgot-password" variant="body2">
                        <b>Get a new password reset link</b>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapState = (state) => {
  return {
    error: state.authentication.error,
    loading: state.authentication.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(ResetPassword);
