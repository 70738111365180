import React, { useEffect, useState } from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { connect } from "react-redux";
import { setBuilding, setProperty, setLease, setRefreshProperty } from "../../../redux/actions/buildingActions";

import { getBuilding } from "../../../services/buildingServices";
import { getProperty } from "../../../services/propertyServices";
import Loading from "../../components/Loading";
import Property from "./Property";
import Ledger from "./Ledger";
import LeaseSetup from "./LeaseSetup";
import ConditionReport from "./ConditionReport";
import Forms from "./forms";
import Lease from "./Lease";
import ROI from "./ROI";
import PropertyMenu from "../components/PropertyMenu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "none",
    [`${theme.breakpoints.down("xs")}`]: {
      display: "flex",
      top: "auto",
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "var(--tsl-brand-border-radius) var(--tsl-brand-border-radius) 0 0",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px -3px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

function PropertyRouter(props) {
  const { property_id } = useParams();
  const classes = useStyles();
  const location = useLocation();

  const [historyExpanded, setHistoryExpanded] = useState(false);
  useEffect(() => {
    const currentPropertyPage = location.pathname.split("/")[2];
    if (historyExpanded && !currentPropertyPage?.includes("lease")) setHistoryExpanded(false);
  }, [location]);

  const checkProperty = async () => {
    let propertyReceived = null;
    let buildingReceived = null;
    if (/^-?\d+$/.test(property_id)) {
      propertyReceived = await getProperty({
        ID: property_id,
        nestLeases: true,
        nestActiveLeases: true,
      });
      console.log("propertyReceived: ", propertyReceived);
      if (propertyReceived["activeLease"]) props.dispatch(setLease(propertyReceived["activeLease"]));
      else if (propertyReceived["leases"]?.length > 0) props.dispatch(setLease(propertyReceived["leases"][0]));
      else props.dispatch(setLease(null));
      buildingReceived = await getBuilding({ ID: propertyReceived["buildingID"] });
    }

    if (!propertyReceived || !propertyReceived["ID"] || propertyReceived["ID"] != property_id) {
      props.history.push("/properties");
      return;
    }

    props.dispatch(setBuilding(buildingReceived));
    props.dispatch(setProperty(propertyReceived));

    if (!propertyReceived["activeLease"] && propertyReceived["leases"]?.length == 0) {
      const currentPropertyPage = location.pathname.split("/")[2];
      let leaseRequiredPages = ["ledger", "forms"];
      if (leaseRequiredPages.includes(currentPropertyPage)) props.history.push(`/${propertyReceived["ID"]}/lease`);
    }
  };

  useEffect(() => {
    checkProperty();
    props.dispatch(setRefreshProperty(checkProperty));
  }, []);

  const pageIsSetup = () => {
    const currentPropertyPage = location.pathname.split("/")[2];
    if (currentPropertyPage == "setup") return true;
    return false;
  };

  if (!props.property || !props.property["ID"] || props.property["ID"] != property_id) return <Loading />;
  if (
    !props.building ||
    !props.building["ID"] ||
    (props.property["status"] !== "paid" && props.property["subscriptionID"])
  )
    props.history.push("/properties");
  //if (!pageIsSetup() && (!props.lease || !props.lease["ID"])) return <Loading />;

  return (
    <>
      <Switch>
        <Route path="/:property_id/condition-report" render={() => <ConditionReport history={props.history} />} />
        <Route path="/:property_id/setup" render={() => <LeaseSetup history={props.history} />} />
        <Route
          path="/:property_id/lease"
          render={() => (
            <Lease historyExpanded={historyExpanded} setHistoryExpanded={setHistoryExpanded} history={props.history} />
          )}
        />
        <Route
          path="/:property_id/ledger"
          render={() => (
            <Ledger historyExpanded={historyExpanded} setHistoryExpanded={setHistoryExpanded} history={props.history} />
          )}
        />
        <Route
          path="/:property_id/forms"
          render={() => (
            <Forms historyExpanded={historyExpanded} setHistoryExpanded={setHistoryExpanded} history={props.history} />
          )}
        />
        <Route path="/:property_id/roi" render={() => <ROI history={props.history} />} />
        <Route path="/:property_id" render={() => <Property history={props.history} />} />
      </Switch>

      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <PropertyMenu mobile />
        </Toolbar>
      </AppBar>
    </>
  );
}

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};
export default connect(mapState, mapDispatch)(PropertyRouter);
