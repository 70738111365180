import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PropertyIcon from "@material-ui/icons/HomeRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import { getBuilding } from "../../services/buildingServices";
import { getPicture } from "../../services/property/pictureServices";
import { getROIData } from "../../services/property/roiServices";
import { getTenants } from "../../services/property/tenantServices";
import { deleteSubscription } from "../../services/billingServices";

import { getToken, addToken } from "../../utils/notification.jsx";
import { editUser } from "../../services/userServices.js";
import ToastFactory from "../components/ToastFactory";
import AddPropertyDoubleDialog from "./components/AddPropertyDoubleDialog";
import Loading from "../components/Loading";
import PropertyCard from "./components/PropertyCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(4),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2em",
    },
  },
  addPropertyIcon: {
    fontSize: "1.8em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.6em",
    },
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  emptyProperties: {
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  emptyPropertiesIcon: {
    fontSize: "5.5em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "4.5em",
    },
  },
  emptyPropertiesTextTop: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.4em",
    },
  },
  emptyPropertiesTextBot: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
    },
  },
  tipArrow: {
    "&:before": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tipBubble: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "10px 20px",
  },
}));

function Properties(props) {
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [propertyImages, setPropertyImages] = useState({});
  const [propertyROIData, setPropertyROIData] = useState({});
  const [propertyTenants, setPropertyTenants] = useState({});
  const [totalProperties, setTotalProperties] = useState(0);
  const [tips, setTips] = useState(props?.profile && "tips" in props?.profile ? JSON.parse(props?.profile?.tips) : {});
  const [enableNotisDialog, setEnableNotisDialog] = useState(false);
  const [isLoaded, setIsLoaded] = useState({
    images: false,
    ROI: false,
    tenants: false,
  });

  const getBuildings = async () => {
    let buildingsReceived = await getBuilding({
      nestProperties: true,
      nestLeases: true,
      nestActiveLeases: true,
    });
    console.log("buildingsReceived: ", buildingsReceived, buildingsReceived?.length);
    setBuildings(buildingsReceived);

    let totalProperties = 0;
    for (let building of buildingsReceived) totalProperties += building?.properties?.length;
    setTotalProperties(totalProperties);

    const firstProperty = buildingsReceived[0]?.properties[0];
    if (firstProperty?.subscriptionID)
      await deleteSubscription({ subscriptionID: firstProperty?.subscriptionID, propertyID: firstProperty?.ID });
  };

  const getPropertyImage = async () => {
    for (let building of buildings) {
      if (building["properties"].length == 0) continue;

      let properties = building["properties"];
      for (let property of properties) {
        if (!property["images"]) {
          setPropertyImages((_propertyImages) => {
            return {
              ..._propertyImages,
              [property["ID"]]: null,
            };
          });
        } else {
          let imageArray = JSON.parse(property.images);
          if (imageArray && imageArray[0] !== "") {
            let picture = await getPicture({
              propertyID: property["ID"],
              imageIndex: 0,
            });
            setPropertyImages((_propertyImages) => {
              return {
                ..._propertyImages,
                [property["ID"]]: picture,
              };
            });
          }
        }
      }
    }

    setIsLoaded((_isLoaded) => {
      return { ..._isLoaded, images: true };
    });
  };

  const getPropertyROIData = async () => {
    for (let building of buildings) {
      if (building["properties"].length == 0) continue;
      let properties = building["properties"];
      for (let property of properties) {
        if (!property["activeLease"] && property["leases"].length == 0) continue;
        let lease = property["activeLease"] ? property["activeLease"] : property["leases"][0];

        let roiData = await getROIData(building, property, lease);
        setPropertyROIData((_propertyROIData) => {
          return {
            ..._propertyROIData,
            [property["ID"]]: roiData,
          };
        });
      }
    }

    setIsLoaded((_isLoaded) => {
      return { ..._isLoaded, ROI: true };
    });
  };

  const getPropertyTenants = async () => {
    for (let building of buildings) {
      if (building["properties"].length == 0) continue;
      let properties = building["properties"];
      for (let property of properties) {
        if (!property["activeLease"]) continue;
        let lease = property["activeLease"];

        let tenants = await getTenants({ leaseID: lease["ID"] });
        setPropertyTenants((_propertyTenants) => {
          return {
            ..._propertyTenants,
            [property["ID"]]: tenants,
          };
        });
      }
    }

    setIsLoaded((_isLoaded) => {
      return { ..._isLoaded, tenants: true };
    });
  };

  useEffect(() => {
    getBuildings();
  }, []);

  useEffect(() => {
    if (buildings) {
      getPropertyImage();
      getPropertyROIData();
      getPropertyTenants();
    }
  }, [buildings]);

  useEffect(() => {
    const profileTips = props.profile?.tips ? JSON.parse(props.profile.tips) : {};
    setTips(profileTips);
    setEnableNotisDialog(!profileTips?.enableNotis ? true : false);
  }, [props.profile]);

  const [addPropertyDialog, setAddPropertyDialog] = useState(false);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  if (!buildings) return <Loading />;

  const enableNotificationsDialog = (
    <Dialog
      open={enableNotisDialog && !tips?.enableNotis}
      onClose={(reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return false;
      }}
      TransitionComponent={Transition}
      disableEscapeKeyDown
    >
      {enableNotisDialog !== "loading" ? (
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Do you want to enable notifications on this device?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                We highly recommend it as you will receive important alerts and updates about your properties
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={async () => {
                  setEnableNotisDialog("loading");
                  const token = await getToken();
                  if (token) {
                    await addToken(token, null, addToast);
                    await editUser({ tips: { ...tips, enableNotis: true } }, null, props.dispatch);
                    setEnableNotisDialog(false);
                  } else {
                    setEnableNotisDialog(true);
                    addToast("Error Occured. Make Sure Notifications Are Allowed On Your Browser", "error");
                  }
                }}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={async () => {
                  // await editUser({ tips: { ...tips, enableNotis: true } }, null, props.dispatch);
                  setEnableNotisDialog(false);
                }}
                variant="contained"
                className={classes.deleteBtn}
              >
                Maybe Later
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <Loading />
      )}
    </Dialog>
  );

  return (
    <>
      <ToastFactory toasts={toasts} />
      {enableNotificationsDialog}
      <AddPropertyDoubleDialog
        addToast={addToast}
        addPropertyDialog={addPropertyDialog}
        setAddPropertyDialog={setAddPropertyDialog}
        updateProperties={getBuildings}
        totalProperties={totalProperties}
      />

      <Grid
        container
        style={{
          paddingBottom: 10,
          flexDirection: "column",
          ...(totalProperties == 0 && buildings.length <= 0 ? { minHeight: "calc(100vh - 65px)" } : {}),
        }}
      >
        <Toolbar>
          <Typography gutterBottom variant="h4" align="center" className={classes.header}>
            My Properties
          </Typography>

          <Tooltip
            placement="bottom"
            arrow
            open={!tips?.addProperty}
            classes={{
              tooltip: classes.tipBubble,
              arrow: classes.tipArrow,
            }}
            PopperProps={{ style: { marginTop: "-20px" } }}
            title={<Typography>Click to add your first property!</Typography>}
          >
            <div>
              <Tooltip title="Add Property" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                <IconButton
                  onClick={() => {
                    setAddPropertyDialog(true);
                    editUser({ tips: { ...tips, addProperty: true } }, null, props.dispatch);
                  }}
                  style={{ marginTop: "22px" }}
                >
                  <AddIcon className={classes.addPropertyIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </Tooltip>
        </Toolbar>
        {totalProperties == 0 && buildings.length <= 0 && (
          <Grid container className={classes.emptyProperties}>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <PropertyIcon className={classes.emptyPropertiesIcon} />
              <Typography variant="h5" align="center" className={classes.emptyPropertiesTextTop}>
                No Properties
              </Typography>
              <Typography variant="h6" align="center" className={classes.emptyPropertiesTextBot}>
                Press the <AddIcon style={{ marginBottom: "-5px" }} /> icon to add your first one
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {buildings.map((building) => {
        if (building?.ID)
          return (
            <PropertyCard
              key={building.ID}
              buildings={buildings}
              setBuildings={setBuildings}
              building={building}
              propertyImages={propertyImages}
              propertyTenants={propertyTenants}
              propertyROIData={propertyROIData}
              refreshProperty={getBuildings}
              totalProperties={totalProperties}
              isLoaded={isLoaded}
              history={props.history}
            />
          );
      })}
    </>
  );
}

const mapState = (state) => {
  return {
    profile: state.authentication.profile,
    clonedUser: state.authentication.clonedUser,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(Properties);
