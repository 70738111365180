import axios from "axios";
import setAxiosDefaultSettings from "../utils/axiosSettings.js";
import { getAlerts } from "./property/alertServices";

setAxiosDefaultSettings(axios);

export const getProperty = async (propertyData = {}) => {
  const clonedUserID = JSON.parse(getCookieValue("clonedUser") || "{}").id;
  return await axios
    .get(`/v2/buildings/properties/?timestamp=${new Date().getTime()}`, {
      params: { ...propertyData, clonedUserID },
    })
    .then((response) => response.data)
    .catch(() => false);
};

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const addProperty = async (propertyData, handleErrors) => {
  return await updateProperty(axios.post, propertyData, handleErrors);
};

export const editProperty = async (propertyData, handleErrors) => {
  return await updateProperty(axios.put, propertyData, handleErrors);
};

export const deleteProperty = async (propertyData, handleErrors, dispatch) => {
  const propertyDeleted = await updateProperty(axios.delete, { data: propertyData }, handleErrors);
  if (dispatch) await getAlerts(dispatch);
  return propertyDeleted;
};

const updateProperty = async (axiosMethod, propertyData, handleErrors = null) => {
  let propertyReceived = false;
  let responseError = null;

  await axiosMethod("/v2/buildings/properties/", propertyData)
    .then(async (response) => {
      if (response.config?.method === "delete") propertyReceived = true;
      else if (response.data) propertyReceived = response.data;
      else {
        responseError = response.data?.error || "Updating Property failed";
        console.log("No property", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Property Information";
      if (responseError?.unitNum == "alreadyExists") propertyReceived = "alreadyExists";
    });

  if (handleErrors) handleErrors(responseError);

  return propertyReceived;
};
