import React, { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

import RadioOption from "../../../components/RadioOption";
import RadioOptions from "../../../components/RadioOptions";
import MultiStepForm from "../../../components/MutliStepForm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { editAlert } from "../../../../services/property/alertServices";
import { deleteLease } from "../../../../services/property/leaseServices";

const errorMessages = {
  reschedule: {
    required: "Please select whether you'd like to reschedule or cancel the move-in",
    pattern: "Please select whether you'd like to reschedule or cancel the move-in",
  },
  moveInDate: {
    required: "Please select the new move-in date",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
    futureDate: "Please select a move-in date that is in the future",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(6, 3, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
    },
  },
  question: {
    fontWeight: "600",
    fontSize: "1.8rem",
    marginBottom: theme.spacing(4),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(2),
      fontSize: "1.4rem",
    },
  },
  answer: {
    marginBottom: theme.spacing(2),
  },
}));

let onSubmit = null;
function RescheduleMoveInForm(props) {
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState({});
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});

  const classes = useStyles();

  const errorAndHelperText = (fieldName) => {
    return {
      error: errors && errors[fieldName] && errors[fieldName] !== null,
      helperText: errors && errors[fieldName] ? errorMessages[fieldName][errors[fieldName].type] : null,
    };
  };

  let formSteps = [
    {
      section: "Reschedule Move-In",
      fieldNames: "reschedule",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Would you like to reschedule the move-in or <span style={{ whiteSpace: "nowrap" }}>cancel it?</span>
          </Typography>
          <Controller
            name="reschedule"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                value={value}
                className={classes.answer}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("reschedule", errorMessages)}
              >
                <RadioOption value="1" label="Reschedule move-in" className={classes.answer} />
                <RadioOption value="0" label="Cancel move-in" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Schedule Move-In",
      fieldNames: "moveInDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What is the new <span style={{ whiteSpace: "nowrap" }}>move-in date?</span>
          </Typography>
          <Controller
            name="moveInDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
              validate: {
                futureDate: (value) => moment(value).isAfter(moment(), "day"),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Move-In Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("moveInDate", errorMessages)}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log(props.lease);
    if (!formData) return;

    let skipping = [];

    if (formData?.reschedule !== "1") skipping.push("moveInDate");

    setStepsToSkip(skipping);
  }, [formData]);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
      setErrorReceived(errors);
    }
  };

  const submitForm = async (data) => {
    if (data.reschedule == "1") {
      console.log(props.alert, formData.moveInDate);
      if (props.alert) {
        let alertAdded = await editAlert(
          {
            ID: props.alert["ID"],
            type: "moveIn",
            data: { date: formData?.moveInDate },
          },
          props.dispatch,
          handleErrors
        );

        if (!alertAdded) {
          props.addToast("An Error Occured While Rescheduling Move-In", "error");
          return false;
        }
      }
      props.addToast("Move-In Rescheduled", "success");
    } else if (data.reschedule == "0") {
      if (props.lease) await deleteLease({ ID: props.lease["ID"] }, props.dispatch);
      if (props.refreshProperty) await props.refreshProperty();
      props.addToast("Move-In Cancelled", "success");
    }
    props.callback();
  };

  return (
    <div className={classes.main}>
      <MultiStepForm
        formSteps={formSteps}
        stepsToSkip={stepsToSkip}
        formData={formData}
        setFormData={setFormData}
        submitForm={submitForm}
        handleSubmit={handleSubmit}
        setOnSubmit={(func) => (onSubmit = func)}
        errorReceived={errorReceived}
        toasts={[]}
        hideStepper
        classes={{
          container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
          mobilePadding: { padding: "0px" },
        }}
      />
    </div>
  );
}

export const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(RescheduleMoveInForm);
