import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ReactBasicScroll } from "react-basic-scroll";
import Header from "../components/LoggedOutHeader";
import { motion } from "framer-motion";
import ITyped from "react-ityped";
import VisitedSensor from "./components/VisitedSensor";
import CountUp from "./components/VisibilityCountUp";
import GavelRoundedIcon from "@material-ui/icons/GavelRounded";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import Button from "@material-ui/core/Button";
import { useTheme, useMediaQuery } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDownRounded";
import VideoAutoPlayer from "./components/VideoAutoPlayer";
import ReactBasicScrollPro from "./components/ReactBasicScrollPro";
import { Brightness1 } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "black",
    minHeight: "100vh",
  },
  gridContainer: {
    minHeight: "100vh",
    padding: "20px 5vw",
  },
  smallGridContainer: {
    minHeight: "75vh",
    padding: "20px 5vw",
  },
  gridItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
  },
  bannerLogo: {
    maxWidth: "90%",
    width: "500px",
  },
  bannerSlogan: {
    marginTop: "80px",
    fontSize: "3.6vw",
    fontWeight: "700",
    lineHeight: "40px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      fontSize: "7.5vw",
    },
  },
  bannerSimple: {
    display: "inline",
    width: "14vw",
    transform: "translateY(20%)",
    marginLeft: "1vw",
    [theme.breakpoints.down("xs")]: {
      width: "28vw",
    },
  },
  callToAction: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(6)}px`,
    fontSize: "1.2vw",
    borderRadius: "1000px",
    marginTop: "60px",
    backgroundColor: "rgb(0, 119, 255, 0.6)",
    textTransform: "capitalize",
    ["&:hover"]: {
      backgroundColor: "rgb(0, 119, 255, 0.75)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      fontSize: "5vw",
    },
  },
  bannerImage: {
    position: "relative",
    maxHeight: "100vh",
    maxWidth: "900px",
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  bannerPhone: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  bannerProperty: {
    position: "absolute",
    top: "15%",
    left: "30%",
    transform: "translateX(-50%)",
    maxWidth: "100%",
    maxHeight: "45%",
  },
  bannerROI: {
    position: "absolute",
    bottom: "10%",
    left: "60%",
    transform: "translateX(-50%)",
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "35%",
  },
  scrollDiv: {
    position: "absolute",
    bottom: "25px",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    fontSize: "1.4em",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  headText: {
    fontSize: "3.6vw",
    fontWeight: "700",
    lineHeight: "1.15em",
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8vw",
    },
  },
  mobileFrame: {
    position: "absolute",
    top: "20px",
    left: "50%",
    maxWidth: "100%",
    maxHeight: "90vh",
    transform: "translateX(-50%)",
  },
  screenshotDiv: {
    position: "sticky",
    top: "20px",
    width: "100%",
    maxHeight: "calc(100vh)",
  },
  screenshot: {
    position: "absolute",
    top: "20px",
    left: "50%",
    maxWidth: "100%",
    maxHeight: "90vh",
    padding: "30px 0px",
    transform: "translateX(-50%)",
    clipPath: "inset(0px round 5vh)",
    [theme.breakpoints.down("xs")]: {
      filter: "brightness(0.7)",
    },
  },
  sellingPoint: {
    fontSize: "4vw",
    fontWeight: "900",
    lineHeight: "5vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "33%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "11vw",
      padding: "0px 30px",
      textAlign: "center",
      lineHeight: "12vw",
    },
  },
  lastPoint: {
    fontSize: "2vw",
    fontWeight: "500",
  },
  staffDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "100vh",
    width: "90vw",
    fontWeight: "700",
    fontSize: "1.8em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "4em",
    },
  },
  staffIcon: {
    fontSize: "2.5vw",
    color: "rgb(0, 119, 255, 0.75)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "6.5vw",
    },
  },
  statistics: {
    fontSize: "3.5vw",
    fontWeight: "800",
    color: "rgb(0, 119, 255, 0.75)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8vw",
    },
  },
}));

let defaultFormFiles = ["cs", "l1", "l2", "l9"];
for (let i = 1; i < 14; i++) {
  if (i == 9) continue;
  defaultFormFiles.push("n" + i);
}

function LandingPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [formFiles, setFormFiles] = useState([...defaultFormFiles, ...defaultFormFiles]);

  let sellingPoints = [
    [
      <>
        Generate <span style={{ whiteSpace: "nowrap" }}>your lease</span> agreement in just a few clicks
      </>,
    ],
    [
      <>
        <p>Track your rental income</p>
        <p style={{ marginTop: "-1.2vw" }}>Print reports on the fly</p>
      </>,
    ],
    ["Track your investment property's performance"],
    [
      <>
        Keep your paperwork organized all in <span style={{ color: theme.palette.primary.main }}>1</span> place
      </>,
    ],
    ["Search for the right Landlord Tenant Board forms", "Fill out any form at the click of a button"],
  ];

  const fadeIn = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  const fadeInUp = {
    show: { opacity: 1, y: 0 },
    hide: { opacity: 0, y: "15%" },
  };

  const fadeInRight = {
    show: { opacity: 1, x: 0 },
    hide: { opacity: 0, x: "-15%" },
  };

  return (
    <div className={classes.main}>
      <Header />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <VisitedSensor permanent>
            {(ref, visited) => (
              <motion.img
                initial={"hide"}
                ref={ref}
                animate={visited ? "show" : "hide"}
                variants={fadeInRight}
                transition={{ delay: 1 }}
                className={classes.bannerLogo}
                src="/LandingPage/SI Landlord.png"
              />
            )}
          </VisitedSensor>
          <VisitedSensor permanent>
            {(ref, visited) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited ? "show" : "hide"}
                variants={fadeInRight}
                transition={{ delay: 1.2 }}
                className={classes.bannerSlogan}
              >
                Making The Life Of Landlords... <img className={classes.bannerSimple} src="/LandingPage/Simple.svg" />
              </motion.div>
            )}
          </VisitedSensor>
          <VisitedSensor permanent>
            {(ref, visited) => (
              <Button
                initial={"hide"}
                ref={ref}
                animate={visited ? "show" : "hide"}
                variants={fadeInRight}
                transition={{ delay: 1.3 }}
                component={motion.button}
                className={classes.callToAction}
                onClick={() => props.history.push("../authentication/signup")}
              >
                Get Started
              </Button>
            )}
          </VisitedSensor>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <div className={classes.bannerImage}>
            <img className={classes.bannerPhone} src="/LandingPage/Banner Phone.png" />
            <ReactBasicScroll
              config={{
                from: "0px",
                to: window.innerHeight + "px",
                props: {
                  [`--bannedPropertyTranslateY`]: {
                    from: "0px",
                    to: -(window.innerHeight / 3) + "px",
                  },
                },
              }}
            >
              <img
                className={classes.bannerProperty}
                style={{ transform: "translate(-50%, var(--bannedPropertyTranslateY))" }}
                src="/LandingPage/Banner Property.png"
              />
            </ReactBasicScroll>
            <ReactBasicScroll
              config={{
                from: "0px",
                to: window.innerHeight + "px",
                props: {
                  [`--bannedROITranslateY`]: {
                    from: "0px",
                    to: -(window.innerHeight / 5) + "px",
                  },
                },
              }}
            >
              <img
                className={classes.bannerROI}
                style={{ transform: "translate(-50%, var(--bannedROITranslateY))" }}
                src="/LandingPage/Banner ROI.png"
              />
            </ReactBasicScroll>
          </div>
        </Grid>
        <div className={classes.scrollDiv}>
          <motion.div animate={{ y: 10 }} transition={{ yoyo: Infinity }}>
            <div>Scroll To Learn More</div>
            <KeyboardArrowDown />
          </motion.div>
        </div>
      </Grid>
      <Grid container className={classes.gridContainer} style={{ justifyContent: "center", gap: "50px" }}>
        <VisitedSensor percentHeight={0.75} percentTop={0.15} permanent>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ duration: passed ? 1 : 2 }}
              className={classes.headText}
              style={{ justifyContent: "flex-end", minHeight: "50vh" }}
            >
              Why did you decide to become a landlord?
            </motion.div>
          )}
        </VisitedSensor>
        <VisitedSensor percentHeight={0.85} percentTop={0.6} permanent>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ delay: 0.1, duration: 2 }}
              className={classes.headText}
              style={{
                justifyContent: "flex-start",
                fontSize: breakpointsDownXS ? "7vw" : "3vw",
                color: theme.palette.primary.main,
                minHeight: "50vh",
              }}
            >
              <ITyped
                className="container ityped-cursor"
                showCursor={false}
                style={{ minHeight: "100px" }}
                strings={[
                  "Free up your time",
                  "Earn passive income",
                  "Invest for retirement",
                  "Planned or unplanned life style changes",
                ]}
                typeSpeed={50}
                backSpeed={20}
                startDelay={500}
                backDelay={2000}
              />
            </motion.div>
          )}
        </VisitedSensor>
      </Grid>
      <Grid container className={classes.smallGridContainer} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <VisitedSensor percentHeight={0.85} percentTop={0.15}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
              >
                No matter the case
                <p style={{ fontSize: breakpointsDownXS ? "6vw" : "2.3vw" }}>
                  Being a landlord can quickly become a full-time job, if you're not prepared
                </p>
              </motion.div>
            )}
          </VisitedSensor>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem} style={{ paddingLeft: "20px" }}>
          <VisitedSensor percentHeight={breakpointsDownXS ? 0.95 : 0.7} percentTop={0.25}>
            {(ref, visited, passed) => (
              <motion.img
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ delay: breakpointsDownXS ? 0 : 0.2, duration: passed ? 1 : 2 }}
                style={{ maxWidth: "100%" }}
                src="/LandingPage/fulltime work.png"
              />
            )}
          </VisitedSensor>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.smallGridContainer}
        style={{ justifyContent: "center", gap: "50px", padding: "0 10vw" }}
      >
        <VisitedSensor percentHeight={0.8} percentTop={-0.35}>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ duration: passed ? 1 : 2 }}
              className={classes.headText}
              style={{ justifyContent: "flex-end", fontSize: breakpointsDownXS ? "5.5vw" : "2.6vw", minHeight: "50vh" }}
            >
              There are a lot of legal requirements and common problems that the average landlord isn’t ready to face
              when starting off
            </motion.div>
          )}
        </VisitedSensor>
        <VisitedSensor percentHeight={0.95} percentTop={0.2}>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ delay: !passed ? 0.1 : 0, duration: 2 }}
              className={classes.headText}
              style={{
                justifyContent: "flex-start",
                fontSize: breakpointsDownXS ? "7vw" : "2.6vw",
                color: theme.palette.primary.main,
                minHeight: "50vh",
              }}
            >
              This is because Ontario is focused on protecting the tenant first
            </motion.div>
          )}
        </VisitedSensor>
      </Grid>
      <Grid container className={classes.smallGridContainer} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <VisitedSensor percentHeight={0.85} percentTop={0.15}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
              >
                <p style={{ fontSize: breakpointsDownXS ? "6vw" : "2.8vw", lineHeight: "1.1em" }}>
                  The Standard Lease Agreement is required by the province in order to lease out a unit
                </p>
                <p style={{ fontSize: breakpointsDownXS ? "5vw" : "2.2vw", lineHeight: "1.1em" }}>
                  Filling it out can be daunting for a lot of new landlords, especially with the number of choices you
                  need to make
                </p>
              </motion.div>
            )}
          </VisitedSensor>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem} style={{ paddingLeft: "20px" }}>
          <VisitedSensor percentHeight={0.7} percentTop={0.35}>
            {(ref, visited, passed) => (
              <motion.img
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ delay: 0.2, duration: passed ? 1 : 2 }}
                style={{ maxWidth: "100%" }}
                src="/LandingPage/Standard Lease Agreement.png"
              />
            )}
          </VisitedSensor>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.gridContainer}
        style={{ justifyContent: "center", flexDirection: breakpointsDownXS ? "column-reverse" : "row" }}
      >
        <Grid item xs={12} sm={6} className={classes.gridItem} style={{ paddingLeft: "20px" }}>
          <VisitedSensor percentHeight={0.8} percentTop={0.15}>
            {(ref, visited, passed) => (
              <motion.img
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ delay: 0.2, duration: passed ? 1 : 2 }}
                style={{ maxWidth: "100%" }}
                src="/LandingPage/LTB logo.png"
              />
            )}
          </VisitedSensor>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={breakpointsDownXS ? { marginBottom: "20px" } : {}}
        >
          <VisitedSensor percentHeight={0.7} percentTop={0.2}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
              >
                <p style={{ fontSize: breakpointsDownXS ? "6vw" : "2.8vw", margin: 0, lineHeight: "1.1em" }}>
                  What if you want to:
                </p>
                <p style={{ fontSize: breakpointsDownXS ? "4.5vw" : "2vw", lineHeight: "1.2em" }}>
                  &bull; Increase the rent at the end of the year
                  <br />
                  &bull; Get missed payments from your tenant
                  <br />
                  &bull; Move into your property
                  <br />
                </p>
                <p style={{ fontSize: breakpointsDownXS ? "5vw" : "2.2vw", margin: 0, lineHeight: "1.1em" }}>
                  In any of those cases, you would have to get an approval from the{" "}
                  <span style={{ color: theme.palette.primary.main }}>Landlord Tenant Board (LTB)</span> first
                </p>
              </motion.div>
            )}
          </VisitedSensor>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.gridContainer}
        style={{ padding: "0", justifyContent: "center", gap: "50px", overflow: "hidden" }}
      >
        <div style={{ position: "relative", width: "100%", padding: breakpointsDownXS ? "30px" : "0vw 10vh" }}>
          <VisitedSensor percentHeight={0.85} percentTop={-0.35}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
                style={{ fontSize: breakpointsDownXS ? "5.5vw" : "2.4vw", height: "50vh", justifyContent: "flex-end" }}
              >
                <div>
                  You would need to fill out the proper paperwork, and even attend LTB hearings to make the smallest
                  change to your lease
                </div>
                <div style={{ marginTop: "50px" }}>
                  Which forms would you submit if you need to
                  <div style={{ height: "75px", color: theme.palette.primary.main }}>
                    <ITyped
                      className="container ityped-cursor"
                      showCursor={false}
                      strings={[
                        "receive missed payments?",
                        "increase your rent?",
                        "evict the tenant to make major repairs?",
                      ]}
                      typeSpeed={50}
                      backSpeed={20}
                      startDelay={500}
                      backDelay={3000}
                    />
                  </div>
                </div>
              </motion.div>
            )}
          </VisitedSensor>
          <VisitedSensor percentHeight={0.85} percentTop={-0.35}>
            {(ref, visited, passed) => (
              <Grid
                item
                xs={12}
                ref={ref}
                component={motion.div}
                initial={{ x: "0%" }}
                animate={{ x: "-200%" }}
                transition={{ duration: 3 * (formFiles.length / 2), ease: "linear", repeat: Infinity, repeatDelay: 0 }}
                className={classes.gridItem}
                style={{ position: "absolute", left: "150vw", bottom: "10vh", padding: "0", flexDirection: "row" }}
              >
                {formFiles.map((formFile, index) => (
                  <img
                    key={index}
                    style={{ width: breakpointsDownXS ? "50vw" : `${(100 * 2) / (formFiles.length / 2)}vw` }}
                    src={`/LTBFormThumbnails/${formFile}.jpg`}
                  />
                ))}
              </Grid>
            )}
          </VisitedSensor>
        </div>
      </Grid>
      <Grid container className={classes.gridContainer} style={{ padding: 0 }}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={breakpointsDownXS ? { padding: "0px 30px", justifyContent: "flex-end" } : {}}
        >
          <VisitedSensor percentHeight={0.7} percentTop={0.2}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
              >
                <p style={{ fontSize: breakpointsDownXS ? "6vw" : "2.8vw", margin: 0, lineHeight: "1.1em" }}>
                  What if your tenant damages <span style={{ whiteSpace: "nowrap" }}>your unit?</span>
                </p>
                <p
                  style={{
                    fontSize: breakpointsDownXS ? "5.5vw" : "2.6vw",
                    marginTop: 20,
                    lineHeight: "1.1em",
                    color: theme.palette.primary.main,
                  }}
                >
                  Are you going to be able to prove the property's original condition to the LTB?
                </p>
              </motion.div>
            )}
          </VisitedSensor>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ paddingLeft: "20px", justifyContent: breakpointsDownXS ? "flex-start" : "" }}
        >
          <VideoAutoPlayer replayOnReappearance percentHeight={0.55} percentTop={-0.35}>
            {(ref) => (
              <video
                ref={ref}
                tabIndex="0"
                autobuffer="autobuffer"
                preload="preload"
                muted="muted"
                style={{
                  width: "100%",
                }}
                playsInline
              >
                <source type="video/mp4" src="/LandingPage/ground cracking.mp4" />
              </video>
            )}
          </VideoAutoPlayer>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.gridContainer}
        style={{ justifyContent: "center", flexDirection: breakpointsDownXS ? "column-reverse" : "row" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ paddingLeft: "20px", justifyContent: breakpointsDownXS ? "flex-start" : "" }}
        >
          <VisitedSensor percentHeight={0.8} percentTop={0.15}>
            {(ref, visited, passed) => (
              <motion.img
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ delay: 0.2, duration: passed ? 1 : 2 }}
                style={{ maxWidth: "100%" }}
                src="/LandingPage/Mortgage.png"
              />
            )}
          </VisitedSensor>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={breakpointsDownXS ? { padding: "0px 30px", justifyContent: "flex-end" } : {}}
        >
          <VisitedSensor percentHeight={0.7} percentTop={0.2}>
            {(ref, visited, passed) => (
              <motion.div
                initial={"hide"}
                ref={ref}
                animate={visited && !passed ? "show" : "hide"}
                variants={fadeIn}
                transition={{ duration: passed ? 1 : 2 }}
                className={classes.headText}
                style={{ fontSize: breakpointsDownXS ? "5.5vw" : "2.4vw" }}
              >
                <p style={{ fontSize: breakpointsDownXS ? "5.5vw" : "2.6vw", margin: 0, lineHeight: "1.1em" }}>
                  Even when you’re not dealing with the government and want to get your{" "}
                  <span style={{ whiteSpace: "nowrap" }}>next mortgage:</span>
                </p>
                <p
                  style={{
                    fontSize: breakpointsDownXS ? "5.5vw" : "2.4vw",
                    marginTop: 20,
                    lineHeight: "1.1em",
                    color: theme.palette.primary.main,
                  }}
                >
                  Lenders insist on a different set of paperwork &amp; documentation to consider your rental income
                </p>
              </motion.div>
            )}
          </VisitedSensor>
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer} style={{ justifyContent: "center", gap: "50px" }}>
        <VisitedSensor percentHeight={0.75} percentTop={0.15} permanent>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ duration: passed ? 1 : 2 }}
              className={classes.headText}
              style={{ justifyContent: "flex-end", minHeight: "50vh" }}
            >
              Being a landlord can feel too complicated at times
            </motion.div>
          )}
        </VisitedSensor>
        <VisitedSensor percentHeight={0.65} percentTop={0.6} permanent>
          {(ref, visited, passed) => (
            <motion.div
              initial={"hide"}
              ref={ref}
              animate={visited && !passed ? "show" : "hide"}
              variants={fadeIn}
              transition={{ duration: passed ? 1 : 2 }}
              className={classes.headText}
              style={{ justifyContent: "flex-start", minHeight: "50vh" }}
            >
              <p style={{ fontSize: "1.5em", margin: 0, ...(breakpointsDownXS ? { lineHeight: "normal" } : {}) }}>
                But there's a{" "}
                <img
                  style={{ display: "inline", width: "3.6em", margin: "0em 0.2em -0.28em" }}
                  src="/LandingPage/Simple w Dollar.png"
                />{" "}
                solution...
              </p>
            </motion.div>
          )}
        </VisitedSensor>
      </Grid>
      <div>
        <ReactBasicScrollPro
          configs={[
            {
              relativeFrom: 6,
              relativeTo: 7,
              props: {
                [`--screenshotOpacity`]: {
                  from: 1,
                  to: 0,
                },
              },
            },
            {
              relativeFrom: -0.7,
              relativeTo: 0.5,
              props: {
                [`--phoneFrameOpacity`]: {
                  from: 0,
                  to: 1,
                },
              },
            },
          ]}
        >
          {(containerRef) => (
            <div ref={containerRef} className={classes.screenshotDiv} style={{ opacity: `var(--screenshotOpacity)` }}>
              <img
                className={classes.screenshot}
                style={{ opacity: `var(--phoneFrameOpacity)` }}
                src="/LandingPage/Screenshot 1.png"
              />
              {[2, 3, 4, 5].map((index) => (
                <ReactBasicScrollPro
                  key={index}
                  configs={[
                    {
                      relativeFrom: index - 1,
                      relativeTo: index - 0.6,
                      props: {
                        [`--opacity${index}`]: {
                          from: 0,
                          to: 1,
                        },
                      },
                    },
                  ]}
                >
                  {(imgRef) => (
                    <img
                      ref={imgRef}
                      key={index}
                      className={classes.screenshot}
                      style={{ opacity: `var(--opacity${index})` }}
                      src={`/LandingPage/Screenshot ${index}.png`}
                    />
                  )}
                </ReactBasicScrollPro>
              ))}

              <img
                className={classes.mobileFrame}
                style={{ opacity: `var(--phoneFrameOpacity)` }}
                src="/LandingPage/Mobile Frame.png"
              />
              <div style={{ height: "100vh" }}></div>
            </div>
          )}
        </ReactBasicScrollPro>

        {sellingPoints.map((sellingArray, index) =>
          sellingArray.map((sellingPoint, partIndex) => (
            <Grid
              container
              key={`${index}_${partIndex}`}
              className={classes.gridContainer}
              style={{
                height: "100%",
                ...((index + partIndex) % 2 == 1 &&
                  !breakpointsDownXS && {
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }),
              }}
            >
              <ReactBasicScrollPro
                configs={[
                  {
                    relativeFrom: -1,
                    relativeTo: -1.5,
                    props: {
                      [`--divOpacity${index + partIndex}`]: {
                        from: 0,
                        to: 1,
                      },
                    },
                  },
                  {
                    relativeFrom: -0.2,
                    relativeTo: -0.8,
                    props: {
                      [`--textOpacity${index + partIndex}`]: {
                        from: 1,
                        to: 0,
                      },
                    },
                  },
                ]}
              >
                {(divRef) => (
                  <div
                    ref={divRef}
                    className={classes.sellingPoint}
                    style={{ opacity: `var(--divOpacity${index + partIndex})` }}
                  >
                    <div
                      style={{
                        position: "sticky",
                        top: "50%",
                        textShadow: "0 0 20px black",
                        opacity: `var(--textOpacity${index + partIndex})`,
                        transform: `translateY(-50%)`,
                      }}
                    >
                      {sellingPoint}
                    </div>
                  </div>
                )}
              </ReactBasicScrollPro>
            </Grid>
          ))
        )}
      </div>
      <Grid
        container
        className={`${classes.gridContainer} ${classes.lastPoint}`}
        style={{
          position: "sticky",
          top: "0px",
          maxHeight: "200vh",
          height: "200vh",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <ReactBasicScrollPro
          configs={[
            {
              relativeFrom: 0,
              relativeTo: 1,
              props: {
                [`--lastPointOpacity`]: {
                  from: 0,
                  to: 1,
                },
              },
            },
          ]}
        >
          {(staffRef) => (
            <div key={staffRef} className={classes.staffDiv} style={{ opacity: `var(--lastPointOpacity)` }}>
              Get your own staff of certified:
              <Grid container style={{ margin: "40px 0px 20px", fontSize: breakpointsDownXS ? "4.5vw" : "2vw" }}>
                <Grid item xs={12} sm={3}>
                  <GavelRoundedIcon className={classes.staffIcon} /> Paralegals
                </Grid>
                <Grid item xs={12} sm={3}>
                  <BuildRoundedIcon className={classes.staffIcon} /> Property Managers
                </Grid>
                <Grid item xs={12} sm={3}>
                  <EqualizerRoundedIcon className={classes.staffIcon} /> Accountants
                </Grid>
                <Grid item xs={12} sm={3}>
                  <HomeWorkRoundedIcon className={classes.staffIcon} /> Condominium Managers
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: breakpointsDownXS ? "40px 0px 40px" : "40px 0px 80px",
                  fontSize: breakpointsDownXS ? "4.5vw" : "2vw",
                }}
              >
                <Grid item xs={12} sm={4}>
                  <div className={classes.statistics}>
                    <CountUp number={150000} duration={1000} />+
                  </div>
                  Rents Collected
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={classes.statistics}>
                    $<CountUp number={225000000} duration={1000} />+
                  </div>
                  In Rents Alone
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={classes.statistics}>
                    <CountUp number={50000} duration={1000} />+
                  </div>
                  Man Hours Of Experience
                </Grid>
              </Grid>
              <ReactBasicScrollPro
                configs={[
                  {
                    relativeFrom: -0.4,
                    relativeTo: 0.3,
                    props: {
                      [`--stingOpacity`]: {
                        from: 0,
                        to: 1,
                      },
                    },
                  },
                ]}
              >
                {(stingRef) => (
                  <div ref={stingRef} style={{ opacity: `var(--stingOpacity)` }}>
                    And leave the hard work to us.
                  </div>
                )}
              </ReactBasicScrollPro>
            </div>
          )}
        </ReactBasicScrollPro>
      </Grid>
      <div>
        <Grid
          container
          className={`${classes.gridContainer} ${classes.lastPoint}`}
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundImage:
              'linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.9)), url("/LandingPage/Global-Image-min.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              ...(!breakpointsDownXS && { maxWidth: "50vw" }),
            }}
          >
            <div>
              <VisitedSensor>
                {(ref, visited) => (
                  <motion.img
                    className={classes.bannerLogo}
                    ref={ref}
                    animate={visited ? "show" : "hide"}
                    variants={fadeInUp}
                    src="/LandingPage/SI Landlord.png"
                  />
                )}
              </VisitedSensor>
              <VisitedSensor>
                {(ref, visited) => (
                  <motion.div
                    className={classes.bannerSlogan}
                    ref={ref}
                    animate={visited ? "show" : "hide"}
                    variants={fadeInUp}
                    transition={{ delay: 0.2 }}
                  >
                    Making The Life Of Landlords...{" "}
                    <img className={classes.bannerSimple} src="/LandingPage/Simple.svg" />
                  </motion.div>
                )}
              </VisitedSensor>
              <VisitedSensor>
                {(ref, visited) => (
                  <Button
                    ref={ref}
                    animate={visited ? "show" : "hide"}
                    variants={fadeInUp}
                    transition={{ delay: 0.3 }}
                    component={motion.button}
                    className={classes.callToAction}
                    onClick={() => props.history.push("../authentication/signup")}
                  >
                    Get Started
                  </Button>
                )}
              </VisitedSensor>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default LandingPage;
