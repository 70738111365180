import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Lock from "@material-ui/icons/LockRounded";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import Collapse from "@material-ui/core/Collapse";
import { CircularProgress } from "@material-ui/core";

import ToastFactory from "../../../../components/ToastFactory";
import Loading from "../../../../components/Loading";
import Logo from "../../../../../assets/logos/simpleLandlordFullsize.png";
import AmexLogo from "../../../../../assets/logos/amex.png";
import DinersClubLogo from "../../../../../assets/logos/dinersclub.png";
import DiscoverLogo from "../../../../../assets/logos/discover.png";
import JCBLogo from "../../../../../assets/logos/jcb.png";
import MasterCardLogo from "../../../../../assets/logos/mastercard.png";
import UnionPayLogo from "../../../../../assets/logos/unionpay.png";
import VisaLogo from "../../../../../assets/logos/visa.png";

import { connect } from "react-redux";

import StripeInput from "../../../../components/StripeInput";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import RadioOption from "../../../../components/RadioOption";
import RadioOptions from "../../../../components/RadioOptions";

import { createPaymentIntent, getCustomer, getPaymentMethod } from "../../../../../services/billingServices.js";
import { getUser } from "../../../../../services/userServices.js";
import { deleteAlert } from "../../../../../services/property/alertServices";

const errorMessages = {
  cardholderName: {
    required: "Please enter your name exactly as it appears on your card",
    length: "The cardholder name cannot exceed 128 characters",
  },
  cardElement: {
    cardComplete: "Please enter all of your card details",
  },
};

const cardLogos = {
  amex: AmexLogo,
  diners: DinersClubLogo,
  discover: DiscoverLogo,
  jcb: JCBLogo,
  mastercard: MasterCardLogo,
  unionpay: UnionPayLogo,
  visa: VisaLogo,
  unknown: null,
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  logo: {
    width: "50%",
    maxWidth: "300px",
    // paddingBottom: "25px",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "80%",
      paddingBottom: "50px",
      paddingTop: "40px",
    },
  },
  subscriptionSection: {
    [`${theme.breakpoints.down("xs")}`]: {
      textAlign: "center",
    },
  },
  subscriptionDetails: {
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.7em",
    },
  },
  subscriptionPrice: {
    fontWeight: "bold",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2.6em",
    },
  },
  orderSummarySection: {
    paddingTop: "25px",
    [`${theme.breakpoints.down("xs")}`]: {
      justifyContent: "center",
      paddingTop: "0px",
      paddingBottom: "50px",
    },
  },
  orderSummaryHeader: {
    paddingBottom: "10px",
    fontSize: "2em",
    [`${theme.breakpoints.down("xs")}`]: {
      paddingBottom: "20px",
      fontSize: "2.4em",
    },
  },
  orderDetails: {
    width: "85%",
    paddingBottom: "12px",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "95%",
    },
  },
  tooltipFont: {
    fontSize: "0.8em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "0.9em",
    },
  },
  questionTooltip: {
    position: "relative",
    top: "4px",
    marginLeft: "10px",
    fontSize: "1.1em",
  },
  orderTotal: {
    width: "85%",
    paddingTop: "12px",
    "& > *": {
      fontWeight: "bold",
    },
    [`${theme.breakpoints.down("xs")}`]: {
      width: "95%",
      "& > *": {
        fontSize: "1.8em",
      },
    },
  },
  divider: {
    width: "85%",
    background: "white",
    padding: "1px",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "95%",
    },
  },
  question: {
    paddingBottom: "35px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2.4em",
    },
  },
  answer: {
    marginBottom: theme.spacing(4),
  },
  infoCard: {
    backgroundColor: theme.palette.background.default,
  },
  cardItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 28px",
    marginBottom: theme.spacing(1),
    border: "1px solid rgba(255, 255, 255, 0.23)",
    borderColor: "#0288d1",
  },
  cardRow: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "0",
    },
    "& > h6": {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "2px 0px",
    },
  },
  cardField: {
    fontSize: "1.2em",
    [`${theme.breakpoints.down(715)}`]: {
      marginLeft: "-15px",
    },
    [`${theme.breakpoints.between("xs", 650)}`]: {
      fontSize: "1.1em",
    },
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.1em",
    },
    [`${theme.breakpoints.down(381)}`]: {
      fontSize: "1em",
    },
    [`${theme.breakpoints.down(360)}`]: {
      fontSize: "0.86em",
    },
  },
  cardDetailsPaper: {
    [`${theme.breakpoints.down("xs")}`]: {
      width: "100vw",
      marginTop: "-10px",
      margin: "-30px -16px",
      borderRadius: 0,
      padding: "var(--tsl-brand-card-padding)",
    },
  },
  cardDetailsSection: {
    [`${theme.breakpoints.down("xs")}`]: {
      marginTop: "0px",
    },
  },
  form: {
    width: "100%",
    margin: theme.spacing(-2, 0, 0),
  },
  expirationDate: {
    color: theme.palette.text.disabled,
    marginLeft: "10px",
  },
  cardNumber: {
    [`${theme.breakpoints.up("sm")}`]: {
      marginLeft: "10px",
    },
  },
  cardBrand: {
    [`${theme.breakpoints.up("sm")}`]: {
      display: "none",
    },
    marginRight: "6px",
  },
  cardLogo: {
    [`${theme.breakpoints.down(715)}`]: {
      display: "none",
    },
  },
  amexLogo: {
    width: "9.5%",
    borderRadius: "6px",
  },
  dinersLogo: {
    width: "8%",
    borderRadius: "6px",
  },
  discoverLogo: {
    width: "13%",
    borderRadius: "6px",
  },
  jcbLogo: {
    width: "6%",
  },
  mastercardLogo: {
    width: "11%",
  },
  unionpayLogo: {
    width: "8%",
  },
  visaLogo: {
    width: "9%",
    borderRadius: "6px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

const cardOptions = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      fontSize: "16px",
      color: "white",
      iconColor: "rgba(255, 255, 255, 0.7)",
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    invalid: {
      color: "#f44336",
      iconColor: "#f44336",
    },
  },
};

function FormCheckoutForm(props) {
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const stripe = useStripe();
  const elements = useElements();

  const [stripeError, setStripeError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [existingPaymentMethod, setExistingPaymentMethod] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const updatePaymentMethod = async () => {
    const customerReceived = await getCustomer();
    if (customerReceived) {
      const paymentMethodReceived = await getPaymentMethod({ paymentMethodID: customerReceived.paymentMethodID });
      if (paymentMethodReceived) setPaymentMethod(paymentMethodReceived);
    }
  };

  useEffect(() => {
    updatePaymentMethod();
  }, []);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const onSubmit = async (cardData) => {
    // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    if (cardComplete || existingPaymentMethod) setProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const user = await getUser();
    if (!user) {
      setProcessing(false);
      addToast("Internal Server Error Occured. Please Try Again Later", "error");
      return;
    }

    const customer = await getCustomer();
    if (!customer) {
      setProcessing(false);
      addToast("Your Subscription Data Cannot Be Found. Please Try Again Later", "error");
      return;
    }

    const paymentIntentReceived = await createPaymentIntent({
      customerID: customer.ID,
      amount: 21680,
      parentID: props.parentID,
    });
    if (!paymentIntentReceived) {
      setProcessing(false);
      addToast("Payment Creation Error. Please Try Again Later", "error");
      return;
    }

    const stripePayload = await stripe.confirmCardPayment(paymentIntentReceived.client_secret, {
      payment_method: existingPaymentMethod
        ? customer.paymentMethodID
        : {
            card: cardElement,
            billing_details: {
              name: cardData.cardholderName,
            },
          },
      receipt_email: user.email,
    });

    if (stripePayload.error) {
      setStripeError(stripePayload.error);
      setProcessing(false);
      addToast("Payment Processing Error. Please Try Again", "error");
      return;
    }

    addToast("Payment Successful", "success");
    await deleteAlert(
      {
        ID: props.alertID,
      },
      props.dispatch
    );
    setTimeout(() => props.history.push(`/${props.propertyID ? props.propertyID : "properties"}`), 3000);
  };

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const existingPaymentMethodSection = (
    <>
      <Card className={`${classes.infoCard} ${classes.cardItem}`}>
        <CardContent className={classes.cardRow}>
          {paymentMethod && paymentMethod.card.brand !== "unknown" && (
            <img
              src={cardLogos[paymentMethod.card.brand]}
              className={`${classes.cardLogo} ${classes[`${paymentMethod.card.brand}Logo`]}`}
              alt="Card Logo"
            />
          )}
          <Typography variant="h6" gutterBottom className={classes.cardField}>
            {paymentMethod ? (
              <>
                <span className={classes.cardNumber}>
                  <b className={classes.cardBrand}>
                    {paymentMethod.card.brand !== "unknown" &&
                      paymentMethod.card.brand?.charAt(0).toUpperCase() + paymentMethod.card.brand.slice(1)}
                  </b>
                  <b>{`•••• ${paymentMethod.card.last4}`}</b>
                </span>
                <span style={{ whiteSpace: "nowrap" }} className={classes.expirationDate}>
                  Expires{" "}
                  {`${paymentMethod.card.exp_month < 10 ? 0 : ""}${paymentMethod.card.exp_month}/${
                    paymentMethod.card.exp_year
                  }`}
                </span>
              </>
            ) : (
              "Payment Method"
            )}
          </Typography>
        </CardContent>
      </Card>
      <Grid item xs={12}>
        <Button
          disabled={!stripe || !elements || processing}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => onSubmit()}
        >
          {processing ? "Processing..." : "Pay $216.80"} {"\u00A0"}
          {processing ? (
            <CircularProgress size={20} style={{ marginBottom: "2px" }} />
          ) : (
            <Lock style={{ fontSize: "17px", marginBottom: "2px" }} />
          )}
        </Button>
      </Grid>
    </>
  );

  const cardDetailsSection = (
    <>
      {stripe && elements ? (
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="cardholderName"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  validate: {
                    length: (value) => value.length <= 128,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="cardholderName"
                    label="Cardholder Name"
                    error={errors.cardholderName && errors.cardholderName !== null}
                    helperText={
                      errors.cardholderName ? errorMessages["cardholderName"][errors.cardholderName.type] : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="cardElement"
                control={control}
                defaultValue=""
                rules={{
                  validate: {
                    stripeError: () => !stripeError,
                    cardComplete: () => cardComplete,
                  },
                }}
                render={() => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="cardElement"
                    onChange={(e) => {
                      setStripeError(e.error);
                      setCardComplete(e.complete);
                      if (e.complete) clearErrors("cardElement");
                    }}
                    error={(stripeError && stripeError !== null) || errors?.cardElement?.type === "cardComplete"}
                    helperText={
                      stripeError
                        ? stripeError.message
                        : null || errors?.cardElement?.type === "cardComplete"
                        ? errorMessages["cardElement"][errors.cardElement.type]
                        : null
                    }
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardElement,
                        options: cardOptions,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                disabled={!stripe || !elements || processing}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {processing ? "Processing..." : "Pay $216.80"} {"\u00A0"}
                {processing ? (
                  <CircularProgress size={20} style={{ marginBottom: "2px" }} />
                ) : (
                  <Lock style={{ fontSize: "17px", marginBottom: "2px" }} />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : null}
    </>
  );

  if (props.loading) {
    return <Loading />;
  }

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.main}>
        <ToastFactory toasts={toasts} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} className={classes.subscriptionSection}>
            <img src={Logo} className={classes.logo} alt="The Simple Landlord Logo" />
            <Grid container className={classes.orderSummarySection}>
              <Typography variant="h5" className={classes.orderSummaryHeader}>
                Order Summary
              </Typography>
              <Grid container justifyContent="space-between" className={classes.orderDetails}>
                <Typography variant="h6">LTB Application Fee</Typography>
                <Typography variant="h6">$186.00</Typography>
              </Grid>
              <Grid container justifyContent="space-between" className={classes.orderDetails}>
                <Typography variant="h6">
                  Convenience Fee
                  <Tooltip
                    title="This fee is due to our payment provider"
                    placement="top"
                    PopperProps={{ style: { marginBottom: "-10px" } }}
                    classes={{ tooltip: classes.tooltipFont }}
                    enterTouchDelay={0}
                  >
                    <QuestionIcon className={classes.questionTooltip} />
                  </Tooltip>
                </Typography>
                <Typography variant="h6">$5.86</Typography>
              </Grid>
              <Grid container justifyContent="space-between" className={classes.orderDetails}>
                <Typography variant="h6">Taxes</Typography>
                <Typography variant="h6">$24.94</Typography>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container justifyContent="space-between" className={classes.orderTotal}>
                <Typography variant="h5">Total</Typography>
                <Typography variant="h5">$216.80</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ marginTop: !mobile && existingPaymentMethod === false ? "-50px" : "0px" }}
            className={classes.cardDetailsSection}
          >
            <Typography variant="h4" align="center" className={classes.question}>
              How would you like to pay?
            </Typography>
            <Controller
              name="paymentMethod"
              control={control}
              defaultValue="0"
              rules={{
                required: true,
                pattern: /^[10]$/,
              }}
              render={({ field: { onChange, value } }) => (
                <RadioOptions
                  className={classes.answer}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onMouseUp={(e) => {
                    if (e.target.value == 0) setExistingPaymentMethod(true);
                    if (e.target.value == 1) setExistingPaymentMethod(false);
                  }}
                >
                  <RadioOption value="0" label="Existing payment method" className={classes.answer} />
                  <RadioOption value="1" label="New payment method" />
                </RadioOptions>
              )}
            />
            <Collapse in={existingPaymentMethod === true}>{existingPaymentMethodSection}</Collapse>
            <Collapse in={existingPaymentMethod === false}>
              {mobile ? <Paper className={classes.cardDetailsPaper}>{cardDetailsSection}</Paper> : cardDetailsSection}
            </Collapse>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

const mapState = (state) => {
  return {
    user: state.authentication.user,
    error: state.authentication.error,
    loading: state.authentication.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(FormCheckoutForm);
