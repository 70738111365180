import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery, Button } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import DashboardIcon from "../../../assets/logos/landlordFavicon.png";
import LedgerIcon from "../../../assets/icons/simpleLedgerIcon.png";
import LeaseIcon from "../../../assets/icons/simpleLeaseIcon.png";
import ROIIcon from "../../../assets/icons/simpleRoiIcon.png";
import FormsIcon from "../../../assets/icons/simpleFormsIcon.png";

import { editUser } from "../../../services/userServices.js";

const useStyles = makeStyles((theme) => ({
  desktopMenu: {
    display: "none",
    [`${theme.breakpoints.up("sm")}`]: {
      display: "flex",
    },
  },
  desktopMenuOptions: {
    minWidth: "auto",
    textTransform: "none",
    opacity: 0.75,
    padding: theme.spacing(0, 5, 0),
    [`${theme.breakpoints.down("sm")}`]: {
      padding: theme.spacing(0, 1.5, 0),
    },
    [`${theme.breakpoints.between(600, 680)}`]: {
      padding: theme.spacing(0, 1, 0),
      fontSize: "0.84em",
    },
    "&$selected": {
      color: "white",
      opacity: 1,
    },
  },
  mobileMenu: {
    display: "none",
    [`${theme.breakpoints.down("xs")}`]: {
      display: "flex",
      width: "100vw",
      marginBottom: "-5px",
    },
  },
  mobileMenuOptions: {
    minWidth: "auto",
    textTransform: "none",
    opacity: 0.75,
    [`${theme.breakpoints.between(390, 426)}`]: {
      fontSize: "1em",
    },
    [`${theme.breakpoints.down(390)}`]: {
      fontSize: "0.9em",
    },
    [`${theme.breakpoints.down(350)}`]: {
      fontSize: "0.8em",
    },
    "&$selected": {
      color: "white",
      opacity: 1,
    },
  },
  indicator: {
    top: 0,
    borderRadius: "var(--tsl-brand-border-radius)",
  },
  selected: {},
  tipArrow: {
    "&:before": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tipBubble: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "10px 20px",
  },
  tooltipButton: {
    background: "none",
    boxShadow: "none",
    padding: "0px",
    fontWeight: "600",
    cursor: "pointer",
  },
}));

const PropertyMenu = (props) => {
  const validPropertyPages = ["lease", "roi", ...(props.lease ? ["ledger", "forms"] : [])];
  const classes = useStyles();
  const location = useLocation();

  const newPath = (destination) => `/${props.property?.ID}${destination}`;

  const currMenuOption = () => {
    const currentPropertyPage = location.pathname.split("/")[2];
    if (validPropertyPages.includes(currentPropertyPage)) return currentPropertyPage;
    else if (currentPropertyPage == "setup") return "lease";
    return "dashboard";
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [tips, setTips] = useState(props.profile?.tips ? JSON.parse(props.profile.tips) : {});

  useEffect(() => {
    setTips(props.profile?.tips ? JSON.parse(props.profile.tips) : {});
  }, [props.profile]);

  return (
    <Tabs
      value={currMenuOption()}
      indicatorColor="primary"
      textColor="primary"
      centered
      className={props.mobile ? classes.mobileMenu : classes.desktopMenu}
      classes={props.mobile ? { indicator: classes.indicator } : {}}
      {...(props.mobile ? { variant: "fullWidth" } : {})}
    >
      <Tab
        component={Link}
        to={newPath("")}
        label="Dashboard"
        value="dashboard"
        icon={<img src={DashboardIcon} height="26" alt="simple-dashboard-icon" />}
        classes={{
          root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
          selected: classes.selected,
        }}
      />
      {props.lease &&
        (!tips?.menuAlert || tips?.ledgerTab ? (
          <Tab
            component={Link}
            to={newPath("/ledger")}
            label="Ledger"
            value="ledger"
            icon={<img src={LedgerIcon} height="26" alt="simple-ledger-icon" />}
            classes={{
              root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
              selected: classes.selected,
            }}
          />
        ) : (
          <Tooltip
            placement="bottom"
            arrow
            open={tips?.menuAlert && !tips?.ledgerTab}
            classes={{
              tooltip: classes.tipBubble,
              arrow: classes.tipArrow,
            }}
            PopperProps={{
              onClick: () => editUser({ tips: { ...tips, ledgerTab: true } }, null, props.dispatch),
              style: { pointerEvents: "auto", display: !!props?.mobile != mobile ? "none" : "block" },
            }}
            title={
              <>
                <Typography style={{ textAlign: "center" }}>
                  Go to the ledger page to keep track of the rent charged and paid
                </Typography>
                <div style={{ textAlign: "right", marginTop: "5px" }}>
                  <Button className={classes.tooltipButton}>OK</Button>
                </div>
              </>
            }
          >
            <Tab
              component={Link}
              to={newPath("/ledger")}
              onClick={() => editUser({ tips: { ...tips, ledgerTab: true } }, null, props.dispatch)}
              label="Ledger"
              value="ledger"
              icon={<img src={LedgerIcon} height="26" alt="simple-ledger-icon" />}
              classes={{
                root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
                selected: classes.selected,
              }}
            />
          </Tooltip>
        ))}
      {tips?.leaseTab ? (
        <Tab
          component={Link}
          to={newPath("/lease")}
          label="Lease"
          value="lease"
          icon={<img src={LeaseIcon} height="26" alt="simple-leaseIcon-icon" />}
          classes={{
            root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
            selected: classes.selected,
          }}
        />
      ) : (
        <Tooltip
          placement="bottom"
          arrow
          open={!tips?.leaseTab}
          classes={{
            tooltip: classes.tipBubble,
            arrow: classes.tipArrow,
          }}
          PopperProps={{
            onClick: () => editUser({ tips: { ...tips, leaseTab: true } }, null, props.dispatch),
            style: { pointerEvents: "auto", display: !!props?.mobile != mobile ? "none" : "block" },
          }}
          title={
            <>
              <Typography style={{ textAlign: "center" }}>
                Go to the lease page to add an active or future lease
              </Typography>
              <div style={{ textAlign: "right" }}>
                <Button className={classes.tooltipButton}>OK</Button>
              </div>
            </>
          }
        >
          <Tab
            component={Link}
            to={newPath("/lease")}
            onClick={() => editUser({ tips: { ...tips, leaseTab: true } }, null, props.dispatch)}
            label="Lease"
            value="lease"
            icon={<img src={LeaseIcon} height="26" alt="simple-leaseIcon-icon" />}
            classes={{
              root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
              selected: classes.selected,
            }}
          />
        </Tooltip>
      )}
      {!tips?.leaseTab || tips?.roiTab ? (
        <Tab
          component={Link}
          to={newPath("/roi")}
          label="R.O.I"
          value="roi"
          icon={<img src={ROIIcon} height="26" alt="simple-roi-icon" />}
          classes={{
            root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
            selected: classes.selected,
          }}
        />
      ) : (
        <Tooltip
          placement="bottom"
          arrow
          open={tips?.leaseTab && !tips?.roiTab}
          classes={{
            tooltip: classes.tipBubble,
            arrow: classes.tipArrow,
          }}
          PopperProps={{
            onClick: () => editUser({ tips: { ...tips, roiTab: true } }, null, props.dispatch),
            style: { pointerEvents: "auto", display: !!props?.mobile != mobile ? "none" : "block" },
          }}
          title={
            <>
              <Typography style={{ textAlign: "center" }}>
                Go to the ROI page to answer a short questionnaire and keep track of your Return On Investment
              </Typography>
              <div style={{ textAlign: "right" }}>
                <Button className={classes.tooltipButton}>OK</Button>
              </div>
            </>
          }
        >
          <Tab
            component={Link}
            to={newPath("/roi")}
            onClick={() => editUser({ tips: { ...tips, roiTab: true } }, null, props.dispatch)}
            label="R.O.I"
            value="roi"
            icon={<img src={ROIIcon} height="26" alt="simple-roi-icon" />}
            classes={{
              root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
              selected: classes.selected,
            }}
          />
        </Tooltip>
      )}
      {props.lease &&
        (!tips?.ledgerTab || tips?.formTab ? (
          <Tab
            component={Link}
            to={newPath("/forms")}
            label="Forms"
            value="forms"
            icon={<img src={FormsIcon} height="26" alt="simple-forms-icon" />}
            classes={{
              root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
              selected: classes.selected,
            }}
          />
        ) : (
          <Tooltip
            placement="bottom"
            arrow
            open={tips?.ledgerTab && !tips?.formTab}
            classes={{
              tooltip: classes.tipBubble,
              arrow: classes.tipArrow,
            }}
            PopperProps={{
              onClick: () => editUser({ tips: { ...tips, formTab: true } }, null, props.dispatch),
              style: { pointerEvents: "auto", display: !!props?.mobile != mobile ? "none" : "block" },
            }}
            title={
              <>
                <Typography style={{ textAlign: "center" }}>
                  Go to the forms &amp; management page to search through common problems experienced by landlords and
                  find their solutions.
                </Typography>
                <div style={{ textAlign: "right", marginTop: "5px" }}>
                  <Button className={classes.tooltipButton}>OK</Button>
                </div>
              </>
            }
          >
            <Tab
              component={Link}
              to={newPath("/forms")}
              onClick={() => editUser({ tips: { ...tips, formTab: true } }, null, props.dispatch)}
              label="Forms"
              value="forms"
              icon={<img src={FormsIcon} height="26" alt="simple-forms-icon" />}
              classes={{
                root: props.mobile ? classes.mobileMenuOptions : classes.desktopMenuOptions,
                selected: classes.selected,
              }}
            />
          </Tooltip>
        ))}
    </Tabs>
  );
};

const mapState = (state) => {
  return {
    profile: state.authentication.profile,
    property: state.building.property,
    lease: state.building.lease,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};
export default connect(mapState, mapDispatch)(PropertyMenu);
