import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { setLTBFormData } from "../../../../redux/actions/LTBFormActions";

import { useParams } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";

import MultiStepForm from "../../../components/MutliStepForm";
import Loading from "../../../components/Loading";

import { addForm } from "../../../../services/property/form/formServices";
import { getTenants } from "../../../../services/property/tenantServices.js";

const useStyles = makeStyles((theme) => ({
  question: {
    fontWeight: "600",
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(6),
      fontSize: "1.65rem",
    },
  },
  questionSmall: {
    fontWeight: "600",
    fontSize: "1.8rem",
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(6),
      fontSize: "1.4rem",
    },
  },
  answer: {
    marginBottom: theme.spacing(3),
  },
  dateRange: {
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    padding: theme.spacing(2),
    [`${theme.breakpoints.up("sm")}`]: {
      paddingBottom: "0px",
    },
  },
  leaseLink: {
    color: theme.palette.primary.main,
  },
}));

const initialAddressData = {
  streetNum: "",
  streetName: "",
  streetType: "",
  direction: "",
  municipality: "",
  province: "",
  postalCode: "",
};

let formDataEffect = null;
let customSubmitForm = null;
let formSteps = [];
let onSubmitIsSet = false;
let mounted = [true];
let tenantsReceived;
function FormFiller(props) {
  let { LTBFormData, setLTBFormData } = props;
  const { property_id, form_id } = useParams();

  const {
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const { isSubmitted } = useFormState({
    control,
  });

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [formData, setFormData] = useState({});
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});

  const [addressData, setAddressData] = useState(initialAddressData);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const [fieldNames, setFieldNames] = useState("");

  const onAddressSelected = (isAddressSelected, addressData) => {
    setIsAddressSelected(isAddressSelected);
    addressData ? setAddressData(addressData) : setAddressData(initialAddressData);

    if (isSubmitted)
      isAddressSelected
        ? clearErrors("mailedAddress") && trigger("mailedAddress")
        : setError("mailedAddress", { type: "chooseOption" });
  };

  let onSubmitRef = useRef(null);

  useEffect(() => {
    formDataEffect = null;
    customSubmitForm = null;
    formSteps = [];
    onSubmitIsSet = false;
    mounted[0] = true;
    setTenantsReceived();
    if (!LTBFormData?.problemTitle || LTBFormData?.problemTitle === "") props.history.push(`/${property_id}/forms`);
    return () => {
      mounted[0] = false;
    };
  }, [form_id]);

  const setTenantsReceived = async () => (tenantsReceived = await getTenants({ leaseID: props.lease["ID"] }));

  useEffect(() => {
    setFormData({});
    setStepsToSkip({});
    setErrorReceived({});
    onSubmitIsSet = false;
    mounted[0] = true;
    if (!LTBFormData?.problemTitle || LTBFormData?.problemTitle === "") props.history.push(`/${property_id}/forms`);
  }, [form_id]);

  const errorAndHelperText = (fieldName, errorMessages) => {
    if (fieldName == "mailedAddress") return errors.mailedAddress;
    else if (Array.isArray(fieldName)) {
      let currentError = errors;
      let currentErrorMessages = errorMessages;
      for (let field of fieldName) {
        if (field in currentError) currentError = currentError[field];
        else currentError = {};
        if (field in currentErrorMessages) currentErrorMessages = currentErrorMessages[field];
        else currentErrorMessages = {};
      }
      if (Object.keys(currentError).length === 0) currentError = null;
      if (Object.keys(currentErrorMessages).length === 0) currentErrorMessages = null;

      return {
        error: errors && currentError && currentError !== null,
        helperText:
          currentError && currentErrorMessages && "type" in currentError
            ? currentErrorMessages[currentError.type]
            : null,
      };
    } else {
      return {
        error: Boolean(errors && errors[fieldName]),
        helperText: errors && errors[fieldName] ? errorMessages[fieldName][errors[fieldName].type] : null,
      };
    }
  };

  const getFormSteps = async () => {
    if (!mounted[0]) return;
    try {
      const { generateFormSteps, runFormDataEffect, runSubmitForm } = await import(
        `./formFillerSteps/${form_id}Filler.jsx`
      );

      if (["cs", "n11"].includes(form_id) && !tenantsReceived) await setTenantsReceived();

      formSteps = generateFormSteps(
        classes,
        control,
        handleSubmit,
        onSubmitRef,
        errorAndHelperText,
        formData,
        props,
        ...(["n9"].includes(form_id) ? [setFormData] : []),
        ...(["cs", "n11"].includes(form_id) ? [tenantsReceived] : []),
        ...(form_id == "cs" ? [onAddressSelected, setIsAddressSelected] : []),
        ...(["n5", "n6", "n7", "n13"].includes(form_id) ? [getValues, setValue, fieldNames, setFieldNames] : [])
      );
      formDataEffect = runFormDataEffect;
      customSubmitForm = runSubmitForm;
      if (!mounted[0]) return;
      if (onSubmitRef.current != null && Object.keys(formData).length === 0 && !onSubmitIsSet) {
        setFormData({});
        onSubmitIsSet = true;
      }
      setLoading(false);
    } catch (err) {
      console.log("An error occured while opening the jsx file");
      props.history.push(`/${property_id}/forms`);
    }
  };

  getFormSteps();

  useEffect(() => {
    console.log("FormData: ", formData);
    if (formDataEffect) formDataEffect(formData, setStepsToSkip, props);
    else setStepsToSkip([]);
  }, [formData]);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
      setErrorReceived(errors);
    }
  };

  const submitForm = async (data, callback = null) => {
    if (customSubmitForm) {
      let doGenerateForm = customSubmitForm({ ...props, addToast }, data, props.history);
      if (!doGenerateForm) return;
    }

    data = {
      ...data,
    };
    data["leaseID"] = props.lease["ID"];
    data["formType"] = form_id;
    data["problemID"] = LTBFormData.problemID;
    data["problemTitle"] = LTBFormData.problemTitle;
    console.log("LTBFormData", LTBFormData);
    if (LTBFormData.parentID) data["parentID"] = LTBFormData.parentID;
    if (form_id == "cs" && addressData !== initialAddressData) {
      const formattedAddress =
        `${data["unitNum"] ? `#${data["unitNum"]} ` : ""}${addressData["streetNum"]} ${addressData["streetName"]} ` +
        `${addressData["streetType"]}${addressData["direction"] ? ` ${addressData["direction"]}` : ""}, ` +
        `${addressData["municipality"]}, ${addressData["province"]} ${addressData["postalCode"]}`;
      data["mailedAddress"] = formattedAddress;
    }
    if (data["eventDateTime"]) {
      for (let [index, value] of data["eventDateTime"].entries()) {
        if (!value) {
          data["event"].splice(index, 1);
          data["eventDateTime"].splice(index, 1);
        }
      }
    }
    if (data["workDate"]) {
      for (let [index, value] of data["workDate"].entries()) {
        if (!value) {
          data["workDate"].splice(index, 1);
          data["plannedWork"].splice(index, 1);
        }
      }
    }
    console.log("Data: ", data);

    if (LTBFormData.problemTitle && LTBFormData.problemTitle !== "") {
      const LTBFormAdded = await addForm(data, handleErrors, props.dispatch);
      console.log("LTBFormAdded: ", LTBFormAdded);
      if (LTBFormAdded && LTBFormAdded["ID"]) {
        addToast("Form Filled Successfully", "success");
        console.log(LTBFormAdded);
        setLTBFormData(LTBFormAdded);

        document.cookie = `formID=${LTBFormAdded["ID"]}; expires=${new Date(
          Date.now() + 86400 * 1000
        ).toUTCString()}; path=/;`;

        if (callback != null) callback(mounted);
        props.history.push(`/${property_id}/forms/viewer`);
        return true;
      }
    }

    addToast("An Error Occured While Creating Form", "error");
    if (callback != null) callback();
    return false;
  };

  if (loading) return <Loading />;

  return (
    <MultiStepForm
      key={form_id}
      formSteps={formSteps}
      stepsToSkip={stepsToSkip}
      formData={formData}
      setFormData={(value) => {
        if (mounted[0]) setFormData(value);
      }}
      submitForm={submitForm}
      handleSubmit={handleSubmit}
      setOnSubmit={(func) => {
        onSubmitRef.current = func;
        getFormSteps();
      }}
      errorReceived={errorReceived}
      toasts={toasts}
      hideStepper={true}
    />
  );
}

const mapState = (state) => {
  return {
    LTBFormData: state.LTBForm.data,
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(FormFiller);
