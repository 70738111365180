import { useEffect, useState } from "react";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import TextField from "@material-ui/core/TextField";

function SearchBar({ value, setValue, rootRef, ...other }) {
  const [icon, SetIcon] = useState([]);

  useEffect(() => {
    if (value) SetIcon(<CloseRoundedIcon style={{ cursor: "pointer" }} onClick={() => setValue("")} />);
    else SetIcon(<SearchRoundedIcon />);
  }, [value]);

  return (
    <TextField
      {...other}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      ref={rootRef}
      InputProps={{
        endAdornment: icon,
      }}
    />
  );
}

export default SearchBar;
