import {
  SET_USER,
  SET_CLONED_USER,
  SET_PROFILE,
  SET_AUTHENTICATION_LOADING,
  SET_AUTHENTICATION_ERROR,
} from "../actionTypes/authenticationActionTypes";

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const setClonedUser = (clonedUser) => ({
  type: SET_CLONED_USER,
  clonedUser,
});

export const setProfile = (profile) => ({
  type: SET_PROFILE,
  profile,
});

export const setLoading = (loading) => ({
  type: SET_AUTHENTICATION_LOADING,
  loading,
});

export const setError = (error) => ({
  type: SET_AUTHENTICATION_ERROR,
  error,
});
