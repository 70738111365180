import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoubleDialog from "../../../components/DoubleDialog";
import EditLedgerForm from "./EditLedgerForm";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
}));

function LedgerDoubleDialog(props) {
  const classes = useStyles();

  //Double Dialog States And Functions
  const [confirmationData, setConfirmationData] = useState(false);

  //The function called if the confirm button is clicked and the callback function for confirm is successful
  const closeDialogSuccessfully = (callbackArgs) => {
    if (callbackArgs.currentAction === "AddPayment") {
      props.setPaymentAdded(true);
      callbackArgs.currentAction = "Add";
    }

    props.addToast(`Transaction Successfully ${callbackArgs.currentAction}ed`, "success");
    setConfirmationData(false);

    if (
      callbackArgs.currentAction !== "Delet" &&
      callbackArgs.NSFPaymentData.NSF &&
      (!props.ledgerFormData || !props.ledgerFormData.NSF)
    )
      props.handleNSFPayment(callbackArgs.NSFPaymentData);

    props.setLedgerFormData(false);
    props.updateLedger();
  };

  //The function that sets up the text and callback for the confirmation dialog
  const setConfirmationDialog = (confirmCallback, callbackArgs) => {
    /*
      callbackArgs will depend on the component being inserted into the main dialog, it just contains additional
      variables that you want to get back from the form and use in the dialogs in some way
    */
    if (callbackArgs.currentAction === "Delet")
      setConfirmationData({
        title: "Are you sure you want to delete this ledger transaction?", //The title of the confirmation dialog
        body: "This action cannot be reverted", //The body of the confirmation dialog
        /*
          This should contain the confirmCallback received from the main dialog's child component (ledgerForm in this case),
          and any other functions that you want to trigger when the confirm button is clicked
        */
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) closeDialogSuccessfully(callbackArgs);
          else setConfirmationData(false);
        },
        primaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the primary button (You can also use secondaryBtnClass)
      });
    else
      setConfirmationData({
        title: "Please ensure the information you've entered is correct",
        body: "This information will be used on official government forms",
        callback: async () => {
          let confirmSuccess = await confirmCallback();
          if (confirmSuccess) closeDialogSuccessfully(callbackArgs);
          else setConfirmationData(false);
        },
        secondaryBtnClass: classes.deleteBtn, //Optional variable to add a class to the secondary button (You can also use primaryBtnClass)
      });
  };
  return (
    <DoubleDialog
      mainTitle={`${props.ledgerFormData.action} Ledger Transaction`}
      mainDialogOpen={props.ledgerFormData !== false} //The condition for the main dialog to open
      closeMainDialog={() => props.setLedgerFormData(false)} //A function to close the main dialog
      confirmationDialogData={confirmationData} //The title, body, and callback of the confirmation dialog
      closeConfirmationDialog={() => setConfirmationData(false)} //A function to close the confirmation dialog
      width={"sm"} //maxWidth prop for the main dialog
    >
      <EditLedgerForm
        action={props.ledgerFormData.action}
        ledgerItem={props.ledgerFormData.ledgerItem}
        data={props?.ledgerFormData?.ledgerItem?.data ? JSON.parse(props.ledgerFormData.ledgerItem.data) : {}}
        leaseID={props.leaseID}
        leaseData={props.leaseData}
        tenants={props.tenants}
        addToast={props.addToast}
        //The only prop related to DoubleDialog
        setConfirmationCallback={setConfirmationDialog}
      />
    </DoubleDialog>
  );
}

export default LedgerDoubleDialog;
