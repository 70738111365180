import { SET_ALERTS } from "../actionTypes/alertsActionTypes";

const defaultState = {
  alerts: null,
};

const AlertsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALERTS:
      return { ...state, alerts: action.alerts };
    default:
      return state;
  }
};

export default AlertsReducer;
