import NumberFormat from "react-number-format";

function PhoneNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format="(###) ###-####"
      type="tel"
    />
  );
}

export default PhoneNumber;
