import React, { useState, useEffect } from "react";

import { useForm, Controller, useFormState } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PhoneNumber from "../components/PhoneNumber";
import ToastFactory from "../components/ToastFactory";
import Loading from "../components/Loading";
import Logo from "../../assets/logos/simpleLandlordFullsize.png";
import KeylineLogo from "../../assets/logos/SimpleLandlordKeylineLogo.png";

import { connect } from "react-redux";
import { postMailingList } from "../../services/mailingListServices.js";

const errorMessages = {
  firstName: {
    required: "Please enter your first name",
    length: "Your first name cannot exceed 64 characters",
  },
  lastName: {
    required: "Please enter your last name",
    length: "Your last name cannot exceed 64 characters",
  },
  email: {
    required: "Please enter your email",
    pattern: "Your email must be in the correct format, e.g. username@domain.com",
    length: "Your email cannot exceed 254 characters",
    alreadyExists: "A User already exists with this email",
  },
  phone: {
    required: "Please enter your phone number",
    pattern: "Your phone number must be in the correct format, e.g. (123) 456-7890",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "12px",
  },
  logoDiv: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(27,27,27,1) 100%), url("/LandingPage/House.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    textAlign: "center",
    [`${theme.breakpoints.up("md")}`]: {
      display: "none",
    },
  },
  mobileLogo: {
    maxWidth: "500px",
    width: "100%",
    padding: "32px 64px",
  },
  logo: {
    width: "100%",
    padding: "32px 64px 0px 64px",
    [`${theme.breakpoints.down("sm")}`]: {
      display: "none",
    },
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bannerImage: {
    position: "relative",
    maxHeight: "100vh",
    maxWidth: "900px",
    width: "100%",
    textAlign: "center",
  },
  bannerPhone: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  bannerProperty: {
    position: "absolute",
    top: "15%",
    left: "50%",
    maxWidth: "100%",
    maxHeight: "45%",
    filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
    animation: "$float 6s ease-in-out infinite",
  },
  bannerROI: {
    position: "absolute",
    bottom: "10%",
    left: "30%",
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "35%",
    filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
    animation: "$float 6s ease-in-out infinite",
  },
  "@keyframes float": {
    "0%": {
      filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
      transform: "translate(0px, 0px)",
    },
    "50%": {
      filter: "drop-shadow(-10px 25px 15px rgba(0,0,0,0.2))",
      transform: "translate(5px, -7px)",
    },
    "100%": {
      filter: "drop-shadow(-10px 5px 15px rgba(0,0,0,0.6))",
      transform: "translate(0px, 0px)",
    },
  },
  gridItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    [`${theme.breakpoints.down("sm")}`]: {
      maxWidth: "100vw",
    },
  },
  header: {
    fontSize: "22px",
    textAlign: "center",
    margin: "16px 0px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "20px",
    },
  },
  stickyPhone: {
    top: "0px",
    position: "sticky",
  },
  formDiv: {
    width: "100%",
    height: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
}));

function PasswordPage(props) {
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const breakpointsDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { isSubmitted } = useFormState({
    control,
  });

  const [addedToMailingList, setAddedToMailingList] = useState(false);

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const onSubmit = async (userData) => {
    const registered = await postMailingList(props.dispatch, userData);
    if (!registered) addToast("Registration Failed", "error");
    else {
      addToast("Registration Successful", "success");
      setAddedToMailingList(true);
    }
  };

  const classes = useStyles();

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Grid container style={{ minHeight: "100vh", flexDirection: breakpointsDownSM ? "column" : "row" }}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ display: breakpointsDownSM ? "none" : "block" }}
        >
          <div className={`${classes.bannerImage} ${classes.stickyPhone}`}>
            <img className={classes.bannerPhone} src="/LandingPage/Banner Phone Reverse.png" />
            <img className={classes.bannerProperty} src="/LandingPage/Banner Property Reverse.png" />
            <img className={classes.bannerROI} src="/LandingPage/Banner ROI Reverse.png" />
          </div>
        </Grid>
        <div className={classes.logoDiv}>
          <img src={KeylineLogo} className={classes.mobileLogo} alt="The Simple Landlord Logo" />
        </div>
        {props.loading ? (
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <Loading />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <div className={classes.formDiv}>
              <div style={{ maxWidth: "450px", textAlign: "center" }}>
                <img src={Logo} className={classes.logo} alt="The Simple Landlord Logo" />
                <Typography variant="h4" style={{ fontWeight: "700" }}>
                  {addedToMailingList ? "Thank You!" : "Coming Soon!"}
                </Typography>
                <Typography variant="h4" className={classes.header} style={{ fontWeight: "500" }}>
                  {addedToMailingList
                    ? "We will notify you as soon as we have further updates on the program."
                    : "Sign up below to be notified when the program is released"}
                </Typography>
                {!addedToMailingList && (
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => mobile && e.key === "Enter" && e.preventDefault()}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="firstName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: true,
                            validate: {
                              length: (value) => value.length <= 64,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              id="firstName"
                              label="First Name"
                              autoFocus
                              error={errors.firstName && errors.firstName !== null}
                              helperText={errors.firstName ? errorMessages["firstName"][errors.firstName.type] : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="lastName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: true,
                            validate: {
                              length: (value) => value.length <= 64,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              id="lastName"
                              label="Last Name"
                              error={errors.lastName && errors.lastName !== null}
                              helperText={errors.lastName ? errorMessages["lastName"][errors.lastName.type] : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: true,
                            pattern: /^\S+@\S+\.\S+$/,
                            validate: {
                              length: (value) => value.length <= 254,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              id="email"
                              label="Email"
                              autoComplete="email"
                              error={errors.email && errors.email !== null}
                              helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: true,
                            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              id="phone"
                              label="Phone"
                              name="phone"
                              error={errors.phone && errors.phone !== null}
                              helperText={errors.phone ? errorMessages["phone"][errors.phone.type] : null}
                              InputProps={{
                                inputComponent: PhoneNumber,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          style={{ marginTop: "8px" }}
                        >
                          Keep Me Updated!
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapState = (state) => {
  return {
    error: state.authentication.error,
    loading: state.authentication.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};

export default connect(mapState, mapDispatch)(PasswordPage);
