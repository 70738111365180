import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function generateUEID() {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = ("000" + first.toString(36)).slice(-3);
  second = ("000" + second.toString(36)).slice(-3);

  return first + second;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ToastFactory({ toasts, dismissTime = 3000 }) {
  const classes = useStyles();
  const [toastList, setToastList] = useState(toasts);

  useEffect(() => {
    setToastList([...toasts]);
  }, [toasts]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && toasts.length) {
        deleteToast(toasts[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toasts, toastList]);

  const deleteToast = (id) => {
    const toastsItemIndex = toasts.findIndex((e) => e.id === id);
    const toastListItemIndex = toastList.findIndex((e) => e.id === id);
    if (toastsItemIndex < 0 || toastListItemIndex < 0) return;
    toasts.splice(toastsItemIndex, 1);
    toastList.splice(toastListItemIndex, 1);
    setToastList([...toastList]);
  };

  return (
    <div className={classes.root}>
      {toastList.map((toast, index) => (
        <Snackbar key={index} anchorOrigin={{ vertical: "top", horizontal: "center" }} open={true}>
          <Alert severity={toast.severity}>{toast.content}</Alert>
        </Snackbar>
      ))}
    </div>
  );
}
