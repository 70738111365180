import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { connect } from "react-redux";
import { setLTBFormData } from "../../../../redux/actions/LTBFormActions";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import MoreIcon from "@material-ui/icons/MoreHorizRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";

import ToastFactory from "../../../components/ToastFactory";
import SearchBar from "../../../components/SearchBar";
import problemList, { problemObj } from "./problemList";

import updateTenantList from "../../../../utils/updateTenantList.js";
import { getSuggestedSpelling } from "../../../../services/property/form/searchServices";
import { getForms, deleteForm } from "../../../../services/property/form/formServices";
import { useParams } from "react-router-dom";
import LeaseHistory from "../../components/LeaseHistory";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "40px",
    justifyContent: "space-between",
    minHeight: "calc(100vh - 90px)",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
      minHeight: "calc(100vh - 180px)",
    },
  },
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginTop: "30px",
  },
  infoCard: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0px`,
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.mid,
    },
  },
  moreBtn: {
    paddingLeft: "26px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      "& $moreArrow": {
        transform: "translateY(2px)",
      },
    },
  },
  moreArrow: {
    margin: "-5px 0px",
    paddingLeft: "10px",
    fontSize: "2.2rem",
    transition: "transform .2s ease-in-out",
  },
  confirmationDialogContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      width: 375,
    },
  },
  dialogContainer: {
    padding: theme.spacing(0),
    background: theme.palette.background.default,
    "&:first-child": {
      paddingTop: theme.spacing(0),
    },
  },
  dialogCloseBtn: {
    marginLeft: "-20px",
    position: "relative",
    top: "-8px",
    right: "-15px",
    [theme.breakpoints.down("xs")]: {
      right: "-20px",
    },
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#aa2e25",
    },
  },
  dialogHeader: {
    display: "flex",
    flex: "1",
    padding: theme.spacing(3) + "px " + theme.spacing(4) + "px!important",
    background: theme.palette.background.default,
    borderBottomLeftRadius: "var(--tsl-brand-border-radius)",
    borderBottomRightRadius: "var(--tsl-brand-border-radius)",
    boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12)`,
  },
  dialogTitle: {
    textAlign: "left",
    flex: "1",
  },
  explanation: {
    padding: theme.spacing(4) + "px!important",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& .buttonContainer": {
      textAlign: "left",
      padding: "15px 0px",
    },
    "& .helperText": {
      color: "lightgrey",
      paddingBottom: "10px",
      margin: "0",
    },
    "& .optionBtn": {
      display: "inline-block",
    },
  },
  cardContent: {
    textAlign: "center",
    minHeight: "55%",
  },
  media: {
    paddingTop: "90%",
    backgroundPosition: "top",
    objectFit: "cover",
    zIndex: 10,
  },
  formImageContainer: {
    position: "relative",
    [`${theme.breakpoints.up("sm")}`]: {
      "&:hover > div": {
        opacity: 1,
      },
    },
  },
  formImageOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.6)",
    transition: "all var(--tsl-brand-transition-time)",
    opacity: 0,
  },
  formImageOverlayMobile: {
    position: "absolute",
    top: 0,
    display: "flex",
    [`${theme.breakpoints.up("sm")}`]: {
      display: "none",
    },
  },
  formImageIconContainer: {
    position: "absolute",
    width: "50%",
    top: 0,
    right: 0,
    textAlign: "right",
  },
  formDeleteIcon: {
    fontSize: "1.8em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.6em",
    },
  },
  formImageIconMobile: {
    fontSize: "1.6em",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "var(--tsl-brand-border-radius)",
  },
}));

let currentSearchTerm = "";
let searchBarContainerNode = null;
function Forms({ history, setLTBFormData, dispatch, ...props }) {
  const classes = useStyles();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [suggestedTerms, setSuggestedTerms] = useState([]);
  const [shownOptionsAmount, setShownOptionsAmount] = useState(4);
  const [problems, setProblems] = useState(problemList);
  const [dialogContent, setDialogContent] = useState(false);
  const [deleteFormDialog, setDeleteFormDialog] = useState(false);
  const [mobileOptions, setMobileOptions] = useState(0);
  const [tenantsByLease, setTenantsByLease] = useState({});

  useEffect(() => {
    problemObj.history = history;
    problemObj.baseURL = window.location.pathname;
    problemObj.setLTBFormData = setLTBFormData;
    return () => {
      searchBarContainerNode = null;
      document.removeEventListener("click", expandSearchResults);
    };
  }, []);

  useEffect(() => {
    currentSearchTerm = searchTerm;
    if (searchTerm && searchTerm != "") {
      prepareSearch();
    } else {
      setSuggestedTerms("");
      setShownOptionsAmount(4);
    }
  }, [searchTerm]);

  const numRelevant = (array) => {
    return array.filter((element) => element.relevance).length;
  };

  const prepareSearch = async () => {
    if (currentSearchTerm != searchTerm) return;
    let suggestions = await getSuggestedSpelling(searchTerm);
    if (currentSearchTerm == searchTerm) searchProblems(searchTerm + "", suggestions);
  };

  const searchProblems = async (text, suggestions = []) => {
    text = text.replaceAll("'", "").toLowerCase();

    var suggestionsUsed = [];

    //Deep Copy
    let searchedProblems = [...problemList];
    for (let i = 0; i < searchedProblems.length; i++) searchedProblems[i] = { ...searchedProblems[i] };

    //Search Problems
    for (var problem of searchedProblems) {
      problem.relevance = 0;
      for (var keyword of problem.keywords) {
        if (text != currentSearchTerm.replaceAll("'", "").toLowerCase()) return;

        if (keyword != "" && text.toLowerCase().includes(keyword.toLowerCase())) problem.relevance++;
        else if (keyword != "" && suggestions && Array.isArray(suggestions)) {
          for (var suggestion of suggestions) {
            if (suggestion.toLowerCase() == keyword.toLowerCase()) {
              problem.relevance++;
              if (!suggestionsUsed.includes(suggestion) && keyword == suggestion) suggestionsUsed.push(suggestion);
            }
          }
        }
      }
    }

    if (text != searchTerm.replaceAll("'", "").toLowerCase()) return;
    searchedProblems.sort((a, b) => b.relevance - a.relevance);
    if (text != searchTerm.replaceAll("'", "").toLowerCase()) return;

    setSuggestedTerms(suggestionsUsed.join(", "));
    let numRelevantProblems = numRelevant(searchedProblems);
    setShownOptionsAmount(numRelevantProblems < 4 ? numRelevantProblems : 4);
    setProblems(searchedProblems);
  };

  const expandSearchResults = (e) => {
    if (!searchBarContainerNode) return;
    if (!searchBarContainerNode.contains(e.target)) setShowSearchResults(false);
    else setShowSearchResults(true);
  };

  const [savedForms, setSavedForms] = useState(null);

  const getSavedForms = async () => {
    const formsReceived = await getForms({ leaseID: props.lease["ID"] });
    console.log(formsReceived);
    setSavedForms(formsReceived ? formsReceived : []);
    if (!formsReceived) console.log("Failed to load formsReceived: ", formsReceived);
  };

  useEffect(() => {
    getSavedForms();
    updateTenantList(props.property["leases"], setTenantsByLease);
  }, []);

  let formsContent = (
    <Grid item xs={12}>
      <Typography variant="h4" align="center">
        Forms &amp; Management
      </Typography>
      <SearchBar
        variant="outlined"
        margin="normal"
        fullWidth
        label="Search"
        value={searchTerm}
        setValue={setSearchTerm}
      />

      <Collapse in={!!(savedForms?.length === 0 || showSearchResults || (searchTerm && searchTerm.length > 0))}>
        <Typography
          variant="body1"
          style={{
            paddingLeft: "14px",
            display: suggestedTerms && suggestedTerms != "" ? "block" : "none",
          }}
        >
          Showing results for: {suggestedTerms}
        </Typography>
        {problems.slice(0, shownOptionsAmount).map((problem) => (
          <Card
            key={problem.id}
            className={classes.infoCard}
            onClick={() => setDialogContent(problem)}
            style={problem.relevance || searchTerm == "" ? {} : { display: "none" }}
          >
            <Typography variant="h6">{problem.title}</Typography>
          </Card>
        ))}

        <div
          style={{
            display:
              shownOptionsAmount < problems.length && (searchTerm == "" || shownOptionsAmount < numRelevant(problems))
                ? "block"
                : "none",
            textAlign: "center",
          }}
        >
          <Button
            className={classes.moreBtn}
            variant="contained"
            onClick={() => setShownOptionsAmount(shownOptionsAmount + 4)}
          >
            Show More Options <ExpandMoreRoundedIcon className={classes.moreArrow} />
          </Button>
        </div>

        <Typography variant="h5" align="center" style={{ marginTop: "40px" }}>
          Commonly Searched
        </Typography>

        {["Rent increase", "Late payments", "Damaged property", "Tenant eviction"].map((commonSearch) => (
          <Card key={commonSearch} className={classes.infoCard} onClick={() => setSearchTerm(commonSearch)}>
            <Typography variant="h6">
              <SearchRoundedIcon style={{ marginBottom: "-5px" }} /> {commonSearch}
            </Typography>
          </Card>
        ))}
      </Collapse>
    </Grid>
  );

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const noForms = () => !savedForms || savedForms?.filter((form) => form.problemID != "-1").length == 0;

  return (
    <>
      <ToastFactory toasts={toasts} />

      <Dialog
        maxWidth="md"
        {...(mobile ? { fullScreen: true } : {})}
        TransitionComponent={Transition}
        open={Boolean(dialogContent)}
        onClose={() => setDialogContent(false)}
      >
        <DialogContent className={classes.dialogContainer}>
          <Grid container spacing={2}>
            <Grid item className={classes.dialogHeader}>
              <Typography className={classes.dialogTitle} variant="h6">
                {dialogContent.title}
              </Typography>
              <IconButton className={classes.dialogCloseBtn} onClick={() => setDialogContent(false)}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item className={classes.explanation}>
              {dialogContent.explanation}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteFormDialog !== false}
        onClose={() => setDeleteFormDialog(false)}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.confirmationDialogContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Are you sure you want to delete this form?
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                This action cannot be reverted
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={async () => {
                  const form = deleteFormDialog;
                  console.log(form);
                  const formDeleted = await deleteForm({ formID: form.ID, leaseID: props.lease["ID"] }, dispatch);
                  if (formDeleted) {
                    console.log(formDeleted);
                    setDeleteFormDialog(false);
                    addToast("Form Successfully Deleted", "success");
                    getSavedForms();
                  } else addToast("Deleting Form Failed", "error");
                }}
                variant="contained"
                className={classes.deleteBtn}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button fullWidth onClick={() => setDeleteFormDialog(false)} color="primary" variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid item xs={12}>
        <Container
          className={classes.container}
          maxWidth="md"
          style={noForms() ? {} : { marginBottom: "50px", minHeight: "0px" }}
        >
          {props.property["leases"].length > 1 && props.lease.status != "active" && (
            <Grid item xs={12} style={{ marginBottom: "25px" }}>
              <Box component="div" display={{ xs: "none", sm: "block" }}>
                <Paper className={classes.content}>
                  <LeaseHistory
                    history={history}
                    historyExpanded={props.historyExpanded}
                    setHistoryExpanded={props.setHistoryExpanded}
                    tenantsByLease={tenantsByLease}
                  />
                </Paper>
              </Box>
              <Box component="div" display={{ xs: "block", sm: "none" }} style={{ flex: "1" }}>
                <LeaseHistory
                  history={history}
                  historyExpanded={props.historyExpanded}
                  setHistoryExpanded={props.setHistoryExpanded}
                  tenantsByLease={tenantsByLease}
                  mobile
                />
              </Box>
            </Grid>
          )}
          <Box
            component="div"
            display={{ xs: "none", sm: "block" }}
            style={{ width: "100%" }}
            ref={(node) => {
              if (!node || searchBarContainerNode) return;
              searchBarContainerNode = node;
              document.addEventListener("click", expandSearchResults);
            }}
          >
            <Paper className={classes.content}>{formsContent}</Paper>
          </Box>
          <Box
            component="div"
            display={{ xs: "block", sm: "none" }}
            style={{ width: "100%", ...(noForms() && { marginBottom: "100px" }) }}
          >
            {formsContent}
          </Box>
          {props.property["leases"].length > 1 && props.lease.status != "active" && <div></div>}
        </Container>
      </Grid>

      {savedForms?.map((form) => {
        if (form.problemID == "-1") return null;

        return (
          <Grid item key={form.ID} xs={12} md={3} lg={3}>
            <Card style={{ height: "100%" }}>
              <CardActionArea
                component="div"
                style={{ height: "100%" }}
                onClick={() => {
                  setLTBFormData({});
                  if (getCookieValue("formID"))
                    document.cookie = `formID=${form.ID}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                  document.cookie = `formID=${form.ID}; expires=${new Date(
                    Date.now() + 86400 * 1000
                  ).toUTCString()}; path=/;`;
                  history.push(`${window.location.pathname}/viewer`);
                }}
              >
                <div className={classes.formImageContainer}>
                  <CardMedia
                    image={process.env.PUBLIC_URL + `/LTBFormThumbnails/${form.type}.jpg`}
                    title={form.title}
                    className={classes.media}
                  />
                  <div
                    className={classes.formImageOverlay}
                    style={mobile ? (mobileOptions ? { opacity: 1 } : { display: "none" }) : {}}
                  >
                    <Grid container className={classes.formImageIconContainer}>
                      <Grid item xs={12}>
                        <Tooltip title="Delete Form" placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteFormDialog(form);
                            }}
                          >
                            <DeleteIcon className={classes.formDeleteIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formImageOverlayMobile}>
                    <Grid container>
                      <Grid item xs={12}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setMobileOptions(!mobileOptions);
                          }}
                        >
                          <MoreIcon className={classes.formImageIconMobile} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <CardContent className={classes.cardContent}>
                  <Tooltip title={form.problemTitle} placement="top" PopperProps={{ style: { marginBottom: "-8px" } }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{ fontSize: form.problemTitle.length > 40 ? "1.1rem" : "1.3rem", marginBottom: "20px" }}
                    >
                      <b>{form.type.toUpperCase()}: </b>
                      {form.problemTitle.slice(0, 100)}
                      {form.problemTitle.length > 100 ? "..." : ""}
                    </Typography>
                  </Tooltip>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontSize: "1.2rem", marginBottom: "0px", color: "lightgrey" }}
                  >
                    {moment(form.time.split(" ")[0]).format("MMMM Do, YYYY")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </>
  );
}

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(Forms);
