import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ImageIcon from "@material-ui/icons/ImageRounded";
import Chip from "@material-ui/core/Chip";

import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(0, 0, 4),
    },
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    borderWidth: "5px",
    borderStyle: "dashed",
    borderRadius: "var(--tsl-brand-border-radius)",
    width: "100%",
    padding: "80px",
    [`${theme.breakpoints.down("xs")}`]: {
      padding: "80px 30px",
    },
  },
  dropzoneBorderError: {
    borderColor: theme.palette.error.main,
  },
  dropzoneText: {
    padding: theme.spacing(0, 0, 1),
    align: "center",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1em",
    },
  },
  uploadIcon: {
    fontSize: "4.5em",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "3em",
    },
  },
  dropzoneError: {
    color: theme.palette.error.main,
    margin: theme.spacing(2, 0, -1),
  },
  selectedFilesTitle: {
    padding: theme.spacing(2, 0, 1),
  },
  selectedFilesChip: {
    maxWidth: "100%",
    borderRadius: "30px",
  },
}));

function Dropzone({ onFileUpload, uploadedFiles = null }) {
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [files, setFiles] = useState(uploadedFiles ? uploadedFiles : null);

  const setErrors = (bool) => {
    setTypeError(bool);
    setSizeError(bool);
    setMaxFilesError(bool);
  };

  const onDropAccepted = (acceptedFiles) => {
    console.log(acceptedFiles);
    setErrors(false);
    setFiles(acceptedFiles[0]);
    onFileUpload(acceptedFiles[0]);
  };

  const onDropRejected = (fileRejections) => {
    const error = fileRejections[0].errors[0].code;
    console.log(error);
    if (error === "file-invalid-type") setTypeError(true);
    else if (error === "file-too-large") setSizeError(true);
    else if (error === "too-many-files") setMaxFilesError(true);
  };

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: "application/pdf",
    maxSize: 8000000,
    maxFiles: 1,
    multiple: false,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Grid container align="center" spacing={1}>
        <div
          {...getRootProps({
            className: `${classes.dropzone} ${
              (!files && (typeError || sizeError || maxFilesError)) || isDragReject ? classes.dropzoneBorderError : ""
            }`,
          })}
        >
          <input {...getInputProps()} />
          <UploadIcon className={classes.uploadIcon} />
          <Typography variant="h6" className={classes.dropzoneText}>
            Click to select a PDF file or drop it here
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        {typeError && (
          <Typography variant="body2" className={classes.dropzoneError}>
            File type must be PDF
          </Typography>
        )}
        {sizeError && !typeError && (
          <Typography variant="body2" className={classes.dropzoneError}>
            File size must be smaller than 8MB
          </Typography>
        )}
        {!typeError && !sizeError && maxFilesError && (
          <Typography variant="body2" className={classes.dropzoneError}>
            You can only upload one file
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.selectedFilesTitle}>
          Selected Files ({files ? 1 : 0} / 1):
        </Typography>
        {files && (
          <Chip
            label={files.name}
            icon={<ImageIcon />}
            onDelete={() => {
              setFiles(null);
              onFileUpload(null);
            }}
            className={classes.selectedFilesChip}
          />
        )}
      </Grid>
    </div>
  );
}

export default Dropzone;
