import { useEffect, useRef, useState } from "react";
import VisitedSensor from "./VisitedSensor";

function VideoAutoPlayer({ children, replayOnReappearance, percentHeight, percentTop }) {
  const vidRef = useRef();
  const [visitedState, setVisitedState] = useState(false);
  const [passedState, setPassedState] = useState(false);

  useEffect(() => {
    if (visitedState && !passedState) vidRef.current.play();
    else {
      if (replayOnReappearance) vidRef.current.currentTime = 0;
      vidRef.current.pause();
    }
  }, [visitedState, passedState]);

  return (
    <VisitedSensor
      refState={vidRef}
      visitedArray={[visitedState, setVisitedState]}
      passedArray={[passedState, setPassedState]}
      permanent
      percentHeight={percentHeight}
      percentTop={percentTop}
    >
      {(ref) => children(ref)}
    </VisitedSensor>
  );
}

export default VideoAutoPlayer;
