import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import VerifiedUserRoundedIcon from "@material-ui/icons/VerifiedUserRounded";

import { forgotPassword } from "../../services/authenticationServices.js";

import ToastFactory from "../components/ToastFactory";
import Loading from "../components/Loading";

import Logo from "../../assets/logos/simpleLandlordFullsize.png";

const errorMessages = {
  email: {
    required: "Please enter your email",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "82px",
  },
  logo: {
    width: "100%",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  form: {
    width: "100%",
    marginTop: "16px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bannerImage: {
    position: "relative",
    maxHeight: "100vh",
    maxWidth: "900px",
    width: "100%",
    textAlign: "center",
  },
  bannerPhone: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  bannerProperty: {
    position: "absolute",
    top: "15%",
    right: "7%",
    transform: "translateX(-50%)",
    maxWidth: "100%",
    maxHeight: "45%",
  },
  bannerROI: {
    position: "absolute",
    bottom: "10%",
    left: "30%",
    transform: "translateX(-50%)",
    position: "absolute",
    maxWidth: "100%",
    maxHeight: "35%",
  },
  gridItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
  stickyPhone: {
    top: "0px",
    position: "sticky",
  },
  paperForm: {
    width: "100%",
    height: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
  emailSentCard: {
    height: "100%",
    backgroundColor: theme.palette.background.default,
    [`${theme.breakpoints.down("xs")}`]: {
      backgroundColor: "auto",
    },
  },
  confirmIconButton: {
    "&:hover": {
      background: "none",
      cursor: "default",
    },
  },
  confirmButton: {
    opacity: "0.8",
    fontSize: "2.6em",
    marginBottom: "-20px",
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "2.2em",
    },
  },
}));

function ForgotPassword(props) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async (forgotPasswordData) => {
    const serverError = await forgotPassword(forgotPasswordData, addToast);
    if (!serverError) {
      addToast("Password Reset Link Sent", "success");
      setEmailSent(true);
    } else addToast("Unexpected Server Error. Try Again Later", "error");
    setValue("email", "");
  };

  const classes = useStyles();

  if (props.loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastFactory toasts={toasts} />
      <Grid container style={{ minHeight: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.gridItem}
          style={{ display: breakpointsDownXS ? "none" : "block" }}
        >
          <div className={`${classes.bannerImage} ${classes.stickyPhone}`}>
            <img className={classes.bannerPhone} src="/LandingPage/Banner Phone Reverse.png" />
            <img
              className={classes.bannerProperty}
              style={{ transform: "translate(-50%, var(--bannedPropertyTranslateY))" }}
              src="/LandingPage/Banner Property Reverse.png"
            />
            <img
              className={classes.bannerROI}
              style={{ transform: "translate(-50%, var(--bannedROITranslateY))" }}
              src="/LandingPage/Banner ROI Reverse.png"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Paper
            className={classes.paperForm}
            style={breakpointsDownXS ? { background: theme.palette.background.default } : {}}
          >
            <div style={{ maxWidth: "450px", textAlign: "center" }}>
              <img src={Logo} className={classes.logo} alt="The Simple Landlord Logo" />
              {!emailSent ? (
                <>
                  <Typography variant="h4" style={{ fontWeight: "700" }}>
                    Forgot Your Password?
                  </Typography>
                  <Typography style={{ marginTop: "10px" }} variant="h6">
                    Don't worry! Just tell us your email and we'll help get your account back in no time
                  </Typography>
                  <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              id="email"
                              label="Email"
                              autoComplete="email"
                              autoFocus
                              error={errors.email && errors.email !== null}
                              helperText={errors.email ? errorMessages["email"][errors.email.type] : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                          Request Password Reset Link
                        </Button>
                      </Grid>
                      <Grid container>
                        <Grid item xs style={{ textAlign: "left" }}>
                          <Link href="/authentication/login" variant="body2">
                            <b>Go back to Log In</b>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="/authentication/signup" variant="body2">
                            <b>Don't have an account? Sign Up</b>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </>
              ) : (
                <>
                  <Box component="div" display={{ xs: "none", sm: "block" }}>
                    <Card className={classes.emailSentCard}>
                      <CardContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <Typography variant="h4" style={{ fontWeight: "700" }}>
                          Email Sent!
                        </Typography>
                        <Typography style={{ marginTop: "10px" }} variant="h6">
                          Please check your inbox for further instructions. The link will expire in 30 minutes. If you
                          can't find it, check your spam folder or request another link.
                        </Typography>
                        <IconButton
                          color="secondary"
                          className={classes.confirmIconButton}
                          disableFocusRipple
                          disableRipple
                        >
                          <VerifiedUserRoundedIcon className={classes.confirmButton} />
                        </IconButton>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box component="div" display={{ xs: "block", sm: "none" }}>
                    <Typography variant="h4" style={{ fontWeight: "700" }}>
                      Email Sent!
                    </Typography>
                    <Typography style={{ marginTop: "10px" }} variant="h6">
                      Please check your inbox for further instructions. The link will expire in 30 minutes. If you can't
                      find it, check your spam folder or request another link.
                    </Typography>
                    <IconButton
                      color="secondary"
                      className={classes.confirmIconButton}
                      disableFocusRipple
                      disableRipple
                    >
                      <VerifiedUserRoundedIcon className={classes.confirmButton} />
                    </IconButton>
                  </Box>
                </>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPassword;
