import { SET_LTB_FORM_DATA } from "../actionTypes/LTBFormActionTypes";

const defaultState = {
  data: null,
};

const LTBFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LTB_FORM_DATA:
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default LTBFormReducer;
