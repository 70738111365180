import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import IconButton from "@material-ui/core/IconButton";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  endAdornmentArrows: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    borderTopRightRadius: "100%",
    borderBottomRightRadius: "100%",
    borderLeft: "1px solid rgba(255, 255, 255, 0.15)",
    padding: "4px 18px 4px 10px",
  },
}));

let TouchStarted = false;
function ArrowNumberInput(props) {
  const { value, onChange, min, InputProps, ...other } = props;

  const inputEl = useRef(null);

  const classes = useStyles();
  const [arrowDirection, setArrowDirection] = useState(0);
  const [updateInterval, setUpdateInterval] = useState(0);

  useEffect(() => {
    if (arrowDirection == 0 || (arrowDirection == -1 && value - 1 < min)) return;
    onChange(value + arrowDirection);
    let newValue = value + arrowDirection;

    if (updateInterval != 0) return;

    setTimeout(() => {
      if (newValue + "" != inputEl?.current?.value) return;

      let intervaler = setInterval(() => {
        let arrowStillPressed = false;

        setArrowDirection((arrowDirection) => {
          arrowStillPressed = arrowDirection != 0 && (arrowDirection != -1 || value - 1 > min);
          return arrowDirection;
        });

        if (arrowStillPressed) setUpdateInterval((updateInterval) => (updateInterval == 0 ? 1 : -updateInterval));
        else {
          clearInterval(intervaler);
          setUpdateInterval(0);
        }
      }, 100);
    }, 1000);
  }, [arrowDirection, updateInterval]);

  return (
    <TextField
      {...other}
      value={value}
      onChange={(e) => {
        console.log(e.target.value);
        let val = parseInt(e.target.value.replace(/[^\d]/, ""));
        onChange(isNaN(val) ? min : val);
      }}
      InputProps={{
        ...InputProps,
        style: { paddingRight: "0" },
        inputRef: (node) => (inputEl.current = node),
        endAdornment: (
          <div className={classes.endAdornmentArrows}>
            <IconButton
              style={{ marginTop: "-4px", position: "relative", top: "3px", padding: "2px" }}
              onMouseDown={() => (TouchStarted ? "" : setArrowDirection(1))}
              onMouseUp={() => setArrowDirection(0)}
              onTouchStart={() => {
                TouchStarted = true;
                setArrowDirection(1);
              }}
              onTouchEnd={() => setArrowDirection(0)}
            >
              <ExpandLessIcon />
            </IconButton>
            <IconButton
              style={{ marginBottom: "-4px", position: "relative", bottom: "3px", padding: "2px" }}
              onMouseDown={() => setArrowDirection(-1)}
              onMouseUp={() => setArrowDirection(0)}
              onTouchStart={() => {
                TouchStarted = true;
                setArrowDirection(-1);
              }}
              onTouchEnd={() => setArrowDirection(0)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        ),
      }}
    />
  );
}

export default ArrowNumberInput;
