import React, { useState, useEffect } from "react";
import VisibilitySensor from 'react-visibility-sensor';

const CountUp = ({number, duration}) => {
  const [count, setCount] = useState("0");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let start = 0;
    const end = number;

    if (start === end || !isVisible) return;

    let incrementTime = (end / duration);

    let timer = setInterval(() => {
      start += incrementTime*10;
      setCount(String(start));
      if (start >= end) clearInterval(timer);
    }, 10);
  }, [number, duration, isVisible]);

  return <>
    <VisibilitySensor onChange={(isVisible) => {
      if (isVisible) setIsVisible(true);
    }}>
      <span>{numberWithCommas(count)}</span>
    </VisibilitySensor>
  </>;
}

export default CountUp;

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}