import { setUser, setLoading, setError } from "../redux/actions/authenticationActions";

import axios from "axios";
import decode from "jwt-decode";
import { setAxiosURL } from "../utils/axiosSettings.js";

const TOKEN_STORAGE_KEY = "userToken";
const MEMORY_STORAGE_KEY = "rememberMe";

setAxiosURL(axios);

const delay = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, time);
  });
};

export const login = async (dispatch, loginData, rememberMe) => {
  let loggedIn = false;
  let responseError = null;
  let user = null;

  dispatch(setLoading(true));
  await axios
    .post("/v2/users/sessions/", loginData)
    .then(async (response) => {
      const token = response.data?.accessToken;
      if (token) {
        try {
          const decodedToken = decode(token);
          localStorage.setItem(TOKEN_STORAGE_KEY, token);
          localStorage.setItem(MEMORY_STORAGE_KEY, rememberMe);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          user = decodedToken;
          loggedIn = true;
        } catch (error) {
          delete axios.defaults.headers.common["Authorization"];
          responseError = "Error storing credentials";
          console.log("Storing", { error });
        }
      } else {
        responseError = response.data?.error || "Login failed";
        console.log("No token", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Login";
    });

  if (loggedIn) dispatch(setUser(user));
  dispatch(setError(responseError));
  dispatch(setLoading(false));

  return loggedIn;
};

export const forgotPassword = async (forgotPasswordData) => {
  let serverError = false;

  await axios
    .post("/v2/users/forgot-password/", forgotPasswordData)
    .then(async (response) => {
      if (response.data) console.log(response.data);
    })
    .catch(async (error) => {
      console.log("Server", { error });
      if (error?.response?.status === 500) serverError = true;
    });

  return serverError;
};

export const resetPassword = async (dispatch, resetPasswordData) => {
  let passwordChanged = false;
  let responseError = false;

  dispatch(setLoading(true));
  await axios
    .post("/v2/users/reset-password/", resetPasswordData)
    .then(async (response) => {
      if (response.data) {
        passwordChanged = true;
        console.log(response.data);
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Resetting Password Failed";
    });

  dispatch(setError(responseError));
  dispatch(setLoading(false));

  return passwordChanged;
};

export const signup = async (dispatch, registrationData) => {
  let registered = false;
  let responseError = null;

  const formData = new FormData();
  Object.keys(registrationData).forEach((field) => formData.append(field, registrationData[field]));

  dispatch(setLoading(true));
  await axios
    .post("/v2/users/", formData)
    .then(async (response) => {
      if (response.data) registered = true;
      else {
        responseError = (response.data && response.data.error) || "Registration failed";
        console.log("No user", { responseError });
      }
    })
    .catch(async (error) => {
      console.log("Server", { error });
      responseError = error.response?.data?.error || "Incorrect Registration";
    });

  dispatch(setError(responseError));
  dispatch(setLoading(false));

  return registered;
};

export const refreshUser = async (dispatch, previousUserValue = false) => {
  let user = null;

  dispatch(setLoading(true));
  try {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const decodedToken = decode(token);
    const expiryTime = decodedToken.exp;
    const currentTime = new Date().getTime() / 1000;
    const expired = currentTime > expiryTime;
    if (!expired) {
      user = decodedToken;
    }
  } catch (e) {}
  if (previousUserValue === false || JSON.stringify(previousUserValue) != JSON.stringify(user)) dispatch(setUser(user));
  dispatch(setLoading(false));
  return user;
};

export const logout = async (dispatch) => {
  let loggedOut = false;
  let logoutError = null;
  dispatch(setLoading(true));
  try {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(MEMORY_STORAGE_KEY);
    dispatch(setUser(null));
    loggedOut = true;
  } catch (error) {
    logoutError = error;
  }

  dispatch(setLoading(false));
  dispatch(setError(logoutError));
  return loggedOut;
};
