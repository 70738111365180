import axios from "axios";
import setAxiosDefaultSettings from "../../../utils/axiosSettings.js";
import { Buffer } from "buffer";

setAxiosDefaultSettings(axios);

export const getFlattenedForm = async (formData) => {
  return await axios
    .get(`/v2/buildings/properties/leases/forms/flattened-forms/?timestamp=${new Date().getTime()}`, {
      params: { ...formData },
      responseType: "arraybuffer",
    })
    .then((response) => {
      if ("emails" in formData) return Buffer.from(response.data, "binary").toString();
      else return Buffer.from(response.data, "binary").toString("base64");
    });
};
