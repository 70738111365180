import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Container from "@material-ui/core/Container";

import { connect } from "react-redux";
import { refreshUser } from "../../services/authenticationServices";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Signup from "./Signup";
import SubscriptionCheckout from "./SubscriptionCheckout";
import Header from "../components/LoggedOutHeader";

import { hasPaidSubscription } from "../../services/billingServices.js";

function AuthenticationRouter(props) {
  const referer =
    props.location && props.location.state && props.location.state.referer ? props.location.state.referer : "/home";

  const [userHasPaid, setUserHasPaid] = useState(null);
  useEffect(() => {
    (async () => {
      const newUser = await refreshUser(props.dispatch, props.user);
      const _userHasPaid = await hasPaidSubscription();
      setUserHasPaid(_userHasPaid);
      if (newUser && !_userHasPaid && window.location.pathname != "/authentication/checkout")
        props.history.push(`/authentication/checkout`);
    })();
  });

  return props.user && userHasPaid ? (
    <Redirect to={referer} />
  ) : (
    <>
      <Header />
      <Container component="main" style={{ maxWidth: "none", padding: "0px" }}>
        <Switch>
          <Route path="/authentication/signup" component={Signup} />
          <Route path="/authentication/forgot-password" component={ForgotPassword} />
          <Route path="/authentication/reset-password" component={ResetPassword} />
          <Route path="/authentication/checkout" component={SubscriptionCheckout} />
          <Route path="/authentication" component={Login} />
        </Switch>
      </Container>
    </>
  );
}

const mapState = (state) => {
  return {
    user: state.authentication.user,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
  };
};
export default connect(mapState, mapDispatch)(AuthenticationRouter);
