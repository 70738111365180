import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { setLTBFormData } from "../../../redux/actions/LTBFormActions";

import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddPhotoIcon from "@material-ui/icons/AddAPhotoRounded";
import CameraEnhanceRounded from "@material-ui/icons/CameraEnhanceRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import AddRounded from "@material-ui/icons/AddRounded";
import TextField from "@material-ui/core/TextField";

import MultiStepForm from "../../components/MutliStepForm";
import ArrowNumberInput from "../../components/ArrowNumberInput";
import { addConditionReport } from "../../../services/property/conditionReportServices";
import { getAlerts, deleteAlert } from "../../../services/property/alertServices";

const filter = createFilterOptions();

const roomTypes = ["living Rooms", "kitchens", "dining Rooms", "bathrooms", "bedrooms"];
const defaultRoomItems = [
  "Floor & Floor Covering(s)",
  "Walls & Ceiling",
  "Lighting Fixture(s)",
  "Window(s) & Screen(s)",
  "Window Covering(s)",
];

const errorMessages = {};

roomTypes.map(
  (roomType) =>
    (errorMessages[roomType.replace(" ", "")] = {
      required: "Please enter the number of rooms",
      min: ["bathrooms", "bedrooms"].includes(roomType)
        ? `You must have at least one ${roomType.slice(0, -1)}`
        : "The number of rooms must be a positive whole number",
    })
);

const useStyles = makeStyles((theme) => ({
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginBottom: theme.spacing(4),
  },
  question: {
    fontWeight: "600",
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(4),
      fontSize: "1.65rem",
    },
  },
  answer: {
    marginBottom: theme.spacing(4),
  },
  dateRange: {
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    padding: theme.spacing(2),
    [`${theme.breakpoints.up("sm")}`]: {
      paddingBottom: "0px",
    },
  },
  sliderMarkLabel: {
    top: "-26px",
  },
  sliderValueLabel: {
    "& > span": {
      marginTop: "-8px",
      marginLeft: "-4px",
      width: "40px",
      height: "40px",
    },
  },
  roomItemTitle: {
    textAlign: "center",
    padding: `0px ${theme.spacing(2)}px`,
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: `0.6em`,
      padding: `0px ${theme.spacing(1)}px`,
    },
  },
  addImgBtn: {
    marginTop: "-7px",
  },
  centeredRow: {
    display: "flex",
    justifyContent: "center",
  },
  centeredColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

let onSubmit = null;
function ConditionReport({ refreshProperty, history, dispatch, setLTBFormData, ...props }) {
  const { property_id } = useParams();

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const theme = useTheme();
  const breakpointsDownXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [formData, setFormData] = useState(
    Object.assign(
      {},
      ...roomTypes.map((roomType) =>
        ["bathrooms", "bedrooms"].includes(roomType)
          ? {
              [roomType.replace(" ", "").slice(0, -1)]: [defaultRoomItems.map((roomItem) => [roomItem, 2])],
            }
          : {}
      )
    )
  );
  useEffect(() => {
    for (let roomType of roomTypes) {
      if (["bathrooms", "bedrooms"].includes(roomType)) {
        setValue(roomType.replace(" ", ""), 1);
        setValue(roomType.replace(" ", "").slice(0, -1), [defaultRoomItems.map((roomItem) => [roomItem, 2])]);
      } else setValue(roomType.replace(" ", ""), 0);
    }
  }, []);
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});

  const errorAndHelperText = (fieldName) => {
    return {
      error:
        (errors && errors[fieldName] && errors[fieldName] !== null) ||
        (errorReceived && errorReceived[fieldName] && errorReceived[fieldName] !== null),
      helperText:
        errorReceived && errorReceived[fieldName]
          ? errorMessages[fieldName][errorReceived[fieldName]]
          : errors && errors[fieldName]
          ? errorMessages[fieldName][errors[fieldName].type]
          : null,
    };
  };

  let formSteps = [
    {
      section: "Setup",
      fieldNames: "numberOfRooms",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Please enter the number of rooms in your property
          </Typography>
          {roomTypes.map((roomType) => (
            <div
              key={roomType.replace(" ", "")}
              className={classes.answer}
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography variant="h6" style={{ paddingRight: theme.spacing(2) }}>
                {roomType.charAt(0).toUpperCase() + roomType.slice(1)}:
              </Typography>
              <Controller
                name={roomType.replace(" ", "")}
                control={control}
                defaultValue={["bathrooms", "bedrooms"].includes(roomType) ? 1 : 0}
                rules={{
                  required: true,
                  min: ["bathrooms", "bedrooms"].includes(roomType) ? 1 : 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <ArrowNumberInput
                    value={(() => {
                      let currentVal = getValues(roomType.replace(" ", "").slice(0, -1));
                      return currentVal ? currentVal.length : 0;
                    })()}
                    onChange={(val) => {
                      if (!isNaN(val) && parseInt(val) > 10) val = 10;
                      let roomID = roomType.replace(" ", "");
                      let newRoomArray = [];
                      if (formData[roomID.slice(0, -1)]) newRoomArray = formData[roomID.slice(0, -1)].slice();
                      let templateRoomItem = defaultRoomItems.map((roomItem) => {
                        return [roomItem, 2];
                      });
                      while (newRoomArray.length > val) newRoomArray.pop();
                      while (newRoomArray.length < val) newRoomArray.push(templateRoomItem.slice());
                      setFormData({ ...formData, [roomID.slice(0, -1)]: newRoomArray });
                      setValue(roomID.slice(0, -1), newRoomArray);
                      setValue(roomID, val);
                    }}
                    style={{ flex: 1, maxWidth: breakpointsDownXS ? "50%" : "70%", borderColor: "red" }}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    min={["bathrooms", "bedrooms"].includes(roomType) ? 1 : 0}
                    InputLabelProps={{ shrink: true }}
                    label={`${breakpointsDownXS ? "#" : "Number"} of rooms`}
                    {...errorAndHelperText(roomType.replace(" ", ""), errorMessages)}
                  />
                )}
              />
            </div>
          ))}
        </>
      ),
    },
    ...roomTypes
      .filter((roomType) => roomType.replace(" ", "").slice(0, -1) in formData)
      .map((roomType) => {
        let roomID = roomType.replace(" ", "");

        let roomTitle = roomType.charAt(0).toUpperCase() + roomType.slice(1);
        let roomArray = [];
        for (let i = 1; i <= formData[roomID.slice(0, -1)].length && i <= 10; i++) {
          roomArray.push({
            section: roomTitle,
            fieldNames: `${roomID.slice(0, -1)}[${i - 1}]`,
            step: (
              <>
                <Typography variant="h4" align="center" className={classes.question}>
                  {roomTitle.slice(0, -1)}
                  {formData[roomID] > 1 ? " " + i : ""} Condition
                </Typography>
                <div className={classes.question}>
                  {formData[roomID.slice(0, -1)] &&
                  formData[roomID.slice(0, -1)][i - 1] &&
                  formData[roomID.slice(0, -1)][i - 1].map
                    ? formData[roomID.slice(0, -1)][i - 1].map((itemObject, itemIndex) =>
                        itemObject != null ? (
                          <div
                            key={itemIndex}
                            className={classes.answer}
                            style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
                          >
                            <div style={{ width: "100%" }} className={classes.centeredRow}>
                              <div style={{ visibility: "hidden", cursor: "pointer" }} className={classes.centeredRow}>
                                <IconButton component="span" className={`${classes.addImgBtn} emptyInput`}>
                                  <AddPhotoIcon />
                                </IconButton>
                                <IconButton className={classes.addImgBtn}>
                                  <ClearRoundedIcon />
                                </IconButton>
                              </div>
                              <div style={{ flex: "1" }} className={classes.centeredRow}>
                                <Typography
                                  variant="h6"
                                  className={classes.roomItemTitle}
                                  style={{ display: itemObject[0] != "" ? "block" : "none" }}
                                >
                                  {itemObject[0]}
                                </Typography>
                                <Controller
                                  name={`${roomID.slice(0, -1)}[${i - 1}]` + `[${itemIndex}][0]`}
                                  control={control}
                                  defaultValue={itemObject[0]}
                                  rules={{}}
                                  render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                      value={value}
                                      onChange={(event, newValue) => {
                                        let valueToSet = newValue;

                                        if (typeof newValue !== "string" && newValue && newValue.inputValue)
                                          valueToSet = newValue.inputValue;
                                        if (!valueToSet) valueToSet = "";

                                        onChange(valueToSet);

                                        const newRoomArray = formData[roomID.slice(0, -1)].slice();
                                        newRoomArray[i - 1][itemIndex][0] = valueToSet;

                                        setFormData({ ...formData, [roomID.slice(0, -1)]: newRoomArray });
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(defaultRoomItems, params);

                                        if (params.inputValue !== "") filtered.push(params.inputValue);

                                        return filtered;
                                      }}
                                      selectOnFocus
                                      handleHomeEndKeys
                                      options={[...defaultRoomItems]}
                                      getOptionLabel={(option) => {
                                        if (typeof option === "string") return option;
                                        if (option && option.inputValue) return option.inputValue;
                                        return option;
                                      }}
                                      renderOption={(option) => option}
                                      groupBy={(option) => "Suggestions"}
                                      style={{
                                        width: 300,
                                        display: itemObject[0] == "" ? "block" : "none",
                                      }}
                                      freeSolo
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Item"
                                          variant="outlined"
                                          margin="normal"
                                          fullWidth
                                          style={{ flex: "1" }}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </div>
                              <div className={classes.centeredRow}>
                                <label
                                  className={classes.centeredColumn}
                                  htmlFor={
                                    `images[${roomID.slice(0, -1)}][${i - 1}]` +
                                    `[${
                                      itemObject[0]
                                        ? itemObject[0].charAt(0).toLowerCase() +
                                          itemObject[0].replaceAll(/[ ()]/g, "").replaceAll("&", "_").slice(1)
                                        : itemIndex
                                    }]`
                                  }
                                >
                                  <input
                                    accept="image/*"
                                    className={classes.input}
                                    style={{ display: "none" }}
                                    id={
                                      `images[${roomID.slice(0, -1)}][${i - 1}]` +
                                      `[${
                                        itemObject[0]
                                          ? itemObject[0].charAt(0).toLowerCase() +
                                            itemObject[0].replaceAll(/[ ()]/g, "").replaceAll("&", "_").slice(1)
                                          : itemIndex
                                      }]`
                                    }
                                    type="file"
                                    {...register(
                                      `images[${roomID.slice(0, -1)}][${i - 1}]` +
                                        `[${
                                          itemObject[0]
                                            ? itemObject[0].charAt(0).toLowerCase() +
                                              itemObject[0].replaceAll(/[ ()]/g, "").replaceAll("&", "_").slice(1)
                                            : itemIndex
                                        }]`
                                    )}
                                    {...(() => {
                                      const imgInput = register(
                                        `images[${roomID.slice(0, -1)}][${i - 1}]` +
                                          `[${
                                            itemObject[0]
                                              ? itemObject[0].charAt(0).toLowerCase() +
                                                itemObject[0].replaceAll(/[ ()]/g, "").replaceAll("&", "_").slice(1)
                                              : itemIndex
                                          }]`
                                      );
                                      return {
                                        onChange: (e) => {
                                          let parentLabel = e.target.closest("label");
                                          if (e.target.value) {
                                            parentLabel.querySelector(".emptyInput").style.display = "none";
                                            parentLabel.querySelector(".filledInput").style.display = "block";
                                            addToast(`Image Added For ${itemObject[0]}`, "success");
                                          }
                                          imgInput.onChange(e);
                                        },
                                      };
                                    })()}
                                  />
                                  <Tooltip
                                    title="Add Image"
                                    placement="top"
                                    PopperProps={{ style: { marginBottom: "-8px" } }}
                                  >
                                    <IconButton component="span" className={`${classes.addImgBtn} emptyInput`}>
                                      <AddPhotoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Replace Image"
                                    placement="top"
                                    PopperProps={{ style: { marginBottom: "-8px" } }}
                                  >
                                    <IconButton
                                      color="secondary"
                                      style={{ display: "none" }}
                                      component="span"
                                      className={`${classes.addImgBtn} filledInput`}
                                      onClick={(e) => {
                                        addToast(`Image Removed For ${itemObject[0]}`, "error");
                                        let parentLabel = e.target.closest("label");
                                        parentLabel.querySelector(".emptyInput").style.display = "block";
                                        parentLabel.querySelector(".filledInput").style.display = "none";
                                        console.log("%c formData: ", "background: limegreen; color: white", formData);
                                        console.log(
                                          "%c itemObject: ",
                                          "background: limegreen; color: white",
                                          itemObject
                                        );
                                        console.log(
                                          "%c itemObject[0]: ",
                                          "background: limegreen; color: white",
                                          itemObject[0]
                                        );
                                        document.getElementById(
                                          `images[${roomID.slice(0, -1)}][${i - 1}]` +
                                            `[${
                                              itemObject[0].charAt(0).toLowerCase() +
                                              itemObject[0].replaceAll(/[ ()]/g, "").replaceAll("&", "_").slice(1)
                                            }]`
                                        ).value = null;
                                      }}
                                    >
                                      <CameraEnhanceRounded />
                                    </IconButton>
                                  </Tooltip>
                                </label>
                                <label className={classes.centeredColumn}>
                                  <Tooltip
                                    title="Remove Section"
                                    placement="top"
                                    PopperProps={{ style: { marginBottom: "-8px" } }}
                                  >
                                    <IconButton
                                      className={classes.addImgBtn}
                                      onClick={() => {
                                        const newRoomArray = formData[roomID.slice(0, -1)].slice();
                                        newRoomArray[i - 1][itemIndex] = null;
                                        let newFormData = {
                                          ...formData,
                                          ...(roomID.slice(0, -1) in formData
                                            ? {
                                                [roomID.slice(0, -1)]: newRoomArray,
                                              }
                                            : {}),
                                        };
                                        setFormData(newFormData);
                                        setValue(`${roomID.slice(0, -1)}[${i - 1}][${itemIndex}]`, null);
                                      }}
                                    >
                                      <ClearRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </label>
                              </div>
                            </div>
                            <Controller
                              name={`${roomID.slice(0, -1)}[${i - 1}]` + `[${itemIndex}][1]`}
                              defaultValue={2}
                              control={control}
                              rules={{}}
                              render={({ field: { onChange, value } }) => (
                                <Slider
                                  value={value}
                                  onChange={(e, val) => onChange(val)}
                                  style={itemIndex == 0 ? { marginTop: "40px", marginBottom: "0px" } : {}}
                                  classes={{ markLabel: classes.sliderMarkLabel, valueLabel: classes.sliderValueLabel }}
                                  valueLabelFormat={(val) => ["Poor", "Fair", "Good"][val]}
                                  getAriaValueText={(val) => val}
                                  aria-labelledby="discrete-slider-restrict"
                                  step={1}
                                  min={0}
                                  max={2}
                                  valueLabelDisplay="auto"
                                  marks={[
                                    { value: 0, label: itemIndex == 0 ? "Poor" : "" },
                                    { value: 1, label: itemIndex == 0 ? "Fair" : "" },
                                    { value: 2, label: itemIndex == 0 ? "Good" : "" },
                                  ]}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      const newRoomArray = formData[roomID.slice(0, -1)].slice();
                      newRoomArray[i - 1].push(["", 2]);
                      let newFormData = {
                        ...formData,
                        ...(roomID.slice(0, -1) in formData
                          ? {
                              [roomID.slice(0, -1)]: newRoomArray,
                            }
                          : {}),
                      };
                      setFormData(newFormData);
                      //setValue(`${roomID.slice(0, -1)}[${i-1}][${newRoomArray.length-1}]`, ['', 2]);
                    }}
                  >
                    Add More <AddRounded />
                  </Button>
                </div>
              </>
            ),
          });
        }

        return roomArray;
      })
      .flat(),
  ];

  useEffect(() => {
    if (!formData) return;
    console.log("reactHookForms: ", getValues());
    console.log("formData: ", formData);

    let skipping = [];

    setStepsToSkip(skipping);
  }, [formData]);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      addToast(errors, "error");
    } else if (typeof errors === "object") {
      setErrorReceived(errors);
    }
  };

  const submitForm = async (conditionReportData, callback = null) => {
    let conditionReportAlert = await getAlerts(null, { propertyID: props.property["ID"], type: "conditionReport" });
    if (!conditionReportAlert || conditionReportAlert.length == 0) {
      addToast("Condition Report Setup Failed", "error");
      if (callback !== null) callback();
      return false;
    }
    conditionReportAlert = conditionReportAlert[0];

    if (conditionReportData[""]) delete conditionReportData[""];

    for (let key in conditionReportData) {
      if (typeof conditionReportData[key] == "number") delete conditionReportData[key];
    }

    for (let [roomType, rooms] of Object.entries(conditionReportData)) {
      if (roomType == "images") continue;
      for (const [roomIndex, roomItems] of rooms.entries()) {
        let itemObject = {};
        for (let roomItem of roomItems) if (roomItem != null) itemObject[roomItem[0]] = roomItem[1];
        rooms[roomIndex] = itemObject;
      }
    }

    for (let [roomType, rooms] of Object.entries(conditionReportData["images"])) {
      for (let roomItems of rooms) {
        for (let [roomItem, fileList] of Object.entries(roomItems)) {
          if (fileList && fileList.length && fileList.length > 0)
            roomItems[roomItem] = {
              name: fileList[0].name,
              size: fileList[0].size,
              type: fileList[0].type,
              content: await readFileAsync(fileList[0]),
            };
          else delete roomItems[roomItem];
        }
      }
    }

    conditionReportData["leaseID"] = conditionReportAlert.leaseID;
    //console.log("conditionReportData", conditionReportData);
    const reportAdded = await addConditionReport(conditionReportData, handleErrors);
    if (!reportAdded) {
      addToast("Condition Report Setup Failed", "error");
      if (callback !== null) callback();
      return false;
    }

    addToast("Condition Report Added Successfully", "success");
    if (callback !== null) callback();
    refreshProperty();

    if (conditionReportAlert) await deleteAlert({ ID: conditionReportAlert["ID"] }, dispatch);

    setLTBFormData(reportAdded);
    document.cookie = `formID=${reportAdded["ID"]}; expires=${new Date(
      Date.now() + 86400 * 1000
    ).toUTCString()}; path=/;`;
    history.push(`/${property_id}/forms/viewer`);

    return true;
  };

  return (
    <>
      <MultiStepForm
        formSteps={formSteps}
        stepsToSkip={stepsToSkip}
        formData={formData}
        setFormData={setFormData}
        submitForm={submitForm}
        handleSubmit={handleSubmit}
        setOnSubmit={(func) => (onSubmit = func)}
        errorReceived={errorReceived}
        toasts={toasts}
        dynamicStepper={true}
        formProps={{ onKeyDown: (e) => e.key === "Enter" && e.preventDefault() }}
      />
    </>
  );
}

const mapState = (state) => {
  return {
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(ConditionReport);

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}
