import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { setLTBFormData } from "../../../redux/actions/LTBFormActions";

import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import AddRounded from "@material-ui/icons/AddRounded";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import MultiStepForm from "../../components/MutliStepForm";
import RadioOption from "../../components/RadioOption";
import RadioOptions from "../../components/RadioOptions";
import MoneyInput from "../../components/MoneyInput";
import Dropzone from "../../components/Dropzone";
import TenantDoubleDialog from "./components/TenantDoubleDialog";
import TenantList from "./components/TenantList";

import { addLease } from "../../../services/property/leaseServices";
import { calculateProratedRent } from "../../../utils/proratedRent";
import { addTenant } from "../../../services/property/tenantServices";
import { getAlerts } from "../../../services/property/alertServices";

let errorMessages = {
  newLease: {
    required: "Please select whether this is a new or existing lease",
    pattern: "Please select whether this is a new or existing lease",
  },
  autoFillLease: {
    required: "Please select whether or not you've already filled out the lease agreement",
    pattern: "Please select whether or not you've already filled out the lease agreement",
  },
  leaseAgreement: {
    required: "Uploading a PDF of your lease agreement is required to setup a lease",
    type: "File type must be PDF",
    size: "File size must be smaller than 8MB",
    error: "Unexpected error occured, please upload the file again",
  },
  tenantCount: {
    required: "At least one tenant is required to setup a lease",
    min: "At least one tenant is required to setup a lease",
    pattern: "At least one tenant is required to setup a lease",
  },
  isCondo: {
    required: "Please select whether or not this is a condo",
    pattern: "Please select whether or not this is a condo",
  },
  parkingSpace: {
    required: "Please fill out the number of parking spots and type of parking available",
  },
  agreeToEmail: {
    required: "Please select whether or not you and the tenant(s) agree to receive notices by email",
    pattern: "Please select whether or not you and the tenant(s) agree to receive notices by email",
  },
  giveContactInfo: {
    required: "Please pick what contact info to provide to your tenant(s)",
    pattern: "Please pick what contact info to provide to your tenant(s)",
  },
  rentPrice: {
    required: "Please enter the rent price",
    pattern: "The rent price must be a positive number and can't have more than 2 decimals",
  },
  service: [
    {
      required: "Please enter the name of the service",
      length: "The name of the service cannot exceed 64 characters",
    },
  ],
  servicePrice: [
    {
      required: "Please enter the service price",
      pattern: "The service price must be a positive number and can't have more than 2 decimals",
    },
  ],
  electricityPayer: {
    required: "Please select who's responsible for paying for electricity",
    pattern: "Please select who's responsible for paying for electricity",
  },
  heatPayer: {
    required: "Please select who's responsible for paying for heat",
    pattern: "Please select who's responsible for paying for heat",
  },
  waterPayer: {
    required: "Please select who's responsible for paying for water",
    pattern: "Please select who's responsible for paying for water",
  },
  rentCycle: {
    required: "Please select how often you are charging rent",
    pattern: "Please select how often you are charging rent",
  },
  moveInDate: {
    required: "Please select the date the tenants will be moving in",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  moveOutDate: {
    required: "Please select the date the tenants moved out",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  leaseStartDate: {
    required: "Please select the date that the lease will start",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  rentCollectionDay: {
    required: "Please select the rent collection day",
    pattern: "Please select a valid collection day",
  },
  insuranceRequired: {
    required: "Please select whether tenant liability insurance is required",
    pattern: "Please select whether tenant liability insurance is required",
  },
  isFixedTerm: {
    required: "Please select whether this is a fixed term lease or not",
    pattern: "Please select whether this is a fixed term lease or not",
  },
  fixedTermDate: {
    required: "Please select the date that the fixed term will end",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  isDeposited: {
    required: "Please select whether there's a rent deposit or not",
    pattern: "Please select whether there's a rent deposit or not",
  },
  rentDeposit: {
    required: "Please enter the amount for the rent deposit",
    pattern: "The rent deposit must be a positive number and can't have more than 2 decimals",
  },
  initialDeposit: {
    required: "Please enter the amount for the initial rent deposit",
    pattern: "The initial deposit must be a positive number and can't have more than 2 decimals",
  },
  depositDate: {
    required: "Please select the date of the rent deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  interestPaid: {
    required: "Please select whether you've paid interest on the rent deposit or not",
    pattern: "Please select whether you've paid interest on the rent deposit or not",
  },
  interestStartDate: {
    required: "Please select the start-date of the last rental period you paid interest on the deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  interestEndDate: {
    required: "Please select the end-date of the last rental period you paid interest on the deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  isKeyDeposited: {
    required: "Please select whether there's a key deposit or not",
    pattern: "Please select whether there's a key deposit or not",
  },
  keyDeposit: {
    required: "Please enter the amount for the key deposit",
    pattern: "The key deposit must be a positive number and can't have more than 2 decimals",
  },
};

const filter = createFilterOptions();
const originalServiceList = [
  "Parking",
  "Gas",
  "Air Conditioning",
  "Additional Storage",
  "On-Site Laundry",
  "Guest Parking",
];

const useStyles = makeStyles((theme) => ({
  content: {
    borderRadius: "var(--tsl-brand-border-radius)",
    padding: "var(--tsl-brand-card-padding)",
    marginBottom: theme.spacing(4),
  },
  question: {
    fontWeight: "600",
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(4),
      fontSize: "1.65rem",
    },
  },
  questionSmall: {
    fontWeight: "600",
    fontSize: "1.8rem",
    marginBottom: theme.spacing(4),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(2),
      fontSize: "1.4rem",
    },
  },
  answer: {
    marginBottom: theme.spacing(4),
  },
  leaseAgreementMessage: {
    fontSize: "1rem",
    fontWeight: "500",
    color: theme.palette.error.main,
    textAlign: "center",
    marginTop: theme.spacing(-2),
  },
  tenantMessage: {
    fontSize: "1.1rem",
    fontWeight: "500",
    color: theme.palette.error.main,
    textAlign: "center",
  },
  primaryColor: {
    color: theme.palette.primary.light,
  },
  secondaryColor: {
    color: theme.palette.secondary.light,
  },
  prorateExplanation: {
    fontWeight: "500",
    textAlign: "center",
    fontSize: "1.3rem",
    "&:last-of-type": {
      marginBottom: theme.spacing(6),
    },
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.1rem",
    },
  },
  dateRange: {
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    fontSize: "1.4em",
    padding: theme.spacing(1.5, 2.5, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      padding: theme.spacing(1.5, 0, 1.5),
    },
  },
  leaseLink: {
    color: theme.palette.primary.main,
  },
  addServiceBtn: {
    marginBottom: theme.spacing(8),
    [`${theme.breakpoints.down("xs")}`]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

let onSubmit = null;
function LeaseSetup({ refreshProperty, history, setLTBFormData, dispatch, ...props }) {
  const { property_id } = useParams();
  /*useEffect(() => {
    if (props.lease) history.push(`/${property_id}`);
  }, [props.lease]);*/

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const [toasts, setToasts] = useState([]);
  const addToast = (content, severity) => setToasts([...toasts, { content, severity }]);

  const [formData, setFormData] = useState({});
  useEffect(() => {
    setValue("service", []);
  }, []);

  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [errorReceived, setErrorReceived] = useState({});

  const [latestLease, setLatestLease] = useState(null);
  const [leaseAgreement, setLeaseAgreement] = useState(null);
  const onLeaseAgreementUpload = (files) => {
    setLeaseAgreement(files);
    setValue("leaseAgreement", files);
    clearErrors("leaseAgreement");
    if (files) addToast("Lease Agreement Uploaded", "success");
  };

  const [tenantFormData, setTenantFormData] = useState(false);
  const [tenants, setTenants] = useState([]);

  const [numServices, setNumServices] = useState(0);
  const [serviceFields, setServiceFields] = useState("services");

  useEffect(() => {
    errorMessages.service = [];
    errorMessages.servicePrice = [];
    let newServiceFields = "services";
    for (let i = 0; i < numServices; i++) {
      errorMessages.service.push({
        required: "Please enter the name of the service",
        length: "The name of the service cannot exceed 64 characters",
      });
      errorMessages.servicePrice.push({
        required: "Please enter the service price",
        pattern: "The service price must be a positive number and can't have more than 2 decimals",
      });
      newServiceFields += ` service[${i}] servicePrice[${i}]`;
      setServiceFields(newServiceFields);
    }
  }, [numServices]);

  useEffect(() => {
    (async () => {
      console.log("Property: ", props.property);
      if (!props.property?.leases || props.property?.leases?.length == 0) return;
      let _latestLease = props.property.leases[0];

      for (let lease of props.property.leases) {
        if (moment(lease.moveOutDate).isAfter(_latestLease.moveOutDate, "day")) _latestLease = lease;
      }

      if (!_latestLease?.moveOutDate) {
        let alertReceived = await getAlerts(null, {
          buildingID: props.building?.ID,
          propertyID: props.property?.ID,
          leaseID: _latestLease.ID,
          type: "moveOut",
        });
        if (alertReceived.length > 0) alertReceived = alertReceived[0];
        console.log("Moveout Alert Received: ", alertReceived);
        let alertData = alertReceived?.data ? JSON.parse(alertReceived?.data) : {};
        if (alertData?.date) _latestLease.moveOutDate = alertData.date;
      }

      setLatestLease(_latestLease);
    })();
  }, [props.property]);

  const [prorateData, setProrateData] = useState({});

  errorMessages = { ...errorMessages };

  const errorAndHelperText = (fieldName) => {
    if (Array.isArray(fieldName)) {
      let currentError = errors;
      let currentErrorMessages = errorMessages;
      for (let field of fieldName) {
        if (field in currentError) currentError = currentError[field];
        else currentError = {};
        if (field in currentErrorMessages) currentErrorMessages = currentErrorMessages[field];
        else currentErrorMessages = {};
      }
      if (Object.keys(currentError).length === 0) currentError = null;
      if (Object.keys(currentErrorMessages).length === 0) currentErrorMessages = null;

      return {
        error: errors && currentError && currentError !== null,
        helperText:
          currentError && currentErrorMessages && "type" in currentError
            ? currentErrorMessages[currentError.type]
            : null,
      };
    } else
      return {
        error: errors && errors[fieldName] && errors[fieldName] !== null,
        helperText: errors && errors[fieldName] ? errorMessages[fieldName][errors[fieldName].type] : null,
      };
  };

  let formSteps = [
    {
      section: "Lease Agreement",
      fieldNames: "newLease",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What would you like to do?
          </Typography>
          <Controller
            name="newLease"
            control={control}
            rules={{
              required: true,
              pattern: /^-?[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onMouseUp={(e) => {
                  handleSubmit(onSubmit)();
                }}
                {...errorAndHelperText("newLease")}
              >
                <RadioOption value="0" label="Add an active lease" className={classes.answer} />
                <RadioOption value="1" label="Schedule a future move-in" className={classes.answer} />
                <RadioOption value="-1" label="Add old lease for bookkeeping" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "invalidLease",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall + " " + classes.answer}>
            {formData?.newLease === "1" ? "A new" : "An active"} lease can't be be{" "}
            {formData?.newLease === "1" ? "scheduled" : "added"} because this property already has{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              {latestLease?.status == "movingIn" ? "a scheduled move-in" : "an active lease"}
            </span>
          </Typography>
          <Typography variant="h6" align="center" style={{ marginBottom: "32px" }}>
            You need to{" "}
            {latestLease?.status == "movingIn"
              ? "delete the scheduled move-in"
              : `${formData?.newLease === "1" ? "schedule a move out for" : "move out"} your active lease`}{" "}
            before a new one can be added
          </Typography>
        </>
      ),
      nextBtnProps: {
        enabled: false,
      },
    },
    {
      section: "Lease Agreement",
      fieldNames: "autoFillLease",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Have you already filled out a lease agreement?
          </Typography>
          <Controller
            name="autoFillLease"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("autoFillLease")}
              >
                <RadioOption value="0" label="Yes, I have" className={classes.answer} />
                <RadioOption value="1" label="No, fill it out for me" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "leaseAgreement",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Please upload your lease agreement
          </Typography>
          <Controller
            name="leaseAgreement"
            control={control}
            rules={{
              validate: {
                required: () => leaseAgreement !== null,
              },
            }}
            render={() => <Dropzone onFileUpload={onLeaseAgreementUpload} uploadedFiles={leaseAgreement} />}
          />
          {errors.leaseAgreement && (
            <FormHelperText className={`${classes.leaseAgreementMessage} ${classes.answer}`}>
              {errorMessages["leaseAgreement"][errors.leaseAgreement.type]}
            </FormHelperText>
          )}
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "tenantCount",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Please add your tenants' information
          </Typography>
          <Controller
            name="tenantCount"
            control={control}
            rules={{
              required: true,
              min: 1,
              pattern: /^\d+$/,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <Box component="div" display={{ xs: "none", sm: "block" }}>
                  <Paper className={classes.content}>
                    <TenantList tenants={tenants} setTenantFormData={setTenantFormData} setTenantCount={onChange} />
                    {errors.tenantCount && (
                      <FormHelperText className={classes.tenantMessage}>
                        {errorMessages["tenantCount"][errors.tenantCount.type]}
                      </FormHelperText>
                    )}
                  </Paper>
                </Box>
                <Box component="div" display={{ xs: "block", sm: "none" }}>
                  <TenantList
                    tenants={tenants}
                    className={classes.answer}
                    setTenantFormData={setTenantFormData}
                    setTenantCount={onChange}
                  />
                  {errors.tenantCount && (
                    <FormHelperText className={`${classes.tenantMessage} ${classes.answer}`}>
                      {errorMessages["tenantCount"][errors.tenantCount.type]}
                    </FormHelperText>
                  )}
                </Box>
              </>
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "isCondo",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Is this property a condominium?
          </Typography>
          <Controller
            name="isCondo"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("isCondo")}
              >
                <RadioOption value="1" label="Yes, it is" className={classes.answer} />
                <RadioOption value="0" label="No, it's not" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "parkingSpace",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Please describe the type of parking &amp; number of parking spaces available:
          </Typography>
          <Controller
            name="parkingSpace"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label="Parking Space"
                className={classes.answer}
                {...errorAndHelperText("parkingSpace")}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "agreeToEmail",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Do you and your tenant(s) agree to receive notices and documents by email?
          </Typography>
          <Controller
            name="agreeToEmail"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("agreeToEmail")}
              >
                <RadioOption value="1" label="Yes, we do" className={classes.answer} />
                <RadioOption value="0" label="No, we don't" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Lease Agreement",
      fieldNames: "giveContactInfo",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Would you like to provide your phone &amp; email address for emergencies?
          </Typography>
          <Controller
            name="giveContactInfo"
            control={control}
            rules={{
              required: true,
              pattern: /^[210]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("giveContactInfo")}
              >
                <RadioOption value="0" label="Don't provide either" className={classes.answer} />
                <RadioOption value="1" label="Only provide my email" className={classes.answer} />
                <RadioOption value="2" label="Provide both my phone and email" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "rentCycle",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How often
            {formData?.newLease === "1" ? " will you be " : formData?.newLease === "-1" ? " were you " : " are you "}
            <span style={{ whiteSpace: "nowrap" }}>charging rent?</span>
          </Typography>
          <Controller
            name="rentCycle"
            control={control}
            defaultValue="Monthly"
            rules={{
              required: true,
              pattern: /^Monthly$|^Weekly$|^Daily$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("rentCycle")}
              >
                <RadioOption value="Monthly" label="Monthly" className={classes.answer} />
                <RadioOption value="Weekly" label="Weekly" className={classes.answer} />
                <RadioOption value="Daily" label="Daily" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "rentPrice",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much
            {formData?.newLease === "1" ? " will you be " : formData?.newLease === "-1" ? " were you " : " are you "}
            charging for the base
            <span style={{ whiteSpace: "nowrap" }}>
              {formData?.rentCycle ? ` ${formData["rentCycle"].toLowerCase()}` : ""} rent?
            </span>
          </Typography>
          <Controller
            name="rentPrice"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Rent Price"
                className={classes.answer}
                {...errorAndHelperText("rentPrice")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: serviceFields,
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Would you like to add any other services to the rent price?
          </Typography>
          {formData["service"]
            ? formData.service.map((serviceVal, index) => (
                <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Controller
                    name={`service[${index}]`}
                    control={control}
                    defaultValue={formData.service && formData.service.length > index ? formData.service[index] : null}
                    rules={{
                      required: true,
                      length: (value) => value.length <= 64,
                    }}
                    render={({ field: { onChange, value } }) => {
                      const autoCompleteOnChange = (event, newValue) => {
                        let valueToSet = newValue;

                        if (typeof newValue !== "string" && newValue && newValue.inputValue)
                          valueToSet = newValue.inputValue;
                        if (!valueToSet) valueToSet = "";

                        onChange(valueToSet);

                        setValue(`service[${index}]`, newValue ? newValue : "");
                        if (newValue) trigger(`service[${index}]`);
                        setFormData({ ...formData, service: getValues("service") });
                      };

                      return (
                        <Autocomplete
                          value={value}
                          onChange={autoCompleteOnChange}
                          filterOptions={(options, params) => {
                            const filtered = filter(originalServiceList, params);

                            if (params.inputValue !== "") filtered.push(params.inputValue);

                            return filtered;
                          }}
                          selectOnFocus={true}
                          handleHomeEndKeys={true}
                          options={[...originalServiceList]}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") return option;
                            if (option && option.inputValue) return option.inputValue;
                            return option;
                          }}
                          renderOption={(option) => option}
                          groupBy={(option) => "Suggestions"}
                          style={{ width: 300 }}
                          freeSolo={true}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label="Service"
                                variant="outlined"
                                margin="normal"
                                fullWidth={true}
                                className={classes.answer}
                                style={{ flex: "1" }}
                                {...errorAndHelperText(["service", index])}
                                onChange={(e) => {
                                  autoCompleteOnChange(e, e.target.value);
                                  params.inputProps.onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center", paddingLeft: "16px", maxWidth: "50%" }}>
                    <Controller
                      name={`servicePrice[${index}]`}
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);

                            let newServicePrice = formData.service.map((val, i) => {
                              if (i == index) return e.target.value;
                              else if (
                                "servicePrice" in formData &&
                                formData.servicePrice &&
                                formData.servicePrice.length > i
                              )
                                return formData.servicePrice[i];
                              return null;
                            });
                            setFormData({ ...formData, servicePrice: newServicePrice });
                          }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Price"
                          className={classes.answer}
                          style={{ flex: "1" }}
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                          {...errorAndHelperText(["servicePrice", index])}
                        />
                      )}
                    />
                    <IconButton
                      style={{ marginTop: "-18px", marginRight: "-20px" }}
                      size="medium"
                      onClick={() => {
                        let newFormData = {
                          ...formData,
                          ...("service" in formData
                            ? { service: formData.service.filter((val, i) => i != index) }
                            : {}),
                          ...("servicePrice" in formData
                            ? { servicePrice: formData.servicePrice.filter((val, i) => i != index) }
                            : {}),
                        };
                        setFormData(newFormData);

                        let controlledServices = getValues("service");
                        for (let i = 0; i < controlledServices.length; i++) {
                          if (i >= newFormData["service"].length) setValue(`service[${i}]`, null);
                          else setValue(`service[${i}]`, newFormData["service"][i]);
                        }

                        controlledServices = getValues("servicePrice");
                        if (!("servicePrice" in newFormData)) return;
                        for (let i = 0; i < controlledServices.length; i++) {
                          if (i >= newFormData["servicePrice"].length) setValue(`servicePrice[${i}]`, null);
                          else setValue(`servicePrice[${i}]`, newFormData["servicePrice"][i]);
                        }

                        setNumServices(numServices - 1);
                        clearErrors([`service[${index}]`, `servicePrice[${index}]`]);
                      }}
                    >
                      <ClearRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))
            : ""}
          <Button
            fullWidth
            variant="contained"
            className={classes.addServiceBtn}
            onClick={() => {
              let newFormData = {
                ...formData,
                ...("service" in formData ? { service: [...formData.service, ""] } : {}),
              };
              setFormData(newFormData);
              setNumServices(numServices + 1);
            }}
          >
            Add {formData["service"] && formData["service"].length > 0 ? "Another " : "A "}
            Service <AddRounded />
          </Button>
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "utilities electricityPayer heatPayer waterPayer",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Who is responsible for paying these utilities?
          </Typography>
          {["electricity", "heat", "water"].map((utility, index) => (
            <div key={utility} style={{ marginBottom: index == 2 ? "64px" : "32px" }}>
              <Typography variant="h6" align="center">
                {utility.charAt(0).toUpperCase() + utility.slice(1)}:
              </Typography>
              <Controller
                name={utility + "Payer"}
                control={control}
                rules={{
                  required: true,
                  pattern: /^Landlord|Tenant$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <RadioOptions
                    value={value}
                    GroupStyle={{ flexDirection: "row", gap: "20px", marginTop: "20px" }}
                    onChange={(e) => onChange(e.target.value)}
                    {...errorAndHelperText(utility + "Payer")}
                  >
                    <RadioOption value="Landlord" label="Landlord" style={{ flex: "1" }} />
                    <RadioOption value="Tenant" label="Tenant" style={{ flex: "1" }} />
                  </RadioOptions>
                )}
              />
            </div>
          ))}
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "moveInDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What day {formData?.newLease === "1" ? "will" : "did"} the tenant(s){" "}
            <span style={{ whiteSpace: "nowrap" }}>move in?</span>
          </Typography>
          {formData?.newLease !== "-1" && latestLease?.moveOutDate && (
            <Typography variant="h6" align="center" style={{ marginBottom: "32px" }}>
              The earliest move-in date you can select is the move-out date of your last lease on{" "}
              {moment(latestLease.moveOutDate).format("MMMM Do, YYYY")}
            </Typography>
          )}
          <Controller
            name="moveInDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Move-In Date"
                  format="yyyy-MM-dd"
                  {...(formData?.newLease !== "-1" &&
                    latestLease?.moveOutDate && {
                      minDate: (() => moment(latestLease.moveOutDate))(),
                      minDateMessage:
                        "The earliest move-in date you can select is the move-out date of your last lease",
                    })}
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("moveInDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "leaseStartDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When {formData?.newLease === "1" ? "does" : "did"} the lease start?
          </Typography>
          <Controller
            name="leaseStartDate"
            control={control}
            defaultValue={
              formData["rentCycle"] === null || formData["moveInDate"] === null
                ? null
                : formData["rentCycle"] !== "Monthly" || moment(formData["moveInDate"]).format("DD") === "01"
                ? moment(formData["moveInDate"]).format("YYYY-MM-DD")
                : moment(formData["moveInDate"]).startOf("month").add(1, "months").format("YYYY-MM-DD")
            }
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Lease Start Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("leaseStartDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "rentCollectionDay",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What day of the
            {formData?.rentCycle === "Weekly" ? " week " : " month "}
            {formData?.newLease === "1" ? " will you be " : formData?.newLease === "-1" ? " were you " : " are you "}
            <span style={{ whiteSpace: "nowrap" }}>charging rent?</span>
          </Typography>
          <Controller
            name="rentCollectionDay"
            control={control}
            defaultValue={
              formData["rentCycle"] === null || formData["rentCycle"] === "Daily"
                ? ""
                : formData["rentCycle"] === "Monthly"
                ? "1"
                : formData["leaseStartDate"] === null
                ? ""
                : moment(formData["leaseStartDate"]).format("dddd")
            }
            rules={{
              required: true,
              pattern:
                formData?.rentCycle === "Weekly"
                  ? /^Monday$|^Tuesday$|^Wednesday$|^Thursday$|^Friday$|^Saturday$|^Sunday$/
                  : /^\d$|^1\d$|^2\d$/,
            }}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
                className={classes.answer}
                error={errors["rentCollectionDay"] && errors["rentCollectionDay"] !== null}
              >
                <InputLabel>Rent Collection Day</InputLabel>
                <Select
                  {...field}
                  label="Rent Collection Day"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {formData?.rentCycle === "Weekly"
                    ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => {
                        return (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        );
                      })
                    : Array.from({ length: 28 }, (_, idx) => `${++idx}`).map((index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                </Select>
                {errors.rentCollectionDay && (
                  <FormHelperText>
                    {errors["rentCollectionDay"]
                      ? errorMessages["rentCollectionDay"][errors["rentCollectionDay"].type]
                      : ""}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "prorateQuestion",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall}>
            So, how much should you be paid in rent for your first month on{" "}
            <span className={classes.primaryColor}>{prorateData["firstPaymentDate"]}</span>?
          </Typography>
          <p className={classes.prorateExplanation}>
            Obviously, you will be paid <span className={classes.primaryColor}>{formData["rentPrice"]}</span> for the
            month of <span className={classes.primaryColor}>{prorateData["leaseMonth"]}</span>, but what about the{" "}
            <span className={classes.primaryColor}>{prorateData["proratedDaysString"]}</span> in{" "}
            <span className={classes.primaryColor}>{prorateData["proratedMonth"]}</span> that your tenant will be
            spending in your property before the official start of the lease?
          </p>
          <p className={classes.prorateExplanation}>
            To answer this question we need to understand prorates and how they work.
          </p>
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "prorateQuestion",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall}>
            So, how much should you be paid in rent for your first month on{" "}
            <span className={classes.primaryColor}>{prorateData["firstPaymentDate"]}</span>?
          </Typography>
          <p className={classes.prorateExplanation}>
            <span className={classes.secondaryColor}>
              Prorated rent is a rent charge that's proportional to the number of days the tenant spends in your
              property the month they moved in.
            </span>
            <br />
          </p>
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "prorateQuestion",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall}>
            So, how much should you be paid in rent for your first month on{" "}
            <span className={classes.primaryColor}>{prorateData["firstPaymentDate"]}</span>?
          </Typography>
          <p className={classes.prorateExplanation}>
            Since your tenant is spending{" "}
            <span className={classes.primaryColor}>{prorateData["proratedDaysString"]}</span> in your property in{" "}
            <span className={classes.primaryColor}>{prorateData["proratedMonth"]}</span>, and{" "}
            <span className={classes.primaryColor}>{prorateData["proratedMonth"]}</span> has{" "}
            <span className={classes.primaryColor}>{prorateData["daysInProratedMonth"]}</span> days, we can calculate
            that the percentage of the month your tenant is spending in your property is:
            <br />
          </p>
          <p className={classes.prorateExplanation}>
            <span className={classes.primaryColor}>{prorateData["numOfProratedDays"]}</span>/
            <span className={classes.primaryColor}>{prorateData["daysInProratedMonth"]}</span> ={" "}
            <span className={classes.primaryColor}>{prorateData["proportionOfProratedMonth"]} </span>
            or <span className={classes.primaryColor}>{prorateData["percentOfProratedMonth"]}</span>
          </p>
          <p className={classes.prorateExplanation}>
            And <span className={classes.primaryColor}>{prorateData["percentOfProratedMonth"]}</span> of your rent is
            <span className={classes.primaryColor}> {formData["rentPrice"]}</span>x
            <span className={classes.primaryColor}>{prorateData["proportionOfProratedMonth"]}</span> ={" "}
            <span className={classes.primaryColor}>${prorateData["proratedRent"]}</span>
          </p>
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "prorateQuestion",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall}>
            So, how much should you be paid in rent for your first month on{" "}
            <span className={classes.primaryColor}>{prorateData["firstPaymentDate"]}</span>?
          </Typography>
          <p className={classes.prorateExplanation}>
            So the total amount of rent your tenant should pay on{" "}
            <span className={classes.primaryColor}>{prorateData["firstPaymentDate"]}</span> is the prorated rent (
            <span className={classes.primaryColor}>${prorateData["proratedRent"]}</span>) +{" "}
            <span className={classes.primaryColor}>{prorateData["leaseMonth"]}</span>'s normal rent (
            <span className={classes.primaryColor}>{formData["rentPrice"]}</span>) adding up to a total of{" "}
            <span className={classes.secondaryColor}>${prorateData["firstMonthsRent"]}</span> for the first month.
          </p>
          <p className={`${classes.prorateExplanation} ${classes.answer}`}>
            From there, your tenant should pay the normal rent of{" "}
            <span className={classes.primaryColor}>{formData["rentPrice"]}</span> on the{" "}
            <span className={classes.primaryColor}>
              {moment("1970-01-" + formData["rentCollectionDay"]).format("Do")}
            </span>{" "}
            of every month after that.
          </p>
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "insuranceRequired",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.questionSmall}>
            Do you want your tenant to get liability insurance as a requirement of
            <span style={{ whiteSpace: "nowrap" }}> this lease?</span>
          </Typography>
          <Controller
            name="insuranceRequired"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("insuranceRequired")}
              >
                <RadioOption
                  value="1"
                  label={`Yes, the tenant must have liability insurance at all times`}
                  className={classes.answer}
                />
                <RadioOption value="0" label={`No, the tenant isn't required to get insurance`} />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Rent Info",
      fieldNames: "moveOutDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What day did the tenant(s) <span style={{ whiteSpace: "nowrap" }}>move out?</span>
          </Typography>
          <Controller
            name="moveOutDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Move-Out Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("moveOutDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Fixed Term",
      fieldNames: "isFixedTerm",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            {formData?.newLease === "1" ? "Will" : formData?.newLease === "-1" ? "Did" : "Does"} this lease have a fixed
            term end date?
          </Typography>
          <Controller
            name="isFixedTerm"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("isFixedTerm")}
              >
                <RadioOption
                  value="1"
                  label={`Yes, it ${formData?.newLease === "-1" ? "did" : "will"} end on a fixed date`}
                  className={classes.answer}
                />
                <RadioOption
                  value="0"
                  label={`No, it ${formData?.newLease === "-1" ? "didn't" : "doesn't"} end on a fixed date`}
                />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Fixed Term",
      fieldNames: "fixedTermDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            What day {formData?.newLease === "-1" ? "did" : "does"} the fixed{" "}
            <span style={{ whiteSpace: "nowrap" }}>term end?</span>
          </Typography>
          <Controller
            name="fixedTermDate"
            control={control}
            defaultValue={
              formData["leaseStartDate"] === null
                ? null
                : moment(formData["leaseStartDate"]).add(1, "years").subtract(1, "days").format("YYYY-MM-DD")
            }
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Fixed-Term End Date"
                  format="yyyy-MM-dd"
                  {...(formData["leaseStartDate"] !== null && {
                    minDate: moment(formData["leaseStartDate"]).add(1, "days").format("YYYY-MM-DD"),
                    minDateMessage:
                      "The earliest fixed term end date you can select is one day after the lease start date",
                  })}
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("fixedTermDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "isDeposited",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            {formData?.newLease === "1" ? "Will you" : "Did you"} collect a deposit for the last
            {formData?.rentCycle === "Weekly" ? " week" : formData?.rentCycle === "Daily" ? " day" : " month"}
            's rent?
          </Typography>
          <Controller
            name="isDeposited"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("isDeposited")}
              >
                <RadioOption
                  value="1"
                  label={`Yes, I ${formData?.newLease === "0" ? "have collected" : "will collect"} a rent deposit`}
                  className={classes.answer}
                />
                <RadioOption
                  value="0"
                  label={`No, I ${formData?.newLease === "0" ? "haven't collected" : "won't collect"} a rent deposit`}
                />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "rentDeposit",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much
            {formData?.newLease === "1"
              ? " have you collected or will you be collecting as a "
              : formData?.newLease === "-1"
              ? " did you have "
              : " do you currently have "}
            <span style={{ whiteSpace: "nowrap" }}>{formData?.newLease === "1" ? "" : "on "}deposit?</span>
          </Typography>
          <Controller
            name="rentDeposit"
            control={control}
            defaultValue={
              formData["leaseStartDate"] !== null &&
              formData["rentPrice"] !== null &&
              moment(formData["leaseStartDate"]) > moment().subtract(1, "years")
                ? formData["rentPrice"]
                : formData["rentPrice"] !== null && formData["newLease"] === "1"
                ? formData["rentPrice"]
                : ""
            }
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Rent Deposit"
                className={classes.answer}
                {...errorAndHelperText("rentDeposit")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "initialDeposit",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much was the initial rent deposit at the start of{" "}
            <span style={{ whiteSpace: "nowrap" }}>the lease?</span>
          </Typography>
          <Controller
            name="initialDeposit"
            control={control}
            defaultValue={formData["rentPrice"] !== null && formData["newLease"] === "0" ? formData["rentPrice"] : ""}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Initial Rent Deposit"
                className={classes.answer}
                {...errorAndHelperText("initialDeposit")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "interestPaid",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            Have you paid interest on the rent deposit before?
          </Typography>
          <Controller
            name="interestPaid"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("interestPaid")}
              >
                <RadioOption
                  value="1"
                  label="Yes, I have paid interest on the rent deposit"
                  className={classes.answer}
                />
                <RadioOption value="0" label="No, I've never paid interest on the rent deposit" />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "interestStartDate interestEndDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When was the last rental period you paid interest on{" "}
            <span style={{ whiteSpace: "nowrap" }}>the deposit?</span>
          </Typography>
          <div className={`${classes.question} ${classes.dateRange}`}>
            <Controller
              name="interestStartDate"
              control={control}
              defaultValue={
                formData["leaseStartDate"] !== null &&
                moment(formData["leaseStartDate"]).year(moment().format("YYYY")) > moment()
                  ? moment(formData["leaseStartDate"])
                      .year(moment().subtract(1, "years").format("YYYY"))
                      .format("YYYY-MM-DD")
                  : moment(formData["leaseStartDate"]).year(moment().format("YYYY")).format("YYYY-MM-DD")
              }
              rules={{
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              }}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={value ? new Date(value + " 00:00:00") : value}
                    onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                    inputVariant="outlined"
                    label="Period Start Date"
                    format="yyyy-MM-dd"
                    {...(formData["leaseStartDate"] !== null && {
                      minDate: moment(formData["leaseStartDate"]).format("YYYY-MM-DD"),
                      minDateMessage: "The earliest interest start date you can select is on the lease start date",
                    })}
                    clearable
                    InputLabelProps={{ shrink: Boolean(value) }}
                    {...errorAndHelperText("interestStartDate")}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
            <Typography variant="body1" align="center" className={classes.dateRangeSeperator}>
              to
            </Typography>
            <Controller
              name="interestEndDate"
              control={control}
              defaultValue={
                formData["leaseStartDate"] !== null &&
                moment(formData["leaseStartDate"]).year(moment().format("YYYY")) > moment()
                  ? moment(formData["leaseStartDate"]).year(moment().format("YYYY")).format("YYYY-MM-DD")
                  : moment(formData["leaseStartDate"])
                      .year(moment().add(1, "years").format("YYYY"))
                      .subtract(1, "days")
                      .format("YYYY-MM-DD")
              }
              rules={{
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              }}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={value ? new Date(value + " 00:00:00") : value}
                    onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                    inputVariant="outlined"
                    label="Period End Date"
                    format="yyyy-MM-dd"
                    {...(formData["leaseStartDate"] !== null && {
                      minDate: moment(formData["leaseStartDate"]).add(1, "days").format("YYYY-MM-DD"),
                      minDateMessage:
                        "The earliest interest end date you can select is one day after the lease start date",
                    })}
                    clearable
                    InputLabelProps={{ shrink: Boolean(value) }}
                    {...errorAndHelperText("interestEndDate")}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </div>
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "isKeyDeposited",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            {formData?.newLease === "1" ? "Will you" : "Did you"} collect a key deposit?
          </Typography>
          <Controller
            name="isKeyDeposited"
            control={control}
            rules={{
              required: true,
              pattern: /^[10]$/,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioOptions
                className={classes.answer}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={(e) => handleSubmit(onSubmit)()}
                {...errorAndHelperText("isKeyDeposited")}
              >
                <RadioOption
                  value="1"
                  label={`Yes, I ${formData?.newLease === "1" ? "will" : "did"} collect a key deposit`}
                  className={classes.answer}
                />
                <RadioOption
                  value="0"
                  label={`No, I ${formData?.newLease === "1" ? "won't" : "didn't"} collect a key deposit`}
                />
              </RadioOptions>
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "keyDeposit",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            How much
            {formData?.newLease === "1"
              ? " have you collected or will you be collecting as a "
              : " did you collect for the "}
            <span style={{ whiteSpace: "nowrap" }}>key deposit?</span>
          </Typography>
          <Controller
            name="keyDeposit"
            control={control}
            rules={{
              required: true,
              pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Key Deposit"
                className={classes.answer}
                {...errorAndHelperText("keyDeposit")}
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      section: "Deposits",
      fieldNames: "depositDate",
      step: (
        <>
          <Typography variant="h4" align="center" className={classes.question}>
            When did you collect the
            {formData?.newLease === "1" ? " " : " initial "}
            <span style={{ whiteSpace: "nowrap" }}>
              {`${formData?.isDeposited === "1" ? "rent" : ""} ${
                formData?.isDeposited === "1" && formData?.isKeyDeposited === "1" ? " and " : ""
              } ${formData?.isKeyDeposited === "1" ? "key" : ""} deposit?`}
            </span>
            {formData?.newLease === "1" ? (
              <p style={{ color: "lightgray", fontSize: "1.5rem" }}>(Or when do you plan to if you haven't already?)</p>
            ) : (
              ""
            )}
          </Typography>
          <Controller
            name="depositDate"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
              pattern: /^\d{4}-\d{2}-\d{2}$/,
            }}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={value ? new Date(value + " 00:00:00") : value}
                  onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                  inputVariant="outlined"
                  label="Deposit Date"
                  format="yyyy-MM-dd"
                  className={classes.answer}
                  clearable
                  InputLabelProps={{ shrink: Boolean(value) }}
                  {...errorAndHelperText("depositDate")}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!formData) return;
    console.log("formData: ", formData);

    if (formData["service"] && typeof formData["service"] == "object" && formData["service"].includes(null)) {
      let nextValue = formData.service.filter((val) => val !== null);
      console.log("Changing formValue services to: ", nextValue);
      setFormData((prevValue) => {
        return { ...prevValue, service: nextValue };
      });
    }

    if (formData["servicePrice"] && typeof formData.servicePrice == "object" && formData["servicePrice"].includes(null))
      setFormData((prevValue) => {
        return { ...prevValue, servicePrice: formData.servicePrice.filter((val) => val !== null) };
      });

    let _prorateData = 0;
    if (
      formData?.newLease !== "0" &&
      formData?.rentCycle == "Monthly" &&
      "moveInDate" in formData &&
      "leaseStartDate" in formData &&
      "rentCollectionDay" in formData &&
      "rentPrice" in formData
    ) {
      _prorateData = calculateProratedRent(
        formData["leaseStartDate"],
        formData["rentCollectionDay"],
        formData["moveInDate"],
        parseFloat(formData["rentPrice"].substring(1).replace(",", ""))
      );

      setProrateData(_prorateData);
    }

    let skipping = [];

    if (formData?.newLease === "1")
      skipping.push("initialDeposit", "interestPaid", "interestStartDate", "interestEndDate");
    else if (formData["newLease"] !== null)
      skipping.push("autoFillLease", "prorateQuestion", "isKeyDeposited", "keyDeposit");

    if (
      formData?.newLease === "-1" ||
      !latestLease ||
      latestLease?.status == "movedOut" ||
      (latestLease?.status != "movingIn" && latestLease?.moveOutDate && formData?.newLease === "1")
    )
      skipping.push("invalidLease");

    if (formData?.newLease !== "-1") skipping.push("moveOutDate");
    if (formData?.newLease === "-1") skipping.push("isDeposited");
    if (formData?.newLease === "-1" || formData?.isDeposited === "0")
      skipping.push("rentDeposit", "initialDeposit", "interestPaid", "interestStartDate", "interestEndDate");
    if (
      formData?.newLease === "-1" ||
      (formData?.newLease === "0" && formData?.isDeposited === "0") ||
      (formData?.isDeposited === "0" && formData?.isKeyDeposited === "0")
    )
      skipping.push("depositDate");

    if (formData?.autoFillLease === "1") skipping.push("leaseAgreement");
    else if (formData?.newLease !== "1" || formData?.autoFillLease === "0")
      skipping.push(
        "isCondo",
        "agreeToEmail",
        "parkingSpace",
        "giveContactInfo",
        "services",
        "utilities",
        "electricityPayer",
        "heatPayer",
        "waterPayer",
        "insuranceRequired"
      );

    if (formData?.rentCycle === "Daily") skipping.push("moveInDate", "rentCollectionDay");
    if (formData?.rentCycle !== "Monthly" || !(_prorateData?.proratedRent > 0)) skipping.push("prorateQuestion");
    if (formData?.isFixedTerm === "0") skipping.push("fixedTermDate");
    if (formData?.interestPaid === "0") skipping.push("interestStartDate", "interestEndDate");
    if (formData?.isKeyDeposited === "0") skipping.push("keyDeposit");

    setStepsToSkip(skipping);
  }, [formData]);

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
      setErrorReceived(errors);
    }
  };

  const submitForm = async (leaseData, callback = null) => {
    leaseData = {
      ...leaseData,
      tenants: JSON.stringify(tenants),
    };
    leaseData["propertyID"] = props.property["ID"];
    let leaseDataCopy = structuredClone(leaseData);

    for (let dollarValue of ["rentPrice", "rentDeposit", "initialDeposit", "keyDeposit"]) {
      leaseData[dollarValue] = (leaseData[dollarValue] + "").replace("$", "").replace(",", "");
    }
    if ("service" in leaseData)
      for (let [index, value] of leaseData["service"].entries()) {
        if (value == "") leaseData["service"].splice(index, 1);
      }
    if ("servicePrice" in leaseData)
      for (let [index, dollarValue] of leaseData["servicePrice"].entries()) {
        if (dollarValue == "") leaseData["servicePrice"].splice(index, 1);
        else leaseData["servicePrice"][index] = (dollarValue + "").replace("$", "").replace(",", "");
      }
    console.log("leaseData: ", leaseData);

    const leaseAdded = await addLease(leaseData, dispatch, handleErrors);
    if (leaseAdded) {
      addToast("Lease Added Successfully", "success");
      if (callback !== null) callback();

      for (let tenant of tenants) {
        let addressData = tenant.addressData;
        let formattedAddress = `${addressData.streetNum}, ${addressData.streetName}, ${addressData.streetType}, ${addressData.direction}, ${tenant.unitNum}, ${addressData.municipality}, ${addressData.province}, ${addressData.postalCode}`;

        let tenantData = {
          ...tenant,
          leaseID: leaseAdded.ID,
          ...(addressData?.streetNum ? { mailingAddress: formattedAddress } : {}),
        };

        delete tenantData.ID;
        delete tenantData.addressData;

        let tenantAdded = await addTenant(tenantData);
        if (!tenantAdded) {
          addToast(`Adding Tenant Failed`, "error");
          return false;
        }
      }

      await refreshProperty();

      if ("form" in leaseAdded) {
        setLTBFormData(leaseAdded["form"]);
        console.log("leaseAdded['form']", leaseAdded["form"]);
        document.cookie = `formID=${leaseAdded["form"]["ID"]}; expires=${new Date(
          Date.now() + 86400 * 1000
        ).toUTCString()}; path=/;`;
        history.push(`/${property_id}/forms/viewer`);
      } else history.push(`/${property_id}`);

      return true;
    } else {
      if ("servicePrice" in leaseData)
        for (let [index, dollarValue] of leaseDataCopy["servicePrice"].entries())
          leaseData["servicePrice"][index] = dollarValue;
      console.log("servicePrices: ", leaseData.servicePrice);
    }

    addToast("Lease Setup Failed", "error");
    if (callback !== null) callback();
    return false;
  };

  return (
    <>
      <TenantDoubleDialog
        addToast={addToast}
        leaseID={props.property["ID"]}
        tenantFormData={tenantFormData}
        setTenantFormData={setTenantFormData}
        updateTenants={() => {}}
        setTenants={setTenants}
      />
      <MultiStepForm
        formSteps={formSteps}
        stepsToSkip={stepsToSkip}
        formData={formData}
        setFormData={setFormData}
        submitForm={submitForm}
        handleSubmit={handleSubmit}
        setOnSubmit={(func) => (onSubmit = func)}
        errorReceived={errorReceived}
        toasts={toasts}
        formProps={{ onKeyDown: (e) => e.key === "Enter" && e.preventDefault() }}
      />
    </>
  );
}

const mapState = (state) => {
  return {
    building: state.building.building,
    property: state.building.property,
    lease: state.building.lease,
    refreshProperty: state.building.refreshProperty,
  };
};

const mapDispatch = (dispatch) => {
  return {
    dispatch: (data) => dispatch(data),
    setLTBFormData: (data) => dispatch(setLTBFormData(data)),
  };
};

export default connect(mapState, mapDispatch)(LeaseSetup);
