import React, { useEffect, useState } from "react";
import ScrollReset from "./ScrollReset";
import AppRoute from "./AppRoute";

import { Provider } from "react-redux";
import configureStore from "./redux/store";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import PasswordPage from "./pages/passwordPage";
import Verfication from "./pages/passwordPage/Verfication";
import authenticationRoutes from "./pages/authentication";
import appRoutes from "./pages/app";
import LandingPage from "./pages/landingPage";

import axios from "axios";
import setAxiosDefaultSettings from "./utils/axiosSettings.js";

setAxiosDefaultSettings(axios);
const store = configureStore();

let verified = false;
function App() {
  const [token, setToken] = useState(() => {
    let _token = getCookieValue("token");
    if (_token) return _token;
    else return null;
  });

  const [verficationCheck, setVerficationCheck] = useState(1);

  useEffect(() => {
    if (!token) return;

    axios
      .get("/v2/users/verification/", { params: { token: token } })
      .then((response) => {
        if (response.data && response.data?.success) {
          verified = true;
          setVerficationCheck((_prev) => _prev + 1);
        } else {
          verified = false;
          setVerficationCheck((_prev) => _prev + 1);
        }
      })
      .catch(() => {
        verified = false;
        setVerficationCheck((_prev) => _prev + 1);
      });
  }, [token]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollReset />
        {verified && verficationCheck > 0 ? (
          <Switch>
            <Route path="/authentication" component={authenticationRoutes} />
            <Route exact path="/" component={LandingPage} />
            <AppRoute path="/" component={appRoutes} />
          </Switch>
        ) : (
          <Switch>
            <Verfication path="/verification" setToken={setToken} />
            <PasswordPage path="/" />
          </Switch>
        )}
      </BrowserRouter>
    </Provider>
  );
}

const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export default App;
