import React, { useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { editLease } from "../../../../services/property/leaseServices.js";
import MoneyInput from "../../../components/MoneyInput";
import RadioOption from "../../../components/RadioOption";
import RadioOptions from "../../../components/RadioOptions";
import Loading from "../../../components/Loading";

const errorMessages = {
  rentPrice: {
    required: "Please enter the rent price",
    pattern: "The rent price must be a positive number and can't have more than 2 decimals",
  },
  rentCycle: {
    required: "Please select how often you are charging rent",
    pattern: "Please select how often you are charging rent",
  },
  leaseStartDate: {
    required: "Please select the date that the lease will start",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  rentCollectionDay: {
    required: "Please select the rent collection day",
    pattern: "Please select a rent collection day from the dropdown menu",
  },
  moveInDate: {
    required: "Please select the date the tenants will be moving in",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  moveOutDate: {
    required: "Please select the date the tenants will be moving out",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  lastRentIncreaseDate: {
    required: "Please select the date that you last increased the rent price",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  NSFFee: {
    maxFee: "The Landlord Tenant Board does not allow landlords to charge an NSF admin fee greater than $20",
    pattern: "The NSF admin fee must be a positive number and can't have more than 2 decimals",
  },
  fixedTermDate: {
    required: "Please select the date that the fixed term will end",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  rentDeposit: {
    required: "Please enter the amount for the rent deposit",
    pattern: "The rent deposit must be a positive number and can't have more than 2 decimals",
  },
  keyDeposit: {
    required: "Please enter the amount for the key deposit",
    pattern: "The key deposit must be a positive number and can't have more than 2 decimals",
  },
  depositDate: {
    required: "Please select the date of the rent deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  initialDeposit: {
    required: "Please enter the amount for the initial rent deposit",
    pattern: "The initial deposit must be a positive number and can't have more than 2 decimals",
  },
  interestStartDate: {
    required: "Please select the start-date of the last rental period you paid interest on the deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
  interestEndDate: {
    required: "Please select the end-date of the last rental period you paid interest on the deposit",
    pattern: "The date must be in the correct format, e.g. 12/01/2021",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0, 6),
    [`${theme.breakpoints.down("xs")}`]: {
      minHeight: "48vh",
      margin: theme.spacing(0, 0, 3),
    },
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      margin: theme.spacing(3, 0, 3),
    },
  },
  radio: {
    marginBottom: theme.spacing(4),
  },
  lastRadio: {
    marginBottom: theme.spacing(1.5),
  },
  rentCycleLabel: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "0.9em",
  },
  dateRange: {
    display: "flex",
    [`${theme.breakpoints.down("xs")}`]: {
      flexDirection: "column",
    },
  },
  dateRangeSeperator: {
    fontSize: "1.4em",
    padding: theme.spacing(1.5, 2.5, 0),
    [`${theme.breakpoints.down("xs")}`]: {
      fontSize: "1.2em",
      padding: theme.spacing(1.5, 0, 1.5),
    },
  },
}));

function EditLeaseForm(props) {
  const {
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const handleErrors = (errors) => {
    if (typeof errors === "string") {
      props.addToast(errors, "error");
    } else if (typeof errors === "object") {
      let field;
      for (field in errors) {
        setError(field, {
          type: errors[field],
        });
      }
    }
  };

  const onSubmit = async (leaseData) => {
    let confirmCallback = async () => {
      leaseData = {
        ...leaseData,
        ID: props.leaseID,
      };
      if (leaseData.rentCollectionDay?.length === 1) leaseData.rentCollectionDay = "0" + leaseData.rentCollectionDay;
      else if (leaseData.rentPrice) leaseData.rentPrice = leaseData.rentPrice.replace("$", "").replaceAll(",", "");
      else if (leaseData.NSFFee) leaseData.NSFFee = leaseData.NSFFee.replace("$", "").replaceAll(",", "");
      else if (leaseData.rentDeposit)
        leaseData.rentDeposit = leaseData.rentDeposit.replace("$", "").replaceAll(",", "");
      else if (leaseData.initialDeposit)
        leaseData.initialDeposit = leaseData.initialDeposit.replace("$", "").replaceAll(",", "");
      else if (leaseData.keyDeposit) leaseData.keyDeposit = leaseData.keyDeposit.replace("$", "").replaceAll(",", "");
      console.log(leaseData);

      const leaseEdited = await editLease(leaseData, props.dispatch, handleErrors);
      if (leaseEdited) return true;

      props.addToast("Updating Lease Failed", "error");
      return false;
    };

    props.setConfirmationCallback(confirmCallback);
  };

  const classes = useStyles();

  const rentPriceForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="rentPrice"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="rentPrice"
              label="Rent Price"
              error={errors.rentPrice && errors.rentPrice !== null}
              helperText={errors.rentPrice ? errorMessages["rentPrice"][errors.rentPrice.type] : null}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const leaseStartDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="leaseStartDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Lease Start Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.leaseStartDate && errors.leaseStartDate !== null}
                helperText={errors.leaseStartDate ? errorMessages["leaseStartDate"][errors.leaseStartDate.type] : null}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const watchRentCycle = watch("rentCycle", "");
  const rentCollectionDayForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="rentCycle"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^Monthly$|^Weekly$|^Daily$/,
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel className={classes.rentCycleLabel}>Rent Cycle</InputLabel>
              <RadioOptions
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onMouseUp={() => setValue("rentCollectionDay", "")}
                error={errors.rentCycle && errors.rentCycle !== null}
                helperText={errors.rentCycle ? errorMessages["rentCycle"][errors.rentCycle.type] : null}
              >
                <RadioOption value="Monthly" label="Monthly" className={classes.radio} />
                <RadioOption value="Weekly" label="Weekly" className={classes.radio} />
                <RadioOption value="Daily" label="Daily" className={classes.lastRadio} />
              </RadioOptions>
            </>
          )}
        />
      </Grid>
      {watchRentCycle !== "Daily" && (
        <Grid item xs={12}>
          <Controller
            name="rentCollectionDay"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern:
                watchRentCycle === "Weekly"
                  ? /^Monday$|^Tuesday$|^Wednesday$|^Thursday$|^Friday$|^Saturday$|^Sunday$/
                  : /^\d$|^1\d$|^2\d$/,
            }}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
                error={errors.rentCollectionDay && errors.rentCollectionDay !== null}
              >
                <InputLabel id="rentCollectionDayLabel">Rent Collection Day</InputLabel>
                <Select
                  {...field}
                  id="rentCollectionDay"
                  labelId="rentCollectionDayLabel"
                  label="Rent Collection Day"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {watchRentCycle === "Weekly"
                    ? ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => {
                        return (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        );
                      })
                    : Array.from({ length: 29 }, (_, idx) => `${++idx}`).map((index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {index}
                          </MenuItem>
                        );
                      })}
                </Select>
                {errors.rentCollectionDay && (
                  <FormHelperText>{errorMessages["rentCollectionDay"][errors.rentCollectionDay.type]}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      )}
    </>
  );

  const moveInDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="moveInDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Move-In Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.moveInDate && errors.moveInDate !== null}
                helperText={errors.moveInDate ? errorMessages["moveInDate"][errors.moveInDate.type] : null}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const moveOutDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="moveOutDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Move-Out Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.moveOutDate && errors.moveOutDate !== null}
                helperText={errors.moveOutDate ? errorMessages["moveOutDate"][errors.moveOutDate.type] : null}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const NSFFeeForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="NSFFee"
          control={control}
          rules={{
            pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
            validate: {
              maxFee: (value) => !value || value.replace("$", "") <= 20,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              id="NSFFee"
              label="NSF Admin Fee"
              error={errors.NSFFee && errors.NSFFee !== null}
              helperText={errors.NSFFee ? errorMessages["NSFFee"][errors.NSFFee.type] : null}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const lastRentIncreaseDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="lastRentIncreaseDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Last Rent Increase Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.lastRentIncreaseDate && errors.lastRentIncreaseDate !== null}
                helperText={
                  errors.lastRentIncreaseDate
                    ? errorMessages["lastRentIncreaseDate"][errors.lastRentIncreaseDate.type]
                    : null
                }
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const fixedTermDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="fixedTermDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Fixed-Term End Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.fixedTermDate && errors.fixedTermDate !== null}
                helperText={errors.fixedTermDate ? errorMessages["fixedTermDate"][errors.fixedTermDate.type] : null}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const rentDepositForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="rentDeposit"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="rentDeposit"
              label="Rent Currently On Deposit"
              error={errors.rentDeposit && errors.rentDeposit !== null}
              helperText={errors.rentDeposit ? errorMessages["rentDeposit"][errors.rentDeposit.type] : null}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const depositDateForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="depositDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Rent Deposit Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.depositDate && errors.depositDate !== null}
                helperText={errors.depositDate ? errorMessages["depositDate"][errors.depositDate.type] : null}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const initialDepositForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="initialDeposit"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="initialDeposit"
              label="Initial Rent Deposit"
              error={errors.initialDeposit && errors.initialDeposit !== null}
              helperText={errors.initialDeposit ? errorMessages["initialDeposit"][errors.initialDeposit.type] : null}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  const lastInterestPeriodForm = (
    <>
      <Grid item xs={12} className={classes.dateRange}>
        <Controller
          name="interestStartDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Period Start Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.interestStartDate && errors.interestStartDate !== null}
                helperText={
                  errors.interestStartDate ? errorMessages["interestStartDate"][errors.interestStartDate.type] : null
                }
              />
            </MuiPickersUtilsProvider>
          )}
        />
        <Typography variant="body1" align="center" className={classes.dateRangeSeperator}>
          to
        </Typography>
        <Controller
          name="interestEndDate"
          control={control}
          defaultValue={null}
          rules={{
            required: true,
            pattern: /^\d{4}-\d{2}-\d{2}$/,
          }}
          render={({ field: { onChange, value } }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value ? new Date(value + " 00:00:00") : value}
                onChange={(date) => onChange(date ? format(date, "yyyy-MM-dd") : null)}
                inputVariant="outlined"
                label="Period End Date"
                format="yyyy-MM-dd"
                fullWidth
                clearable
                InputLabelProps={{ shrink: Boolean(value) }}
                error={errors.interestEndDate && errors.interestEndDate !== null}
                helperText={
                  errors.interestEndDate ? errorMessages["interestEndDate"][errors.interestEndDate.type] : null
                }
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
    </>
  );

  const keyDepositForm = (
    <>
      <Grid item xs={12}>
        <Controller
          name="keyDeposit"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: /^\$(((\d{1,3},){0,3}\d{3})|\d{1,3})(\.\d{1,2})?$/,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              id="keyDeposit"
              label="Key Deposit"
              error={errors.keyDeposit && errors.keyDeposit !== null}
              helperText={errors.keyDeposit ? errorMessages["keyDeposit"][errors.keyDeposit.type] : null}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          )}
        />
      </Grid>
    </>
  );

  useEffect(() => {
    let field;
    for (field in props.fields) {
      if (props.fields[field]) {
        if (
          field === "rentPrice" ||
          field === "NSFFee" ||
          field === "rentDeposit" ||
          field === "initialDeposit" ||
          field === "keyDeposit"
        )
          props.fields[field] = props.fields[field]?.replaceAll("$", "");
        setValue(field, props.fields[field]);
      }
    }
  }, []);

  const forms = {
    rentPrice: rentPriceForm,
    leaseStartDate: leaseStartDateForm,
    rentCollectionDay: rentCollectionDayForm,
    moveInDate: moveInDateForm,
    moveOutDate: moveOutDateForm,
    NSFFee: NSFFeeForm,
    lastRentIncreaseDate: lastRentIncreaseDateForm,
    fixedTermDate: fixedTermDateForm,
    rentDeposit: rentDepositForm,
    depositDate: depositDateForm,
    initialDeposit: initialDepositForm,
    lastInterestPeriod: lastInterestPeriodForm,
    keyDeposit: keyDepositForm,
    // leaseAgreement: leaseAgreementForm,
  };

  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={classes.main}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {forms[props.form]}

          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default EditLeaseForm;
